export default {
	crypto: {
		title: {
			0: "Buy Crypto",
			1: "Buy Crypto",
			2: "C2C",
		},
		list: {
			0: "C2C Trading",
			1: "Buy",
			2: "Sell",
			3: "Advertisers (Completion rate)",
			4: "Trades",
			5: "Price",
			6: "Available",
			7: "Limit",
			8: "Payment Method",
			9: "Trade",
			10: "The advertiser may place transaction restrictions on the ad, and you will see the reason for the restriction after logging in.",
		},
		buy: {
			0: "Buy Crypto",
			1: "Sell Crypto",
			2: "History",
			3: "Support",
			4: "Pay",
			5: "Receive",
			6: "Select payment method",
			7: "Est. arrival time",
			8: "FEE",
			9: "Order Details",
			10: "Payment method",
			11: "Learn more",
			12: "Price",
			13: "Total (fee included)",
			14: "Disclaimer",
			15: "You are leaving HKDFinanc and will be redirected to Mercuryo, a licensed financial institution that provides credit/debit card payment service as a third party. Any risk that occurs during the use of the service shall be borne by Mercuryo. Please read and agree to the Terms of Use before proceeding.",
			16: "I have read and agree to the Terms of Use",
			17: "You will get",
			18: "Submit",
			19: "Please enter the amount",
			20: "Insufficient account balance",
			21: "Available",
			22: "Spot Account Availiable",
			23: "Single purchase limit",
			24: "Minimum",
			25: "Maximum",
		}
	},
	common: {
		confirm: "Define",
		cancel: "Peruutukset",
		tip: "Kiinnittää huomiota johonkin.",
		place_select: "Valitse",
		submit: "Toimittaa (raportti jne.)",
		more: "Lisää",
		deposit: "Talletus",
		real_trade: "Real Deal",
		paper_trade: "Demo kaupankäynti",
		build_paperTrade: "Luo demo-tili",
		start_trade: "Aloita kaupankäynti nyt",
		app_name: "HKDFINANC",
		success: "Onnistumiset！",
		dataSources_name: "Trading Central",
		reset: "Uudelleentarkastelu",
		iknow: "Sain sen!",
		noData: "Tietoja ei ole saatavilla",
		modify: "Muutokset",
		pingcang: "Sulkea asema",
		all: "Full",
		placeEnter: "Ole hyvä ja kirjoita",
		countryCode: "Maan/alueen koodi",
	},
	currency: {
		btcusdt: "Bitcoin ",
		ethusdt: "Etherlands",
		ltcusdt: "Litecoin (virtuaalivaluutta)",
		xrpusdt: "Ripple (valuutta)",
		adausdt: "Ada-valuutta",
		eosusdt: "Pomelo kolikko",
		dotusdt: "Boca Raton (entinen Boca Raton), Venäjän valuutta.",
		trxusdt: "Wave farmland coin (laskenta)",
		xmrusdt: "Monroe-kolikko (esim. Yhdysvaltain dollari)",
		dogeusdt: "koiran kolikko",
		bnbusdt: "BNB",
		solusdt: "SOL",
		aptusdt: "Aptusdt",
		fx_saudusd: "AUD USD",
		fx_sgbpusd: "GBP USD",
		fx_seurusd: "Eurodollar",
		fx_snzdusd: "Uuden-Seelannin dollari (NZD) USD",
		fx_susdcad: "USD CAD",
		fx_susdchf: "USD Sveitsin frangi",
		fx_susdjpy: "USD ja JPY",
		fx_saudcad: "AUD CAD",
		fx_saudchf: "AUD Sveitsin frangi",
		fx_saudjpy: "AUD JPY",
		fx_saudnzd: "AUD Uuden-Seelannin dollari (NZD)",
		fx_scadchf: "CAD Sveitsin frangi",
		fx_scadjpy: "Kanadan dollari Japanin jeni",
		fx_schfjpy: "Sveitsin frangi jeni",
		fx_seuraud: "Euro AUD",
		fx_seurcad: "Euro CAD",
		fx_seurgbp: "Euro GBP",
		fx_seurjpy: "Euro ja Japanin jeni",
		fx_seurnzd: "Euro Uuden-Seelannin dollari (NZD)",
		fx_sgbpaud: "GBP AUD",
		fx_sgbpchf: "GBP Sveitsin frangi",
		fx_sgbpjpy: "GBP JPY",
		fx_sgbpnzd: "GBP NZD",
		fx_scadnzd: "Kanadan dollari Uuden-Seelannin dollari",
		hf_CL: "WTI New York raakaöljy",
		hf_OIL: "Brent-raakaöljy",
		hf_GC: "New Yorkin kulta",
		hf_SI: "New York Silver",
		hf_HG: "Kupari (lainasana)",
		hf_NG: "Yhdysvallat Maakaasu",
		hf_CAD: "Kupari (lainasana)",
		hf_AHD: "Lontoon alumiini",
		HX_AAPL: "Granaattiomena",
		HX_BABA: "Ali Baba, hahmo Arabian öistä",
		HX_GOOG: "Google",
		HX_WMT: "Wal-Mart, Walmart (vähittäismyyjä)",
		HX_AMZN: "HX_AMZN",
		HX_TSLA: "Tesla",
		HX_N225: "Nikkei 225",
		HX_GDAXI: "DAX30, Saksa",
		HX_IBEX: "IBEX35, Espanja",
		HX_SPX: "S&P 500 -indeksi",
		HX_FTSE: "FTSE 100",
		HX_KS11: "KOSPI Korea",
		HX_NZ50: "Uusi-Seelanti 50",
		HX_PSI20: "Portugali PSI20",
		hf_ZSD: "Lontoon sinkki",
		hf_NID: "Lontoon nikkeli",
		hf_PBD: "Lontoon johto",
		hf_SND: "Lontoon tina",
		hf_XAU: "Lontoon kulta (Spot Gold)",
		hf_XAG: "Lontoon hopea (spot hopea)",
		hf_XPT: "Platinan futuurit",
		hf_XPD: "Palladium futuurit",
		hf_CT: "Yhdysvaltain puuvilla",
		hf_SM: "Soijapapujauhe",
		hf_BO: "Yhdysvaltain soijaöljy",
		HX_SENSEX: "SENSEX, Mumbai, Intia",
		HX_KLSE: "FTSE Malaysia KLCI",
		HX_KSE100: "Karachi, Pakistan",
		HX_FCHI: "CAC40, Ranska",
		HX_SX5E: "Euroopan snooker 50",
		HX_ICEXI: "ICEX, Islanti",
		HX_ASE: "ASE Ateena, Kreikka",
		HX_OMXC20: "OMX Kööpenhamina 20",
		HX_OSEBX: "OSEBX, Norja",
		HX_OMXSPI: "OMXSPI, Ruotsi",
		HX_AMD: "Chaowei Semiconductor",
		HX_DIS: "Disney (nimi)",
		HX_MELI: "MercadoLibre Inc",
		HX_PSX: "Phillips 66",
		HX_TRUE: "TrueCar Inc",
		HX_SDRL: "Seadrill Ltd",
		HX_MA: "MasterCard (tuotemerkki)",
		HX_NVDA: "NVIDIA",
		HX_INTC: "Intel",
		HX_GOGO: "Gogo Inc",
		HX_STX: "Seagate Technology",
		usdcusdt: "USDC",
		filusdt: "FIL",
		maticusdt: "MATIC",
		linkusdt: "LINK",
		avaxusdt: "AVAX",
		atomusdt: "AstroCoin",
		icpusdt: "ICP",
		uniusdt: "UNI",
	},
	addnew: {
		paypass: "Kaupankäynnin salasana",
		loginpassTit: "Vaihda salasana",
		paypassTit: "Muokkaa pankkiautomaatin salasanaa",
		paypassTit2: "Sosiaalinen salasana on aluksi kirjautumissalasana.",
		buyUp: "Kohoava",
		buyDown: "Ostaa ja myydä",
		addressName1: "China Gold Financial (International) Holdings Limited",
		addressValue1: "Huone 1101, 11. kerros, Koon Kwan Mansion (entinen Citibank Tower), 3 Garden Road, Central, Hongkong.",
		addressName2: "Hongkong Zhong Cai Financial Investment Company Limited",
		addressValue2: "Zhongcai Centre, 131-133 Queen's Road Central, keskus, Hongkong, Hongkong",
		addressName3: "Po Sun Financial Holdings Limited",
		addressValue3: "Huone 1908, 19. kerros, 89 Queensway, Admiralty, Hongkong Lippo Centre, torni 2",
		loan: "Laina",
		repaid: "Maksettu takaisin",
        unpaid: "Maksamattomat",
		loanMore: "Haluan lainan.",
		immediateRepayment: "Välitön takaisinmaksu",
		accountType1: "Kolikkotili",
		accountType2: "Sopimustili",
		accountType3: "Optiotili",
		from: "Alkaen",
        to: "Kunnes",
		get: "Saa",
		labelBreed: "Valuuttatyyppi",
		placeBreed: "Valitse valuutta",
		labelTransAccount: "Siirtotili",
		labelAmount: "Siirretty määrä",
		placeAmount: "Kirjoita siirtosumma",
		transferTit:"Tilisiirrot",
		convertTit:"Kolikon flash vaihto",
		balanceTit:"Tilin saldo",
		available: "Käytettävissä oleva luotto",
        pending: "Lukitus ",
        equivalent: "Määrä on",
		coinTrade: "Kolikkokauppa",
		secondContract: "Toinen sopimus (urheilussa)",
		number: "Määrät",
		labelNumber: "Määrät",
		placeNumber: "Syötä lunnaiden määrä",
		time: "Kertaa",
		loadAmount: "Odotettu lainamäärä",
		repaymentCycle: "Lainan takaisinmaksusykli",
		dailyRate: "Päivittäinen korko",
		repaymentMethod: "Takaisinmaksutapa",
		loanTip: "Luottolainat (varmista, että kuvat näkyvät selvästi)",
		loanTip1: "Lataa kiinteistötodistus",
        loanTip2: "Todiste tuloista (työsuhde)",
        loanTip3: "Pankkitiliotteen tiedot",
        loanTip4: "Lataa henkilökorttisi etukuva",
        interest: "Kiinnostus ",
		repaymentMethod1: "Erääntyy maksettavaksi takaisin yhdessä erässä",
	},
	params: {
		product_foreign: "Valuutanvaihto",
		product_shop: "Kauppatavara",
		product_number: "Eksponentit",
		product_stock: "Varasto (markkinat)",
		product_coin: "Salattu",
		billTypes: [
			{v: 101, name: 'Lataus (rahaa kortille)'},
			{v: 102, name: 'Talletus '},
			{v: 103, name: 'Jäädyttäminen'},
			{v: 104, name: 'Sulata'},
			{v: 105, name: 'Talletus  '},
			{v: 106, name: 'Talletus  '},
			{v: 201, name: 'Nostojen jäädyttäminen'},
			{v: 202, name: 'Nostaa varoja'},
			{v: 203, name: 'Peruuttaminen onnistui'},
			{v: 204, name: 'Jättävät nostamatta varoja'},
			{v: 205, name: 'Peruuttamismaksu'},
			{v: 206, name: 'Siirtää pois'},
			{v: 207, name: 'Siirtyä'},
			{v: 208, name: 'Kolikon siirto'},
			{v: 209, name: 'Kolikon siirto'},
			{v: 301, name: 'Sopimuksen käsittelymaksu'},
			{v: 302, name: 'Sopimustulot'},
			{v: 303, name: 'Sopimustappio'},
			{v: 304, name: 'Sopimusmarginaali'},
			{v: 305, name: 'Sopimusmarginaalin tuotto'},
			{v: 311, name: 'Optioiden osto'},
			{v: 312, name: 'Optiovoitto'},
			{v: 313, name: 'Vaihtoehto Palaa'},
			{v: 314, name: 'Option käsittelymaksu'},
			{v: 315, name: 'Kolikkojen oston jäädyttäminen'},
			{v: 316, name: 'Kolikko-ostovähennys'},
			{v: 317, name: 'Coin Buy Palautukset'},
			{v: 318, name: 'Kolikon sisäänosto tilille'},
			{v: 319, name: 'Kolikon sisäänosto tilille'},
			{v: 320, name: 'Kolikon myynnin jäädyttäminen'},
			{v: 321, name: 'Kolikoiden myynnin vähennys'},
			{v: 322, name: 'Kolikon myyntipalautukset'},
			{v: 323, name: 'Kolikon myynti tilille'},
			{v: 324, name: 'Kolikon myynti tilille'},
			{v: 325, name: 'Kolikon käsittelymaksu'},
			{v: 401, name: 'Kaivosmiehet liittyvät'},
			{v: 402, name: 'Miner Returns'},
			{v: 403, name: 'Kaivoskoneista saatavat tulot'},
			{v: 404, name: 'Kaivoskoneiden poistuminen'},
			{v: 405, name: 'Kaivostoiminnan lopettamismaksu'},
			{v: 411, name: 'Lainatulot'},
			{v: 412, name: 'Lainausmaksu'},
			{v: 413, name: 'Onnistunut takaisinmaksu'},
        ],
	},
	message: {
		logout: "Kirjaudu ulos",
		register_success: "Onnistunut rekisteröinti",
		place_inner_personal: "Anna ensin henkilökohtaiset tietosi",
		submit_success: "Toimitettu onnistuneesti！",
		copy_success: "Kopioi menestys！",
		copy_error: "Lisääntymisen epäonnistuminen！",
		modify_success: "Muutettu onnistuneesti",
		no_balance: "Riittämätön saldo vakuuden kattamiseksi",
		palce_password: "Anna salasanasi",
		trade_success: "Onnistunut tapahtuma",
		placeEnterAmount: "Kirjoita summa",
		tipTit1: "Haluatko varmasti maksaa tämän tilauksen?",
	},
	web: {
		account: {
			menu1: "Tilin tiedot",
			menu2: "Omaisuuteni",
			menu3: "Tiliraportit",
			menu4: "Tilin turvallisuus",
			menu5: "Ilmoitus",
			menu6: "Toimintakeskus",
			menu7: "Lompakkoni.",
			menu8: "Vetäytyminen",
			walletAdd: "Lisää uusi lompakko",
			report: {
				history_tit: "Tapahtumahistorian raportti",
				stream_tit: "Rahavirtaraportti",
				desc: "Valitse sopiva päivämääräväli tiliraportin vientiä varten. (Pääset tarkastelemaan tilitietoja viimeisen 1 vuoden ajalta).",
				select_date: "Valitse päivämääräalue",
				week: "Viime viikolla",
				month: "Noin kuukausi",
				threeMonth: "Viimeiset kolme kuukautta",
				sixMonth: "Viimeiset kuusi kuukautta",
				time: "Kertaa",
				view_report: "Näytä raportti",
				email_send: "Lähetetty sähköpostitse",
				dialog_tit: "Tiliraportit",
				dialog_p1: "HKDFINANC Holding Ltd on rekisteröity Caymansaarilla (SIB-numero: 1612446), ja se käsittelee tapahtumasi kauppayhtiönä.",
				tit2: "Mitade kaupankäyntihistorian raportti",
				tit4: "Mitaden rahavirtaraportti",
				tit3: "Tilin tiedot",
				label1: "Tilin nimi",
				label2: "Tilin tunnus",
				label3: "Tilin valuutta",
				label4: "Raportointiaika",
				label5: "Raportointijakso",
				label6: "Kassavirta",
				empty: "Sinulla ei ole suljettuja kauppoja",
				email_des: "Raportti lähetetään sähköpostiosoitteeseesi",
				tab1: "Raportointi",
				tab2: "Päivittäinen selvitys",
				tab3: "Kuukausittainen tiliote",
			},
			account: {
				tit1: "Tervetuloa HKDFINANCiin",
				tit2: "Täydellinen henkilöllisyyden todentaminen aidoilla talletuksilla käytävää kauppaa varten",
				p1: "Avaa live-kaupankäyntitili",
				p2: "Todennus",
				btn1: "Todennus",
				tit3: "Henkilökohtaiset tiedot",
				label_name: "Etu- ja sukunimi",
				label_email: "Sähköpostiosoite",
				label_address: "Kotiosoite",
				tit4: "Tilin tiedot",
				label_account: "Kauppatili (todellinen liiketoimi)",
				label_currency: "Perusvaluutta (reaalitransaktiot)",
				label_company: "Käyttötili Yritys",
				company_tip: "HKDFINANC Holding Ltd,HKDFINANC Holding Ltd HKDFINANC Group of Companies on Caymansaarten rahaviranomaisen (CIMA) myöntämä toimilupa.",
				tit5: "Avaa live-kaupankäyntitili",
				tit6: "Tiedottamisen parantaminen",
				tit7: "Täytä perustietosi",
				tit8: "Riskinarviointi",
				tit9: "Kaupankäynnin riskinsietokyvyn arviointi",
				tit10: "Todennus",
				tit11: "Henkilöllisyyden todentaminen sääntelyvaatimusten mukaisesti.",
				p3: "Valitse jokin seuraavista tositteista tarkistusta varten",
				type1: "Henkilökortit",
				type2: "Ajokortti",
				type3: "Passi",
				p4: "Vahvistan, että kaikki annetut tiedot ja asiakirjat ovat täydellisiä, totuudenmukaisia ja tarkkoja, ja sitoudun toimittamaan päivitetyt tiedot HKDFINANCille 30 päivän kuluessa päivityksen tapahtuessa.",
				next: "Seuraava askel",
				btn2: "Hyväksytty akkreditointi",
				modify_personal_tit: "Henkilötietojen muuttaminen",
			},
			activity: {
				tit: "Toimintakeskus",
				desc: "Voit osallistua aktiviteetteihin saadaksesi palkintoja, jotka parantavat sijoitustulojasi.",
			},
			capital: {
				real_trade: "Real Deal",
				label_nowAccount: "Vaihtotase",
				label_tradeAccount: "Kaupankäyntitilin numero",
				label_currency: "Perusvaluutta",
				deposit_withdraw_record: "Talletus- ja nostotiedot",
				capital_flow_details: "Varojen virtausta koskevat tiedot",
			},
			notification: {
				tit: "Ilmoitusasetukset",
				tit1: "Avoimet ilmoituskanavat",
				email: "Sähköpostiosoite",
				message: "Tekstiviestit",
				push: "Työnnä",
				verify: "Validoida (teoria)",
				verified: "Validoitu",
				p1: "Avaa/vahvista seuraavat ilmoituskanavat, jotta pysyt kärryillä tärkeistä uutisista!",
				type1: "Markkinapaikka",
				tit2: "Markkinointitoimista ilmoittaminen",
				desc2: "Alustan erikoistarjoustiedot, toiminta ja muut uutiset",
				tit3: "Käyttöohjeet",
				desc3: "Runsas ja erikoistunut tietomateriaali",
			},
			security: {
				tit: "Salaus",
				isSet: "Konfiguroitu",
				set: "Mene eteenpäin ja aseta",
				verify: "Validoida (teoria)",
				verified: "Validoitu",
				tit1: "Turvallisuustodistus",
				email: "Sähköpostiosoite",
				phone: "Matkapuhelinnumero",
				phone_tit: "Matkapuhelinnumero Sitova",
				password_tit: "Vaihda salasanasi",
			},
		},
		market: {
			hot: "Muodissa",
			prev: "Edellinen sivu",
			next: "Seuraava sivu",
			line: "Ajankäytön jakaminen",
			main_subplot: "Pää- ja sivukaavion indikaattorit",
			main: "Tärkein kaavion indikaattori",
			subplot: "Kaavioindikaattori",
			go_trade: "Siirry tapahtumasivulle",
		},
		news: {
			tip: "Vastuuvapauslauseke: Yllä olevat tiedot ovat vain viitteellisiä yleistietoja, eikä niitä tule käyttää kaupankäyntipäätösten perustana.",
		},
		trade: {
			state1: "Tilat",
			state2: "Vireillä oleva tilaus",
			state3: "Historiat",
			state: "Asiaintila",
			state4: "Suljettu asento",
			state5: "Peruutettu",
			type: "Typologia",
			type1: "Full",
			type2: "Reach (hinta huutokaupassa)",
			type3: "Buy-in",
			left_label1: "Itsepalvelu",
			left_label2: "Äskettäin katsottu",
			left_type1: "Kompakti pylväsmalli",
			left_type2: "Väljä pylväskuvio",
			left_type3: "Näytä kaavio",
			all: "Full",
			right_bp: "Eilinen sato",
			right_sp: "Nykypäivä",
			right_height: "Ylin",
			right_low: "Alhaisin",
			remind_tit: "Uudet hälytykset",
			remindList: "Muistutusluettelo",
			remind_btn: "Uudet hälytykset",
			right_tab1: "Liiketoimet",
			right_tab2: "Tiedot",
			right_sell: "Reach (hinta huutokaupassa)",
			right_buy: "Buy-in",
			right_sell1: "Reach (hinta huutokaupassa)",
			right_buy1: "Buy-in",
			right_type: "Typologia",
			right_type1: "Markkinahinnasto",
			right_type2: "Vireillä oleva tilaus",
			right_priceMoney: "Vireillä olevan toimeksiannon hinta",
			right_pointerNumber: "Määrä (erät)",
			right_lever: "Piikkaustanko",
			right_balance: "käytettävissä oleva saldo",
			right_stop_profit: "Ottaa voittoa",
			right_stop_loss: "Stop-loss",
			right_profit_loss: "Nouseva ja laskeva",
			now_order: "Tilaa nyt",
			confirm_order: "Vahvista tilaus",
			right_tit1: "Kaupankäyntistrategia",
			right_rinei: "Muutaman päivän kuluttua",
			right_short: "Lyhytaikainen",
			right_middle: "Puolivälissä",
			right_time: "Vapautumisaika",
			right_tit2: "Vaihtoehtoinen strategia",
			right_tit3: "Tekninen katsaus",
			right_tit4: "Numerot",
			right_tit5: "Kaupankäynnin mieliala",
			right_label_sell: "Myyjä (tavaroiden)",
			right_label_buy: "Ostaja",
			right_tip: "Ainoastaan tiedoksi, ei jaoston kanta.",
			right_tip2: "Päivitetään 15 minuutin välein",
			right_tit6: "Hinnanvaihtelu",
			minute: "Minuuttia",
			today: "Tällä hetkellä",
			right_tit7: "Hinnanmuutosalue",
			now_price: "Nykyhinta",
			right_low_price: "Alhaisin hinta",
			right_height_price: "Korkein hinta",
			right_tit8: "Sopimusta koskevat tiedot",
			right_tit9: "AUD/CHF",
			right_label1: "Yksittäisten tapahtumien määrä",
			right_label2: "Suurin vipuvaikutus",
			right_label3: "Sopimuksen koko",
			right_label4: "Työpaikkojen enimmäismäärä yhteensä",
			right_label5: "Kelluva spread",
			right_label6: "Yön yli lataus",
			hand: "Käsi",
			right_collection_time: "Keräysaika",
			right_tip3: "Maksuperuste: liiketoimen kokonaiskoon perusteella",
			right_label7: "Marginaalisuhde",
			right_label8: "Ylläpitokateprosentti",
			right_label9: "Kaupankäynnin aukioloajat",
			right_label10: "Nykyinen kaupankäyntijakso",
			right_label11: "Seuraava kaupankäyntijakso",


		},
	},
	home: {
		banner_p1: "",
		banner_p2: "Alustatapahtuma",
		banner_p3: "Forex, hyödykkeet, indeksit, osakkeet, osakkeet",
		banner_p4: "Ja suositummat rahoitustuotteet",
		banner_p5: "Voit tutustua maailman kuumimpiin rahoitusmarkkinoihin. ",
		hot: "Muodissa",
		tit1: "Ystävällisemmät liiketoimet",
		p1: "Yksinkertainen ja intuitiivinen käyttöliittymä helpottaa käyttöä",
		p2: "Web-, mobiilisovellus- ja työpöytätuki, kaupankäynti käden ulottuvilla.",
		p3: "Riskinhallintavälineet, kuten stop loss/trailing stop -välineet",
		p4: "Useita teknisiä kaavioita ja taloudellisia kalentereita, reaaliaikaisia uutisia.",
		p5: "Reaaliaikaiset sähköposti-, tekstiviesti- ja push-ilmoitukset",
		p6: "Jatkuva parantaminen kaupankäyntikokemuksen parantamiseksi",
		btn1: "Tutustu alustoihimme",
		row3_tit: "Kauppaa vapaasti milloin tahansa, missä tahansa",
		row3_tip: "Web-, mobiilisovellus- ja työpöytätuki, kaupankäynti käden ulottuvilla.",
		row3_tit2: "Skannaa koodi ladataksesi",
		row3_tit3: "Työpöytä",
		row3_tit4: "Mobiili",
		regular_tit: "Luotettava kansainvälinen foorumi",
		regular_tip: "Olemme sitoutuneet tarjoamaan asiakkaillemme turvallisen ja vastuullisen kaupankäyntiympäristön.。",
		regular_tit1: "Arvovaltaisten organisaatioiden sääntelemät",
		regular_p1: "Alan viranomaisten sääntelemä ja valtuuttama, ja asiakkaat luottavat siihen maailmanlaajuisesti.",
		regular_tit2: "Taloudellisen turvallisuuden suojaaminen",
		regular_p2: "Vähittäisasiakkaiden talletukset erotetaan sijoitustileille tarpeen mukaan sääntelyvaatimusten mukaisesti.",
		regular_tit3: "Negatiivisen saldon suojaus",
		regular_p3: "Tarjota sinulle tilin negatiivisen saldon suoja, negatiivinen saldo ajoissa tyhjentää nolla, niin että tappio ei ylitä määrää merkinnän, mielenrauhan liiketoimet",
		regular_tit4: "7 * 24 Tunnin asiakaspalvelu",
		regular_p4: "Vilpitön ja ammattitaitoinen asiakaspalvelutiimi, 24 tunnin online-tuki, mielellään ratkaista kaikki ongelmasi!",
		service_tit: "Lisää palveluita avuksesi",
		service_tit1: "Kaupankäyntistrategia",
		service_p1: "Reaaliaikaiset kaupankäyntistrategiat auttavat sinua ymmärtämään viimeisimmät markkinasuuntaukset, ymmärtämään paremmin kaupankäynnin ajoituksen.",
		service_tit2: "Kaupankäynnin tietämys",
		service_p2: "与HKDFINANC一起，免费学习交易知识，Opi käymään kauppaa ilmaiseksi HKDFINANCin kanssa ja paranna kaupankäyntitaitojasi.",
		service_tit3: "Riskienhallinta",
		service_p3: "Tutustu HKDFINANCin tarjoamiin ilmaisiin riskinhallintatyökaluihin, joiden avulla voit suojata omaisuuttasi paremmin.",
		step_tit: "Helppo ja kätevä avata tili",
		step_tip: "Kolme yksinkertaista vaihetta tilin avaamiseen muutamassa minuutissa",
		step_tit1: "Ilmoittautuminen",
		step_p1: "Täytä tiedot ja lähetä hakemus",
		step_tit2: "Talletus",
		step_p2: "Nopea varojen tallettaminen useilla eri menetelmillä",
		step_tit3: "Kaupankäynnin aloittaminen",
		step_p3: "Löydä kaupankäyntimahdollisuudet ja tee toimeksiannot nopeasti",
		award_tit: "Saavutuksemme",
		award_p1: "Olemme aina tavoitelleet huippuosaamista ja pyrkineet huippuosaamiseen, ja olemme sitoutuneet tarjoamaan asiakkaillemme laadukkaita transaktiopalveluja.",
		award_p2: "HKDFINANC-tuotemerkillä on kunnia saada tämä arvostettu palkinto alan arvostetulta instituutiolta, joka tunnustaa tiimin jatkuvat ponnistelut ja sitoutumisen asiakkaillemme.",
		row7_tit: "Reaaliaikaiset maailmanlaajuiset taloudelliset tiedot",
		row7_tit1: "Reaaliaikaiset uutiset",
	},
	learn: {
		education: {

		}
	},
	login: {
		click_download: "Lataa klikkaamalla",
		p1: "Kaupankäynti milloin tahansa, missä tahansa",
		p2: "Käy kauppaa nopeammin ja älykkäämmin",
		p3: "0 välityspalkkiot",
		p4: "Maailman kuumin sijoitus",
		renderDom1: "Valuutanvaihto",
		renderDom2: "Yhdysvaltain osakemarkkinat",
		renderDom3: "Platina",
		renderDom4: "Raakaöljy",
		renderDom5: "Eksponentit",
		renderDom6: "Kryptovaluutta",
		login: "Kirjaudu sisään",
		elail: "Postilaatikko",
		phone: "Matkapuhelinnumero",
		no_account: "Ei tiliä？",
		go_register: "Siirry rekisteröitymään",
		register: "Ilmoittautuminen",
		now_register: "Rekisteröidy nyt",
		have_account: "Sinulla on jo tili？",
		go_login: "Siirry kirjautumiseen",
	},
	about: {
		serviceFee: "Palvelumaksu",
		introduction: "Alustan esittely",
		trade_platform: "Kauppapaikka",
		complaint_process: "Valitusprosessi",
		page: "Verkkosivu",
		place_search: "Etsi haluamasi tiedot",
		no_answer: "Et löydä tarvitsemaasi vastausta?",
		contact_us: "Ota yhteyttä",
		awards: {
			h1: "Palkinnot ja kunniamaininnat",
			p1: "Jatkuva pyrkimyksemme huippuosaamiseen ja omistautumisemme tarjota asiakkaillemme laadukkaita transaktiopalveluja antaa HKDFINANC-brändille kunnian saada alan arvostetun organisaation myöntämä palkinto, joka on osoitus tiimin jatkuvista ponnisteluista ja sitoutumisesta asiakkaitamme kohtaan.",
			p2: "Tai koe palkittu online-kaupankäyntialustamme välittömästi!",
			name1: "Latinalaisen Amerikan paras välitysliike",
			name2: "Paras CFD-välittäjä",
			name3: "Paras Fintech Broker",
			name4: "Top 10 vaikutusvaltaisinta yritystä vuonna 2021",
			name5: "Läpinäkyvin välittäjä",
			name6: "Ultimate Fintech Awards -palkinnot",
		},
		charges: {
			h1: "Maksut ja palkkiot",
			p1: "HKDFINANC haluaa tarjota asiakkaillemme kustannustehokkaampaa kaupankäyntipalvelua, mikä on olennainen osa asiakaskokemuksen parantamista. Pyrimme olemaan avoimia ja läpinäkyviä maksujen ja palkkioiden suhteen, eikä meillä ole piilotettuja maksuja. Tärkeimmät asiakkaille aiheutuvat transaktiokustannukset ovat osto-/myyntikurssiero ja yön yli -positiokorko.",
			h2: "Ensinnäkin voit nauttia seuraavista eduista HKDFINANCin kanssa käytävässä kaupankäynnissä",
			li1: "Välitön tuotetarjous",
			li2: "Ammatilliset ja tekniset indikaattorit",
			li3: "Yksinomainen markkina-analyysi",
			free: "Ilmaisohjelmat",
			view: "Katso ylös",
			h3: "Osto- ja myyntitarjousten välinen ero",
			p2: "HKDFINANC veloittaa osto- ja myyntitarjousten erotuksen korvauksena palveluistaan. Tämä palkkio näkyy tuotetarjouksessa, ja maksat itse asiassa marginaalin, kun avaat position. Koska marginaalimaksu ei ole kiinteä, sinun on suositeltavaa siirtyä tietyn tuotteen tarjoussivulle tai kirjautua sisään osoitteeseen",
			h4: "Talletus- ja nostomaksut",
			p3: "Jotta asiakkaidemme kustannukset olisivat mahdollisimman pienet, emme useimmissa tapauksissa veloita sinua varojen käyttämisestä. Kolmannen osapuolen yritys tai pankki voi kuitenkin periä sinulta maksun talletuksen tai noston tekemisestä, kuten pankin siirtomaksun.",
			p4: "Ota yhteyttä asiakaspalveluumme saadaksesi lisätietoja maksuista.。",
			p5: "Esim. kansainväliset luottokorttitapahtumat, pankkitilisiirrot sisään/ulos tai tapahtumat tukemattomilla valuutoilla (valuutan muuntaminen) jne.",
			h5: "Yöpymispaikan kustannukset",
			p6: "Jos sinulla on yhä positio klo 22.00 GMT (22:00 GMT), sinulta veloitetaan/siirtyy yön yli -maksu. Kunkin tuotteen yön yli -maksuja koskevat tiedustelut voi tehdä tuotekohtaisella tarjoussivulla tai kirjautumalla sisään osoitteessa",
			h6: "Muut kustannukset",
			p7: "Ei muita maksuja. Veloituskäytäntömme on täysin läpinäkyvä, ja kaikista maksullisista kohteista ilmoitetaan etukäteen, joten piilomaksuista ei tarvitse huolehtia."
		},
		contact: {
			h1: "Ota yhteyttä",
			address: "Osoite",
			custom_email: "Asiakkaan yhteydenottosähköposti",
			business_email: "Yritysyhteistyö Sähköposti",
			h2: "Kirjoita meille.",
			p1: "Jos sinulla on kysyttävää palveluistamme, voit lähettää kysymyksesi alla olevalla lomakkeella, ja yksi asiakaspalvelumme asiantuntijoista vastaa sinulle mahdollisimman pian. Vaihtoehtoisesti, jos haluat tilata päivittäiset kaupankäyntistrategiamme, jotka tarjotaan yhteistyössä Trading Centralin kanssa, valitse 'Tilaa päivittäiset markkina-analyysit' alla olevan lomakkeen tyypistä ja täytä haluamasi kieliversio, ja me rekisteröimme sinut. Tilauspalvelu on saatavilla vain nykyisille HKDFINANCin todellisille asiakkaille.",
			h3: "Edustusmenettelyt",
			p2: "Asiantuntijatiimimme tutkii ja ratkaisee kaikki valitukset, lisätietoja on osoitteessa",
		},
		faq: {
			h1: "Yleiset ongelmat",
			tit1: "Mitä hyötyä demotilistä on？",
			tit2: "Kuinka avata live-tili？",
			tit3: "Markkinatilauksen luominen？",
			tit4: "Miksi en voi tehdä tilausta?？",
			p1: "Demotilit ovat enimmäkseen samoja kuin oikeat tilit kaupankäynnin käyttöliittymän, tietojen ja operaatioiden osalta. Demotilit sisältävät 50 000 dollaria virtuaalisia varoja, ja ne on suunniteltu siten, että asiakkaat voivat tutustua alustan ominaisuuksiin simuloitujen operaatioiden avulla riskeeraamatta varojaan.",
			p2: "Jos haluat avata live-tilin, toimi seuraavasti",
			p3: "1. Anna HKDFINANC-rekisteröintisivu, kehotteiden mukaan: rekisteröi postilaatikko / matkapuhelinnumero, aseta kirjautumissalasana, lähetä vahvistus ja luo automaattisesti [Demo-tili].",
			p4: "2. Valitse Demo Account -sivulla 'Switch to Real Account' (Siirry oikealle tilille) ja noudata menettelyä avataksesi 'Real Account' (Oikea tili).",
			p5: "Ennen kuin avaat live-tilin, suosittelemme, että luet ja ymmärrät kaupankäyntiin liittyvät oikeudelliset tiedot.",
			p6: "Huomaa, että voit tallettaa live-tilillesi vasta, kun olet suorittanut henkilöllisyyden tarkistuksen.",
			p7: "Klikkaa 'Trade' (Kauppa) näyttää kaikki tuotteet saatavilla kaupankäynnin alustalla, voit syöttää koodin tai tuotteen nimen oikealla olevaan syöttölaatikkoon etsiäksesi sitä, valitse tuote ja klikkaa 'SELL/BUY' (MYYNTI/OSTA) avataksesi kaupankäynti-ikkunan, jossa näet nykyisen hinnan ja arvioidun vaaditun marginaalin. Voit manuaalisesti säätää avattavien kauppojen lukumäärää, asettaa stop-loss- ja take-profit-arvot riskinhallintaa varten ja napsauttaa sitten 'SELL/BUY' (MYYNTI/KAUPPA) saadaksesi position avaamisen päätökseen. Huomaa, että kaikkien rahoitustuotteiden hinnat vaihtelevat ja päivittyvät markkinoiden mukana milloin tahansa, ja markkinat ovat saattaneet muuttua ennen kuin painat OSTO/MYYNTI-painiketta.",
			p8: "Tarkista ensin, onko verkkoyhteys normaali. Kun kaupankäynnin lajien markkinat on tilapäisesti suljettu lainaus tai suljettu aiheuttaa kyvyttömyyden tehdä tilauksia. Ota yhteyttä online-asiakaspalveluumme saadaksesi lisäapua.",

		},
		fundSecurity: {
			h1: "Taloudellinen turvallisuus",
			p1: "HKDFINANCilla on auktoriteettiasemien myöntämä toimilupa, ja sitä sääntelevät arvovaltaiset sääntelyelimet. Vaatimustenmukaisuus on kaiken toimintamme ja toimintakulttuurimme ytimessä. Rahojesi turvallisuus on ensisijaisen tärkeää, ja kaikki asiakastalletukset on erotettu toisistaan.",
			p2: "Vähittäisasiakkaiden talletukset säilytetään tarvittaessa erillisillä sijoitustileillä sääntelyvaatimusten mukaisesti.",
			p3: "HKDFINANC ei käytä asiakkaiden varoja mihinkään muuhun kuin lakien ja asetusten sallimaan kaupalliseen toimintaan.",
			p4: "HKDFINANC ei spekuloi millään markkinoilla.",
			p5: "Kolmannen osapuolen tilintarkastusyritysten tekemät riippumattomat tilintarkastukset",
		},
		help: {
			h1: "Tervetuloa HKDFINANC-apukeskukseen",
		},
		media: {
			h1: "Mediakeskus",
			p1: "Tutustu HKDFINANC-brändiin liittyviin tietoihin, lehdistötiedotteisiin, mediaominaisuuksiin, ilmoituksiin ja muihin tietoihin.",
		},
		mitrade: {
			h1: "Tietoja HKDFINANCista",
			view_certificate: "（Tarkastusmenetelmät）",
			p1: "HKDFINANC on viranomaisten valtuuttama ja sääntelemä fintech-yritys, joka keskittyy tarjoamaan sijoittajille yksinkertaisen ja kätevän kaupankäyntikokemuksen. Innovatiivinen HKDFINANCin monipuolinen kaupankäyntialusta on toistuvasti voittanut palkintoja, kuten Best Mobile Trading Platform ja Most Innovative Brokerage.",
			p2: "HKDFINANC-alustan kautta voit sijoittaa ja käydä kauppaa laajemmilla kansainvälisillä rahoitusmarkkinoilla, jotka kattavat osakkeet, indeksit, hyödykkeet, valuutat ja sadat muut suositut lajit. Ei rajoitu vain käyttäjäystävällisiin mobiili- ja verkkopohjaisiin kaupankäyntialustoihin, HKDFINANC tarjoaa sinulle myös kilpailukykyiset kaupankäyntikustannukset, nopean kaupankäynnin toteuttamisen, erinomaisen asiakaspalvelun ja runsaan tietotuen, joka auttaa sinua tarttumaan ajoissa investointeihin, kaupankäyntimahdollisuuksiin.",
			p3: "HKDFINANCia säännellään tiukasti alan viranomaisen, usean viraston, toimesta. HKDFINANC International on saanut toimiluvan ja sitä säännellään Mauritiuksen rahoituspalvelukomission (Financial Services Commission, FSC) toimiluvalla GB20025791. Mauritiuksen toimilupaa koskevat tiedot löytyvät FSC:n virallisilta verkkosivuilta osoitteesta https://www. fscmauritius.org",
			p4: "HKDFINANC Globalilla on Australian arvopaperi- ja sijoituskomission (ASIC) myöntämä rahoituspalvelulupa (AFSL 398528). Lisätietoja Australian toimiluvista on ASIC:n verkkosivuilla osoitteessa www.asic.gov.au.",
			p5: "HKDFINANC on Caymansaarten rahaviranomaisen (CIMA) valtuuttama ja sääntelemä SIB-lisenssillä nro 1612446. Lisätietoja Caymansaarten lisensseistä saa CIMA:n virallisilta verkkosivuilta osoitteesta www.cima.ky.",
			p6: "HKDFINANCin kaikki liiketoiminta tapahtuu tiukassa valvonnassa ja kaikkien säännösten mukaisesti.",
			h2: "HKDFINANCin tausta",
			p7: "Australiassa Melbournessa perustetun HKDFINANCin filosofiana on tehdä kaupankäynnistä helpompaa ja käyttäjäystävällisempää, ja sen perustajina toimivat kokeneet ja asiantuntevat finanssikaupan ja fintech-alan ammattilaiset.",
			p8: "Kokonaisvaltainen strategiamme ja liiketoimintamallimme mahdollistavat sen, että meillä on maailmanlaajuinen ja syvällinen havainnointi ja tietämys toimialan kehityksestä ja markkinoiden kysynnästä, jotta voimme kohdentaa resursseja entistä kohdennetummin, innovoida jatkuvasti teknologiaa ja optimoida tehokkuutta sekä tarjota asiakkaillemme entistä helpomman ja ystävällisemmän kaupankäyntikokemuksen. ",
			h3: "Miksi HKDFINANC",
			why1: "Matalan kynnyksen kaupankäyntiolosuhteet",
			why2: "Alan viranomaisten sääntelemä",
			why3: "Yksinkertainen ja intuitiivinen kaupankäyntijärjestelmä",
			why4: "Korkeatasoinen verkkotuki",
			why5: "Kilpailukykyiset transaktiokustannukset",
			why6: "Negatiivisen saldon suojaus",
			h4: "Palkinnot ja kunniamaininnat",
			p9: "Olemme aina tavoitelleet huippuosaamista ja pyrkineet huippuosaamiseen, ja olemme sitoutuneet tarjoamaan asiakkaillemme laadukkaita transaktiopalveluja.",
			p10: "HKDFINANC-tuotemerkillä on kunnia saada tämä arvostettu palkinto alan arvostetulta instituutiolta, joka tunnustaa tiimin jatkuvat ponnistelut ja sitoutumisen asiakkaillemme.",
			new_add: "Uusia käyttäjiä maailmanlaajuisesti",
			ol1: "Paras uutisten ja analyysien tarjoaja",
			ol2: "Paras Forex Broker Aasiassa",
			ol3: "Australian Forexin asiakastyytyväisyyspalkinnot",
			ol4: "Paras mobiilisovellus",
			ol5: "Australian nopeimmin kasvava välittäjä",
			h5: "HKDFINANCin toimintafilosofia",
			tip_tit1: "Velat",
			tip_tit2: "Yksinkertainen mutta kaunis",
			tip_tit3: "Avoin (ei-salainen)",
			tip_tit4: "Kulkea uusia polkuja",
			tip_p1: "Kaikkeen kaupankäyntiin liittyy riski, ja HKDFINANC on ryhtynyt lisätoimiin tarjotakseen asiakkailleen vastuullisen kaupankäyntiympäristön. HKDFINANCia sääntelevät alan viranomaiset, useat organisaatiot, ja se toimii korkealla turvallisuuden ja vakauden tasolla. Alustamme tarjoaa edelleen useita vastuullisia kaupankäyntivälineitä, mukaan lukien riskienhallinta.",
			tip_p2: "Yksinkertaisuus on kauneutta, yksityiskohdat ovat huippuosaamista, HKDFINANC on sitoutunut helppokäyttöisyyteen ja käyttäjäkokemukseen kaikissa yksityiskohdissa. Jatkamme alustamme ja palveluidemme optimointia siinä toivossa, että kaikki, aloittelijasta kokeneeseen sijoittajaan, voivat hyödyntää HKDFINANC-alustan tarjoamia kaupankäyntimahdollisuuksia.",
			tip_p3: "Jotta asiakkaamme voivat täysin hyötyä oikeudenmukaisesta ja tehokkaasta kaupankäyntiympäristöstä, HKDFINANC noudattaa tiukkaa ja läpinäkyvää itsesääntelyjärjestelmää tarjotakseen kattavan ja läpinäkyvän alustan tuotetiedot, hinnoitteluympäristö ja tiedot, kaikki maksut ovat selkeitä ja avoimia sen varmistamiseksi, että koko palveluprosessi on täysin vilpitön ja avoin.",
			tip_p4: "HKDFINANC uskoo vahvasti, että teknologinen kehitys, rahoitusalan innovaatiot ja laajemmat palvelut ovat kasvun moottoreita ja että voimme edelleen luoda ja jakaa arvoa. Tätä varten kannustamme avoimeen ajatteluun ja jatkamme innovaatiotoimintaa tavoitteenamme olla innovatiivinen ja johtava toimija finanssiteknologian alalla.",
			row6_tit1: "Tehtävämme",
			row6_tit2: "Visiomme",
			row6_tit3: "Työmahdollisuudet",
			row6_p1: "Tarjota useammille kaupankäyntiä haluaville oikeudenmukainen, tehokas ja läpinäkyvä kaupankäyntiympäristö, jota ei rajoita tila, aika tai markkinat, ja johtaa alaa rahoitus- ja teknologiainnovaatioissa.",
			row6_p2: "Uskomme, että teknologinen kehitys, rahoitusinnovaatiot ja monipuoliset palvelut ovat voimia, jotka vauhdittavat kasvuamme ja antavat meille mahdollisuuden luoda ja jakaa arvoa yhteiskunnassa.",
			row6_p3: "Tätä varten kannustamme ennakkoluulottomuuteen ja edistämme innovaatiota, ja tavoitteenamme on tulla jatkuvan kasvun ja edistymisen avulla",
			row6_p4: "Maailman johtava valuutanvaihtoyhtiö",
			row6_p5: "Olemme maailmanlaajuinen tiimi, joka toimii seitsemässä eri maassa, ja koska tiimimme kasvaa nopeasti, etsimme aina ammattilaisia joukkoomme. Tee sitä, mitä rakastat monikulttuurisessa tiimissä!",
			row6_p6: "Lähetä meille sähköpostitse ajantasainen ansioluettelosi ja kerro, miksi haluaisit työskennellä kanssamme!：",
			row6_p7: "Vaihtoehtoisesti voit myös tutustua tarkemmin",
			row6_p8: "Ehkä",
			row6_p9: "Tutustu avoimiin työpaikkoihimme osoitteessa。",
			row6_p10: "HKDFINANC suojaa tiukasti henkilötietojesi turvallisuutta. HKDFINANC ei pyydä sinulta taloudellisia tietoja työnhakuprosessin aikana. Emme vaadi rekrytointiimme liittyviä taloudellisia tietoja, luottokortti- tai pankkitilitietoja tai minkäänlaisia maksutapoja.",
			row6_p11: "Jos epäilet, että asema on petollinen, ota meihin yhteyttä osoitteessa",
			row6_p12: "Ota yhteyttä HKDFINANCin henkilöstöosastoon. Jos olet varma, että sinua on huijattu väärällä työtarjouksella, ota yhteyttä asianomaisen organisaation paikalliseen osastoon.",

		}
	},
	product: {
		row2: {
			tit: "Kauppalajikkeet",
			sell: "Reach (hinta huutokaupassa)",
			buy: "Buy-in",
			trade: "Liiketoimet",
			more_product: "Lisää kaupankäynnin tuotteita",
		},
		row3: {
			tit: "Tukee useita laitetapahtumia",
			tit1: "Tukee useita laitetapahtumia",
			p1: "HKDFINANC tarjoaa sinulle verkkopohjaisia, mobiili- ja työpöytäkaupankäyntialustoja. Voit käydä kauppaa milloin ja missä tahansa.",
			download: "Lataaminen",
		},
		row4: {
			tit: "Valitse HKDFINANC",
			tit1: "Yksinkertainen ja intuitiivinen alusta",
			p1: "Sitaattien, kaupankäynnin, tilinhallinnan, tietojen, riskienhallinnan ja tehokkaiden toimintojen integrointi paremman käyttäjäkokemuksen saavuttamiseksi.",
			tit2: "Kilpailukykyiset transaktiokustannukset",
			p2: "0 kaupankäyntipalkkio, erittäin kilpailukykyinen ja läpinäkyvä alhainen leviää, alhainen SWAPs, joten voit nauttia edullisen kaupankäynnin!",
			tit3: "Matalan kynnyksen kaupankäyntiolosuhteet",
			p3: "Kunkin transaktion pienin eräkoko on vain 0,01 erää, ja voit avata position pienellä marginaalilla.",
			tit4: "Viranomaisen sääntelemä",
			p4: "Toimialan viranomaisten valtuuttama ja sääntelemä, kaikki asiakkaiden talletukset säilytetään erillisessä erillisessä varastossa asiakkaiden varojen turvallisuuden takaamiseksi.",
			tit5: "Negatiivisen saldon suojaus",
			p5: "Tilisi ei koskaan menetä enempää kuin sen pääoma missään markkinatilanteessa, ja negatiiviset saldot selvitetään ajoissa riskienhallintamahdollisuuksiesi parantamiseksi.",
			tit6: "Korkeatasoinen verkkotuki",
			p6: "Tarjoaa nopean vastauksen online-asiakaspalveluun, tarjoaa parempaa palvelua ammattitaitoisen tiimin keskeytymättömien ponnistelujen kautta.",
		},
		row5: {
			tit: "Aiheeseen liittyvät kysymykset",
		},
		commodities: {
			title: "Hyödykekauppa",
			p: "贵金属、能源等大宗商品被认为既可抵御通胀，又有投资增值的机会，是多元化投资的重要组成部分。其中，黄金、白银、石油等是交易量巨大的商品交易品种，公开、透明的价格以及高流动性，受到了投资者的广泛欢迎。商品价格由于受到供需、经济和政策形势、货币等因素的影响而显著波动，提供了风险收益的机会。",
		},
		forex: {
			title: "Valuuttakauppa",
			p: "Valuuttakauppa on yhden valuutan muuntamista toiseksi valuutaksi, ja valuuttamarkkinat ovat maailman vilkkaimmin vaihdetut rahoitusmarkkinat. Valuuttamarkkinoilla on suuri määrä osallistujia, jotka käyvät kauppaa niiden kautta suorittaakseen maksuja, suojautuakseen valuuttakurssimuutosten riskiltä tai saadakseen voittoa. Valuuttakauppa tapahtuu pankkien, laitosten ja yksittäisten kauppiaiden verkoston kautta ympäri maailmaa, ja kurssivaihtelut tapahtuvat 24 tuntia vuorokaudessa viitenä päivänä viikossa, mikä tarjoaa mahdollisuuksia riskin ja tuoton väliseen vaihteluun.",
		},
		indices: {
			title: "indices",
			p: "Osakeindeksi on tilastollinen arvo, joka edustaa tietyntyyppistä osaketta tietyssä pörssissä, ja se on indikaattori tietyntyyppisen osakkeen yleisestä hintatasosta ja liikkeestä markkinoilla. Kaupankäynti osakeindekseillä, jotka heijastavat sijoitusmahdollisuuksia kokonaismarkkinoilla tai -alalla, voi vähentää yksittäisiin osakkeisiin sijoittamisen riskiä. Yhdysvaltojen, Euroopan, Aasian ja Australian markkinoiden suosituimmat indeksit, kuten Australia 200, US Tech 100, Hongkong 50, Saksa 30 jne. tarjoavat erilaisia mahdollisuuksia maailmanlaajuisilla osakemarkkinoilla.",
		},
		shares: {
			title: "Osakekauppa",
			p: "Osake on pörssissä liikkeeseen laskettu jälkimarkkinakelpoinen arvopaperi. Pääoman hankkimiseksi yritykset jakavat yrityksen omistusoikeutta pörssinoteerattujen pörssien kautta todistusten avulla. Pelkästään vuonna 2019 maailman osakekauppa oli yli 60 biljoonaa dollaria, mikä on niin suuri ja likvidi, että se on edelleen yksi suosituimmista rahoitusvälineistä maailmanlaajuisten sijoittajien silmissä, vielä vuosienkin jälkeen. Osakkeiden hintoihin vaikuttavat monet eri tekijät, kuten makrotalous, toimialan näkymät ja yritysten toiminta, ja ne ovat erittäin epävakaita, mutta sijoitus- ja kaupankäyntimahdollisuuksia on aina tarjolla.",
		},
		coins: {
			title: "Kryptovaluutta",
			p: "Kryptovaluutat ovat koodin avulla luotuja digitaalisia valuuttoja. Ne toimivat itsenäisesti perinteisten pankki- ja hallitusjärjestelmien ulkopuolella. Kuten nimestä voi päätellä, kryptovaluutat käyttävät salausta transaktioiden turvaamiseen ja mahdollistavat muiden yksiköiden luomisen. Bitcoin on alkuperäinen ja ylivoimaisesti tunnetuin kryptovaluutta, jonka Satoshi Nakamoto loi ja joka lanseerattiin tammikuussa 2009. Vielä nykyäänkään ei tiedetä, viittaako nimi Satoshi Nakamoto henkilöön vai ihmisryhmään. Bitcoinia pidetään ensimmäisenä hajautettuna kryptovaluuttana. Kuten kaikkia kryptovaluuttoja, sitä ohjataan lohkoketjun transaktiotietokannan avulla, joka toimii hajautettuna julkisena pääkirjana. Tähän mennessä yli 1 000 kryptovaluuttaa on saatavilla verkkokauppaa varten."
		}
	},
	trade: {
		data_sources: "Tietolähteet",
		tip: "Yllä olevat tiedot ovat vain viitteellisiä. HKDFINANC ei takaa tietojen oikeellisuutta, ajantasaisuutta tai täydellisyyttä, eikä sinun tulisi luottaa perusteettomasti annettuihin tietoihin. Nämä tiedot eivät sisällä hintatietojamme eivätkä tarjousta tai kehotusta käydä kauppaa rahoitusvälineillä. HKDFINANC ei ole rahoitusneuvontayritys, ja se tarjoaa ainoastaan toimeksiantojen toteuttamispalveluja. Lukijoita kehotetaan hankkimaan omat sijoitusneuvonsa. Tutustu täydelliseen vastuuvapauslausekkeeseen.",
		tip2: "Päivitetään joka perjantai klo 15:00 (GMT)",
		tip3: "Trading Views on työkalu markkinoiden kaupankäyntitunnelmien ennustamiseen, joka heijastaa alan asiantuntijoiden lyhyen ja keskipitkän aikavälin näkemyksiä ja trendiennusteita kullekin kuumalle tuotteelle.",
		analysis: {
			tit: "Kaupankäyntistrategia",
			all: "Full",
			day: "Muutaman päivän kuluttua",
			tit1: "Kaupankäyntistrategia",
			tit2: "Tekninen katsaus",
		},
		calendar: {
			tit: "Talouskalenteri",
			prev_week: "Viime viikolla",
			next_week: "Ensi viikolla",
			place_date: "Hakupäivä",
			select: "Vaihtoehto",
			select1: "Kansakunnat",
			select2: "Merkitys",
			start_time: "Aloitusaika",
			end_time: "Päättymisaika",
			search: "Etsiä mitä tahansa",
		},
		contract: {
			tit: "Sopimuseritelmät",
			type1: "Full",
			type2: "Valuutanvaihto",
			type3: "Kauppatavara",
			type4: "Eksponentit",
			type5: "Yhdysvaltain osake",
			type6: "Australian osakkeet",
			tit1: "Tärkeimmät valuuttamarkkinat",
			tit2: "Vähäinen valuutta",
			tit3: "Kauppatavara",
			tit4: "Tärkeimmät indeksit",
			tit5: "Pieni indeksi",
			tit6: "Yhdysvaltain osake",
			tit7: "Australian osakkeet",
			tit8: "Yleiset säännöt",
			p1: "Pakotettu tasaisuusaste",
			p2: "Vireillä olevan tilauksen voimassaolo",
			p3: "Rajaton",
			p4: "Maksujen selvitysaika yön yli",
			p5: "Päivittäinen selvitys; Kesä: 05:00, Talvi: 06:00.",
			p6: "Tiedot vipuvaikutuksesta, yön yli -koroista jne.",
			p7: "Katso kauppapaikat",
			p8: "Edellä mainittu koskee kaikkia kaupan lajikkeita",
			tit9: "Kaupankäynnin viikoittainen kalenteri",
			p9: "Markkinoiden ulkopuolella",
			p10: "Edellä mainituissa kaupankäyntiajoissa ei oteta huomioon juhlapyhien tai erityisten markkinasopeutusten vaikutusta.",
			p11: "Erityiset kaupankäyntiaikasuositukset",
			p12: "Kirjautumisalusta",
			p13: "Tutustu kyseisten lajikkeiden profiileihin.。",
		},
		forecast: {
			tit: "Kaupankäynnin näkökulmat",
			p1: "Keskiarvo",
			p2: "Bullish",
			p3: "Bearish",
			p4: "Ristivirrat",
			p5: "Suunnat",
		},
		news: {
			tit: "Reaaliaikaiset uutiset",
		},
		platforms: {
			tit: "HKDFINANC-kauppapaikka",
			p: "Yksinkertainen ja intuitiivinen käyttöliittymä, erinomainen kaupankäynnin suorituskyky, vastaa useampien sijoittajien tarpeisiin. Aloita kaupankäynti HKDFINANCin kanssa tänään.",
			tit1: "Kätevä ja helppokäyttöinen HKDFINANC-kaupankäyntijärjestelmä",
			tit2: "Kauppaa milloin ja missä tahansa HKDFINANC-sovelluksen avulla!",
			li1: "Kauppaa sadoilla suosituilla markkinoilla, joilla on arvovaltainen sääntely varojen turvallisuuden varmistamiseksi.",
			li2: "Seuraa markkinasuuntauksia reaaliajassa, katso kaupankäyntianalyysejä ja selaa uusimpia talousuutisia.",
			li3: "Tarkastele erityyppisiä kaupankäyntikaavioita milloin tahansa ja sovella kaaviointitoimintoja ilman vaivannäköä.",
			li4: "Koe HKDFINANCin palkittu kaupankäyntialusta Android- tai iOS-laitteellasi!",
			code_download: "Skannaa koodi ladataksesi",
			tit3: "Ei tarvitse ladata, kaupankäynti verkossa selaimen kautta",
			li5: "Latausta ei tarvita, kaupankäynti verkossa suoraan tietokoneen selaimesta.",
			li6: "Palkittu: Aasian paras Forex Broker 2022, Australian paras kauppapaikka 2022, maailman nopeimmin kasvava Forex Fintech Broker 2022.",
			li7: "Tarjoaa tehokkaita teknisiä indikaattoreita: MACD, KDJ, RSI, TRIX, DMA, CCI jne.",
			li8: "Varustettu riskinhallintatyökaluilla, kuten stop-loss- ja negatiivisen saldon suojauksella, jotka vähentävät kauppojen mahdollisia riskejä.",
			tit4: "Täydellinen ratkaisu työpöytätapahtumiin",
			li9: "Helposti tarkastella markkinoiden lainauksia itse valittuun luetteloon.",
			li10: "Ota hälytykset käyttöön saadaksesi hinnanmuutosilmoituksia ja muita tietoilmoituksia.",
			li11: "Useiden kaavioiden avulla voit seurata markkinoita eri aikaulottuvuuksista.",
			li12: "Sopimusmäärittely, kaupankäyntitunnelma ja kaupankäyntianalyysi kaikki yhdessä käyttöliittymässä, vaihtamista ei tarvita.",
			li13: "Hanki uusimmat kaupankäynnin uutiset suoraan HKDFINANC!",
			tit5: "Miksi valita HKDFINANC-kauppapaikka?",
			row5_p1: "Turvallisuus",
			row5_p2: "0 % Provisio",
			row5_p3: "Monialustatuki",
			row5_p4: "Tekninen kaavio",
			row5_p5: "Pääsy milloin tahansa, missä tahansa",
			row5_p6: "Erilaiset piirtotyökalut",
		},
		quotes: {
			tit: "Reaaliaikaiset tarjoukset",
			p1: "Tarjoaa sinulle välittömät noteeraukset, markkinatunnelmat ja niihin liittyvät uutiset tämän hetken kuumista rahoitustuotteista.",
			hot: "Muodissa",
		},
		risk: {
			tit: "Riskienhallinta",
			p1: "Kaikkeen kaupankäyntiin liittyy riski. Käyttämällä HKDFINANCin tarjoamia ilmaisia riskinhallintatyökaluja voit hallita riskiäsi tehokkaasti markkinaolosuhteista riippumatta.",
			function_tit1: "Take Profit/Stop Loss -toiminto",
			function_tit2: "Jälkipysäytystoiminto",
			function_p1: "Voittojen lukitseminen",
			function_p2: "Tappioiden rajoittaminen",
			function_p3: "Maksimoi voittojen sitominen",
			function_p4: "Asentoja ei tarvitse seurata automaattisesti",
			function_p5: "Tappion sulkemisen hinnan mukauttaminen",
			row2_tit1: "Take Profit/Stop Loss -toiminto",
			row2_p1: "Kun luot uuden toimeksiannon tai muutat olemassa olevaa toimeksiantoa, voit asettaa 'Take Profit' ja'Stop Loss'. Kun tilaus on asetettu, se suljetaan yleensä asettamallasi tavoitehinnalla, mikä auttaa sinua lukitsemaan voittosi, kun saavutat tavoitteesi, tai vähentämään tappiotasi, jos markkinat kehittyvät sinua vastaan. Huomaa, että mikä tahansa toimeksianto voidaan myydä lyhyeksi markkinoiden volatiliteetin vuoksi, jolloin järjestelmä ei pysty toteuttamaan toimeksiantoa oletushintaan, vaan sulkee positiosi tavoitehinnasta seuraavaksi edullisimpaan hintaan.",
			row2_tit2: "Esimerkkejä",
			row2_p2: "EUR/USD:n tämänhetkinen hinta on 1,13816/1,13837 (Myy/osta). Avaat 1 erän ostotoimeksiannon (1 erä = 100 000 euroa) hintaan 1,13837 ja asetat Stop Lossin hintaan 1,13806.",
			row2_p3: "Yleensä, kun hinta laskee 1,13806:een, stop loss -toimeksiantosi aktivoituu ja positio suljetaan 1,13806:een, jolloin yhteenlaskettu tappio on 31 dollaria.",
			row2_p4: "Kun markkinaolosuhteet kuitenkin muuttuvat ja hinta liukuu suoraan 1,13837:stä 1,13795:een ohittaen Stop Loss -tavoitteesi, markkinat hyppäävät ja järjestelmä ei pysty sulkemaan positiota 1,13806:een, vaan sulkee position puolestasi seuraavaan edullisimpaan hintaan eli 1,13795:een, ja lopullinen tappio on 42 USD.",
			row3_tit1: "Jälkipysäytystoiminto",
			row3_tit2: "Esimerkkejä",
			row3_p1: "Trailing Stopit (tunnetaan myös nimellä trailing stops) ovat tehokas ominaisuus, jonka avulla voit lukita voitot tai minimoida tappiot ilman, että sinun tarvitsee jatkuvasti seurata positioitasi. Kun luot toimeksiannon, asetat yksinkertaisesti trailing stopin, ja kun hinta liikkuu eduksesi, stop-toimeksiantosi päivittyy automaattisesti viimeisimmän hinnan mukaan. Sitä vastoin, kun hinta liikkuu sinua vastaan, stop loss -toimeksianto aktivoituu ja positio suljetaan asettamallasi tappioetäisyydellä. Huomaa, että mikä tahansa toimeksianto voi jäädä lyhyeksi markkinoiden volatiliteetin vuoksi, jolloin järjestelmä ei pysty toteuttamaan toimeksiantoa oletushinnalla, vaan sulkee position puolestasi tavoitehinnasta seuraavaksi edullisimpaan hintaan.",
			row3_p2: "EUR/USD:n tämänhetkinen hinta on 1,13816/1,13837 (myydä/ostaa). Olet antanut 1 erän ostotoimeksiannon hintaan 1,13837 ja 100 pisteen (0,00100) trailing stop lossilla.",
			row3_p3: "Kun tuotteen hinta on 1,13816, Stop Loss on 1,13716. Jos tuotteen myyntihinta nousee 1,13845:een, Stop Loss päivitetään asettamallasi etäisyydellä, ja päivitetty Stop Loss on 1,13745.",
			row3_p4: "Päinvastoin, kun tuotteen hinta liukuu 1,13845:stä 1,13745:een, trailing stop käynnistyy ja positio suljetaan 1,13745:ssä.",
			tip: "Piste: Yleensä rahoitusvälineen hinnan pienintä muutosyksikköä kutsutaan pisteeksi. HKDFINANC-alustalla se tarkoittaa instrumentin hinnan viimeistä numeroa tai desimaalia.",
		},
		sentiment: {
			tit: "Tunneindeksi",
			type1: "Full",
			type2: "Valuutanvaihto",
			type3: "Kauppatavara",
			type4: "Eksponentit",
			long: "Pitkäaikainen (rahoitus)",
			short: "Lyhyt (rahoitus)",
		},
	},
	layout: {
		aside: {
			nav1: "Liiketoimet",
			nav2: "Nykyinen markkinatilanne",
			nav3: "Tiedot",
			nav4: "College",
			nav5: "Minun",
			newsDialog: {
				title: "Uutiskeskus",
				type1: "Järjestelmäilmoitus",
				type2: "Tiedote",
				allRead: "Merkitse kaikki luetuiksi",
			},
			settingDialog: {
				title: "Perustaminen",
				nav1: "Tavanomainen (aseet)",
				nav2: "Näytä asetukset",
				nav3: "Järjestelmätiedot",
				logout: "Kirjaudu ulos",
				setting1: "Monikielisyys",
				setting2: "Liiketoimet",
				setting2_tip: "Avoimet paikat lisäävät automaattisesti itse valitut lajikkeet",
				setting3: "Lähettää tietoja takaisin",
				setting3_tip: "Toiminnalliset suositukset",
				setting4: "Todennus oikealla nimellä",
				setting4_tip: "Todennus oikealla nimellä",
				setting5: "Teematila",
				setting5_label1: "Hämärästi",
				setting5_label2: "Kirkas väri",
				setting6: "Nousevat tai laskevat värit",
				setting6_label1: "Vihreä nousee ja punainen putoaa",
				setting6_label2: "Punainen nousu ja vihreä lasku",
				setting7: "Kaavioasetukset",
				setting7_label1: "Standard Edition",
				setting7_label2: "TradingView Edition",
				setting8: "Julistukset ja sopimukset",
				setting8_label1: "Tietosuojakäytäntö",
				setting8_label2: "Tuotetta koskeva ilmoitus",
				setting8_label3: "Riskien ilmoittaminen",
				setting8_label4: "Asiakassopimus",
				feedback_title: "Toiminnalliset suositukset",
			}
		},
		footer: {
			hot: "Suosittuja lajikkeita",
			sort: "Valikoima",
			code: "Koodaus",
			buy_price: "Ostohinta",
			sell_price: "Myyjän hinta",
			chg: "Nousu- ja laskualue",
		},
		header: {
			place_search: "Kaupankäyntilajikkeiden haku",
			balance: "Käytettävissä oleva saldo",
			balance_tip: "Käytettävissä oleva saldo: tilillä oleva rahamäärä, jota voidaan käyttää uusien positioiden avaamiseen.",
			profit_loss: "Nouseva ja laskeva",
			asset_view: "Yleiskatsaus omaisuuseriin",
			netValue: "Nettoarvo",
			netValue_tip: "Nettovarallisuus: käyttötilin arvo, mukaan lukien kaikkien positioiden voitot ja tappiot.",
			marginLevel: "Marginaalitaso",
			margin: "Marginaali",
			margin_tip: "Marginaali (Margin): määrä, joka vaaditaan valuuttamääräisen position avaamiseen ja ylläpitämiseen.",
			maintainsMargin: "Ylläpitomarginaali",
			maintainsMargin_tip: "Ylläpitomarginaali: Marginaalin vähimmäismäärä, joka on säilytettävä tililläsi, kun pidät kaikki positiosi.",

		},
	},
	table: {
		label_name: "Nimi",
		label_buyPrice: "Tarjoushinta",
		label_sellPrice: "Myyntikurssi",
		label_variableValue: "Muutoksen arvo",
		trade: "Liiketoimet",
		label_code: "Tuotekoodi",
		label_title: "Nimi (jonkin asian nimi)",
		label_start: "Merkitys",
		label_country: "Kansakunnat",
		label_event: "Tapahtuma",
		label_previous: "Edellinen arvo",
		label_predictive: "Ennakoitu arvo",
		label_announced: "Julkaistu arvo",
		label_stop_loss_min: "Vähimmäisetäisyys stop loss -toimeksiannoille",
		label_difference: "Dynaaminen leviäminen",
		label_contract_size: "Sopimuksen koko",
		label_max_hand: "Yksittäisen liiketoimen enimmäiseräkoko",
		label_min_hand: "Erän vähimmäiskoko",
		label_trade_week: "Kaupankäynnin viikoittainen kalenteri",
		label_week: "1 viikko",
		label_month: "1 Tammikuu",
		label_quarter: "1 neljännes",
		label_dailyTrend: "Yhden päivän trendi",
		label_tradeId: "Tapahtuman tunnus",
		label_breed: "Valikoima",
		label_tradeType: "Liiketoimen tyyppi",
		label_currency: "Valuutta",
		label_amount: "Rahasumma",
		label_balance: "Saldot",
		label_type: "Typologia",
		label_time: "Kertaa",
		label_orderNumber: "Tilausnumero",
		label_pointer_number: "Järjestysluku",
		label_price_money: "Avaushinta",
		label_stop_win_price: "Ottaa voittoa",
		label_stop_lose_price: "Stop-loss",
		label_openTime: "Aukioloaika",
		label_profit_loss: "Nouseva ja laskeva",
		label_sell_buy: "Hintapyyntö/ostohinta",
		label_chg: "Nousu- ja laskualue",
		label_sell_price: "Myyjän hinta",
		label_buy_price: "Ostohinta",
		label_condition: "Ennakkoedellytys",
	},
	form: {
		label_feedback: "Kysymykset ja suositukset",
		place_feedback: "Kirjoita kysymyksesi tai ehdotuksesi",
		label_img: "Valokuva",
		label_img_tip: "Valinnainen, anna kuvakaappaus ongelmasta",
		feedback_tip: "Jos sinulla on kiireellinen kysymys, ota yhteyttä",
		online: "Online-asiakaspalvelu",
		label_name: "Etu- ja sukunimi",
		place_name: "Kirjoita nimesi",
		label_email: "Sähköpostiosoite",
		place_email: "Sähköpostiosoite",
		message_email: "Kirjoita sähköpostiosoitteesi",
		label_nationality: "Kansalaisuus",
		place_nationality: "Kirjoita kansalaisuutesi",
		place_questionType: "Ongelman tyyppi",
		message_questionType: "Valitse tyyppi",
		questionType0: "Kauppapaikka",
		questionType1: "Tuotteet & maksut",
		questionType2: "Tilaa päivittäiset markkina-analyysit",
		questionType3: "Asiakaspalvelu",
		questionType4: "Loput",
		place_question: "Kysymyksesi",
		message_question: "Kirjoita kysymys",
		submit_question: "Kysymysten esittäminen",
		label_phone: "Matkapuhelinnumero",
		place_phone: "Anna matkapuhelinnumerosi",
		message_phone: "Anna matkapuhelinnumerosi",
		label_password: "Salaus",
		place_password: "Anna salasanasi",
		message_password: "Anna salasanasi",
		label_confirmPassword: "Vahvista salasana",
		place_confirmPassword: "Syötä salasanasi uudelleen",
		message_confirmPassword: "Syötä salasanasi uudelleen",
		label_captcha: "Tarkastuskoodi",
		place_captcha: "Syötä vahvistuskoodi",
		message_captcha: "Syötä vahvistuskoodi",
		get_captcha: "Hanki CAPTCHA",
		label_inviteId: "Kutsukoodi",
		place_inviteId: "Kutsukoodi (valinnainen)",
		to: "Kunnes",
		start_time: "Aloitusaika",
		end_time: "Päättymisaika",
		label_cardNumber: "Todistuksen numero",
		place_cardNumber: "Kirjoita ID-numerosi",
		message_cardNumber: "Kirjoita ID-numerosi",
		label_cardMain: "Asiakirjan etusivu",
		message_cardMain: "Kirjoittakaa asiakirjan etusivu",
		label_cardBack: "Asiakirjan kääntöpuoli",
		message_cardBack: "Kirjoita asiakirjan kääntöpuoli",
		confirm_modify: "Muutosten vahvistaminen",
		label_realName: "Täydellinen nimi",
		place_realName: "Kirjoita nimesi",
		message_realName: "Kirjoita koko nimesi",
		label_firstName: "Sukunimi",
		place_firstName: "Kirjoita sukunimesi",
		message_firstName: "Kirjoita sukunimi",
		label_lastName: "Nimi (jonkin asian nimi)",
		place_lastName: "Kirjoita toinen nimesi",
		message_lastName: "Kirjoita nimi",
		label_birthday: "Syntymäaika",
		place_birthday: "Kirjoita syntymäaikasi",
		message_birthday: "Kirjoita syntymäaikasi",
		place_nowPsd: "Anna nykyinen salasanasi",
		message_nowPsd: "Anna nykyinen salasanasi",
		place_newPsd: "Anna uusi salasana",
		message_newPsd: "Anna uusi salasana",
		place_crmPsd: "Vahvista uusi salasana",
		message_crmPsd: "Vahvista uusi salasana",
		label_breed: "Valikoima",
		place_breed: "Kirjoita laji",
		label_phase: "Milloin",
		label_buyPrice: "Ostohinta",
		label_sellPrice: "Myyjän hinta",
		label_do: "Koska",
		label_height: "Suurempi kuin",
		label_low: "Olla pienempi kuin",
		label_equal: "Tai yhtä suuri kuin",
		labelWalletName: "Lompakon nimi",
		messageWalletName: "Kirjoita lompakon nimi",
		placeWalletName: "Kirjoita lompakon nimi",
		labelWalletAddress: "Lompakon osoite",
		messageWalletAddress: "Anna lompakkosi osoite",
		placeWalletAddress: "Anna lompakkosi osoite",
		labelAmount: "Rahasumma",
		messageAmount: "Kirjoita summa",
		placeAmount: "Kirjoita summa",
		placeTimeLimit: "Anna aikaraja",
		messageTimeLimit: "Anna aikaraja",
	},
	pay: {
		title: "Helppo varojen saanti",
		p1: "Erilaisia yleisesti käytettyjä maksukanavia, tuki nopeille talletuksille ja nostoille.",
		p2: "Jotkin maksutavat eivät ehkä ole saatavilla maassasi.",
		p3: "Tervetuloa kokemaan HKDFINANC-palvelut",
		btn: "Avaa tili ja käy kauppaa nyt",
	},
	header: {
		tip1: "Vipukauppa on monimutkainen rahoitustuote, johon liittyy suuri nopean tappion riski.",
		btn1: "Kauppapaikka",
		btn2: "Kirjaudu ulos",
		btn3: "Kirjaudu sisään",
		BigUint64Array: "Kauppa nyt",
	},
	footer: {
		help: "Haluavat？",
		tip1: "Seuraa yhteisöämme",
		tip2: "Huomaa, että HKDFINANC ei ole perustanut virallista Telegram-ryhmää, ja minkä tahansa HKDFINANCin nimissä muodostetun Telegram-ryhmän epäillään olevan väärennös.",
		tip3: "RISKIVAROITUS: Kaupankäynti voi johtaa kaikkien varojen menettämiseen. OTC-johdannaiskauppa ei sovellu kaikille. Lue oikeudelliset asiakirjamme huolellisesti ennen palveluidemme käyttämistä ja varmista, että ymmärrät täysin niihin liittyvät riskit ennen kaupankäyntiä. Et itse asiassa omista tai pidä hallussasi mitään kohde-etuuksia.",
		tip4: "HKDFINANC ei anna mitään neuvoja, suosituksia tai mielipiteitä ostamisesta, hallussapidosta tai myynnistä. Kaikki tarjoamamme tuotteet ovat globaaleihin varoihin perustuvia OTC-johdannaisia. kaikki HKDFINANCin tarjoamat palvelut perustuvat ainoastaan kauppatoimeksiantojen toteuttamiseen.",
		tip5: "HKDFINANC on useiden yritysten yhteinen tuotemerkki, jota käytetään seuraavien yritysten kautta:",
		tip6: "HKDFINANC International Ltd on tällä verkkosivustolla kuvattujen tai käytettävissä olevien rahoitustuotteiden liikkeeseenlaskija. HKDFINANC International Ltd on saanut toimiluvan ja on Mauritiuksen rahoituspalvelukomission (FSC) sääntelemä toimiluvan numerolla GB20025791, ja sen sääntömääräinen kotipaikka on osoitteessa 6 St Denis Street, 1st Floor River Court, Port Louis 11328, Mauritius.",
		tip7: "HKDFINANC Global Pty Ltd on rekisteröity numerolla ABN 90 149 011 361, Australian Financial Services Licence (AFSL) numero 398528.",
		tip8: "HKDFINANC Holding Ltd:llä on Caymansaarten rahaviranomaisen (CIMA) myöntämä toimilupa ja sitä säännellään SIB-lisenssinumerolla 1612446.",
		tip9: "Tämän verkkosivuston tietoja ei ole tarkoitettu Amerikan yhdysvaltojen, Kanadan, Japanin tai Uuden-Seelannin asukkaille, eikä niitä ole tarkoitettu käytettäväksi missään sellaisessa maassa tai sellaisella lainkäyttöalueella, jossa niiden julkaiseminen tai käyttäminen olisi vastoin paikallista lainsäädäntöä tai määräyksiä. Huomaa, että englanti on palveluidemme ensisijainen kieli ja kaikkien sopimusasiakirjojemme oikeudellisesti sitova kieli. Muille kielille tehdyt käännökset ovat vain viitteellisiä, ja jos englanninkielisen ja kiinankielisen version välillä on eroja, englanninkielinen versio on ensisijainen.",
		tip10: "SSL-turvallinen tietoliikenteen salaus. Copyright © HKDFINANC, Kaikki oikeudet pidätetään.",
		link1: "Tietosuojakäytäntö",
		link2: "Tuotetta koskeva ilmoitus",
		link3: "Valitusprosessi",
		link4: "Riskien ilmoittaminen",
		link5: "Asiakassopimus",
		toTop: "Sticky (Internet-foorumin viestiketju jne.)",
	},
	nav: {
		tit1: "Tarjoukset",
		tit2: "Liiketoimet",
		tit3: "Sijoittamisen oppiminen",
		tit4: "Tietoa meistä",
		nav1: "Valuutanvaihto",
		nav2: "Eksponentit",
		nav3: "Kauppatavara",
		nav4: "Osakkeet",
		nav5: "Kauppapaikka",
		nav6: "Kaupankäyntistrategia",
		nav7: "Kaupankäynnin näkökulmat",
		nav8: "Talouskalenteri",
		nav9: "Reaaliaikaiset uutiset",
		nav10: "Reaaliaikaiset tarjoukset",
		nav11: "Tunneindeksi",
		nav12: "Riskienhallinta",
		nav13: "Sopimuseritelmät",
		nav14: "Johdatus sijoittamiseen",
		nav15: "Sijoitusnäkemys",
		nav16: "Academy",
		nav17: "Tietoja HKDFINANCista",
		nav18: "Palkinnot ja kunniamaininnat",
		nav19: "Mediakeskus",
		nav20: "Fund security",
		nav21: "Maksut ja palkkiot",
		nav22: "Affiliates",
		nav23: "Ota yhteyttä",
		nav24: "Yleiset ongelmat",
		nav25: "Ohjekeskus",
	},

	city: {
		albania: "Albania",
		algeria: "Algeria",
		argentina: "Argentiina",
		armenia: "Armenia",
		australia: "Australia",
		pakistan: "Pakistan",
		austria: "Itävallan",
		bahrain: "Bahrain",
		belgium: "Belgia",
		bosnia_and_Herzegovina: "Bosnia ja Hertsegovinan lyhenne",
		brazil: "Brasilialainen",
		brunei: "Brunei",
		bulgaria: "Bulgaria",
		cambodia: "Kambodža",
		canada: "Kanadalainen",
		cameroon: "Kamerun",
		chile: "Chile",
		colombia: "Columbia",
		costa_Rica: "Costa Rica",
		croatia: "Kroatian tasavalta (1991-)",
		cyprus: "Kypros",
		czech_Republic: "Tšekin tasavalta ",
		denmark: "Tanska",
		dominican_Republic: "Dominikaaninen tasavalta",
		egypt: "Egypti",
		estonia: "Viro",
		ethiopia: "Etiopia",
		finland: "Suomi",
		france: "Ranskan",
		georgia: "Georgia (maa)",
		germany: "Saksan",
		ghana: "Ghana, Länsi-Afrikka",
		greece: "Kreikka",
		guyana: "Guyana",
		honduras: "Honduras",
		hong_Kong_China: "Hongkong, Kiina",
		hungary: "Unkari",
		iceland: "Islantilainen",
		ireland: "Irlantilainen",
		italy: "Italia",
		india: "Intia",
		indonesia: "Indonesia",
		israel: "Israelilainen",
		iran: "Iranilainen",
		iraq: "Irakilainen",
		japan: "Japanilainen",
		kazakstan: "Kazakstan",
		kenya: "Kenia",
		korea: "Korea",
		kuwait: "Kuwaitilainen",
		kyrgyzstan: "Kirgisia",
		laos: "Laos",
		latvia: "Latvia",
		lithuania: "Liettua",
		luxembourg: "Luxemburg",
		macao_China: "Macao, Kiina",
		macedonia: "Makedonia",
		malaysia: "Malesia",
		malta: "Maltalainen",
		mexico: "Meksiko",
		moldova: "Moldova",
		monaco: "Monaco",
		mongolia: "Mongolia",
		montenegro: "Montenegro",
		morocco: "Marokko",
		myanmar: "Myanmar",
		netherlands: "Alankomaat",
		new_Zealand: "New Zeeland",
		nepal: "Nepali",
		nigeria: "Nigeria",
		norway: "Norja",
		oman: "Omani",
		palestine: "Palestiina",
		panama: "Panama",
		paraguay: "Paraguay",
		peru: "Perulainen",
		philippines: "Filippiinit",
		poland: "Puolan",
		portugal: "Portugali",
		puerto_Rico: "Puerto Rico",
		qatar: "Doha",
		romania: "Romania",
		russia: "Georgia",
		republic_of_Trinidad_and_Tobago: "Trinidad ja Tobago",
		rwanda: "Ruanda",
		saudi_Arabia: "Saudi-Arabia",
		serbia: "Serbia",
		singapore: "Singapore",
		slovakia: "Slovakia",
		slovenia: "Slovenia",
		south_Africa: "Etelä-Afrikka",
		spain: "Espanjan",
		sri_Lanka: "(Entinen) Ceylon",
		sweden: "Ruotsi",
		switzerland: "Sveitsi",
		taiwan_China: "Kiina-Taiwan",
		tajikistan: "Tadžikistan",
		tanzania: "Tansania",
		thailand: "Thaimaa",
		turkey: "Istanbul",
		turkmenistan: "Turkmenistan",
		ukraine: "Valko-Venäjä",
		united_Arab_Emirates: "UAE",
		united_Kingdom: "Yhdistynyt kuningaskunta",
		united_States: "Yhdysvallat",
		uzbekistan: "Uzbekistan",
		venezuela: "Venezuela",
		vietnam: "Vietnam",
		afghanistan: "Afganistan",
		angola: "Angola",
		azerbaijan: "Azerbaidžan",
		bangladesh: "Bangladesh",
		belarus: "Valko-Venäjä",
		belize: "Belizean",
		benin: "Benin",
		bhutan: "Bhutan",
		bolivia: "Bolivia",
		botswana: "Botswana",
		british_Virgin_Islands: "Brittiläiset Neitsytsaaret",
		burkina_Faso: "Burkina Faso, Länsi-Afrikka",
		burundi: "Burundi",
		cape_Verde: "Kap Verde",
		cayman_Islands: "Caymansaaret",
		central_African_Republic: "Keski-Afrikan tasavalta",
		chad: "Tšadilainen",
		comoros: "Komorit",
		the_Republic_of_the_Congo: "Kongo (Brazzaville)",
		democratic_Republic_of_the_Congo: "Kongon demokraattinen tasavalta",
		djibouti: "Djibouti",
		ecuador: "Ecuador",
		el_Salvador: "El Salvador",
		equatorial_Guinea: "Päiväntasaajan Guinea",
		eritrea: "Eritrea",
		fiji: "Fidži",
		gabon: "Gabonilainen",
		gambia: "Gambia",
		greenland: "Grönlanti",
		guatemala: "Guatemala",
		guinea: "Guinea",
		haiti: "Haiti",
		isle_of_Man: "Mannin saari",
		cote_d_Ivoire: "Norsunluurannikko",
		jamaica: "Jamaikalainen",
		jordan: "Jordan",
		lebanon: "Vihollisuudet",
		lesotho: "Lesotho",
		liberia: "Liberia",
		libya: "Libya",
		madagascar: "Madagaskar",
		malawi: "Malawi",
		maldives: "Malediivit",
		mali: "Mali",
		mauritania: "Mauritania",
		mauritius: "Mauritius",
		mozambique: "Mosambik",
		namibia: "Namibia",
		nicaragua: "Nicaragua",
		republic_of_Niger: "Niger",
		north_Korea: "Pohjois-Korea",
		reunion: "Reunionsaari",
		san_Marino: "San Marino",
		senegal: "Senegal",
		sierra_Leone: "Sierra Leone",
		somalia: "Mogadishu",
		sudan: "Sudan",
		suriname: "Suriname",
		eswatini: "Swazimaa",
		syria: "Syyria",
		togo: "Togo",
		tonga: "Tonga",
		tunisia: "Tunisia",
		united_States_Virgin_Islands: "Yhdysvaltain Neitsytsaaret (USVI)",
		uganda: "Uganda",
		uruguay: "Uruguay",
		vatican: "Vatikaani",
		yemen: "Jemeniläinen",
		yugoslavia: "Jugoslavia",
		zambia: "Seychellit",
		zimbabwe: "Zimbabwe",
		china: "Kiina",
	}
};
