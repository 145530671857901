import Vue from 'vue';
import VueI18n from 'vue-i18n';
import elementZHTW from 'element-ui/lib/locale/lang/zh-TW'
import elementEN from 'element-ui/lib/locale/lang/en'
import elementKO from 'element-ui/lib/locale/lang/ko'
import elementPTBR from 'element-ui/lib/locale/lang/pt-br'
import elementES from 'element-ui/lib/locale/lang/es'
import elementTH from 'element-ui/lib/locale/lang/th'
import elementVI from 'element-ui/lib/locale/lang/vi'
import elementID from 'element-ui/lib/locale/lang/id'
import elementDE from 'element-ui/lib/locale/lang/de'
import elementFR from 'element-ui/lib/locale/lang/fr'
import elementJA from 'element-ui/lib/locale/lang/ja'
import elementFI from 'element-ui/lib/locale/lang/fi'
import elementHU from 'element-ui/lib/locale/lang/hu'
import elementSK from 'element-ui/lib/locale/lang/sk'
import elementSVSE from 'element-ui/lib/locale/lang/sv-SE'
import elementIT from 'element-ui/lib/locale/lang/it'
Vue.use(VueI18n);

//导入语言包
import Zh from '../lang/zh.js'; // 中文语言包
import ZhTw from '../lang/zh-tw.js'; // 中文繁体语言包
import En from '../lang/en.js'; // 英文语言包
import In from '../lang/in.js'; // 印地语言包
import Ko from '../lang/ko.js'; // 韩语语言包
import PtBr from '../lang/pt-br.js'; // 葡萄牙语言包
import Es from '../lang/es.js'; // 西班牙语言包
import Th from '../lang/th.js'; // 泰语语言
import Vi from '../lang/vi.js'; // 越南
import Id from '../lang/id.js'; // 印尼
import My from '../lang/my.js'; // 马来
import De from '../lang/de.js'; // 德语
import Fr from '../lang/fr.js'; // 法语
import Ja from '../lang/ja.js'; // 日语
import Fi from '../lang/fi.js'; // 芬兰
import Hu from '../lang/hu.js'; // 匈牙利语
import Sk from '../lang/sk.js'; // 斯洛伐克语
import SvSe from '../lang/sv-SE.js'; // 瑞典语
import It from '../lang/it.js'; // 意大利语

const messages = {
    zhTw: {
        ...elementZHTW,
        ...ZhTw
    },
    en: {
        ...elementEN,
        ...En
    },
    in: {
        ...elementEN,
        ...In
    },
    ko: {
        ...elementKO,
        ...Ko
    },
    ptbr: {
        ...elementPTBR,
        ...PtBr
    },
    es: {
        ...elementES,
        ...Es
    },
    th: {
        ...elementTH,
        ...Th
    },
    vi: {
        ...elementVI,
        ...Vi
    },
    id: {
        ...elementID,
        ...Id
    },
    my: {
        ...elementEN,
        ...My
    },
    de: {
        ...elementDE,
        ...De
    },
    fr: {
        ...elementFR,
        ...Fr
    },
    ja: {
        ...elementJA,
        ...Ja
    },
    fi: {
        ...elementFI,
        ...Fi
    },
    hu: {
        ...elementHU,
        ...Hu
    },
    sk: {
        ...elementSK,
        ...Sk
    },
    svse: {
        ...elementSVSE,
        ...SvSe
    },
    it: {
        ...elementIT,
        ...It
    },

}
window.localStorage.setItem("lang", 'en')
export default new VueI18n({
    locale: 'en', // set locale 默认显示英文
    fallbackLocale: 'en', //如果语言包没有，则默认从中文中抽取
    messages: messages // set locale messages
});
