export default {
	crypto: {
		title: {
			0: "Buy Crypto",
			1: "Buy Crypto",
			2: "C2C",
		},
		list: {
			0: "C2C Trading",
			1: "Buy",
			2: "Sell",
			3: "Advertisers (Completion rate)",
			4: "Trades",
			5: "Price",
			6: "Available",
			7: "Limit",
			8: "Payment Method",
			9: "Trade",
			10: "The advertiser may place transaction restrictions on the ad, and you will see the reason for the restriction after logging in.",
		},
		buy: {
			0: "Buy Crypto",
			1: "Sell Crypto",
			2: "History",
			3: "Support",
			4: "Pay",
			5: "Receive",
			6: "Select payment method",
			7: "Est. arrival time",
			8: "FEE",
			9: "Order Details",
			10: "Payment method",
			11: "Learn more",
			12: "Price",
			13: "Total (fee included)",
			14: "Disclaimer",
			15: "You are leaving HKDFinanc and will be redirected to Mercuryo, a licensed financial institution that provides credit/debit card payment service as a third party. Any risk that occurs during the use of the service shall be borne by Mercuryo. Please read and agree to the Terms of Use before proceeding.",
			16: "I have read and agree to the Terms of Use",
			17: "You will get",
			18: "Submit",
			19: "Please enter the amount",
			20: "Insufficient account balance",
			21: "Available",
			22: "Spot Account Availiable",
			23: "Single purchase limit",
			24: "Minimum",
			25: "Maximum",
		}
	},
	common: {
		confirm: "Definovať",
		cancel: "Zrušenie",
		tip: "Upozorniť na niečo",
		place_select: "Vyberte, prosím.",
		submit: "Predložiť",
		more: "Viac",
		deposit: "Záloha",
		real_trade: "Skutočná dohoda",
		paper_trade: "Demo obchodovanie",
		build_paperTrade: "Vytvorenie demo účtu",
		start_trade: "Začnite obchodovať teraz",
		app_name: "HKDFINANC",
		success: "Úspechy！",
		dataSources_name: "Trading Central",
		reset: "Reprovision",
		iknow: "Mám to!",
		noData: "Nie sú k dispozícii žiadne údaje",
		modify: "Úpravy",
		pingcang: "Uzavrieť pozíciu",
		all: "Úplný",
		placeEnter: "Prosím, zadajte",
		countryCode: "Kód krajiny/oblasti",
	},
	currency: {
		btcusdt: "Bitcoin",
		ethusdt: "Etherlands",
		ltcusdt: "Litekoin",
		xrpusdt: "Vlnenie (mena)",
		adausdt: "Ada minca",
		eosusdt: "Pomelo minca",
		dotusdt: "Boca Coin",
		trxusdt: "Minca vlna poľnohospodárskej pôdy (výpočtová technika)",
		xmrusdt: "Monroe minca",
		dogeusdt: "Mince pre psíkov",
		bnbusdt: "BNB",
		solusdt: "SOL",
		aptusdt: "Aptusdt",
		fx_saudusd: "AUD USD",
		fx_sgbpusd: "USD GBP",
		fx_seurusd: "EUR USD",
		fx_snzdusd: "Novozélandský dolár (NZD) Americký dolár",
		fx_susdcad: "USD CAD",
		fx_susdchf: "Švajčiarsky frank USD",
		fx_susdjpy: "USD JPY",
		fx_saudcad: "AUD CAD",
		fx_saudchf: "AUD Švajčiarsky frank",
		fx_saudjpy: "AUD JPY",
		fx_saudnzd: "AUD Nový Zélandský dolár (NZD)",
		fx_scadchf: "CAD švajčiarsky frank",
		fx_scadjpy: "Kanadský dolár Japonský jen",
		fx_schfjpy: "Švajčiarsky frank a japonský yen",
		fx_seuraud: "AUD Eura",
		fx_seurcad: "CAD Eurách",
		fx_seurgbp: "EUR GBP",
		fx_seurjpy: "Euro  japonský yen",
		fx_seurnzd: "Euro Nový Zélandský dolár (NZD)",
		fx_sgbpaud: "AUD GBP",
		fx_sgbpchf: "Švajčiarsky franc GBP",
		fx_sgbpjpy: "GBP JPY",
		fx_sgbpnzd: "Novozélandský dolár (NZD)",
		fx_scadnzd: "CAD NZD",
		hf_CL: "Ropa WTI New York",
		hf_OIL: "Ropa Brent",
		hf_GC: "Zlato z New Yorku",
		hf_SI: "New York Silver",
		hf_HG: "Meď (výpožička)",
		hf_NG: "Spojené štáty zemný plyn",
		hf_CAD: "Meď (výpožička)",
		hf_AHD: "Londýnsky hliník",
		HX_AAPL: "Granátové jablko",
		HX_BABA: "Ali Baba, postava z filmu Arabské noci",
		HX_GOOG: "Google",
		HX_WMT: "Wal-Mart, Walmart (maloobchodný predajca)",
		HX_AMZN: "HX_AMZN",
		HX_TSLA: "Nikola Tesla (1856-1943), srbský vynálezca a inžinier",
		HX_N225: "Nikkei 225",
		HX_GDAXI: "DAX30, Nemecko",
		HX_IBEX: "IBEX35, Španielsko",
		HX_SPX: "Index S&P 500",
		HX_FTSE: "FTSE 100",
		HX_KS11: "KOSPI Kórea",
		HX_NZ50: "Nový Zéland 50",
		HX_PSI20: "Portugalsko PSI20",
		hf_ZSD: "Londýnsky zinok",
		hf_NID: "London Nickel",
		hf_PBD: "Vedúca pozícia v Londýne",
		hf_SND: "Londýnska plechovka",
		hf_XAU: "Londýnske zlato (spotové zlato)",
		hf_XAG: "Londýnske striebro (Spot Silver)",
		hf_XPT: "Futures na platinu",
		hf_XPD: "Futures na paládium",
		hf_CT: "Americká bavlna",
		hf_SM: "Sójový prášok",
		hf_BO: "Sójový olej z USA",
		HX_SENSEX: "SENSEX, Bombaj, India",
		HX_KLSE: "FTSE Malaysia KLCI",
		HX_KSE100: "Karáči, Pakistan",
		HX_FCHI: "CAC40, Francúzsko",
		HX_SX5E: "Európsky snooker 50",
		HX_ICEXI: "ICEX, Island",
		HX_ASE: "ASE Atény, Grécko",
		HX_OMXC20: "OMX Kodaň 20",
		HX_OSEBX: "OSEBX, Nórsko",
		HX_OMXSPI: "OMXSPI, Švédsko",
		HX_AMD: "Chaowei Semiconductor",
		HX_DIS: "Disney (názov)",
		HX_MELI: "MercadoLibre Inc",
		HX_PSX: "Phillips 66",
		HX_TRUE: "TrueCar Inc",
		HX_SDRL: "Seadrill Ltd",
		HX_MA: "MasterCard (značka)",
		HX_NVDA: "NVIDIA",
		HX_INTC: "Intel",
		HX_GOGO: "Gogo Inc",
		HX_STX: "Technológia Seagate",
		usdcusdt: "USDC",
		filusdt: "FIL",
		maticusdt: "MATIC",
		linkusdt: "LINK",
		avaxusdt: "AVAX",
		atomusdt: "AstroCoin",
		icpusdt: "ICP",
		uniusdt: "UNI",
	},
	addnew: {
		paypass: "Obchodné heslo",
		loginpassTit: "Zmena hesla",
		paypassTit: "Úprava hesla do bankomatu",
		paypassTit2: "Sociálne heslo je pôvodne prihlasovacie heslo.",
		buyUp: "Vznášajúce sa",
		buyDown: "Nákup a predaj",
		addressName1: "China Gold Financial (International) Holdings Limited",
		addressValue1: "Miestnosť 1101, 11. poschodie, Koon Kwan Mansion (predtým Citibank Tower), 3 Garden Road, Central, Hongkong",
		addressName2: "Hongkong Zhong Cai Financial Investment Company Limited",
		addressValue2: "Zhongcai Centre, 131-133 Queen's Road Central, Central, Hongkong",
		addressName3: "Po Sun Financial Holdings Limited",
		addressValue3: "Miestnosť 1908, 19. poschodie, 89 Queensway, Admiralty, Hongkong Lippo Centre Tower 2",
		loan: "Získať pôžičku (napr. od banky).",
		repaid: "Vyplatené späť",
        unpaid: "Neplatenie",
		loanMore: "Chcem pôžičku.",
		immediateRepayment: "Okamžité splatenie",
		accountType1: "Účet mincí",
		accountType2: "Zmluvný účet",
		accountType3: "Účet opcií",
		from: "Cez",
        to: "Do",
		get: "Dostane",
		labelBreed: "Typ meny",
		placeBreed: "Prosím, vyberte menu",
		labelTransAccount: "Účet prevodu",
		labelAmount: "Prevedená suma",
		placeAmount: "Zadajte sumu prevodu",
		transferTit:"Prevody účtov",
		convertTit:"Blesková výmena mincí",
		balanceTit:"Zostatok na účte",
		available: "Dostupný úver",
        pending: "Uzamknutie",
        equivalent: "Sumu na",
		coinTrade: "Obchodovanie s mincami",
		secondContract: "Druhá zmluva (v športe)",
		number: "Množstvá",
		labelNumber: "Množstvá",
		placeNumber: "Zadajte počet výkupov",
		time: "Krát",
		loadAmount: "Očakávaná výška úveru",
		repaymentCycle: "Cyklus splácania pôžičky",
		dailyRate: "Denná úroková sadzba",
		repaymentMethod: "Spôsob splácania",
		loanTip: "Úverové pôžičky (uistite sa, že sú jasne viditeľné obrázky)",
		loanTip1: "Nahratie osvedčenia o vlastníctve",
        loanTip2: "Doklad o príjme (pracovný pomer)",
        loanTip3: "Údaje z bankového výpisu",
        loanTip4: "Nahrať prednú fotografiu preukazu totožnosti",
        interest: "Záujem",
		repaymentMethod1: "Splatné v jednej splátke",
	},
	params: {
		product_foreign: "Devízová mena",
		product_shop: "Tovar",
		product_number: "Exponenty",
		product_stock: "Akcie (trh)",
		product_coin: "Zašifrované",
		billTypes: [
			{v: 101, name: 'Dobíjanie'},
			{v: 102, name: 'Vklad'},
			{v: 103, name: 'Mraziť'},
			{v: 104, name: 'Rozmrazenie'},
			{v: 105, name: 'Depozit'},
			{v: 106, name: 'Depozit'},
			{v: 201, name: 'Zmrazenie výberu'},
			{v: 202, name: 'Vybrať finančné prostriedky'},
			{v: 203, name: 'Úspešné stiahnutie'},
			{v: 204, name: 'Nevyberiete finančné prostriedky'},
			{v: 205, name: 'Poplatok za stiahnutie'},
			{v: 206, name: 'Prenos von'},
			{v: 207, name: 'Posunúť na'},
			{v: 208, name: 'Prenos mincí'},
			{v: 209, name: 'Prenos mincí'},
			{v: 301, name: 'Poplatok za spracovanie zmluvy'},
			{v: 302, name: 'Zmluvné príjmy'},
			{v: 303, name: 'Strata zmluvy'},
			{v: 304, name: 'Zmluvné rozpätie'},
			{v: 305, name: 'Návratnosť zmluvnej marže'},
			{v: 311, name: 'Nákup opcií'},
			{v: 312, name: 'Zisk z opcie'},
			{v: 313, name: 'Vrátenie možností'},
			{v: 314, name: 'Poplatok za manipuláciu s opciou'},
			{v: 315, name: 'Zmrazenie nákupu mincí'},
			{v: 316, name: 'Odpočet pri kúpe mincí'},
			{v: 317, name: 'Vrátenie nákupu mincí'},
			{v: 318, name: 'Zápis mincí na účet'},
			{v: 319, name: 'Zápis mincí na účet'},
			{v: 320, name: 'Zmrazenie predaja mincí'},
			{v: 321, name: 'Odpočet pri predaji mincí'},
			{v: 322, name: 'Vrátenie predaných mincí'},
			{v: 323, name: 'Predaj mincí na účet'},
			{v: 324, name: 'Predaj mincí na účet'},
			{v: 325, name: 'Poplatok za manipuláciu s mincami'},
			{v: 401, name: 'Baníci sa pridávajú'},
			{v: 402, name: 'Návraty baníkov'},
			{v: 403, name: 'Príjmy z ťažobných strojov'},
			{v: 404, name: 'Výstup z ťažobného stroja'},
			{v: 405, name: 'Výstupný poplatok za ťažbu'},
			{v: 411, name: 'Príjmy z výpožičiek'},
			{v: 412, name: 'Výpožičný poplatok'},
			{v: 413, name: 'Úspešné splatenie'},
        ],
	},
	message: {
		logout: "Odhlásenie",
		register_success: "Úspešná registrácia",
		place_inner_personal: "Najprv zadajte svoje osobné údaje",
		submit_success: "Úspešne odoslané！",
		copy_success: "Úspech kopírovania！",
		copy_error: "Zlyhanie reprodukcie！",
		modify_success: "Úspešne upravený",
		no_balance: "Nedostatočný zostatok na pokrytie kaucie",
		palce_password: "Zadajte svoje heslo",
		trade_success: "Úspešná transakcia",
		placeEnterAmount: "Zadajte, prosím, sumu",
		tipTit1: "Ste si istí, že chcete zaplatiť za túto objednávku?",
	},
	web: {
		account: {
			menu1: "Informácie o účte",
			menu2: "Môj majetok",
			menu3: "Správy o účtoch",
			menu4: "Zabezpečenie účtu",
			menu5: "Oznámenie",
			menu6: "Centrum aktivít",
			menu7: "Moja peňaženka.",
			menu8: "Vybrať peniaze",
			walletAdd: "Pridať novú peňaženku",
			report: {
				history_tit: "Správa o histórii transakcií",
				stream_tit: "Správa o toku finančných prostriedkov",
				desc: "Vyberte príslušný rozsah dátumov na export správy o účte. (Budete mať prístup k informáciám o účte za posledný 1 rok)",
				select_date: "Vyberte rozsah dátumov",
				week: "V uplynulom týždni",
				month: "Asi mesiac",
				threeMonth: "Posledné tri mesiace",
				sixMonth: "Posledných šesť mesiacov",
				time: "Krát",
				view_report: "Zobraziť správu",
				email_send: "Odoslané e-mailom",
				dialog_tit: "Správy o účtoch",
				dialog_p1: "Spoločnosť HKDFINANC Holding Ltd je registrovaná na Kajmanských ostrovoch (číslo SIB: 1612446) a spracúva vaše transakcie ako obchodný subjekt.",
				tit2: "Správa o histórii obchodovania Mitade",
				tit4: "Správa o toku finančných prostriedkov Mitade",
				tit3: "Informácie o účte",
				label1: "Názov účtu",
				label2: "ID účtu",
				label3: "Mena účtu",
				label4: "Čas podávania správ",
				label5: "Obdobie vykazovania",
				label6: "Peňažný tok",
				empty: "Nemáte žiadne uzavreté obchody",
				email_des: "Správa bude odoslaná na vašu e-mailovú adresu",
				tab1: "Nahlasovanie",
				tab2: "Denný výkaz",
				tab3: "Mesačný výkaz",
			},
			account: {
				tit1: "Vitajte v HKDFINANC",
				tit2: "Úplné overenie totožnosti na obchodovanie so skutočnými vkladmi",
				p1: "Otvorte si reálny obchodný účet",
				p2: "Overovanie",
				btn1: "Overovanie",
				tit3: "Osobné informácie",
				label_name: "Meno a priezvisko",
				label_email: "E-mailová adresa",
				label_address: "Adresa bydliska",
				tit4: "Informácie o účte",
				label_account: "Obchodný účet (reálna transakcia)",
				label_currency: "Základná mena (reálne transakcie)",
				label_company: "Spoločnosť prevádzkujúca bežný účet",
				company_tip: "HKDFINANC Holding Ltd,HKDFINANC Holding Ltd Skupina spoločností HKDFINANC má licenciu a povolenie od Menového úradu Kajmanských ostrovov (CIMA).",
				tit5: "Otvorte si reálny obchodný účet",
				tit6: "Zlepšenie informácií",
				tit7: "Vyplňte svoje základné informácie",
				tit8: "Hodnotenie rizík",
				tit9: "Posúdenie tolerancie obchodného rizika",
				tit10: "Overovanie",
				tit11: "Dokončenie overenia totožnosti v súlade s regulačnými požiadavkami",
				p3: "Na overenie vyberte niektorý z nasledujúcich podporných dokumentov",
				type1: "Občianske preukazy",
				type2: "Vodičský preukaz",
				type3: "Pas",
				p4: "Potvrdzujem, že všetky poskytnuté informácie a dokumenty sú úplné, pravdivé a presné, a zaväzujem sa, že v prípade akejkoľvek aktualizácie poskytnem HKDFINANC do 30 dní aktualizované informácie.",
				next: "Další krok",
				btn2: "Akreditácia prešla",
				modify_personal_tit: "Úprava osobných údajov",
			},
			activity: {
				tit: "Centrum aktivít",
				desc: "Môžete sa zúčastňovať na aktivitách a získavať odmeny, ktoré zvýšia váš investičný výnos.",
			},
			capital: {
				real_trade: "Skutočná dohoda",
				label_nowAccount: "Bežný účet",
				label_tradeAccount: "Číslo obchodného účtu",
				label_currency: "Základná mena",
				deposit_withdraw_record: "Záznamy o vkladoch a výberoch",
				capital_flow_details: "Podrobnosti o toku finančných prostriedkov",
			},
			notification: {
				tit: "Nastavenia oznámení",
				tit1: "Otvorenie oznamovacích kanálov",
				email: "E-mailová adresa",
				message: "Zasielanie textových správ",
				push: "Stlačte",
				verify: "Potvrdiť (teóriu)",
				verified: "Overené",
				p1: "Otvorte/overte nasledujúce oznamovacie kanály, aby ste mali prehľad o dôležitých novinkách!",
				type1: "Trhovisko",
				tit2: "Oznámenie o marketingových aktivitách",
				desc2: "Informácie o špeciálnej ponuke platformy, prevádzkové aktivity a ďalšie novinky",
				tit3: "Návod na obsluhu",
				desc3: "Bohatý a špecializovaný znalostný materiál",
			},
			security: {
				tit: "Kryptografické",
				isSet: "Nakonfigurované",
				set: "Pokračujte a nastavte",
				verify: "Potvrdiť (teóriu)",
				verified: "Overené",
				tit1: "Bezpečnostná certifikácia",
				email: "E-mailová adresa",
				phone: "Číslo mobilného telefónu",
				phone_tit: "Viazanie čísla mobilného telefónu",
				password_tit: "Zmeniť heslo",
			},
		},
		market: {
			hot: "V móde",
			prev: "Predchádzajúca strana",
			next: "Dalšia strana",
			line: "Zdieľanie času",
			main_subplot: "Hlavné a vedľajšie ukazovatele grafu",
			main: "Hlavný indikátor grafu",
			subplot: "Indikátor grafu",
			go_trade: "Prejsť na stránku transakcie",
		},
		news: {
			tip: "Upozornenie: Vyššie uvedené informácie sú len všeobecné informácie pre referenciu a nesmú sa používať ako základ pre obchodné rozhodnutia.",
		},
		trade: {
			state1: "Holdingy",
			state2: "Cakajúca objednávka",
			state3: "Histórie",
			state: "Stav vecí",
			state4: "Zatvorená poloha",
			state5: "Stiahnuté",
			type: "Typológia",
			type1: "Uplný",
			type2: "Reach (cena v aukcii)",
			type3: "Buy-in",
			left_label1: "Samoobslužné služby",
			left_label2: "Nedávno zobrazené",
			left_type1: "Kompaktný stĺpcový model",
			left_type2: "Voľný stĺpovitý vzor",
			left_type3: "Zobraziť graf",
			all: "Uplný",
			right_bp: "Včerajšia úroda",
			right_sp: "Moderný deň",
			right_height: "Najvyšší",
			right_low: "Najnižšia",
			remind_tit: "Nové upozornenia",
			remindList: "Zoznam pripomienok",
			remind_btn: "Nové upozornenia",
			right_tab1: "Rokovania",
			right_tab2: "Udaje",
			right_sell: "Reach (cena v aukcii)",
			right_buy: "Buy-in",
			right_sell1: "Reach (cena v aukcii)",
			right_buy1: "Buy-in",
			right_type: "Typológia",
			right_type1: "Trhový cenník",
			right_type2: "Cakajúca objednávka",
			right_priceMoney: "Cena čakajúcej objednávky",
			right_pointerNumber: "Počet (šarží)",
			right_lever: "Páčidlo",
			right_balance: "Disponibilný zostatok",
			right_stop_profit: "Prevzatie zisku",
			right_stop_loss: "Stop-loss",
			right_profit_loss: "Ochabovanie a ubúdanie",
			now_order: "Objednajte si teraz",
			confirm_order: "Potvrdenie objednávky",
			right_tit1: "Obchodná stratégia",
			right_rinei: "V priebehu dňa",
			right_short: "Krátkodobé",
			right_middle: "V polovici obdobia",
			right_time: "Čas vydania",
			right_tit2: "Alternatívna stratégia",
			right_tit3: "Technické preskúmanie",
			right_tit4: "Čísla",
			right_tit5: "Obchodná nálada",
			right_label_sell: "Predajca (tovaru)",
			right_label_buy: "Kupujúci",
			right_tip: "Len na informačné účely, nie je to stanovisko oddelenia",
			right_tip2: "Aktualizované každých 15 minút",
			right_tit6: "Zmena ceny",
			minute: "Zápisnice",
			today: "V súčasnosti",
			right_tit7: "Rozsah zmeny ceny",
			now_price: "Aktuálna cena",
			right_low_price: "Najnižšia cena",
			right_height_price: "Najvyššia cena",
			right_tit8: "Informácie o zmluve",
			right_tit9: "AUD/CHF",
			right_label1: "Počet jednotlivých transakcií",
			right_label2: "Maximálny pákový efekt",
			right_label3: "Veľkosť zmluvy",
			right_label4: "Maximálny celkový počet pozícií",
			right_label5: "Plávajúce rozpätie",
			right_label6: "Nočné nabíjanie",
			hand: "Ruka",
			right_collection_time: "Čas zberu",
			right_tip3: "Základ poplatku: z celkovej veľkosti transakcie",
			right_label7: "Pomer marže",
			right_label8: "Pomer udržiavacieho rozpätia",
			right_label9: "Obchodné hodiny",
			right_label10: "Aktuálna obchodná relácia",
			right_label11: "Nasledujúca obchodná seansa",


		},
	},
	home: {
		banner_p1: "",
		banner_p2: "Transakcia platformy",
		banner_p3: "Forex, komodity, indexy, akcie",
		banner_p4: "A ďalšie obľúbené finančné produkty",
		banner_p5: "Môžete preskúmať najhorúcejšie finančné trhy na svete. ",
		hot: "V móde",
		tit1: "Priateľskejšie transakcie",
		p1: "Jednoduché a intuitívne rozhranie na jednoduché ovládanie",
		p2: "Podpora pre web, mobilné aplikácie a počítače, obchodovanie na dosah ruky",
		p3: "Nástroje na riadenie rizík, ako napríklad stop loss/trailing stop",
		p4: "Viaceré technické grafy a finančné kalendáre, správy v reálnom čase",
		p5: "E-mailové, SMS a push upozornenia v reálnom čase",
		p6: "Neustále zlepšovanie pre lepší obchodný zážitok",
		btn1: "Preskúmajte naše platformy",
		row3_tit: "Obchodujte voľne kedykoľvek a kdekoľvek",
		row3_tip: "Podpora pre web, mobilné aplikácie a počítače, obchodovanie na dosah ruky",
		row3_tit2: "Naskenujte kód na stiahnutie",
		row3_tit3: "Stolný počítač",
		row3_tit4: "Mobilný",
		regular_tit: "Dôveryhodná medzinárodná platforma",
		regular_tip: "Zaväzujeme sa poskytovať našim zákazníkom bezpečné a zodpovedné obchodné prostredie.。",
		regular_tit1: "Regulované autoritatívnymi organizáciami",
		regular_p1: "Regulované a autorizované priemyselnými orgánmi a dôveryhodné pre zákazníkov na celom svete",
		regular_tit2: "Ochrana finančnej bezpečnosti",
		regular_p2: "Vklady retailových klientov sa podľa potreby oddeľujú na správcovských účtoch v súlade s regulačnými požiadavkami.",
		regular_tit3: "Ochrana pred záporným zostatkom",
		regular_p3: "Poskytnite si ochranu pred záporným zostatkom na účte, záporný zostatok včas vymažte z nuly, aby vaša strata nepresiahla sumu vstupu, pokojné transakcie",
		regular_tit4: "7*24 Hodín zákaznícky servis",
		regular_p4: "Úprimný a profesionálny tím zákazníckeho servisu, 24 hodín online podpora, radi vyriešime akýkoľvek váš problém!",
		service_tit: "Ďalšie služby, ktoré vám pomôžu",
		service_tit1: "Obchodná stratégia",
		service_p1: "Obchodné stratégie v reálnom čase, ktoré vám pomôžu pochopiť najnovšie trendy na trhu, lepšie pochopiť načasovanie obchodovania.",
		service_tit2: "Znalosti o obchodovaní",
		service_p2: "Naučte sa obchodovať zadarmo s HKDFINANC a zlepšite svoje obchodné zručnosti.",
		service_tit3: "Riadenie rizík",
		service_p3: "Prečítajte si o bezplatných nástrojoch na riadenie rizík, ktoré ponúka HKDFINANC, aby ste lepšie ochránili svoj majetok.",
		step_tit: "Jednoduché a pohodlné otvorenie účtu",
		step_tip: "Tri jednoduché kroky na otvorenie účtu za pár minút",
		step_tit1: "Zápis",
		step_p1: "Vyplňte informácie a odošlite žiadosť",
		step_tit2: "Záloha",
		step_p2: "Rýchly vklad finančných prostriedkov prostredníctvom rôznych metód",
		step_tit3: "Začiatok obchodovania",
		step_p3: "Objavte obchodné príležitosti a rýchlo zadávajte objednávky",
		award_tit: "Naše úspechy",
		award_p1: "Vždy sme sa usilovali o dokonalosť a usilujeme sa o dokonalosť a sme odhodlaní poskytovať našim klientom kvalitné transakčné služby.",
		award_p2: "Značka HKDFINANC je poctená, že môže získať toto prestížne ocenenie od prestížnej inštitúcie v tomto odvetví, ktoré je uznaním nepretržitého úsilia a záväzku tímu voči svojim klientom.",
		row7_tit: "Globálne finančné informácie v reálnom čase",
		row7_tit1: "Správy v reálnom čase",
	},
	learn: {
		education: {

		}
	},
	login: {
		click_download: "Kliknite pre stiahnutie",
		p1: "Obchodujte kedykoľvek a kdekoľvek",
		p2: "Obchodujte rýchlejšie a inteligentnejšie",
		p3: "0 provízie z obchodovania",
		p4: "Najhorúcejšia investícia na svete",
		renderDom1: "Devízová mena",
		renderDom2: "Americký akciový trh",
		renderDom3: "Platina",
		renderDom4: "Surová ropa",
		renderDom5: "Exponenty",
		renderDom6: "Kryptomena",
		login: "Prihlásiť sa",
		elail: "Schránka",
		phone: "Císlo mobilného telefónu",
		no_account: "Žiadny účet？",
		go_register: "Prejsť na registráciu",
		register: "Zápis",
		now_register: "Zaregistrujte sa teraz",
		have_account: "Už máte účet？",
		go_login: "Prejsť na prihlásenie",
	},
	about: {
		serviceFee: "Poplatok za služby",
		introduction: "Predstavenie platformy",
		trade_platform: "Obchodná platforma",
		complaint_process: "Proces podávania sťažností",
		page: "Webová stránka",
		place_search: "Vyhľadajte, čo chcete vedieť",
		no_answer: "Nemôžete nájsť potrebnú odpoveď?",
		contact_us: "Kontaktujte nás",
		awards: {
			h1: "Ocenenia a vyznamenania",
			p1: "Značka HKDFINANC je poctená, že v rámci našej neustálej snahy o dokonalosť a odhodlania poskytovať našim klientom kvalitné transakčné služby získala ocenenie od prestížnej organizácie v tomto odvetví, ktoré je uznaním nepretržitého úsilia tímu a jeho oddanosti našim klientom.",
			p2: "Alebo si okamžite vyskúšajte našu oceňovanú online obchodnú platformu!",
			name1: "Najlepšie maklérstvo v Latinskej Amerike",
			name2: "Najlepší CFD broker",
			name3: "Najlepší sprostredkovateľ Fintech",
			name4: "10 najvplyvnejších podnikov v roku 2021",
			name5: "Najtransparentnejší maklér",
			name6: "Ultimate Fintech Awards",
		},
		charges: {
			h1: "Poplatky a platby",
			p1: "Ako neoddeliteľnú súčasť zlepšovania skúseností klientov chce HKDFINANC poskytovať našim klientom nákladovo efektívnejšie obchodné služby. Snažíme sa byť otvorení a transparentní v našich poplatkoch a platbách, bez skrytých poplatkov. Hlavnými transakčnými nákladmi pre klientov sú rozpätie nákup/predaj a úrok z nočnej pozície.",
			h2: "Po prvé, pri obchodovaní s HKDFINANC môžete využívať nasledujúce výhody",
			li1: "Okamžitá cenová ponuka produktu",
			li2: "Odborné a technické ukazovatele",
			li3: "Exkluzívna analýza trhu",
			free: "Freeware",
			view: "Pozrite sa nahor",
			h3: "Rozpätie ponuky a dopytu",
			p2: "HKDFINANC si ako kompenzáciu za svoje služby účtuje rozpätie ponuky a dopytu. Tento poplatok je zohľadnený v kotácii produktu a pri otvorení pozície tento spread skutočne platíte. Keďže poplatok za spread nie je pevne stanovený, odporúčame vám, aby ste prešli na stránku s ponukou konkrétneho produktu alebo sa prihlásili do",
			h4: "Poplatky za vklady a výbery",
			p3: "S cieľom minimalizovať náklady našich zákazníkov vám vo väčšine prípadov neúčtujeme poplatky za prístup k vašim finančným prostriedkom. Spoločnosť tretej strany alebo banka vám však môže účtovať poplatok za uskutočnenie vkladu alebo výberu, napríklad poplatok za tranzit cez banku.",
			p4: "Ďalšie informácie o poplatkoch získate od nášho pracovníka služieb zákazníkom.。",
			p5: "Napr. prostredníctvom medzinárodných transakcií kreditnými kartami, prevodov na/z bankových účtov alebo transakcií v nepodporovaných menách (konverzie cudzích mien) atď.",
			h5: "Náklady na jednodňovú pozíciu",
			p6: "Ak máte pozíciu ešte stále v držbe o 22:00 GMT (22:00 SEČ), bude vám účtovaný/odpočítaný nočný poplatok. Otázky týkajúce sa nočných poplatkov za jednotlivé produkty môžete zadávať na stránke s ponukou konkrétneho produktu alebo po prihlásení sa na",
			h6: "Ostatné náklady",
			p7: "Žiadne iné poplatky. Naša politika spoplatňovania je úplne transparentná a všetky položky, ktoré si vyžadujú poplatok, budú oznámené a oznámené vopred, takže sa nemusíte obávať skrytých poplatkov."
		},
		contact: {
			h1: "Kontaktujte nás",
			address: "Adresa",
			custom_email: "Kontaktný e-mail zákazníka",
			business_email: "Obchodná spolupráca E-mail",
			h2: "Napíšte nám.",
			p1: "Ak máte akékoľvek otázky týkajúce sa našich služieb, vyplňte nasledujúci formulár a pošlite svoju otázku, na ktorú vám čo najskôr odpovie jeden z našich špecialistov na služby zákazníkom. Prípadne ak sa chcete zaregistrovať na odber našich denných obchodných stratégií poskytovaných v spolupráci so spoločnosťou Trading Central, vyberte v type formulára nižšie možnosť 'Prihlásiť sa k odberu denných trhových analýz' a vyplňte jazykovú verziu, ktorú chcete dostávať, a my vás zaregistrujeme. Služba predplatného je dostupná len pre existujúcich reálnych klientov HKDFINANC.",
			h3: "Postupy zastupovania",
			p2: "Všetky sťažnosti prešetrí a vyrieši náš špecializovaný tím, viac informácií nájdete na",
		},
		faq: {
			h1: "Bežné problémy",
			tit1: "Na čo slúži demo účet？",
			tit2: "Ako si otvoriť živý účet？",
			tit3: "Ako vytvoriť trhový príkaz？",
			tit4: "Prečo nemôžem zadať objednávku?？",
			p1: "Demo účty sú väčšinou rovnaké ako reálne účty, pokiaľ ide o obchodné rozhranie, údaje a operácie. Demo účty obsahujú 50 000 USD virtuálnych prostriedkov a sú navrhnuté tak, aby sa klienti mohli oboznámiť s funkciami platformy prostredníctvom simulovaných operácií bez toho, aby riskovali svoje prostriedky.",
			p2: "Ak si chcete otvoriť reálny účet, postupujte podľa týchto krokov：",
			p3: "1. Vstúpte na registračnú stránku HKDFINANC, podľa pokynov: zaregistrujte poštovú schránku / číslo mobilného telefónu, nastavte prihlasovacie heslo, odošlite potvrdenie a automaticky vytvorte [Demo účet].",
			p4: "2. Na stránke ukážkového účtu vyberte možnosť 'Switch to Real Account' (Prepnúť na skutočný účet) a podľa postupu otvorte 'Real Account' (Skutočný účet).",
			p5: "Pred otvorením reálneho účtu vám odporúčame, aby ste si prečítali právne informácie súvisiace s obchodovaním a porozumeli im.",
			p6: "Upozorňujeme, že na svoj živý účet môžete vložiť peniaze až po overení totožnosti.",
			p7: "Kliknutím na ''Trade' (Obchodovať) sa zobrazia všetky produkty dostupné na obchodovanie v platforme, do vstupného poľa vpravo môžete zadať kód alebo názov produktu, aby ste ho vyhľadali, vybrať produkt a kliknutím na 'SELL/BUY' (Predávať/Kúpiť) sa zobrazí okno obchodovania, v ktorom môžete vidieť aktuálnu cenu a odhadovanú požadovanú maržu. Môžete ručne upraviť počet obchodov, ktoré sa majú otvoriť, nastaviť stop-loss a take-profit na kontrolu rizika a potom kliknutím na 'SELL/BUY' (Predaj/kupón) dokončiť otvorenie pozície. Upozorňujeme, že ceny všetkých finančných produktov sa kedykoľvek menia a aktualizujú spolu s trhom a trh sa mohol zmeniť skôr, ako ste stlačili tlačidlo BUY/SELL.",
			p8: "Najprv skontrolujte, či je sieťové pripojenie normálne. Keď je trh s obchodnými druhmi dočasne zatvorený citácia alebo zatvorený spôsobí nemožnosť zadávať príkazy. Pre ďalšiu pomoc sa obráťte na náš online zákaznícky servis.",

		},
		fundSecurity: {
			h1: "Finančné zabezpečenie",
			p1: "HKDFINANC je autorizovaná a regulovaná autoritatívnymi regulačnými orgánmi. Dodržiavanie predpisov je základom všetkého, čo robíme, a našej kultúry. Bezpečnosť vašich peňazí je prvoradá a všetky vklady klientov sú oddelené.",
			p2: "Vklady retailových zákazníkov sa podľa potreby vedú oddelene na správcovských účtoch v súlade s regulačnými požiadavkami",
			p3: "HKDFINANC nepoužíva finančné prostriedky klientov na žiadne komerčné aktivity okrem tých, ktoré sú povolené zákonmi a predpismi.",
			p4: "HKDFINANC nebude špekulovať na žiadnom trhu.",
			p5: "Nezávislé audity vykonávané účtovnými firmami tretích strán",
		},
		help: {
			h1: "Vitajte v Centre pomoci HKDFINANC",
		},
		media: {
			h1: "Mediálne centrum",
			p1: "Zobrazte si informácie, tlačové správy, mediálne príspevky, oznámenia a ďalšie informácie týkajúce sa značky HKDFINANC.",
		},
		mitrade: {
			h1: "O spoločnosti HKDFINANC",
			view_certificate: "（Metódy overovania）",
			p1: "HKDFINANC je fintech spoločnosť, ktorá má povolenie a je regulovaná úradmi a zameriava sa na poskytovanie jednoduchého a pohodlného obchodovania investorom. Inovatívna diverzifikovaná obchodná platforma HKDFINANC opakovane získala ocenenia ako Najlepšia mobilná obchodná platforma a Najinovatívnejšie maklérstvo.",
			p2: "Prostredníctvom platformy HKDFINANC môžete investovať a obchodovať na širšom spektre medzinárodných finančných trhov, ktoré zahŕňajú akcie, indexy, komodity, devízy a stovky ďalších populárnych druhov. HKDFINANC sa neobmedzuje len na užívateľsky prívetivé mobilné a webové obchodné platformy, ale poskytuje vám aj konkurencieschopné obchodné náklady, rýchle vykonávanie obchodov, vynikajúci zákaznícky servis a bohatú informačnú podporu, čo vám pomáha využiť včasné investičné, obchodné príležitosti.",
			p3: "Spoločnosť HKDFINANC je prísne regulovaná priemyselným orgánom, viacerými agentúrami. Spoločnosť HKDFINANC International je autorizovaná a regulovaná maurícijskou Komisiou pre finančné služby (FSC) pod licenčným číslom GB20025791. Podrobnosti o maurícijskej licencii nájdete na oficiálnej webovej stránke FSC https://www. fscmauritius.org",
			p4: "HKDFINANC Global je držiteľom licencie na poskytovanie finančných služieb (AFSL 398528), ktorú udelila Austrálska komisia pre cenné papiere a investície (ASIC). Viac informácií o austrálskych licenciách nájdete na webovej stránke ASIC www.asic.gov.au.",
			p5: "HKDFINANC je autorizovaná a regulovaná menovým úradom Kajmanských ostrovov (CIMA) na základe licencie SIB č. 1612446. Viac informácií o kajmanských licenciách nájdete na oficiálnej webovej stránke CIMA www.cima.ky.",
			p6: "Všetky obchodné operácie HKDFINANC sa vykonávajú pod prísnym dohľadom a v súlade so všetkými predpismi.",
			h2: "Pozadie HKDFINANC",
			p7: "Filozofiou spoločnosti HKDFINANC, založenej v austrálskom Melbourne tímom skúsených a erudovaných odborníkov na finančné obchodovanie a fintech, je uľahčiť obchodovanie a urobiť ho užívateľsky prívetivejším.",
			p8: "Naša komplexná stratégia a obchodný model nám umožňujú globálne a hĺbkové pozorovanie a poznanie vývoja odvetvia a dopytu na trhu, aby sme mohli cielenejšie alokovať zdroje, neustále inovovať technológie a optimalizovať efektivitu a naďalej prinášať našim zákazníkom pohodlnejšie a priateľskejšie obchodovanie. ",
			h3: "Prečo HKDFINANC",
			why1: "Nízkoprahové obchodné podmienky",
			why2: "Regulované priemyselnými orgánmi",
			why3: "Jednoduchá a intuitívna obchodná platforma",
			why4: "Vysoká úroveň online podpory",
			why5: "Konkurenčné transakčné náklady",
			why6: "Ochrana pred záporným zostatkom",
			h4: "Ocenenia a vyznamenania",
			p9: "Vždy sme sa usilovali o dokonalosť, usilujeme sa o dokonalosť a sme odhodlaní poskytovať našim klientom kvalitné transakčné služby.",
			p10: "Značka HKDFINANC je poctená, že môže získať toto prestížne ocenenie od prestížnej inštitúcie v tomto odvetví, ktoré je uznaním nepretržitého úsilia tímu a jeho oddanosti našim zákazníkom.",
			new_add: "Noví používatelia na celom svete",
			ol1: "Najlepší poskytovateľ správ a analýz",
			ol2: "Najlepší Forex Broker v Ázii",
			ol3: "Austrálske ocenenie spokojnosti zákazníkov Forex",
			ol4: "Najlepšia mobilná aplikácia",
			ol5: "Najrýchlejšie rastúci maklér v Austrálii",
			h5: "Prevádzková filozofia spoločnosti HKDFINANC",
			tip_tit1: "Záväzky",
			tip_tit2: "Jednoduchý, ale krásny",
			tip_tit3: "Otvorené (netajné)",
			tip_tit4: "Raziť nové cesty",
			tip_p1: "Každé obchodovanie je spojené s rizikom a spoločnosť HKDFINANC prijala ďalšie opatrenia na zabezpečenie zodpovedného obchodného prostredia pre svojich klientov. Spoločnosť HKDFINANC je regulovaná priemyselnými orgánmi, viacerými organizáciami a funguje s vysokou úrovňou bezpečnosti a stability. Naša platforma naďalej ponúka množstvo nástrojov zodpovedného obchodovania vrátane riadenia rizík.",
			tip_p2: "V jednoduchosti je krása, v detailoch dokonalosť, HKDFINANC dbá na jednoduchosť používania a používateľský zážitok v každom detaile. Naďalej optimalizujeme našu platformu a služby v nádeji, že každý, od začiatočníka až po skúseného investora, bude môcť využiť obchodné príležitosti, ktoré sú k dispozícii prostredníctvom platformy HKDFINANC.",
			tip_p3: "Aby naši zákazníci mohli plne využívať výhody spravodlivého a efektívneho obchodného prostredia, HKDFINANC sa riadi prísnym a transparentným samoregulačným systémom, ktorý poskytuje komplexné a transparentné informácie o produktoch platformy, cenové prostredie a údaje, všetky poplatky sú jasné a otvorené, aby sa zabezpečilo, že celý proces služieb je úplne úprimný a transparentný.",
			tip_p4: "HKDFINANC pevne verí, že technologický pokrok, finančné inovácie a širšie služby sú tým, čo poháňa rast, a že môžeme naďalej vytvárať a zdieľať hodnoty. S týmto cieľom podporujeme otvorené myslenie a pokračujeme v inováciách s cieľom byť inovátorom a lídrom v oblasti finančných technológií.",
			row6_tit1: "Naše poslanie",
			row6_tit2: "Naša vízia",
			row6_tit3: "Pracovné príležitosti",
			row6_p1: "Poskytnúť väčšiemu počtu ľudí, ktorí chcú obchodovať, spravodlivé, efektívne a transparentné obchodné prostredie, ktoré nie je obmedzené priestorom, časom a trhom, a byť lídrom v odvetví finančných a technologických inovácií.",
			row6_p2: "Veríme, že technologický pokrok, finančné inovácie a diverzifikované služby sú hybnou silou nášho rastu a umožňujú nám naďalej vytvárať a zdieľať hodnoty v spoločnosti.",
			row6_p3: "Za týmto účelom podporujeme otvorenosť a inovácie s cieľom stať sa prostredníctvom neustáleho rastu a pokroku",
			row6_p4: "Popredná svetová devízová spoločnosť",
			row6_p5: "Sme globálny tím v 7 krajinách a s rýchlo rastúcim tímom stále hľadáme odborníkov, ktorí by sa k nám pridali. Robte to, čo vás baví, v multikultúrnom tíme!",
			row6_p6: "Pošlite nám svoj aktuálny životopis a dôvody, prečo by ste chceli pracovať práve u nás:",
			row6_p7: "Prípadne sa môžete dozvedieť viac o",
			row6_p8: "Možno",
			row6_p9: "Pozrite si naše voľné pracovné miesta na。",
			row6_p10: "HKDFINANC prísne chráni bezpečnosť vašich osobných údajov. Spoločnosť HKDFINANC vás počas procesu podávania žiadosti o zamestnanie nebude žiadať o finančné informácie. Nevyžadujeme žiadne informácie týkajúce sa financií, kreditnej karty, bankového účtu ani žiadnu formu platby za nábor.",
			row6_p11: "Ak máte podozrenie, že pozícia je podvodná, kontaktujte nás prostredníctvom",
			row6_p12: "Kontaktujte personálne oddelenie HKDFINANC. Ak ste si istí, že ste boli podvedení falošnou pracovnou ponukou, obráťte sa na miestne oddelenie príslušnej organizácie.",

		}
	},
	product: {
		row2: {
			tit: "Obchodovanie s odrodami",
			sell: "Reach (cena v aukcii)",
			buy: "Buy-in",
			trade: "Rokovania",
			more_product: "Viac obchodných produktov",
		},
		row3: {
			tit: "Podporuje transakcie s viacerými zariadeniami",
			tit1: "Podporuje transakcie s viacerými zariadeniami",
			p1: "HKDFINANC vám ponúka webové, mobilné a desktopové obchodné platformy. Obchodovať môžete kedykoľvek a kdekoľvek.",
			download: "Sťahovanie",
		},
		row4: {
			tit: "Vyberte položku HKDFINANC",
			tit1: "Jednoduchá a intuitívna platforma",
			p1: "Integrácia kotácií, obchodovania, správy účtov, informácií, riadenia rizík, výkonných funkcií na dosiahnutie lepšej používateľskej skúsenosti",
			tit2: "Konkurenčné transakčné náklady",
			p2: "0 obchodná provízia, veľmi konkurencieschopné a transparentné nízke spready, nízke SWAPy, takže si môžete vychutnať nízkonákladové obchodovanie!",
			tit3: "Nízkoprahové obchodné podmienky",
			p3: "Minimálna veľkosť lotu pre každú transakciu je len 0,01 lotu a pozíciu môžete otvoriť s nízkou maržou.",
			tit4: "Regulované úradom",
			p4: "Všetky vklady klientov, ktoré sú autorizované a regulované priemyselnými orgánmi, sa uchovávajú v oddelených skladoch na ochranu bezpečnosti aktív klientov.",
			tit5: "Ochrana pred záporným zostatkom",
			p5: "Váš účet nikdy nestratí viac ako svoju istinu za akýchkoľvek trhových podmienok a záporné zostatky budú včas zúčtované, aby sa zlepšili vaše možnosti riadenia rizika.",
			tit6: "Vysoká úroveň online podpory",
			p6: "Poskytovať rýchlu odozvu online zákaznícky servis, bude poskytovať lepšie služby vďaka neutíchajúcemu úsiliu profesionálneho tímu",
		},
		row5: {
			tit: "Súvisiace problémy",
		},
		commodities: {
			title: "Obchod s komoditami",
			p: "Komodity, ako sú drahé kovy a energie, sa považujú za dôležitú súčasť diverzifikovaných investícií, pretože poskytujú ochranu pred infláciou, ako aj možnosti zhodnotenia investície. Spomedzi nich sú zlato, striebro a ropa komoditami, s ktorými sa obchoduje v obrovských objemoch, a ich otvorené a transparentné ceny, ako aj vysoká likvidita sú investormi všeobecne vítané. Ceny komodít výrazne kolíšu v dôsledku takých faktorov, ako je ponuka a dopyt, hospodárska a politická situácia a meny, čo poskytuje príležitosti na zhodnotenie rizika.",
		},
		forex: {
			title: "Obchodovanie s devízami",
			p: "Forexové obchodovanie je konverzia jednej meny na inú a devízový trh je najintenzívnejšie obchodovaným finančným trhom na svete. Devízový trh má veľký počet účastníkov, ktorí prostredníctvom neho obchodujú s cieľom uskutočniť platby, zabezpečiť sa proti riziku pohybu mien alebo dosiahnuť zisk. Obchodovanie na Forexe prebieha prostredníctvom siete bánk, inštitúcií a individuálnych obchodníkov na celom svete, pričom kolísanie cien 24 hodín denne, 5 dní v týždni, poskytuje príležitosti na výmenu rizika a zisku.",
		},
		indices: {
			title: "indices",
			p: "Akciový index je štatistická hodnota reprezentujúca určitý typ charakteristických akcií na konkrétnej burze a je ukazovateľom celkovej cenovej úrovne a pohybu určitého typu akcií na trhu. Obchodovanie s akciovými indexmi, ktoré odrážajú investičné príležitosti na celkovom trhu alebo v sektore, môže znížiť riziko investovania do jednotlivých akcií. Najobľúbenejšie indexy na amerických, európskych, ázijských a austrálskych trhoch, ako napríklad Australia 200, US Tech 100, Hong Kong 50, Germany 30 atď. ponúkajú rôzne príležitosti na svetových akciových trhoch.",
		},
		shares: {
			title: "Transakcia s akciami",
			p: "Akcia je obchodovateľný cenný papier vydaný na burze. S cieľom získať kapitál spoločnosti distribuujú vlastníctvo spoločnosti prostredníctvom kótovanej burzy pomocou certifikátov. Len v roku 2019 dosiahol objem svetových obchodov s akciami viac ako 60 biliónov USD, čo je taký veľký a likvidný objem, že aj po mnohých rokoch zostáva v očiach svetových investorov jedným z najpopulárnejších finančných nástrojov. Ceny akcií sú ovplyvňované rôznymi faktormi vrátane makroekonomiky, vyhliadok odvetvia a činnosti spoločnosti a sú veľmi volatilné, pričom príležitosti na investovanie a obchodovanie sú vždy prítomné.",
		},
		coins: {
			title: "Kryptomena",
			p: "Kryptomeny sú digitálne meny vytvorené pomocou kódu. Fungujú autonómne mimo tradičných bankových a vládnych systémov. Ako už názov napovedá, kryptomeny používajú šifrovanie na zabezpečenie transakcií a umožňujú vytváranie ďalších jednotiek, pričom pôvodnou a zďaleka najznámejšou kryptomenou je Bitcoin, ktorý vytvoril Satoshi Nakamoto a uviedol na trh v januári 2009. Dodnes nie je známe, či sa meno Satoshi Nakamoto vzťahuje na osobu alebo skupinu ľudí. Bitcoin sa považuje za prvú decentralizovanú kryptomenu. Podobne ako všetky kryptomeny je riadený prostredníctvom databázy transakcií blockchain, ktorá slúži ako distribuovaná verejná účtovná kniha. K dnešnému dňu je na obchodovanie online k dispozícii viac ako 1 000 kryptomien."
		}
	},
	trade: {
		data_sources: "Zdroj údajov",
		tip: "Vyššie uvedené informácie slúžia len ako referencia. HKDFINANC nezaručuje presnosť, aktuálnosť ani úplnosť informácií a na poskytnuté informácie by ste sa nemali neprimerane spoliehať. Tieto informácie neobsahujú naše cenové záznamy ani ponuku či výzvu na obchodovanie so žiadnymi finančnými nástrojmi. HKDFINANC nie je spoločnosť poskytujúca finančné poradenstvo a poskytuje len služby vykonávania pokynov. Čitateľom odporúčame, aby si sami vyhľadali investičné poradenstvo. Pozrite si, prosím, naše úplné vyhlásenie o vylúčení zodpovednosti.",
		tip2: "Aktualizované každý piatok o 15:00 (GMT)",
		tip3: "Trading Views je nástroj na predpovedanie obchodných nálad na trhu, ktorý odráža krátkodobé a strednodobé názory odborníkov z odvetvia a prognózy trendov pre každý horúci produkt.",
		analysis: {
			tit: "Obchodná stratégia",
			all: "Uplný",
			day: "V priebehu dňa",
			tit1: "Obchodná stratégia",
			tit2: "Technické preskúmanie",
		},
		calendar: {
			tit: "Finančný kalendár",
			prev_week: "Minulý týždeň",
			next_week: "Budúci týždeň",
			place_date: "Dátum vyhľadávania",
			select: "Možnosť",
			select1: "Národy",
			select2: "Význam",
			start_time: "Čas začatia",
			end_time: "Čas ukončenia",
			search: "Hľadať niečo",
		},
		contract: {
			tit: "Špecifikácie zmluvy",
			type1: "Uplný",
			type2: "Devízová mena",
			type3: "Tovar",
			type4: "Exponenty",
			type5: "Americké zásoby",
			type6: "Austrálske akcie",
			tit1: "Hlavné devízové kurzy",
			tit2: "Menšie devízové kurzy",
			tit3: "Tovar",
			tit4: "Hlavné indexy",
			tit5: "Menší index",
			tit6: "Americké zásoby",
			tit7: "Austrálske akcie",
			tit8: "Všeobecné pravidlá",
			p1: "Pomer nútenej rovinnosti",
			p2: "Platnosť čakajúcej objednávky",
			p3: "Neobmedzené",
			p4: "Čas zúčtovania poplatkov cez noc",
			p5: "Denné vyúčtovanie; leto: 05:00, zima: 06:00",
			p6: "Informácie o pákovom efekte, jednodňových sadzbách atď.",
			p7: "Pozrite si obchodné platformy",
			p8: "Uvedené platí pre všetky obchodné odrody",
			tit9: "Obchodný týždenný kalendár",
			p9: "Ukončenie (trhu)",
			p10: "Uvedené obchodné hodiny nezohľadňujú účinky sviatkov alebo osobitných úprav trhu.。",
			p11: "Špecifické odporúčania týkajúce sa času obchodovania",
			p12: "Prihlasovacia platforma",
			p13: "，Pozrite si profily príslušných odrôd.。",
		},
		forecast: {
			tit: "Perspektívy obchodovania",
			p1: "Priemerná hodnota",
			p2: "Bullish",
			p3: "Medvedí",
			p4: "Priečne prúdy",
			p5: "Orientácie",
		},
		news: {
			tit: "Správy v reálnom čase",
		},
		platforms: {
			tit: "Obchodná platforma HKDFINANC",
			p: "Jednoduché a intuitívne rozhranie, vynikajúci obchodný výkon, uspokojenie potrieb viacerých investorov. Začnite obchodovať s HKDFINANC ešte dnes.",
			tit1: "Pohodlná a ľahko použiteľná obchodná platforma HKDFINANC",
			tit2: "Obchodujte kedykoľvek a kdekoľvek s aplikáciou HKDFINANC!",
			li1: "Obchodovanie na stovkách populárnych trhov s autoritatívnou reguláciou na zaistenie bezpečnosti finančných prostriedkov",
			li2: "Sledujte trendy na trhu v reálnom čase, prezerajte si obchodné analýzy a najnovšie finančné správy.",
			li3: "Zobrazte si kedykoľvek rôzne typy obchodných grafov a použite funkcie grafov bez akýchkoľvek ťažkostí",
			li4: "Vyskúšajte oceňovanú obchodnú platformu HKDFINANC na svojom zariadení so systémom Android alebo iOS!",
			code_download: "Naskenujte kód na stiahnutie",
			tit3: "Nie je potrebné sťahovať, obchodujte online cez prehliadač",
			li5: "Nie je potrebné sťahovanie, obchodujte online priamo z prehliadača počítača",
			li6: "Ocenenie: Najlepší Forex Broker v Ázii 2022, Najlepšia obchodná platforma v Austrálii 2022, Najrýchlejšie rastúci Forex Fintech Broker na svete 2022",
			li7: "Poskytuje výkonné technické ukazovatele: MACD, KDJ, RSI, TRIX, DMA, CCI atď.",
			li8: "Vybavené nástrojmi na riadenie rizík, ako je ochrana proti stop-loss a zápornému zostatku, ktoré zmierňujú potenciálne riziko vašich obchodov.",
			tit4: "Dokonalé riešenie pre transakcie na pracovnom stole",
			li9: "Jednoduché zobrazenie trhových kotácií v zozname, ktorý si sami vyberiete",
			li10: "Povolenie upozornení na prijímanie upozornení na zmenu ceny a ďalších informačných oznámení",
			li11: "Viaceré rozloženia grafov umožňujú sledovať trh z rôznych časových dimenzií.",
			li12: "Špecifikácia zmlúv, obchodný sentiment a analýza obchodovania - všetko v jednom rozhraní, bez potreby prepínania",
			li13: "Získajte najnovšie obchodné správy priamo v HKDFINANC!",
			tit5: "Prečo si vybrať obchodnú platformu HKDFINANC?",
			row5_p1: "Bezpečnosť",
			row5_p2: "0% Provízia",
			row5_p3: "Podpora viacerých platforiem",
			row5_p4: "Technický graf",
			row5_p5: "Prístup kedykoľvek a odkiaľkoľvek",
			row5_p6: "Rôzne nástroje na kreslenie",
		},
		quotes: {
			tit: "Cenové ponuky v reálnom čase",
			p1: "Poskytuje vám okamžité kotácie, nálady na trhu a súvisiace správy o aktuálnych finančných ukazovateľoch.",
			hot: "V móde",
		},
		risk: {
			tit: "Riadenie rizík",
			p1: "Každé obchodovanie zahŕňa riziko. Využitím bezplatných nástrojov na riadenie rizík, ktoré poskytuje HKDFINANC, môžete efektívne riadiť svoje riziko bez ohľadu na trhové podmienky.",
			function_tit1: "Funkcia Take Profit/Stop Loss",
			function_tit2: "Funkcia Trailing Stop",
			function_p1: "Uzamknutie zisku",
			function_p2: "Obmedzenie strát",
			function_p3: "Maximalizácia uzamknutia zisku",
			function_p4: "Nie je potrebné automaticky monitorovať pozície",
			function_p5: "Úprava ceny za ukončenie straty",
			row2_tit1: "Funkcia Take Profit/Stop Loss",
			row2_p1: "Pri vytváraní nového príkazu alebo zmene existujúceho príkazu ponúkame možnosť nastaviť 'Take Profit' a 'Stop Loss'. Po nastavení sa príkaz zvyčajne uzavrie pri nastavenej cieľovej cene, čo vám pomôže uzamknúť zisky, keď dosiahnete svoj cieľ, alebo znížiť straty, ak sa trh pohne proti vám. Upozorňujeme, že každý pokyn môže byť krátky z dôvodu volatility trhu, v takom prípade systém nebude môcť vykonať pokyn za predvolenú cenu, ale uzavrie vašu pozíciu za najbližšiu najvýhodnejšiu cenu od cieľovej ceny.",
			row2_tit2: " Príklady",
			row2_p2: "Aktuálna cena EUR/USD je 1,13816/1,13837 (Predaj/Kúpa). Otvoríte príkaz na nákup 1 lotu (1 lot = 100 000 EUR) na úrovni 1,13837 a nastavíte stop loss na 1,13806.",
			row2_p3: "Vo všeobecnosti, keď cena klesne na 1,13806, váš stop loss príkaz sa aktivuje a pozícia sa uzavrie na 1,13806 s kombinovanou stratou 31 USD.",
			row2_p4: "Keď sa však trhové podmienky zmenia a cena sa posunie priamo z 1,13837 na 1,13795, pričom preskočí váš cieľ Stop Loss, trh skočí a systém nebude schopný uzavrieť pozíciu na 1,13806, ale uzavrie pozíciu za vás na ďalšej najvýhodnejšej cene, t. j. 1,13795, a konečná strata bude 42 USD.",
			row3_tit1: "Funkcia Trailing Stop",
			row3_tit2: "Príklady",
			row3_p1: "Trailing stops (známe aj ako koncové zastávky) sú výkonnou funkciou, ktorá vám umožňuje uzamknúť zisky alebo minimalizovať straty bez toho, aby ste museli neustále sledovať svoje pozície. Pri vytváraní príkazu jednoducho nastavíte trailing stop a keď sa cena pohne vo váš prospech, váš príkaz stop sa automaticky aktualizuje o najnovšiu cenu. Naopak, keď sa cena pohne vo váš neprospech, príkaz stop loss sa aktivuje a pozícia sa uzavrie vo vzdialenosti, ktorú ste nastavili pre stratu. Upozorňujeme, že akýkoľvek pokyn môže byť krátky z dôvodu volatility trhu, v takom prípade systém nebude môcť vykonať pokyn za vašu predvolenú cenu, ale uzavrie pozíciu za vás za najbližšiu najvýhodnejšiu cenu od cieľovej ceny.",
			row3_p2: "Aktuálna cena EUR/USD je 1,13816/1,13837 (sell/buy). Vytvorili ste príkaz na nákup s hodnotou 1 lotu na úrovni 1,13837 s trailing stop loss 100 pipov (0,00100).",
			row3_p3: "Keď je cena produktu na úrovni 1,13816, váš Stop Loss je 1,13716. Ak sa predajná cena produktu posunie na 1,13845, Stop Loss sa aktualizuje o vami nastavenú vzdialenosť a aktualizovaný Stop Loss bude 1,13745.",
			row3_p4: " Naopak, keď cena produktu klesne z 1,13845 na 1,13745, aktivuje sa trailing stop a pozícia sa uzavrie na úrovni 1,13745.",
			tip: "Bod: Zvyčajne sa najmenšia jednotka zmeny ceny finančného nástroja nazýva bod. V platforme HKDFINANC sa vzťahuje na poslednú číslicu alebo desatinné číslo ceny nástroja.",
		},
		sentiment: {
			tit: "Emocionálny index",
			type1: "Uplný",
			type2: "Devízová mena",
			type3: "Tovar",
			type4: "Exponenty",
			long: "Dlhodobé",
			short: "Krátky (financie)",
		},
	},
	layout: {
		aside: {
			nav1: "Rokovania",
			nav2: "Trh",
			nav3: "Informácie",
			nav4: "Vysoká škola",
			nav5: "Môj",
			newsDialog: {
				title: "Centrum správ",
				type1: "Systémové oznámenie",
				type2: "Bulletiny",
				allRead: "Označte všetky ako prečítané",
			},
			settingDialog: {
				title: "Nastaviť",
				nav1: "Konvenčné",
				nav2: "Zobraziť predvoľby",
				nav3: "Systémové informácie",
				logout: "Odhlásenie",
				setting1: "Viacjazyčnosť",
				setting2: "Rokovania",
				setting2_tip: "Otvorené pozície automaticky pridávajú samostatne vybrané odrody",
				setting3: "Spätná väzba",
				setting3_tip: "Funkčné odporúčania",
				setting4: "Autentikácia skutočného názvu",
				setting4_tip: "Autentikácia skutočného názvu",
				setting5: "Režim témy",
				setting5_label1: "Nejasne",
				setting5_label2: "Jasná farba",
				setting6: "Stúpajúce alebo klesajúce farby",
				setting6_label1: "Zelené rastúce a červené pády",
				setting6_label2: "Červený vzostup a zelený pád",
				setting7: "Nastavenia grafu",
				setting7_label1: "Štandardné vydanie",
				setting7_label2: "Vydanie TradingView",
				setting8: "Vyhlásenia a dohody",
				setting8_label1: "Politika súkromia",
				setting8_label2: "Vyhlásenie o zverejnení informácií o výrobku",
				setting8_label3: "Vyhlásenie o zverejnení rizík",
				setting8_label4: "Dohoda so zákazníkom",
				feedback_title: "Funkčné odporúčania",
			}
		},
		footer: {
			hot: "Obľúbené odrody",
			sort: "Sortiment",
			code: "Kódovanie",
			buy_price: "Nákupná cena",
			sell_price: "Cena predávajúceho",
			chg: "Nárast alebo pokles ceny",
		},
		header: {
			place_search: "Vyhľadávanie obchodných odrôd",
			balance: "Dostupný zostatok",
			balance_tip: "Dostupný zostatok: suma peňazí na účte, ktorú možno použiť na otvorenie nových pozícií.",
			profit_loss: "Výnosy a straty",
			asset_view: "Prehľad aktív",
			netValue: "Čistá hodnota",
			netValue_tip: "Čistá hodnota: hodnota bežného účtu vrátane ziskov a strát zo všetkých pozícií",
			marginLevel: "Úroveň marže",
			margin: "Margin",
			margin_tip: "Marža: suma potrebná na otvorenie a udržanie pozície v cudzej mene.",
			maintainsMargin: "Rozpätie údržby",
			maintainsMargin_tip: "Udržiavacia marža: Minimálna výška marže, ktorú je potrebné udržiavať na účte, kým držíte všetky svoje pozície.",

		},
	},
	table: {
		label_name: "Meno ",
		label_buyPrice: "Ponuková cena",
		label_sellPrice: "Predajná sadzba",
		label_variableValue: "Hodnota zmeny",
		trade: "Rokovania",
		label_code: "Kód produktu",
		label_title: "Názov (veci)",
		label_start: "Význam",
		label_country: "Národy",
		label_event: "Udalosť",
		label_previous: "Predchádzajúca hodnota",
		label_predictive: "Predpokladaná hodnota",
		label_announced: "Zverejnená hodnota",
		label_stop_loss_min: "Minimálna vzdialenosť pre príkazy stop loss",
		label_difference: "Dynamické rozpätie",
		label_contract_size: "Veľkosť zmluvy",
		label_max_hand: "Maximálna veľkosť dávky pre jednu transakciu",
		label_min_hand: "Minimálna veľkosť pozemku",
		label_trade_week: "Obchodný týždenný kalendár",
		label_week: "1 Týždeň",
		label_month: "1 Mesiac",
		label_quarter: "1 Stvrťrok",
		label_dailyTrend: "Jednodňový trend",
		label_tradeId: "ID transakcie",
		label_breed: "Sortiment",
		label_tradeType: "Typ transakcie",
		label_currency: "Mena",
		label_amount: "Suma peňazí",
		label_balance: "Zostatky",
		label_type: "Typológia",
		label_time: "Krát",
		label_orderNumber: "Císlo objednávky",
		label_pointer_number: "Počet rúk",
		label_price_money: "Otváracia cena",
		label_stop_win_price: "Prevzatie zisku",
		label_stop_lose_price: "Stop-loss",
		label_openTime: "Cas otvorenia",
		label_profit_loss: "Ochabovanie a ubúdanie",
		label_sell_buy: "Požadovaná/kúpna cena",
		label_chg: "Nárast alebo pokles ceny",
		label_sell_price: "Cena predávajúceho",
		label_buy_price: "Nákupná cena",
		label_condition: "Predpoklad",
	},
	form: {
		label_feedback: "Problémy a odporúčania",
		place_feedback: "Zadajte svoju otázku alebo návrh",
		label_img: "Fotografia",
		label_img_tip: "Nepovinné, uveďte snímku obrazovky s problémom",
		feedback_tip: "Ak máte naliehavú otázku, kontaktujte",
		online: "Online zákaznícky servis",
		label_name: "Meno a priezvisko",
		place_name: "Zadajte svoje meno",
		label_email: "E-mailová adresa",
		place_email: "E-mailová adresa",
		message_email: "Zadajte svoju e-mailovú adresu",
		label_nationality: "Občianstvo",
		place_nationality: "Zadajte svoju štátnu príslušnosť",
		place_questionType: "Typ problému",
		message_questionType: "Vyberte typ",
		questionType0: "Obchodná platforma",
		questionType1: "Produkty a poplatky",
		questionType2: "Prihláste sa na odber denných analýz trhu",
		questionType3: "Služby zákazníkom",
		questionType4: "Zvyšok",
		place_question: "Vaša otázka",
		message_question: "Zadajte otázku",
		submit_question: "Predkladanie otázok",
		label_phone: "Císlo mobilného telefónu",
		place_phone: "Zadajte svoje mobilné telefónne číslo",
		message_phone: "Zadajte svoje mobilné telefónne číslo",
		label_password: "Kryptografické",
		place_password: "Zadajte svoje heslo",
		message_password: "Zadajte svoje heslo",
		label_confirmPassword: "Potvrdenie hesla",
		place_confirmPassword: "Zadajte prosím svoje heslo znova",
		message_confirmPassword: "Zadajte prosím svoje heslo znova",
		label_captcha: "CAPTCHA",
		place_captcha: "Zadajte overovací kód",
		message_captcha: "Zadajte overovací kód",
		get_captcha: "Získať CAPTCHA",
		label_inviteId: "Pozývací kód",
		place_inviteId: "Pozývací kód (nepovinné)",
		to: "Do",
		start_time: "Čas začatia",
		end_time: "Čas ukončenia",
		label_cardNumber: "Číslo osvedčenia",
		place_cardNumber: "Zadajte svoje identifikačné číslo",
		message_cardNumber: "Zadajte svoje identifikačné číslo",
		label_cardMain: "Predná strana dokumentu",
		message_cardMain: "Zadajte prosím prednú stranu dokumentu",
		label_cardBack: "Zadná strana dokumentu",
		message_cardBack: "Prosím, uveďte druhú stranu dokumentu",
		confirm_modify: "Potvrdenie zmien",
		label_realName: "Celé meno",
		place_realName: "Zadajte svoje meno",
		message_realName: "Zadajte svoje celé meno",
		label_firstName: "Priezvisko",
		place_firstName: "Zadajte svoje priezvisko",
		message_firstName: "Zadajte prosím priezvisko",
		label_lastName: "Názov (veci)",
		place_lastName: "Zadajte svoje druhé meno",
		message_lastName: "Zadajte meno",
		label_birthday: "Dátum narodenia",
		place_birthday: "Zadajte svoj dátum narodenia",
		message_birthday: "Zadajte svoj dátum narodenia",
		place_nowPsd: "Zadajte svoje aktuálne heslo",
		message_nowPsd: "Zadajte svoje aktuálne heslo",
		place_newPsd: "Zadajte nové heslo",
		message_newPsd: "Zadajte nové heslo",
		place_crmPsd: "Potvrďte nové heslo",
		message_crmPsd: "Potvrďte nové heslo",
		label_breed: "Sortiment",
		place_breed: "Zadajte prosím druh",
		label_phase: "(Slov.) zlyhať (študent)",
		label_buyPrice: "Nákupná cena",
		label_sellPrice: "Cena predávajúceho",
		label_do: "Pretože",
		label_height: "Väčšia ako",
		label_low: "Byť nižšia ako",
		label_equal: "Alebo sa rovná",
		labelWalletName: "Názov peňaženky",
		messageWalletName: "Zadajte názov svojej peňaženky",
		placeWalletName: "Zadajte názov svojej peňaženky",
		labelWalletAddress: "Adresa peňaženky",
		messageWalletAddress: "Zadajte adresu svojej peňaženky",
		placeWalletAddress: "Zadajte adresu svojej peňaženky",
		labelAmount: "Suma peňazí",
		messageAmount: "Zadajte, prosím, sumu",
		placeAmount: "Zadajte, prosím, sumu",
		placeTimeLimit: "Zadajte časový limit",
		messageTimeLimit: "Zadajte časový limit",
	},
	pay: {
		title: "Jednoduchý prístup k finančným prostriedkom",
		p1: "Rôzne bežne používané platobné kanály, podpora rýchleho vkladu a výberu",
		p2: "Niektoré spôsoby platby nemusia byť vo vašej krajine dostupné.",
		p3: "Vitajte na skúsenostiach so službami HKDFINANC",
		btn: "Otvorte si účet a obchodujte teraz",
	},
	header: {
		tip1: "Obchodovanie s pákovým efektom je zložitý finančný produkt s vysokým rizikom rýchlej straty.",
		btn1: "Obchodná platforma",
		btn2: "Odhlásenie",
		btn3: "Prihlásiť sa",
		BigUint64Array: "Obchodovať teraz",
	},
	footer: {
		help: "Chcete？",
		tip1: "Sledujte našu komunitu",
		tip2: "Upozorňujeme, že HKDFINANC nezaložila oficiálnu skupinu Telegramu a akákoľvek skupina Telegramu vytvorená v mene HKDFINANC je podozrivá z falšovania.",
		tip3: "VAROVANIE PRED RIZIKOM: Obchodovanie môže viesť k strate všetkých vašich finančných prostriedkov. Obchodovanie s mimoburzovými derivátmi nie je vhodné pre každého. Pred použitím našich služieb si pozorne prečítajte naše právne dokumenty a uistite sa, že pred obchodovaním plne chápete súvisiace riziká. V skutočnosti nevlastníte ani nedržíte žiadne z podkladových aktív.",
		tip4: "HKDFINANC neposkytuje žiadne rady, odporúčania ani názory na nákup, držbu alebo predaj. Všetky produkty, ktoré ponúkame, sú mimoburzové deriváty založené na globálnych aktívach. všetky služby poskytované spoločnosťou HKDFINANC sú založené len na realizácii obchodných príkazov.",
		tip5: "HKDFINANC je obchodná značka zdieľaná viacerými spoločnosťami a prevádzkovaná prostredníctvom týchto spoločností:",
		tip6: "Spoločnosť HKDFINANC International Ltd je emitentom finančných produktov opísaných alebo dostupných na tejto webovej stránke. Spoločnosť HKDFINANC International Ltd je autorizovaná a regulovaná maurícijskou Komisiou pre finančné služby (FSC) pod licenčným číslom GB20025791 a má sídlo na adrese 6 St Denis Street, 1st Floor River Court, Port Louis 11328, Maurícius",
		tip7: "Spoločnosť HKDFINANC Global Pty Ltd je registrovaná pod číslom ABN 90 149 011 361, austrálska licencia na poskytovanie finančných služieb (AFSL) číslo 398528.",
		tip8: "Spoločnosť HKDFINANC Holding Ltd je autorizovaná a regulovaná menovým úradom Kajmanských ostrovov (CIMA) pod číslom licencie SIB 1612446.",
		tip9: "Informácie na tejto webovej lokalite nie sú určené pre obyvateľov Spojených štátov amerických, Kanady, Japonska alebo Nového Zélandu, ani nie sú určené na použitie v krajine alebo jurisdikcii, kde by ich zverejnenie alebo použitie bolo v rozpore s miestnymi zákonmi alebo predpismi. Upozorňujeme, že angličtina je hlavným jazykom našich služieb a je právne záväzným jazykom všetkých našich zmluvných dokumentov. Preklady do iných jazykov slúžia len ako referencia a v prípade akéhokoľvek rozporu medzi anglickou a čínskou verziou je rozhodujúca anglická verzia.",
		tip10: "Zabezpečené šifrovanie komunikácie SSL. Copyright © HKDFINANC, Všetky práva vyhradené.",
		link1: "Politika súkromia",
		link2: "Vyhlásenie o zverejnení informácií o výrobku",
		link3: "Proces podávania sťažností",
		link4: "Vyhlásenie o zverejnení rizík",
		link5: "Dohoda so zákazníkom",
		toTop: "Vrchol",
	},
	nav: {
		tit1: "Ponuky",
		tit2: "Rokovania",
		tit3: "Investičné vzdelávanie",
		tit4: "O nás",
		nav1: "Devízová mena",
		nav2: "Exponenty",
		nav3: "Tovar",
		nav4: "Akcie (trh)",
		nav5: "Obchodná platforma",
		nav6: "Obchodná stratégia",
		nav7: "Perspektívy obchodovania",
		nav8: "Finančný kalendár",
		nav9: "Správy v reálnom čase",
		nav10: "Cenové ponuky v reálnom čase",
		nav11: "Emocionálny index",
		nav12: "Eiadenie rizík",
		nav13: "Špecifikácie zmluvy",
		nav14: "Úvod do investovania",
		nav15: "Investičný pohľad",
		nav16: "Academy",
		nav17: "O spoločnosti HKDFINANC",
		nav18: "Ocenenia a vyznamenania",
		nav19: "Mediálne centrum",
		nav20: "Finančné zabezpečenie",
		nav21: "Poplatky a platby",
		nav22: "Affiliates",
		nav23: "Kontaktujte nás",
		nav24: "Bežné problémy",
		nav25: "Centrum pomoci",
	},

	city: {
		albania: "Albánsko",
		algeria: "Alžírsko",
		argentina: "Argentína",
		armenia: "Arménsko",
		australia: "Austrália",
		pakistan: "Pakistan",
		austria: "Rakúska",
		bahrain: "Bahrajn",
		belgium: "Belgicko",
		bosnia_and_Herzegovina: "Bosna a Hercegovina",
		brazil: "Brazílsky",
		brunei: "Brunei",
		bulgaria: "Bulharsko",
		cambodia: "Kambodža",
		canada: "Kanadský",
		cameroon: "Kamerun",
		chile: "Čile",
		colombia: "Kolumbia",
		costa_Rica: "Kostarika",
		croatia: "Chorvátska republika (1991-)",
		cyprus: "Cyprus",
		czech_Republic: "Česká republika",
		denmark: "Dánsko",
		dominican_Republic: "Dominikánska republika",
		egypt: "Egypt",
		estonia: "Estónsko",
		ethiopia: "Etiópia",
		finland: "Suomi",
		france: "Francúzsky",
		georgia: "Gruzínsko (krajina)",
		germany: "Nemčina",
		ghana: "Ghana",
		greece: "Grécko",
		guyana: "Guyana",
		honduras: "Honduras",
		hong_Kong_China: "Hongkong, Čína",
		hungary: "Maďarsko",
		iceland: "Islandský",
		ireland: "Írsky",
		italy: "Taliansko",
		india: "India",
		indonesia: "Indonézia",
		israel: "Izraelský",
		iran: "Iránsky",
		iraq: "Irak",
		japan: "Japonský",
		kazakstan: "Kazachstan",
		kenya: "Keňa",
		korea: "Kórea",
		kuwait: "Kuvajt",
		kyrgyzstan: "Kirgizsko",
		laos: "Laos",
		latvia: "Lotyšsko",
		lithuania: "Litva",
		luxembourg: "Luxembursko",
		macao_China: "Macao, Čína",
		macedonia: "Macedónčina",
		malaysia: "Malajzia",
		malta: "Maltský",
		mexico: "Mexiko",
		moldova: "Moldavsko",
		monaco: "Monaco",
		mongolia: "Mongolsko",
		montenegro: "Čierna Hora",
		morocco: "Maroko",
		myanmar: "Mjanmarsko",
		netherlands: "Holandsko",
		new_Zealand: "Nový Zéland",
		nepal: "Nepálsky",
		nigeria: "Nigéria, západná Afrika",
		norway: "Nórsko",
		oman: "Omán",
		palestine: "Palestína",
		panama: "Panama",
		paraguay: "Paraguaj",
		peru: "Peruánsky",
		philippines: "Filipíny",
		poland: "Poľský",
		portugal: "Portugalsko",
		puerto_Rico: "Puerto Rico",
		qatar: "Doha",
		romania: "Rumunsko",
		russia: "Georgia",
		republic_of_Trinidad_and_Tobago: "Trinidad a Tobago",
		rwanda: "Rwanda",
		saudi_Arabia: "Saudská Arábia",
		serbia: "Srbsko",
		singapore: "Singapur",
		slovakia: "Slovensko",
		slovenia: "Slovinsko",
		south_Africa: "Južná Afrika",
		spain: "Španielčina",
		sri_Lanka: "Srí Lanka",
		sweden: "Švédsko",
		switzerland: "Švajčiarsko",
		taiwan_China: "Čína-Taiwan",
		tajikistan: "Tadžikistan",
		tanzania: "Tanzánia",
		thailand: "Thajsko",
		turkey: "Istanbul",
		turkmenistan: "Turkménsko",
		ukraine: "Bielorusko",
		united_Arab_Emirates: "SAE",
		united_Kingdom: "Veľká Británia",
		united_States: "Spojené Štáty Americké",
		uzbekistan: "Uzbekistan",
		venezuela: "Venezuela",
		vietnam: "Vietnam",
		afghanistan: "Afganistan",
		angola: "Angola",
		azerbaijan: "Azerbajdžan",
		bangladesh: "Bangladéš",
		belarus: "Bielorusko",
		belize: "Belizean",
		benin: "Benin",
		bhutan: "Bhután",
		bolivia: "Bolívia",
		botswana: "Botswana",
		british_Virgin_Islands: "Britské Panenské ostrovy",
		burkina_Faso: "Burkina Faso, západná Afrika",
		burundi: "Burundi",
		cape_Verde: "Kapverdy",
		cayman_Islands: "Kajmanské ostrovy",
		central_African_Republic: "Stredoafrická republika",
		chad: "Čadská",
		comoros: "Komory",
		the_Republic_of_the_Congo: "Kongo (Brazzaville)",
		democratic_Republic_of_the_Congo: "Kongo (Demokratická republika)",
		djibouti: "Džibutsko",
		ecuador: "Ekvádor",
		el_Salvador: "Salvádor",
		equatorial_Guinea: "Rovníková Guinea",
		eritrea: "Eritrea",
		fiji: "FidžiName",
		gabon: "Gabonské",
		gambia: "Gambia",
		greenland: "Grónsko",
		guatemala: "Guatemala",
		guinea: "Guinea",
		haiti: "Haiti",
		isle_of_Man: "Ostrov Mann",
		cote_d_Ivoire: "Pobrežie Slonoviny",
		jamaica: "Jamajka",
		jordan: "Jordan",
		lebanon: "Nepriateľstvo",
		lesotho: "Lesotho",
		liberia: "Libéria",
		libya: "Líbya",
		madagascar: "Madagaskar",
		malawi: "Malawi",
		maldives: "Maldivy",
		mali: "Mali",
		mauritania: "Mauritánia",
		mauritius: "Maurícius",
		mozambique: "Mozambik",
		namibia: "Namíbia",
		nicaragua: "Nikaragua",
		republic_of_Niger: "Niger",
		north_Korea: "Severná Kórea",
		reunion: "Reunion Island",
		san_Marino: "San Maríno",
		senegal: "Senegal",
		sierra_Leone: "Sierra Leone",
		somalia: "Mogadišo",
		sudan: "Sudán",
		suriname: "Surinam",
		eswatini: "Svazijsko",
		syria: "Sýria",
		togo: "Togo",
		tonga: "Tonga",
		tunisia: "Tunisko",
		united_States_Virgin_Islands: "Americké Panenské ostrovy (USVI)",
		uganda: "Uganda",
		uruguay: "Uruguaj",
		vatican: "Vatikán",
		yemen: "Jemen",
		yugoslavia: "Juhoslávia",
		zambia: "Seychely",
		zimbabwe: "Zimbabwe",
		china: "Čína",
	}
};
