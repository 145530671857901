export default {
	crypto: {
		title: {
			0: "Buy Crypto",
			1: "Buy Crypto",
			2: "C2C",
		},
		list: {
			0: "C2C Trading",
			1: "Buy",
			2: "Sell",
			3: "Advertisers (Completion rate)",
			4: "Trades",
			5: "Price",
			6: "Available",
			7: "Limit",
			8: "Payment Method",
			9: "Trade",
			10: "The advertiser may place transaction restrictions on the ad, and you will see the reason for the restriction after logging in.",
		},
		buy: {
			0: "Buy Crypto",
			1: "Sell Crypto",
			2: "History",
			3: "Support",
			4: "Pay",
			5: "Receive",
			6: "Select payment method",
			7: "Est. arrival time",
			8: "FEE",
			9: "Order Details",
			10: "Payment method",
			11: "Learn more",
			12: "Price",
			13: "Total (fee included)",
			14: "Disclaimer",
			15: "You are leaving HKDFinanc and will be redirected to Mercuryo, a licensed financial institution that provides credit/debit card payment service as a third party. Any risk that occurs during the use of the service shall be borne by Mercuryo. Please read and agree to the Terms of Use before proceeding.",
			16: "I have read and agree to the Terms of Use",
			17: "You will get",
			18: "Submit",
			19: "Please enter the amount",
			20: "Insufficient account balance",
			21: "Available",
			22: "Spot Account Availiable",
			23: "Single purchase limit",
			24: "Minimum",
			25: "Maximum",
		}
	},
	common: {
		confirm: "पुष्टि करें",
		cancel: "रद्द करें",
		tip: "प्रश्न",
		place_select: "कृपया चुनें",
		submit: "भेजें",
		more: "अधिक",
		deposit: "पैसा डोसिट",
		real_trade: "वास्तविक ट्रांसेक्शन्स",
		paper_trade: "सिमुलेटेड ट्रांसेक्शन्स",
		build_paperTrade: "सिमुलेटेड खाता स्थापित कर रहा है",
		start_trade: "ट्रेडिंग तुरंत से प्रारंभ करें",
		app_name: "HKDFINANC",
		success: "सफलता！",
		dataSources_name: "Trading Central",
		reset: "रिसेट करें",
		iknow: "मिलाया",
		noData: "वर्तमान में कोई डाटा उपलब्ध नहीं है",
		modify: "परिवर्धित करें",
		pingcang: "स्थिति बन्द कर रहा है",
		all: "पूर्ण",
		placeEnter: "कृपया प्रविष्ट करें",
		countryCode: "देश कोड",
	},
	addnew: {
		realFirst: "कृपया पहिले परिचय प्रमाणीकरण करें",
		payFirst: "कृपया पासवर्ड पहले सेट करें",
		historyOrder: "इतिहास आदेश",
		forgot: "पासवर्ड भूल गया",
		contractTrade: "संपर्क",
		paypass: "पासवर्ड",
		loginpassTit: "लॉगइन पासवर्ड बदलें",
		paypassTit: "पासवर्ड बदलें",
		paypassTit2: "प्रारंभिक सामाजिक पासवर्ड लॉगइन पासवर्ड है",
		buyUp: "खरीद कर रहा है",
		buyDown: "नीचे खरीद करें",
		addressName1: "Sino-Gold Financial (International) Holdings Limited",
		addressValue1: "Room 1101, 11th Floor, Crown Tower (formerly Citibank Tower), 3 Garden Road, Central, Hong Kong.",
		addressName2: "Hong Kong Sino Finance Investment Limited",
		addressValue2: "131-133 Queen's Road Central, Zhongcai Center, Central, Hong Kong ",
		addressName3: "Prosperity Financial Holdings Limited",
		addressValue3: "Room 1908, 19th Floor, 89 Queensway, Admiralty, Hong Kong Lippo Centre Tower 2",
		loan: "कर्ज",
		repaid: "लॉन दुरुस्त",
		unpaid: "बिना पैसा",
		loanMore: "मुझे एक कर्ज की जरूरत है",
		immediateRepayment: "तुरंत प्रतिक्रिया",
		accountType1: "खाता",
		accountType2: "संपर्क खाता",
		accountType3: "विकल्प खाता",
		from: "से",
		to: "में",
		get: "प्राप्त होगा",
		labelBreed: "मुद्रा",
		placeBreed: "कृपया एक मुद्रा चुनें",
		labelTransAccount: "खाता स्थानान्तरण करें",
		labelAmount: "स्थानान्तरण मात्रा",
		placeAmount: "कृपया स्थानान्तरण मात्रा भरें",
		transferTit: "खाता स्थानान्तरण",
		convertTit: "मुद्रा फ्लैश बदलें",
		balanceTit: "खाताbalance",
		available: "उपलब्ध क्रीडिट",
		pending: "ताला लगाता है",
		equivalent: "बराबर",
		coinTrade: "मुद्रा ट्रेडिंग",
		secondContract: "दूसरा कंट्रांट",
		number: "मात्रा",
		labelNumber: "मात्रा",
		placeNumber: "कृपया विकास मूल्य प्रविष्ट करें",
		time: "समय",
		loadAmount: "अपेक्षित लान मात्रा",
        repaymentCycle: "लॉन पुनरावृत्ति साइकल",
        dailyRate: "दिनांक व्याज दर",
        repaymentMethod: "पुनरावृत्ति विधि",
        loanTip: "क्रेडिट लॉन (कृपया निश्चित करें कि छवि स्पष्ट और दृश्यात्मक है)",
        loanTip1: "गुण प्रमाणपत्र अपलोड करें",
        loanTip2: "आगत का प्रमाण (काम संबंध)",
        loanTip3: "बैंक स्टेटेंशन विवरण",
        loanTip4: "अपने आईडी कार्ड के आगे की फोटो अपलोड करें",
        interest: "प्राथमिकता",
		repaymentMethod1: "पुराने के बारे में एक बदला है",
	},
	params: {
		product_foreign: "विदेशी बदलें",
		product_shop: "कमोडिटी",
		product_number: "सूची",
		product_stock: "साझेदार",
		product_coin: "एनक्रिप्शन",
		billTypes: [
			{ v: 101, name: 'फिर चार्ज करें' },
			{ v: 102, name: 'जोड़ें' },
			{ v: 103, name: 'जल्दी' },
			{ v: 104, name: 'टाविंग' },
			{ v: 105, name: 'डिपोस्ट' },
			{ v: 106, name: 'डिपोस्ट' },
			{ v: 201, name: 'हटाएँ' },
			{ v: 202, name: 'डिडुक्शन' },
			{ v: 203, name: 'विडडवाल सफलता' },
			{ v: 204, name: 'वाटडवाल असफल' },
			{ v: 205, name: 'हटावाल फी' },
			{v: 206, name: 'स्थानान्तरण'},
			{v: 207, name: 'स्थानान्तरण'},
			{v: 208, name: 'मुद्रा स्थानान्तरण बाहर'},
			{v: 209, name: 'मुद्रा स्थानान्तरण'},
			{ v: 301, name: 'डोपोसिट का निकाल' },
			{ v: 302, name: 'हैंडलिंग फीज़ का डिड्यूक्शन' },
			{ v: 303, name: 'डोपोसिट का पुनरावृत्ति' },
			{ v: 304, name: 'लाभ' },
			{ v: 305, name: 'गुमान' },
			{v: 311, name: 'विकल्प'},
			{v: 312, name: 'विकल्प वापस होता है'},
			{v: 313, name: 'विकल्प पुनरावृत्ति'},
			{v: 314, name: 'विकल्प हैंडलिंग फी'},
			{v: 315, name: 'मुद्रा'},
			{v: 316, name: 'मुद्रा'},
			{v: 317, name: 'कोइन खरीद लौटाना'},
			{v: 318, name: 'प्राप्त कोइन्स का खरीदान'},
			{v: 319, name: 'प्राप्त कोइन्स का खरीदान'},
			{v: 320, name: 'मुद्रा विक्रेता फ्रीज़'},
			{v: 321, name: 'मुद्रा'},
			{v: 322, name: 'कोइन विक्रेता परित्याग'},
			{v: 323, name: 'खाता में बेचा गया कोइन्स'},
			{v: 324, name: 'खाता में बेचा गया कोइन्स'},
			{v: 325, name: 'मुद्रा हैंडलिंग फी'},
			{v: 401, name: 'मिनिंग मशीन जोड़ें'},
			{v: 402, name: 'मिनिंग मशीन वापस'},
			{v: 403, name: 'मिनिंग मशीनरी आगत'},
			{v: 404, name: 'मिनिंग मशीन बाहर होना'},
			{v: 405, name: 'मिनिंग मशीन हैंडलिंग फी'},
			{v: 411, name: 'आयाम लेना है'},
			{v: 412, name: 'लॉन हैंडलिंग फीज़'},
			{v: 413, name: 'सफलतापूर्वक बदला'},
		],
	},
	message: {
		logout: "लागइन से लॉग आउट",
		register_success: "लागइन सफल होता है",
		place_inner_personal: "कृपया पहिले व्यक्तिगत जानकारी भरें",
		submit_success: "सफलतापूर्वक जमा किया गया！",
		copy_success: "सफलतापूर्वक नक़ल किया गया！",
		copy_error: "नकल असफल！",
		modify_success: "सफलतापूर्वक परिवर्धित",
		no_balance: "उत्तर देने के लिए अपर्याप्त बालेंस",
		palce_password: "कृपया पासवर्ड भरें",
		trade_success: "सफलतापूर्वक ट्रांसेक्शन",
		placeEnterAmount: "कृपया मात्रा भरें",
		tipTit1: "क्या आप निश्चित है कि आप इस क्रम के लिए भुगता करना चाहते हैं",
	},
	web: {
		account: {
			menu1: "खाता जानकारी",
			menu2: "मेरे मालिक",
			menu3: "खाता रिपोर्ट",
			menu4: "खाता सुरक्षा",
			menu5: "सूचना",
			menu6: "क्रिया केंद्र",
			menu7: "मेरा पाकीट",
			menu8: "कैश आउट",
			walletAdd: "नया वालेट जोड़ें",
			report: {
				history_tit: "ट्रांसेक्शन इतिहास रिपोर्ट",
				stream_tit: "कैपिटल फ्लोव रिपोर्ट",
				desc: "कृपया खाता रिपोर्ट निर्यात करने के लिए उचित तारीख सीमा चुनें. (आप पिछले साल में खाता जानकारी जाँच सकते हैं)",
				select_date: "तारीख सीमा चुनें",
				week: "पिछले सप्ताह",
				month: "पिछला महीना",
				threeMonth: "पिछले तीन महीने में",
				sixMonth: "पिछले छह महीने में",
				time: "समय",
				view_report: "रिपोर्ट दिखाएँ",
				email_send: "इमेल द्वारा भेजें",
				dialog_tit: "खाता रिपोर्ट",
				dialog_p1: "मिट्रेड होल्डिंग लिट्डिंग कैमेन द्वीप में रिजिस्टर किया गया है (SIB नंबर 1612446) और आपका ट्रांसेक्शन के विषय में प्रक्रिया",
				tit2: "मिटेड ट्रांसेक्शन इतिहास रिपोर्ट",
				tit4: "Mitade कैपिटल फ्लोव रिपोर्ट",
				tit3: "खाता जानकारी",
				label1: "खाता का शीर्षक",
				label2: "खाता आईडी",
				label3: "खाता मुद्रा",
				label4: "रिपोर्ट समय",
				label5: "रिपोर्टिंग अवधि",
				label6: "राजधानीय प्रवाह",
				empty: "तुम्हारे पास कोई बंद व्यापार नहीं है",
				email_des: "रिपोर्ट आपके इमेल पर भेजा जाएगा",
				tab1: "रिपोर्ट@ info: tooltip",
				tab2: "दिनांक विवरण",
				tab3: "महिनावारी संदेश",
			},
			account: {
				tit1: "स्वागत है HKDFINANC",
				tit2: "वास्तविक डेपोसिट ट्रांसेक्शन के साथ जारी करने के लिए पहिचान परीक्षण पूरा करें",
				p1: "वास्तविक ट्रांसेक्शन खाता खोल रहा है",
				p2: "सत्यापन",
				btn1: "सत्यापन",
				tit3: "व्यक्तिगत जानकारी",
				label_name: "नाम",
				label_email: "इमेल",
				label_address: "रिज़िस्टेन्सियल पता",
				tit4: "खाता जानकारी",
				label_account: "खाता ट्रेडिंग (वास्तविक ट्रांसेक्शन)",
				label_currency: "आधार मुद्रा",
				label_company: "मौजूदा खाता खोलने कंपनी",
				company_tip: "मिट्रेड होल्डिंग Ltd, मिट्रेड ग्रूप के नीचे एक कंपनी, कैमन द्वीप मोनेटारी अधिकारी से अनुमति और अधिकार प्राप्त हुई है.",
				tit5: "वास्तविक ट्रांसेक्शन खाता खोल रहा है",
				tit6: "जानकारी सुधारें",
				tit7: "अपनी मूल जानकारी में भरें",
				tit8: "जोखिया",
				tit9: "ट्रेडिंग रोसिस टोलेंस",
				tit10: "सत्यापन",
				tit11: "नियमित आवश्यकता के अनुसार पहचान परीक्षण पूरा करें",
				p3: "कृपया निम्न दस्तावेज़ों में से किसी को चुनें",
				type1: "आईडी कार्ड",
				type2: "ड्राइवर की लाइसेंस",
				type3: "पासपोर्ट",
				p4: "मैं पुष्टि करता हूँ कि प्रदान किया गया है सभी जानकारी और दस्तावेज़ पूरी हैं, सच्चा और सच्चा है. यदि जानकारी के लिए कोई अद्यतन है, तो म",
				next: "अगला चरण",
				btn2: "प्रमाणपत्र",
				modify_personal_tit: "व्यक्तिगत जानकारी सुधारें",
			},
			activity: {
				tit: "क्रिया केंद्र",
				desc: "आप प्रतिदान प्राप्त करने के लिए क्रियाकलाप में सहभागी कर सकते हैं",
			},
			capital: {
				real_trade: "वास्तविक ट्रांसेक्शन्स",
				label_nowAccount: "मौजूदा खाता",
				label_tradeAccount: "खाता ट्रेडिंग",
				label_currency: "आधार मुद्रा",
				deposit_withdraw_record: "डिपोज़िट और छोड़ने के रेकॉर्ड",
				capital_flow_details: "राजधानी फ्लोव विवरण",
			},
			notification: {
				tit: "सूचना विन्यास",
				tit1: "सूचना चैनल खोलें",
				email: "इमेल",
				message: "संदेश",
				push: "पुश करें",
				verify: "वैध",
				verified: "सत्यापित",
				p1: "कृपया निम्न सूचना चैनल खोलें/सत्यापित करें, महत्वपूर्ण संदेश गुम नहीं है!",
				type1: "मार्केटिंग",
				tit2: "मार्किंग घटना सूचना",
				desc2: "प्लेटफॉर्म की प्रगति सूचना, कार्यालय क्रियाओं और अन्य संदेश",
				tit3: "ऑपरेटिंग सूचना",
				desc3: "श्रीमंत और प्रेशिक ज्ञान सामग्री",
			},
			security: {
				tit: "पासवर्ड",
				isSet: "सेट करें",
				set: "सेट करने के लिए",
				verify: "वैध",
				verified: "सत्यापित",
				tit1: "सुरक्षा परीक्षण",
				email: "इमेल",
				phone: "मोबाइल फोन संख्या",
				phone_tit: "फोन संख्या बाइंडिंग",
				password_tit: "पासवर्ड बदलें",
			},
		},
		market: {
			hot: "पोपलियर",
			prev: "अंतिम पृष्ठ",
			next: "अगला पृष्ठ",
			line: "समय साझेदारी",
			main_subplot: "मुख्य तथा सहायक छवि सूचक",
			main: "मुख्य छवि सूचक",
			subplot: "द्वितीय छवि सूचक",
			go_trade: "ट्रांसेक्शन पृष्ठ पर जाएँ",
		},
		news: {
			tip: "डिस्क्लिपकर: उच्च सूचना सामान्य है और सिर्फ संदर्भ के लिए, और व्यापारिक निर्णयों के लिए आधार के रूप में उप",
		},
		trade: {
			state1: "स्थिति",
			state2: "सूची",
			state3: "इतिहास",
			state: "स्थिति",
			state4: "बन्द स्थिति",
			state5: "पुनरावृत्ति",
			type: "प्रकार",
			type1: "पूर्ण",
			type2: "बेचें",
			type3: "खरीद करें",
			left_label1: "स्वयं चयन",
			left_label2: "हाल में दृश्य",
			left_type1: "संकुचित स्तम्भ मोड",
			left_type2: "लूस स्तम्भ मोड",
			left_type3: "ट्रेंड चार्ट दिखाएँ",
			all: "पूर्ण",
			right_time: "अद्यतन समय",
			right_bp: "कल प्राप्त",
			right_sp: "आज की संग्रह",
			right_height: "उच्च",
			right_low: "न्यूनतम",
			remind_tit: "नया रिमाइन्डर",
			remindList: "स्मार्क सूची",
			remind_btn: "नया रिमाइन्डर",
			right_tab1: "ट्रांसेक्शन",
			right_tab2: "विवरण",
			right_sell: "बेचें",
			right_buy: "खरीद करें",
			right_sell1: "बेचें",
			right_buy1: "खरीद करें",
			right_type: "प्रकार",
			right_type1: "मार्केट ",
			right_type2: "विचाराधीन ",
			right_priceMoney: "हैंग यूनिट मूल्य",
			right_pointerNumber: "मात्रा (हाथ)",
			right_lever: "लेवर",
			right_tip: "डिपोस्ट आवश्यक",
			right_balance: "उपलब्ध",
			right_stop_profit: "उत्पन्न रोकें",
			right_stop_loss: "गुमान रोकें",
			right_profit_loss: "प्रोफाइट और हराम",
			now_order: "क्रम तुरंत रखें",
			confirm_order: "क्रम पुष्टि करें",
			right_tit1: "ट्रेडिंग रणनीति",
			right_rinei: "दिन में,",
			right_short: "छोटा समय",
			right_middle: "मध्यम",
			right_tit2: "वैकल्पिक रणनीति",
			right_tit3: "तकनीकीय पुनरावृत्ति",
			right_tit4: "डाटा",
			right_tit5: "ट्रेडिंग संवेदन",
			right_label_sell: "विक्रेता",
			right_label_buy: "ब्यूयर",
			right_tip2: "प्रत्येक 15 मिनट अद्यतन करें",
			right_tit6: "गुणगुणगुण",
			minute: "मिनट",
			today: "आज",
			right_tit7: "गुणधर्म वेरिफेशन सीमा",
			now_price: "मौजूदा मूल्य",
			right_low_price: "निम्न मूल्य",
			right_height_price: "अधिकतम मूल्य",
			right_tit8: "संपर्क जानकारी",
			right_tit9: "AUD से CHF",
			right_label1: "एकल ट्रांसेक्शन की संख्या",
			right_label2: "अधिकतम स्तर",
			right_label3: "कंट्राक्ट आकार",
			right_label4: "अधिकतम कुल स्थिति मात्रा",
			right_label5: "फ्लोटिंग मूल्य फरक",
			right_label6: "रात का फीड",
			hand: "手",
			right_collection_time: "संग्रह समय",
			right_tip3: "फीड आधार: पूरा ट्रांसेक्शन आकार से चार्ज करें",
			right_label7: "सीमा स्तर",
			right_label8: "मेंटेन्ट सीमा अनुपात",
			right_label9: "ट्रेडिंग अवधि",
			right_label10: "वर्तमान ट्रेडिंग अवधि",
			right_label11: "अगला व्यापार अवधि",


		},
	},
	home: {
		banner_p1: "प्राप्त करें",
		banner_p2: "प्लेटफॉर्म ट्रांसेक्शन्स",
		banner_p3: "विदेशी परिवर्तन, वस्तुओं, इन्डेस्स, खण्ड",
		banner_p4: "और अधिक लोकप्रिय आर्थिक उत्पादन",
		banner_p5: "आप दुनिया के सर्वोत्तम आर्थिक बाजारों को खोज सकते हैं. मिट्रेड शून्य कमिशियन, बहुत उपयोगी निम्न मूल्य भिन्न, तथा धीर्मिल लेवेरेज कॉन्फ़िगरेशन्स को उपलब्ध करता",
		hot: "पोपलियर",
		tit1: "अधिक मित्र संपर्क",
		p1: "आसान प्रक्रिया के लिए साधारण तथा इंटरफेस इंटरफेस",
		p2: "वेबसाइट, मोबाइल ऐप और डेस्कटॉप संस्करण का समर्थन करते हुए, सौदा अब आपकी उंगलीयों में होता है। ",
		p3: "धोका प्रबंधन औजार जहिना हराम को रोकें",
		p4: "बहुत तकनीक चार्ट और आर्थिक कैलेंडर, वास्तविक समय समाचार",
		p5: "वास्तविक समय इमेल संदेश और सूचनाओं को दबाएँ",
		p6: "एक बेहतर व्यापार अनुभव प्राप्त करने के लिए सदैव सुधारित होता है",
		btn1: "हमारा प्लेटफ़ॉर्म विश्वास करें",
		row3_tit: "जहां भी मुक्त व्यापार, जहां भी",
		row3_tip: "वेबसाइट, मोबाइल ऐप और डेस्कटॉप संस्करण का समर्थन करते हुए, सौदा अब आपकी उंगलीयों में होता है। ",
		row3_tit2: "कोड ड डाउनलोड स्कैन करें",
		row3_tit3: "डेस्कटॉप",
		row3_tit4: "मोबाइल अंत",
		regular_tit: "विश्वसनीय अंतरराष्ट्रीय प्लेटफॉर्म ",
		regular_tip: "हम ग्राहकों को एक सुरक्षित और उत्तरदार वातावरण के साथ प्रदान करने के लिए आवश्यक हैं।",
		regular_tit1: "नियमित अधिकार",
		regular_p1: "विश्वव्यापी ग्राहकों का विश्वास जीता है संस्थापित अधिकारित संदर्भ और अनुमोदन",
		regular_tit2: "आर्थिक सुरक्षा सुरक्षा",
		regular_p2: "रिटेल ग्राहक डेस्पोज़िट अलग रिटेल ग्राहक को नियमित नियमों के द्वारा आवश्यक हुए विश्वास खाते में डे",
		regular_tit3: "Negative balance protection",
		regular_p3: "हम तुम्हें तुम्हारे खाते के लिए नाकारात्मक बैलेंस सुरक्षा देते हैं, और नाकारात्मक बैलेंस एक समय के रूप में शून्य के लिए साफ कर दिया जात",
		regular_tit4: "7 * 24 घंटे ग्राहक सेवा",
		regular_p4: "24 घंटे ऑनलाइन समर्थन के साथ एक सच्चा और प्रोफासिकल ग्राहक सेवा टीम अपने किसी समस्या को हल करने की इच्छा है",
		service_tit: "आपकी मदद करने के लिए अधिक सेवा",
		service_tit1: "ट्रेडिंग रणनीति",
		service_p1: "वास्तविक समय व्यापार व्यापार व्यापार व्यापार व्यापार व्यापार व्यापार व्यापार व्यापार की संभा",
		service_tit2: "ट्रेडिंग ज्ञान",
		service_p2: "मिट्रेड के साथ व्यापार ज्ञान मुक्त होने के लिए सिखाओ अपने व्यापार कुशल को बढ़ाने के लिए.",
		service_tit3: "जोखिया प्रबंधन",
		service_p3: "मिट्रेड के मुक्त जीवित प्रबंधन औजारों के बारे में सीखो अपने मालिकों को बेहतर रक्षा करने के लिए.",
		step_tit: "एकटा खाता खोलना साधारण और उपयोगी है",
		step_tip: "केवल कुछ मिनटों में एक खाता खोलने के लिए तीन साधारण चरण简单三步，数分钟内即可开通账户",
		step_tit1: "रेजिस्टर",
		step_p1: "जानकारी भरें और अपने अनुप्रयोग को भेजें",
		step_tit2: "पैसा डोसिट",
		step_p2: "बहुत जल्दी मार्गों के लिए डॉस्पोज़िट फंड्स",
		step_tit3: "ट्रेडिंग प्रारंभ करें",
		step_p3: "व्यापार संभावना और आदेशों को जल्दी से स्पष्ट करें",
		award_tit: "हमारे श्रेणी",
		award_p1: "हमने हमेशा उत्तम और उत्तम का पीछा किया है, जो ग्राहकों को उच्च गुणवत्ता व्यापार सेवा से प्रदान करने के लिए व",
		award_p2: "मिट्रेड ब्रैंड को उद्योग में प्रसिद्ध संस्थाओं से प्रतिज्ञा प्राप्त करने के लिए बहुत सम्मान है, जो टीम की सदैव प्रयास और ग्र",
		row7_tit: "वास्तविक विश्वव्यापी आर्थिक जानकारी",
		row7_tit1: "वास्तविक समय समाचार",
	},
	learn: {
		education: {

		}
	},
	login: {
		click_download: "डाउनलोड करने के लिए क्लिक करें",
		p1: "जहाँ कहीं भी ट्रेड करते हैं, कहीं भी",
		p2: "तेज़ और स्मार्ट अनुवाद",
		p3: "0 कमिशन ट्रांसेक्शन्स",
		p4: "दुनिया में सर्वोत्तम निवेश प्रकार",
		renderDom1: "विदेशी बदलें",
		renderDom2: "यूएस शेयर्स",
		renderDom3: "सोनाcolor",
		renderDom4: "क्रूड तेल",
		renderDom5: "सूची",
		renderDom6: "क्रिप्टोकरनComment",
		login: "लॉगइन",
		elail: "मेलबाक्स",
		phone: "मोबाइल फोन संख्या",
		no_account: "कोई खाता नहीं？",
		go_register: "रेजिस्टर में जाएँ",
		register: "रेजिस्टर",
		now_register: "अब रेजिस्टर करें",
		have_account: "मौजूदा खाता？",
		go_login: "लॉग इन जाएँ",
	},
	about: {
		serviceFee: "सेवा चार्ज",
		introduction: "प्लेटफॉर्म परिचय",
		trade_platform: "ट्रेडिंग प्लेटफ़ॉर्म",
		complaint_process: "संपूर्ण प्रक्रिया",
		page: "पृष्ठ",
		place_search: "जो आप जानना चाहते हैं उसे खोजें",
		no_answer: "आपकी जरूरत जवाब ढूंढने में अक्षम?",
		contact_us: "हमसे संपर्क",
		awards: {
			h1: "प्रतिदान और सम्मान",
			p1: "हमने हमेशा उत्तम और उत्तम का पीछा किया है, जो ग्राहकों को उच्च गुणवत्ता व्यापार सेवा से प्रदान करने के लिए व मिट्रेड ब्रैंड को उद्योग में प्रसिद्ध संस्थाओं से प्रतिज्ञा प्राप्त करने के लिए बहुत सम्मान है, जो टीम की सदैव प्रयास और ग्र",
			p2: "या सिर्फ हमारा प्रतिज्ञा जीता है ऑनलाइन ट्रेडिंग प्लेटफर्म",
			name1: "लैटिन अमेरिका में सर्वोत्तम ब्रॉकर",
			name2: "डिफ़रेन्स ब्रॉकर के लिए सर्वोत्तम कंट्राक्ट",
			name3: "सर्वोत्तम आर्थिक तकनीक ब्रोकर",
			name4: "२००१ में बहुत से बहुत से इन्फ्लुनिशियल इंटरप्राइज़स",
			name5: "ट्रांसेक्शन के लिए सबसे पारदर्शी ब्रॉकर",
			name6: "सर्वोत्तम नॉन ट्रेडर प्लेटफॉर्म (NDD) बाहर ब्रॉकर",
		},
		charges: {
			h1: "फीज़ और फीज़",
			p1: "ग्राहक अनुभव को सुधार करने के एक अवयव के रूप में मिट्रेड आशा करता है कि ग्राहकों को अधिक क्षमता प्रभावित व्यापार स हमने खुला और पारदर्शी को फीजों और फीजों में बचाने के लिए ज़िम्मेदारी है ग्राहकों के लिए मुख्य ट्रांसेक्शन क्षमता है कि बीड-पूछा उनके स्थितियों पर फैलाया गया है और रात के बाद र",
			h2: "पहले तुम्हारे लिए आने के लिए",
			li1: "वास्तविक समय उत्पादन क्वोटेशन",
			li2: "व्यवसायिक तकनीक सूचक",
			li3: "एक्क्लुसिव बाजार विश्लेषण",
			free: "मुक्त",
			view: "दृश्य",
			h3: "बीड- पूछें",
			p2: "मिट्रेड अपने सेवाओं के लिए अभिव्यक्ति देता है कि बीड-पूछने के मूल्य का फरक चार्ज देता है. इस खर्च का एक भाग उत्पादन क्वोटेशन में प्रतिक्रिया किया गया है, और जब आप एक स्थान स्थापित किया गया, तो आप वास्तव म क्योंकि मूल्य अलग फीज निश्चित नहीं है, हम सुझाते हैं कि आप निर्दिष्ट उत्पादित क्वोटेशन पृष्ठ पर जाओ या लॉग",
			h4: "डिपोज़िट और छोड़ने के फीज",
			p3: "ग्राहक की खर्च कम करने के लिए, हम तुम्हें बहुत से किसी के लिए तुम्हारे डोपोसिट के लिए चार्ज नहीं करेंगे। लेकिन तीसरी पार्टी कंपनीयों या बैंक्सियों के लिए हैंडलिंग फीज के लिए चार्ज कर सकते हैं, जैसे कि आपके डोसिट के दौरा",
			p4: "स्थितियों के लिए जहाँ फीज चार्ज होने की जरूरत है, आप हमारे ग्राहक सेवा विशेषक से जाँच सकते हैं。",
			p5: "उदाहरण के लिए, अंतर्राष्ट्रीय क्रेडिट कार्ड ट्रांसेक्शन, बैंक खाता स्थानान्तरण/स्थानान्तरण के द्वारा या अन्तर्",
			h5: "रात के लिए रात के लिए",
			p6: "यदि आप अभी भी 10pm ग्रीन्विच मध्य समय में स्थिति रखते हैं (जीएमटी 22:00), आपको एक रात के साथ चार्ज किया जाएगा. प्रत्येक उत्पादक के लिए एक रात के फीज के बारे में जानकारी के लिए, आप विशिष्ट उत्पादक क्वोटेशन पृष्ठ पर ज",
			h6: "अन्य खर्च",
			p7: "और कोई फीज नहीं. हमारी चार्जिंग पालिसी पूर्णतः पारदर्शी है, और जो कोई चार्जिंग की आवश्यकता है उसे प्रारंभ में सूचना दिया जाएगा और सूचना द"
		},
		contact: {
			h1: "हमसे संपर्क",
			address: "पता",
			custom_email: "मनपसंदीदा संपर्क इमेल",
			business_email: "व्यवसाय सहयोग इमेल",
			h2: "हमें एक पत्र लिखें",
			p1: "यदि आपके पास हमारे सेवा के बारे में कोई प्रश्न है, कृपया प्रश्न देने के लिए निम्न फॉर्म भरें, और हमारे ग्राहक सेवा विशेष और अगर आप रोज़ी ट्रेडिंग केंद्र के साथ प्रदान किया गया है तो यदि आप रोज़ी ट्रेडिंग केंद्र के साथ प्रदान किया जाने के लिए रेजिस्टर करना चाहते हैं, कृपया निम सदस्यता सेवा केवल मौजूदा मिट्रेड के वास्तविक ग्राहकों के लिए लागू है.",
			h3: "प्रक्रिया",
			p2: "हमारे पास एक विशिष्ट टीम होगा कि आपके लिए सभी शिक्षाओं को जाँच और समाधान करने के लिए। कृपया संदर्भ के लिए विवरण所有投诉我们会有专门团队为您调查解决，详情可参考",
		},
		faq: {
			h1: "बारे में पूछा गया प्रश्न",
			tit1: "सिमुलेटेड खाता का उपयोग क्या है?",
			tit2: "वास्तविक खाता कैसे खोलें？",
			tit3: "बाजार क्लिस सूची कैसे स्थापित करना है？",
			tit4: "मैं एक आदेश क्यों नहीं कर सकता？",
			p1: "सिमुलेटेड खाता और वास्तविक खाता के लिए प्लेटफ़ॉर्म के विभिन्न फंक्शन, डाटा तथा कार्यों के अनुसार में बहुत से समान हैं. सिमुलेटेड खाता में आभासी फंड",
			p2: "एक वास्तविक खाता खोलने के लिए नीचे की चरणों को अनुसरण करें：",
			p3: "1. मिट्रेड रिजिस्ट्रेशन पृष्ठ भरें और प्रोम्प्ट्स का अनुसरण करें: इमेल/फोन संख्या रिजिस्टर करें, लॉगइन पासवर्ड सेट करें, और पुष्",
			p4: "2.सिमुलेटेड खाता पृष्ठ पर, 'वास्तविक खाता में स्विच करें' और 'वास्तविक खाता खोलने के लिए प्रक्रिया का अनुसरण",
			p5: "एक वास्तविक खाता खोलने से पहले हम सुझाते हैं कि आप लान्सेक्शन से सम्बन्धित व्यवस्था प्रदर्शन दस्तावेज़",
			p6: "कृपया टिप्पणी करें कि आप केवल अपने वास्तविक खाता में फंड डोसिट कर सकते हैं, पहचान परीक्षण पूरा करने के बाद",
			p7: "ट्रेड (Trade) पर क्लिक करने पर प्लेटफ़ॉर्म द्वारा उपलब्ध सभी उत्पादों का विवरण प्रदर्शित होता है, आप अपने डायलॉग बॉक्स में वरीयता कोड या नाम दर्ज करके सर्च कर सकते हैं,  'खरीदें/बेचें' (SELL/BUY) पर क्लिक करने से आप ट्रेड पूरा कर सकते हैं। कृपया ध्यान दें कि सभी वित्तीय उत्पाद मार्केट वोलेटिलिटी के आधीन होते हुए विनिमय की जाती हैं, इसलिए आपके द्वारा ट्रेड करने से पहले कुछ समय का ",
			p8: "प्रथम, कृपया जाँचें कि क्या नेटवर्क कनेक्शन सामान्य है. जब व्यापारिक विविध बाजार अस्थायी रूप से बन्द या बन्द हो जाता है, तो यह आदेशों को निर्धारित करने के लिए अक्षम प ज्यादा जानकारी के लिए, कृपया अतिरिक्त मदद के लिए हमारे ऑनलाइन ग्राहक सेवा संपर्क करें.",

		},
		fundSecurity: {
			h1: "आर्थिक सुरक्षा",
			p1: "मिट्रेड अधिकार और नियंत्रित नियंत्रण संस्था द्वारा नियंत्रित है. संपूर्ण है कि हम काम करते हैं, हर काम का कोर और संस्कृति है। तुम्हारी आर्थिक सुरक्षा महत्वपूर्ण है, क्योंकि सभी ग्राहकों के डेस्पोज़िट्स विभाजन और भण्डार कि",
			p2: "नियमित आवश्यकता के अनुसार आवश्यकता के अनुसार विश्वास खाते में रिटेल ग्राहक डोपोज़िट अलग रिटेल किया ज",
			p3: "मिट्रेड के लिए कोई व्यापारिक क्रिया के लिए व्यापारिक अनुमति के बाहर किसी व्यापारिक क्रिया के",
			p4: "मिट्रेड किसी बाजार में विशिष्ट क्रियाओं में सहभागी नहीं होगी",
			p5: "तीसरे पार्टी काउंटिंग फ़र्मिनों के द्वारा स्वतन्त्र ऑडिट",
		},
		help: {
			h1: "मिट्रेड मदद केंद्र में स्वागत है",
		},
		media: {
			h1: "मीडिया केंद्र",
			p1: "कृपया HKDFINANC ब्रांड से संबंधित जानकारी, प्रेस रिलीज़, मीडिया कवरेज, घोषणाएं और अन्य सूचनाओं के लिए देखें।",
		},
		mitrade: {
			h1: "मिट्रेड के बारे में",
			view_certificate: "（सत्यापन विधि）",
			p1: "मिट्रेड एक आर्थिक तकनीकी कंपनी है जो अधिकारित संस्थाओं द्वारा अधिकारित और नियंत्रण किया गया है, जो निवेशिकों को साध नवीन मिट्रेड विविध व्यापारित व्यापारित प्लेटफ़रेम कभी कभी प्रतिज्ञा जीता है जैसे सबसे अच्छा मोबाइल व्यापार",
			p2: "मिट्रेड प्लेटफ़ॉर्म द्वारा आप अंतर्राष्ट्रीय आर्थिक बाजारों के विस्तार में निवेश और व्यापार कर सकते हैं, जैसे संख्या, इन्डिक्स, वस्त प्रयोक्ता सिर्फ मोबाइल और वेब ट्रेडिंग प्लेटफ़ॉर्म के लिए सीमित नहीं है, मिट्रेड तुम्हारे लिए भी स्पर्धित ट्रांसेक्शन क्षति, तेज ट्रांसेक्शन कार्या",
			p3: "मिट्रेड संस्था और बहुत संस्थाओं द्वारा नियंत्रण है। मारीशियास आर्थात्मक सेवा कमिसियन (एफसीसी) के द्वारा मार्किशस आर्थात्मक संस्था जीबी20025791 की लाइसेंस संख्या क मारीशियास लाइसेंस प्लेट पर अधिक जानकारी के लिए, कृपया एफसीसी आधिकारिक वेबसाइट को भेजें https://www.fscmauritius.org",
			p4: "क्वेरी का निर्णय करें. HKDFINANC ग्लोबल्यूबल एक आर्थिक सेवा लाइसेंस (AFSL 398528) के पास ऑस्ट्रेलिया सुरक्षित और निवेश कमिसियन (ASIC) से अधिकारित है ऑस्ट्रेलियन लाइसेंस प्लेट पर अधिक विस्तृत विवरण के लिए, कृपया www.asic.gov.au पर ASIC आधिकारिक वेबसाइट को भेजें",
			p5: "क्वेरी का निर्णय करें. आईट्रेड होल्डिंग कैमन द्वीप के मोनेटारी ऑर्टिटेरी (CIMA) के द्वारा अनुमोदन और नियंत्रण किया जाता है जिसके साथ सीबी ला कैमन लाइसेंस प्लेट पर अधिक विस्तृत विवरण के लिए, कृपया www.cima.ky पर सीएमए के आधिकारिक वेबसाइट को भेजें",
			p6: "क्वेरी का निर्णय करें. मिट्रेड की सभी व्यवसाय कार्य कार्यान्वयं सीधी नियंत्रण के अनुसार किया जाता है.",
			h2: "मिट्रेड का पृष्ठभूमि",
			p7: "मिट्रेड है आर्थिक व्यापार और फिनटेक्स्ट्रेलिया संस्थापित मेलबर्न, ऑस्ट्रेलिया में स्थापित किया गया है. मिट्रेड की तत्वज्ञान है कि अनुवादों को आसान और अधिक मित्र बनाना है",
			p8: "हमारी संयोजना तथा व्यवसाय मॉडल हमें एक सम्पूर्ण और गहिरी निरीक्षण और संस्थापित विकास और बाजार की अनुरोध के समझाने के लिए शक्ति देती है, जो हमें अधिक लक्ष्य, नियमित तकनीकी निर्म स्थिति",
			h3: "मिट्रेड क्यों चुनें",
			why1: "कम थ्रेशोल्ड ट्रेडिंग परिस्थिति",
			why2: "उद्योगिक अधिकारी द्वारा नियंत्रण",
			why3: "सुलभ और साफ़ ट्रेडिंग प्लेटफ़ॉर्म।",
			why4: "उच्च स्तर ऑनलाइन समर्थन",
			why5: "कंपेटिव ट्रांसेक्शन लागत",
			why6: "Negative balance protection",
			h4: "प्रतिज्ञा और सम्मान",
			p9: "हमने हमेशा उत्तम और उत्तम का पीछा किया है, जो ग्राहकों को उच्च गुणवत्ता व्यापार सेवा से प्रदान करने के लिए व",
			p10: "मिट्रेड ब्रैंड को उद्योग में प्रसिद्ध संस्थाओं से प्रतिज्ञा प्राप्त करने के लिए बहुत सम्मान है, जो टीम की सदैव प्रयास और ग्र",
			new_add: "वैश्विक नवीन प्रयोक्ता",
			ol1: "सबसे अच्छा समाचार और विश्लेषण सूचना का प्रदायक",
			ol2: "एशिया के सर्वोत्तम विदेशी एक्सचेंज सुरक्षित फ़िर्म",
			ol3: "ऑस्ट्रेलिया विदेशी एक्सचेन्ज क्यास्टरनेटर संतृप्ति प्रतिज्ञा",
			ol4: "सर्वोत्तम मोबाइल अनुप्रयोग",
			ol5: "ऑस्ट्रेलिया की सबसे तेजी वृद्धि सुरक्षित संस्था",
			h5: "मिट्रेड की कार्यान्वयन तत्वज्ञान",
			tip_tit1: "ज़िम्मेदारी",
			tip_tit2: "साधारण हालाँकि सुंदर",
			tip_tit3: "पारदर्शी",
			tip_tit4: "नवीन करें",
			tip_p1: "सभी ट्रांसेक्शन्स जोखियाँ हैं, और मिट्रेड के लिए ग्राहकों को प्रतिकारित व्यापार परिवेश से देने के लिए अधि मिट्रेड उद्योगिक अधिकार के विषय में है, बहुत संस्थाओं से संकट संशोधन है, और उच्च स्तर सुरक्षा और स्थिर कार्यान् हमारा प्लेटफ़र्म बहुत जवाब देने वाले ट्रेडिंग औजार प्रदान करने के लिए जारी रखता है, जिसके सम",
			tip_p2: "साधारण सुंदर है, और विवरण अच्छा प्रकार प्राप्त है। साधारण, उपयोग के आसान, और उपयोक्ता अनुभव के हर विवरण के लिए मिट्रेड की प्रयास हम अपने प्लेटफ़ॉर्म और सेवाओं को अप्टीमाइज करने के लिए जारी रखते हैं, आशा रखते हैं कि हर किसी नेविस या अनुभव किया गया नेवेस्ट्राइज़",
			tip_p3: "हमारे ग्राहकों को एक साधारण और प्रकारिय व्यापारित व्यापारित परिवेश से पूरा लाभ देने के लिए, मिट्रेड ने एक सामर्थ्य और पारदर्शी स्वयं नियंत्रण सिस्टम का अनुसरण किया, पूरा पारदर्श",
			tip_p4: "मिट्रेड स्थिर मानता है कि तंत्रज्ञानिक प्रगति, आर्थिक संस्था और विस्तार सेवा वृद्धि के लिए ड्राइविंग शक्ति हैं, जो न इस कारण के लिए हम खुली सोचना को प्रोत्साहित करते हैं, निर्णय प्रोत्साहित करते हैं, और आर्थिक तकनीकों में निर्माता और न",
			row6_tit1: "हमारा मिशन",
			row6_tit2: "हमारी दृश्य",
			row6_tit3: "कार्य संभावना",
			row6_p1: "एक साधारण, प्रभावित और पारदर्शी व्यापारित व्यापारित परिवेश प्रदान करने के लिए जो स्थान, समय या या बाजार के लिए अधिक लोगों के लिए सीमित नहीं",
			row6_p2: "हम विश्वास करते हैं कि तकनीकी प्रगति, आर्थिक निर्माण और विविध सेवा हैं जो हमारी वृद्धि को चलाते हैं, हमें समाज में निरन्तरित मूल",
			row6_p3: "इसके लिए, हम खुली सोचना को प्रोत्साहित करते हैं, नवीनताओं को प्रगति देते हैं, और एक होने के लिए",
			row6_p4: "विश्वव्यापी प्रधान विदेशीय व्यापारी कंपनी",
			row6_p5: "हम एक वैश्विक टीम हैं, जो 7 देशों को बढ़ाता है, और टीम के तेजी विकास के साथ, हम हमारे साथ जोड़ने के लिए व्यवसायिक प्राप् एक बहुसंस्कृतिक टीम के साथ काम करो जो आपको मज़ारी है!",
			row6_p6: "कृपया हमें अपने नवीनतम पुनरारंभ और कारणों को इमेल करो कि आप हमारे साथ काम करना चाहते हैं:",
			row6_p7: ". अतिरिक्त, आप भी जाँच सकते हैं",
			row6_p8: "अथवा",
			row6_p9: "हमारे सार्वजनिक उपलब्ध रिकामों को जाँचें.",
			row6_p10: "मिट्रेड तुम्हारे व्यक्तिगत जानकारी की सुरक्षा से संरक्षित है. अनुप्रयोग प्रक्रिया के दौरान मिट्रेड आपसे आर्थिक जानकारी निवेदन नहीं करेगा. हमारे प्राप्त करने के लिए कोई आर्थिक संबंधित नहीं है, क्रेडिट कार्ड, बैंक खाता जानकारी या कोई पैसा की रूप",
			row6_p11: "यदि आप एक निश्चित स्थिति में विश्वासघात का व्यवहार संदेश करते हैं, कृपया संपर्क करें",
			row6_p12: "मिट्रेड के मानव संसाधन विभाग संपर्क करो। यदि आप पुष्टि करें कि आपको गलत रिकर्मिटिंग जानकारी द्वारा धोखा दिया गया है, कृपया अपने स्थानीय संबंधित संस्थाप",

		}
	},
	product: {
		row2: {
			tit: "ट्रेडिंग विविध",
			sell: "बेचें",
			buy: "खरीद करें",
			trade: "transaction",
			more_product: "अधिक व्यापारित उत्पादन",
		},
		row3: {
			tit: "बहुत से युक्ति ट्रांसेक्शन समर्थित करें",
			tit1: "बहुत से युक्ति ट्रांसेक्शन समर्थित करें",
			p1: "Name तुम कहीं भी कहीं भी व्यापार कर सकते हो।",
			download: "डाउनलोड",
		},
		row4: {
			tit: "मिट्रेड चुनें",
			tit1: "सुलभ और साफ़ ट्रेडिंग प्लेटफ़ॉर्म।",
			p1: "बाजार, व्यापार, खाता प्रबंधन, जानकारी और जोखिल प्रबंधन के संयोजन किया जा रहा है, इसे एक बेहतर उपयोक्ता अनुभव",
			tit2: "कंपेटिव ट्रांसेक्शन लागत",
			p2: "0 ट्रांसेक्शन कॉमिशिशन, बहुत स्वीकारित और पारदर्शित कम मूल्य भिन्न, कम रात्री संगत, आपको कम कास ट्रांसेक्शन्स का",
			tit3: "कम थ्रेशोल्ड ट्रेडिंग परिस्थिति",
			p3: "प्रत्येक ट्रांसेक्शन की न्यूनतम संख्या 0. 01 के रूप में कम हो सकता है, और कम सीमा व्यापार खोलने के लिए उपयोग में लिया",
			tit4: "सत्याधिकारी निरीक्षण",
			p4: "व्यवस्था में अधिकारित अनुमति और संदर्भ, सभी ग्राहकों के मालिकों की सुरक्षा के लिए विभाजन और भण्डार किया जाता है कि ग्राहकों के म",
			tit5: "Negative balance protection",
			p5: "किसी बाजार परिस्थितियों में तुम्हारे खाता की खोरी मुख्य मात्रा से अधिक नहीं होगी, और नाकारात्मक बैलेंस को समय के रीति साफ करने क",
			tit6: "उच्च स्तर ऑनलाइन समर्थन",
			p6: "तेज प्रतिक्रिया ऑनलाइन ग्राहक सेवा को प्रदान करते हैं, हम एक प्रोफेशनल टीम के अपमानित प्रयासों के द्वारा उच्",
		},
		row5: {
			tit: "संबंधित मुद्दा",
		},
		commodities: {
			title: "कमोडिटी व्यापार",
			p: "महत्वपूर्ण धातु, ऊर्जा और अन्य वस्तुओं को दोनों इंप्लेशन के विरोध में सामर्थ्य बनाया जाता है और पैदा करने के लिए आवश्यकता है, जिनको व उनके बीच सोने, चांदी, तेल और अन्य वस्तुओं की व्यापारित व्यापारित व्यापारित व्यापारित व्यापारित व्यापारित व्यापारित व्यापारित व् उत्पादन और अनुरोध, अर्थव्यवस्था और पालिसी स्थितियों और मुद्रा के लिये मुद्रा की संभावना प्रदान करने के लिये उत्प",
		},
		forex: {
			title: "विदेशी परिवर्तन ट्रांसेक्शन्स",
			p: "विदेशी व्यापार व्यापार व्यापार व्यापार व्यापार व्यापार व्यापार व्यापार व्यापार व्यापारिक व्यापारिक विदेशी विमान बाजार में बहुत से सहभागी हैं, जो पैसा करते हैं, मुद्रा विमान के विरूद्ध हेड्ज करते हैं, या प्रतिक्रिया प्राप बैंक, संस्थाओं और व्यक्तिगत व्यापारिकों के बीच इंटरनेट में व्यापारित व्यापारित के द्वारा इंटरनेट के द्वारा कार्य किया जाता ह",
		},
		indices: {
			title: "indices",
			p: "स्टॉक अनुक्रमणिका एक स्थितिय मूल्य है जो एक विशिष्ट बदलाव में विशिष्ट विशिष्ट विशेषता स्टॉक का प्रतिनिधित्व प्रकार है, जो बाजा ट्रेडिंग स्टॉक इन्डेक्स सारे संस्थाओं ने सारे बाजार या उद्योग में निवेश संस्थाओं की संस्थाओं को प्रतिकृत अमेरिका, यूरोप, एशिया और ऑस्ट्रेलिया बाजारों में सबसे लोकप्रिय संकेत, जैसे ऑस्ट्रेलिया 200, यूएस्ट्रेलिया टेक्लॉज 100, हांग कांग",
		},
		shares: {
			title: "स्टॉक ट्रेडिंग",
			p: "स्टॉक एक प्रकार का मूल्यवर्धित प्रमाणपत्र होता है जो विनिमय बोर्ड में जारी किया जाता है। उद्यमियों को धन जुटाने के लिए, वे शेयर खोखले के माध्यम से कंपनी की संपत्ति वितरित करते हैं। 2019 में ही विश्व भर में स्टॉक के व्यापार का कुल मूल्य 60 ट्रिलियन अमेरिकी डालर से अधिक था, जो एक बहुत बड़ी मात्रा थी, और इसकी लिक्विडिटी काफी मजबूत होती है, इसलिए यह अभी भी वैश्विक निवेशकों के ब",
		},
		coins: {
			title: "क्रिप्टोकरन",
			p: "क्रिप्टोकुरेंट कोड द्वारा बनाया गया डिजिटल मुद्रा है. वे पारंपरिक बैंकिंग और सरकारी सिस्टम के बाहर स्वतंत्रित रूप से काम करते हैं. जैसा नाम सुझाता है, क्रिप्टोवेरेंसिस क्रिप्टोवेरेंसिस क्रिप्टोवेरेंसिशन तकनीक को प्रयोग करते हैं कि ट्रां BTC बिट्कोइन है जो सातोशी नाकामोटो द्वारा बनाया गया है और जानवरी 2009 में प्रारंभ किया गया है। आज तक, यह अभी भी अज्ञात है कि नाकामोटो का नाम एक व्यक्ति या समूह का संदर्भ है. सभी क्रिप्टोवेरेंसिस की तरह, यह एक ब्लॉक्टोचेन ट्रांसेक्शन डाटाबेस के द्वारा नियंत्रण किया जाता है, जो वितरित आज तक, ऑनलाइन ट्रेडिंग के लिए 1000 से अधिक क्रिप्टोवेलेंसी उपलब्ध हैं."
		}
	},
	trade: {
		data_sources: "डाटा स्रोत",
		tip: "उत्तम जानकारी सिर्फ संदर्भ के लिए है. मिट्रेड सूचना सामग्री की सच्चाई, वास्तविक समय और पूर्णता को गारंट नहीं देता, तो आपको प्रदान किया जानकारी पर अधिक भरोसा न इस जानकारी में हमारे ट्रांसेक्शन क्लिस रेकॉर्ड नहीं है, या आर्थिक अओजार के लिए किसी भी उपलब्ध अओजार के लिए या  मिट्रेड एक कंपनी नहीं है जो आर्थिक सल्लाह प्रदान करता है, और सिर्फ केवल निर्देशों के प्रकृति में सेवा प्रदान ",
		tip2: "प्रत्येक शुक्रवार 15: 00 में अद्यतन किया जाता है (GMT)",
		tip3: "ट्रेडिंग दृश्य है मार्केट ट्रेडिंग व्यापार संवेदन के अनुप्रतीक्षा के लिए, उद्योगिक व्यापारितों के छोटे समय और मध्यम समय दृश्",
		analysis: {
			tit: "ट्रेडिंग रणनीति",
			all: "पूर्ण",
			day: "दिन में,",
			tit1: "ट्रेडिंग रणनीति",
			tit2: "तकनीकीय पुनरावृत्ति",
		},
		calendar: {
			tit: "आर्थिक कैलेन्डर",
			prev_week: "पिछले सप्ताह",
			next_week: "अगला सप्ताह",
			place_date: "खोज तिथि",
			select: "विकल्प",
			select1: "देश",
			select2: "महत्वपूर्ण",
			start_time: "प्रारंभ समय",
			end_time: "अंतिम समय",
			search: "खोजें",
		},
		contract: {
			tit: "विवरण",
			type1: "पूर्ण",
			type2: "विदेशी बदलें",
			type3: "कमोडिटी",
			type4: "सूची",
			type5: "यूएस बराबर",
			type6: "ऑस्ट्रेलिया समान",
			tit1: "महान विदेशी बदलें",
			tit2: "द्वितीय विदेशी बदलें",
			tit3: "कमोडिटी",
			tit4: "मुख्य अनुक्रमणिका",
			tit5: "द्वितीय अनुक्रमणिका",
			tit6: "यूएस बराबर",
			tit7: "ऑस्ट्रेलिया समान",
			tit8: "सामान्य नियम",
			p1: "तीव्र फ्लैट अनुपात",
			p2: "क्रम की वैध अवधि",
			p3: "अपरिमित",
			p4: "रात की फीड सेटिंग समय",
			p5: "दिन दिन सेटिंग; ग्रामी: 05: 00, जीवाल: 06: 00",
			p6: "प्राप्त, रात्री के बाद, और अन्य जानकारी",
			p7: "कृपया ट्रेडिंग प्लेटफ़ॉर्म पर संदर्भ करें",
			p8: "ऊपर सामग्री सभी व्यापारिक विविधियों पर लागू है",
			tit9: "ट्रैडिंग सप्ताह कैलेंडर",
			p9: "ऑफ मार्केट",
			p10: "उत्तम व्यापार घण्टाओं ने धुक्कार या विशेष बाजार के अनुकूलन के प्रभाव को खाते नहीं लिया。",
			p11: "सुझावित विशिष्ट ट्रांसेक्शन समय",
			p12: "लॉगइन प्लेटफॉर्म",
			p13: "，संबंधित प्रकारों के परिचय में जाँचें。",
		},
		forecast: {
			tit: "ट्रेडिंग दृश्य",
			p1: "औसत मूल्य",
			p2: "बुलिश",
			p3: "बीरिशCity name (optional, probably does not need a translation)",
			p4: "क्षैतिज प्लेट",
			p5: "गंतव्य",
		},
		news: {
			tit: "वास्तविक समय समाचार",
		},
		platforms: {
			tit: "मिट्रेड ट्रेडिंग प्लेटफ़ॉर्म",
			p: "साधारण और साधारण इंटरफेस, उत्तम व्यापार कार्य, अधिक निवेशित करनेवालों की जरूरतों को पूरा करने के लिए. आज मिट्रेड में व्यापार शुरू करें।",
			tit1: "आसान और सुविधाजनक HKDFINANC ट्रेडिंग प्लेटफॉर्म। (यह साउंड करने को अच्छा नहीं है)",
			tit2: "मिट्रेड एप के साथ कोई बार, कहीं भी",
			li1: "सौ लोकप्रिय बाजारों में व्यापार, अधिकारित संशोधन सुरक्षा सुरक्षा देता है",
			li2: "बाजार ट्रेंड्स के रियल समय मॉनिटरिंग, ट्रांसेक्शन विश्लेषण देख रहे हैं, और नवीनतम आर्थिक समाचार ब्राउज़",
			li3: "विभिन्न प्रकार के ट्रेडिंग चार्टों को कभी भी देखें, एक्सेसिबिलिटी के साथ ड्राइंग फ़ंक्शन का उपयोग करें। (यह साउंड करने को अच्छा नहीं है)",
			li4: "तुम्हारे एन्ड्रोड या एब्बीडी युक्ति पर मिट्रेड की प्रतिज्ञा विजेत प्लेटफ़ॉर्म",
			code_download: "कोड ड डाउनलोड स्कैन करें",
			tit3: "डाउनलोड करने के लिए कोई जरूरत नहीं, ब्राउज़र द्वारा ऑनलाइन व्यापार करें",
			li5: "कोई डाउनलोड आवश्यक नहीं, पीसी ब्राउजर में सीधे ऑनलाइन ट्रांसेक्शन्स",
			li6: "बहुत बार प्रतीक्षित: 2022 में एशिया में सर्वोत्तम विदेशी एक्सचेंज ब्रोकर, 2022 में ऑस्ट्रेलिया में सर्वोत्तम ट्रेडिंग प्लेटफॉर्म, और 2022 में वि",
			li7: "शक्तिशाली तकनीक सूचक जहिना MACD, KDJ, RSI, TRIX, DMA, CCI इत्यादि प्रदान करें",
			li8: "जैसे रोकस प्रबंधन औजारों के साथ समीक्षा किया जाता है जैसे रोका गया और नाकारात्मक सुरक्षा के लिए आपके ट्र",
			tit4: "आपके डेस्कटॉप ट्रेडिंग के लिए पूर्ण समाधान",
			li9: "चयनित सूची में बाजार ट्रेंड्स आसान देखें",
			li10: "मान बदलाने के लिए रिमाइन्डर फ़ंक्शन सक्षम करें",
			li11: "बहुतेक चार्ट लेआउट आपको विभिन्न समय परिमाणों से बाजार ट्रेंड्स ट्रैक करने की अनुमति देती है",
			li12: "संपर्क विशिष्टिकरण, ट्रांसेक्शन संवेदन, तथा ट्रांसेक्शन विश्लेषण सब एक ही इंटरफेस में हैं, बिना स्विच करने क",
			li13: "मिट्रेड में नवीनतम ट्रेडिंग समाचार सीधे मिलाओ",
			tit5: "मिट्रेड ट्रेडिंग प्लेटफर्म क्यों चुनें?",
			row5_p1: "सुरक्षा गारंटी",
			row5_p2: "0 कमिशन",
			row5_p3: "मल्टी प्लेटफ़ॉर्म समर्थन",
			row5_p4: "तकनीकीय चार्ट",
			row5_p5: "कहीं भी पहुँच",
			row5_p6: "विविध रेखाचित्र औजार",
		},
		quotes: {
			tit: "वास्तविक समय क्वोटेशन",
			p1: "हम तुम्हें वास्तविक समय उद्धरण, बाजार संवेदन, और मौजूदा लोकप्रिय आर्थिक उत्पादनों के बारे में सम्बन्",
			hot: "पोपलियर",
		},
		risk: {
			tit: "जोखिया प्रबंधन",
			p1: "सभी ट्रांन्सेक्शन्स जोखियों में है, और मिट्रेड द्वारा दिया गया मुक्त जोखियों प्रबंधन उपकरणों को बाजार परिस्थितियों के अत",
			function_tit1: "लाभ/नष्ट फंक्शन बन्द करें",
			function_tit2: "ट्रैकिंग गुमान फंक्शन",
			function_p1: "लाभ में ताला लगाएँ",
			function_p2: "सीमित हराम",
			function_p3: "लाभ में ताला लगाया जा रहा है",
			function_p4: "स्थिति स्वचालित रूप से निरीक्षण करने की जरूरत नहीं",
			function_p5: "हराम के लिए बन्द की मूल्य समायोजन किया जा रहा है",
			row2_tit1: "लाभ/नष्ट फंक्शन बन्द करें",
			row2_p1: "जब नया आदेश स्थापित करें या मौजूदा आदेश परिवर्धित करें, हम लाभ स्थापित करने को रोक सकते हैं और गुमान रोक सकते हैं. सेट करने के बाद, आदेश सामान्य रूप से जो लक्ष्य क्रिया आपने सेट किया है आधारित हो जाएगा, जब लक्ष्य पहुँच जाता है या गुमावट को कम करने के लिए कृपया टिप्पी करें कि किसी क्रम मार्केट फ्लुक्यूटेशन केम",
			row2_tit2: "उदाहरण",
			row2_p2: "मौजूदा मूल्य EUR/USD का 1.13816/1.13837 है (विक्रा/खरीदी). आपने एक हाथ स्थापित किया है (एक हाथ=100000 यूरो) 1.13837 पर क्रम खरीद किया है, और 1.13806 पर रोक गलत क्रम नियत किया है.",
			row2_p3: "सामान्य, जब मूल्य 1.13806 तक छोड़ जाएगा, तुम्हारा रोका क्रम ट्रिगर किया जाएगा और स्थिति 1.13806 के मूल्य से बन्द हो जाएगा, जो 31 डॉलर का कुल घा",
			row2_p4: "हालाँकि जब बाजार परिवेश अचानक परिवर्तन हो जाएगा और मूल्य सीधे 1.13837 से 1.13795 से छोड़ जाएगा, तुम्हारे लिए स्थिति को बन्द करने के लिए, बाजार का अनुभव होगा और सिस्टम 1.13806 से स्थिति बन्द नहीं कर सकता। हालाँकि अगले अधि",
			row3_tit1: "ट्रैकिंग गुमान फंक्शन",
			row3_tit2: "उदाहरण",
			row3_p1: "ट्रेलिंग स्टॉप (जिसे मूविंग स्टॉप भी कहा जाता है) की अधिकतम ताकत है, स्टॉप लॉस प्राइस स्वतः ही लाभ की दिशा में चलने पर स्वतः ही बदल जाता है, इससे आपको निरंतर पदोन्नति का नजररखने की आवश्यकता नहीं होती है, और आपको लाभ बढ़ाने या हानि कम करने की आवश्यकता रहती है। नए आदेश बनाते समय, आपको केवल एक ट्रेलिंग स्टॉप प्वाइंट सेट करना होगा, जबकि मूल्य आपके पक्ष में चल रहा हो, तब आपके स्टॉप लॉस ",
			row3_p2: "वर्तमान मूल्य EUR/USD के लिए 1.13816/1.13837 है (विक्रेत/खरीद). आपने 1.13837 पर एक हाथ खरीद क्रम स्थापित किया और 100 बिन्दु (0.00100) के ट्रैकिंग स्थापित कर दिया.",
			row3_p3: "जब उत्पादक क्रम 1.13816 पर है, तुम्हारा रोका क्रम 1.13716 है. यदि उत्पादक का विक्रेत क्रम 1.13845 तक बढ़ा जाएगा, तो तुम नियत दूरी के अनुसार रोक गया क्रम अद्यतन किया जाएगा, और अद्यतन किया गया क्रम 1.13745",
			row3_p4: "विरूद्ध, जब उत्पादक क्रम 1.13845 से 1.13745 तक छोड़ जाएगा, ट्रैकिंग रोक नाश को ट्रैक किया जाएगा और बन्द स्थिति 1.13745 पर चलाया जाएगा.",
			tip: "बिन्दु: सामान्य, आर्थिक अओजारों के मूल्य में परिवर्तन का न्यूनतम एकाई बिन्दु कहता है. मिट्रेड प्लेटफ़ॉर्म पर यह साधन की मूल्य के अंतिम अंक या दशमलव की संदर्भ करता है",
		},
		sentiment: {
			tit: "भावनात्मक अनुक्रमणिका",
			type1: "पूर्ण",
			type2: "विदेशी बदलें",
			type3: "कमोडिटी",
			type4: "सूची",
			long: "लंबा",
			short: "बीरिश स्थिति",
		},
	},

	layout: {
		aside: {
			nav1: "transaction",
			nav2: "मार्केट",
			nav3: "वास्तविक समय जानकारी",
			nav4: "स्कूल",
			nav5: "मेरा",
			newsDialog: {
				title: "संदेश केंद्र",
				type1: "तंत्र सूचना",
				type2: "संदेश",
				allRead: "सभी पढ़े हुए रूप में चिह्नित करें",
			},
			settingDialog: {
				title: "सेटअप",
				nav1: "रूटिन",
				nav2: "प्रदर्शन पसंद",
				nav3: "तंत्र जानकारी",
				logout: "लागइन से लॉग आउट",
				setting1: "भाषा",
				setting2: "transaction",
				setting2_tip: "स्वचालित चयन के लिए खुला भण्डार की स्वचालित जोड़ें",
				setting3: "फीडबैक",
				setting3_tip: "फंक्शनल सिफारिसेंस",
				setting4: "वास्तविक नाम प्रमाणीकरण",
				setting4_tip: "वास्तविक नाम प्रमाणीकरण",
				setting5: "प्रसंग मोड",
				setting5_label1: "अंधार",
				setting5_label2: "चमकी रंग",
				setting6: "ऊपर और नीचे का रंग",
				setting6_label1: "हरी उठाई और लाल गिराई",
				setting6_label2: "लाल उठाया और हरा गिराई",
				setting7: "चार्ट विन्यास",
				setting7_label1: "मानक संपादन",
				setting7_label2: "ट्रैडिंग दृश्य संपादन",
				setting8: "विवरण तथा करार",
				setting8_label1: "निजी नीति",
				setting8_label2: "उत्पाद खुलासा विवरण।",
				setting8_label3: "रिस्क डिस्क्लोज़र स्टेटमेंट",
				setting8_label4: "अनुकूलन करार",
				feedback_title: "फंक्शनल सिफारिसेंस",
			}
		},
		footer: {
			hot: "प्रसिद्ध वेरिएट्स",
			sort: "विविध",
			code: "कोड",
			buy_price: "मूल्य खरीद करें",
			sell_price: "मूल्य बेच रहा है",
			chg: "फ्लूक्यूटेशन सीमा",
		},
		header: {
			place_search: "ट्रांसेक्शन प्रकारों के लिए खोजें",
			balance: "उपलब्ध",
			balance_tip: "उपलब्ध बैलेंस: The amount in the account that can be used to open a new position",
			profit_loss: "प्रोफाइट और हराम",
			asset_view: "एसेट ओवरव्यू",
			netValue: "नेट मूल्य",
			netValue_tip: "नेट वर्थ: मौजूदा खाता मूल्य, सभी होल्डिंग्स के लाभ-हानि सहित।",
			marginLevel: "सीमा स्तर",
			margin: "सीमा",
			margin_tip: "मार्जिन: सभी पोजीशन की खोलने और बनाए रखने के लिए आवश्यक धनराशि।",
			maintainsMargin: "मेंटेन्ट सीमा",
			maintainsMargin_tip: "मार्जिन पालन करें: सभी स्थितियों को पकड़ने पर आपको अपने खाते में रखने की जरूरत है न्यूनतम सीमा की मात्रा.",

		},
	},
	table: {
		label_name: "नाम",
		label_buyPrice: "मूल्य किया जा रहा है",
		label_sellPrice: "मूल्य बेच रहा है",
		label_variableValue: "चल मूल्य",
		trade: "transaction",
		label_code: "उत्पादन कोड",
		label_title: "नाम",
		label_start: "महत्वपूर्ण",
		label_country: "देश",
		label_event: "घटना",
		label_previous: "पिछला मान",
		label_predictive: "आनुमान",
		label_announced: "निर्देशित मूल्य",
		label_stop_loss_min: "रोके गलत क्रम की न्यूनतम दूरी",
		label_difference: "गतिशील मूल्य फरक",
		label_contract_size: "कंट्राक्ट आकार",
		label_max_hand: "प्रत्येक ट्रांसेक्शन की अधिकतम संख्या",
		label_min_hand: "प्रत्येक ट्रांसेक्शन की न्यूनतम संख्या",
		label_trade_week: "ट्रैडिंग सप्ताह कैलेंडर",
		label_week: "1 सप्ताह",
		label_month: "1 महीना",
		label_quarter: "1 चौथाई",
		label_dailyTrend: "रोज़ी ट्रेंड",
		label_tradeId: "ट्रांसेक्शन आईडी",
		label_breed: "विविध",
		label_tradeType: "ट्रांसेक्शन प्रकार",
		label_currency: "मुद्रा",
		label_amount: "पैसा",
		label_balance: "balance",
		label_type: "प्रकार",
		label_time: "समय",
		label_orderNumber: "क्रम संख्या",
		label_pointer_number: "हात गणना",
		label_price_money: "मूल्य खोल रहा है",
		label_stop_win_price: "उत्पन्न रोकें",
		label_stop_lose_price: "गुमान रोकें",
		label_openTime: "समय खोलें",
		label_profit_loss: "प्रोफाइट और हराम",
		label_sell_buy: "बेचना/खरीदा किया जा रहा है",
		label_chg: "फ्लूक्यूटेशन सीमा",
		label_sell_price: "मूल्य बेच रहा है",
		label_buy_price: "मूल्य खरीद करें",
		label_condition: "अवस्था",
	},
	form: {
		label_feedback: "प्रश्न और सुझाव",
		place_feedback: "कृपया अपना प्रश्न या सुझाव भरें",
		label_img: "छवि",
		label_img_tip: "वैकल्पिक, समस्या के स्क्रीनशॉट प्रदान करें",
		feedback_tip: "यदि आपके पास अत्यन्त प्रश्न है, कृपया संपर्क करें",
		online: "ऑनलाइन सेवा",
		label_name: "नाम",
		place_name: "कृपया अपना नाम भरें",
		label_email: "इमेल",
		place_email: "इमेल पता",
		message_email: "कृपया अपना इमेल पता भरें",
		label_nationality: "राष्ट्रीय",
		place_nationality: "कृपया अपनी राष्ट्रीयता भरें",
		place_questionType: "समस्या प्रकार",
		message_questionType: "कृपया एक प्रकार चुनें",
		questionType0: "ट्रेडिंग प्लेटफ़ॉर्म",
		questionType1: "उत्पादन तथा फीज",
		questionType2: "रोज़ी बाजार विश्लेषण में सदस्यता लें",
		questionType3: "ग्राहक सेवा",
		questionType4: "अन्य",
		place_question: "आपका प्रश्न",
		message_question: "कृपया एक प्रश्न भरें",
		submit_question: "प्रश्न भेजें",
		label_phone: "मोबाइल फोन संख्या",
		place_phone: "कृपया अपना फोन संख्या भरें",
		message_phone: "कृपया अपना फोन संख्या भरें",
		label_password: "पासवर्ड",
		place_password: "कृपया पासवर्ड भरें",
		message_password: "कृपया पासवर्ड भरें",
		label_confirmPassword: "पासवर्ड पुष्टि करें",
		place_confirmPassword: "कृपया पासवर्ड फिर भरें",
		message_confirmPassword: "कृपया पासवर्ड फिर भरें",
		label_captcha: "परीक्षण कोड",
		place_captcha: "कृपया सत्यापन कोड भरें",
		message_captcha: "कृपया सत्यापन कोड भरें",
		get_captcha: "सत्यापन कोड प्राप्त करें",
		label_inviteId: "निमन्त्रण कोड",
		place_inviteId: "निमन्त्रण कोड (वैकल्पिक)",
		to: "में",
		start_time: "प्रारंभ समय",
		end_time: "अंतिम समय",
		label_cardNumber: "पहचान संख्या",
		place_cardNumber: "कृपया अपना आईडी संख्या भरें",
		message_cardNumber: "कृपया अपना आईडी संख्या भरें",
		label_cardMain: "आईडी के आगे",
		message_cardMain: "कृपया आईडी के आगे भरें",
		label_cardBack: "दस्तावेज़ के उलटे किनारे पर",
		message_cardBack: "कृपया आईडी का उल्टा पार्श्व दिखाएँ",
		confirm_modify: "परिवर्तन सुनिश्चित करें",
		label_realName: "पूरा नाम",
		place_realName: "कृपया अपना पहला नाम भरें",
		message_realName: "कृपया अपना पूरा नाम भरें",
		label_firstName: "उपनाम",
		place_firstName: "कृपया अपना अंतिम नाम भरें",
		message_firstName: "कृपया अंतिम नाम भरें",
		label_lastName: "नाम",
		place_lastName: "कृपया अपना मध्य नाम भरें",
		message_lastName: "कृपया एक नाम भरें",
		label_birthday: "जन्म का तारीख",
		place_birthday: "कृपया अपने जन्म का तिथि भरें",
		message_birthday: "कृपया जन्म का तारीख भरें",
		place_nowPsd: "कृपया मौजूदा पासवर्ड भरें",
		message_nowPsd: "कृपया मौजूदा पासवर्ड भरें",
		place_newPsd: "कृपया एक नया पासवर्ड भरें",
		message_newPsd: "कृपया एक नया पासवर्ड भरें",
		place_crmPsd: "कृपया नया पासवर्ड पुष्टि करें",
		message_crmPsd: "कृपया नया पासवर्ड पुष्टि करें",
		label_breed: "विविध",
		place_breed: "कृपया विविध भरें",
		label_phase: "जब",
		label_buyPrice: "मूल्य खरीद करें",
		label_sellPrice: "मूल्य बेच रहा है",
		label_do: "द्वारा",
		label_height: "उप्पर",
		label_low: "नीचे",
		label_equal: "या बराबर है",
		labelWalletName: "वालेट नाम",
		messageWalletName: "कृपया वालेट नाम भरें",
		placeWalletName: "कृपया वालेट नाम भरें",
		labelWalletAddress: "वालेट पता",
		messageWalletAddress: "प्लेस वालेट पता भरें",
		placeWalletAddress: "प्लेस वालेट पता भरें",
		labelAmount: "मात्रा",
		messageAmount: "कृपया मात्रा भरें",
		placeAmount: "कृपया मात्रा भरें",
		placeTimeLimit: "कृपया एक समय सीमा भरें",
		messageTimeLimit: "कृपया एक समय सीमा भरें",
	},
	pay: {
		title: "उपयोगी डेस्पोज़िट और छोड़ना",
		p1: "बहुत सामान्य प्रयोग किया जाता है पैसा चैनल, तेज डेस्पोसिट और छोड़ने को समर्थन करता है",
		p2: "आपके देश/क्षेत्र में कुछ पैसा पद्धति उपलब्ध नहीं होगी",
		p3: "मिट्रेड सेवा के अनुभव में स्वागत है",
		btn: "ट्रांसेक्शन के लिए एक खाता तुरंत खोलें",
	},
	header: {
		tip1: "व्यापारित व्यापार है एक जटिल आर्थिक उत्पादक है जिसके साथ तीव्र घाटे की उच्च जोखित है।",
		btn1: "ट्रेडिंग प्लेटफ़ॉर्म",
		btn2: "लागइन से लॉग आउट",
		btn3: "लॉगइन",
		BigUint64Array: "तुरंत ट्रांसेक्शन",
	},
	footer: {
		help: "मदद जरूरत है？",
		tip1: "हमारी समुदाय का अनुसरण करें",
		tip2: "कृपया नोट करें कि मिट्रेड ने एक आधिकारिक टेल्ग्रूप स्थापित नहीं किया है, और मिट्रेड के नाम के अनुसार किसी टेल्ग्रू",
		tip3: "रोसिक चेतावनी: ट्रेडिंग आपको सभी फ़ॉन्ड खोल देता है. OTC डेरिवेटिव ट्रेडिंग हर के लिए उपयुक्त नहीं है. कृपया हमारे सेवा के प्रयोग से पहले हमारे व्यवस्था दस्तावेज़ को सावधान से पढ़ने के लिए और सुनिश्चित कर आप वास्तव में किसी संबंधित अधिकार मालिक नहीं रखते हैं.",
		tip4: "मिट्रेड कोई सल्लाह, सिफारिस, या विक्रेता के बारे में कोई मतलब नहीं देता। सभी उत्पादन जो हम प्रदान करते हैं, वैश्विक मालिकों पर आधारित हैं. सभी सेवा जो मिट्रेड द्वारा प्रदान किया गया है सिर्फ ट्रांसेक्शन निर्देशों को चलाने पर आधारि",
		tip5: "मिट्रेड एक व्यवसाय ब्रैंड है जो बहुत संसार के द्वारा प्रयोग करता है और निम्न कंपनियों के द्वारा काम करता",
		tip6: "मिट्रेड इंटरनेटेन्सटेन्शन लिट्रेड इस वेबसाइट में उपयोग के लिए वर्णन या उपलब्ध आर्थिक उत्पादकों  मिट्रेड इंटरनेटार्नेशनल लिट्रेड स्ट्रीट, पहिले फ्लॉडर नदी कोर्ट, पोर्ट लुइस 11328, मारिशियास में मारिशियास फानियन सेविस कमिसियन (एफसीसी) क",
		tip7: "मिट्रेड ग्लोबल पीटी लिट्टी के पास एबीएन 90 149 011 361 की रिजिस्ट्रेड संख्या है और एक ऑस्ट्रेलिया आर्थिक सेवा लाइसेंस (ए",
		tip8: "मिट्रेड होल्डिंग लिट्टी के द्वारा कैमन द्वीप मोनेटारी अधिकारी द्वारा अनुमोदन और नियंत्रण किया जाता है जिसके साथ सीबी",
		tip9: "इस वेबसाइट पर जानकारी यूनाइटेड स्टेट, कैनाडा, जापान या न्यू जीलैंड के राज्यों के लिए नहीं है; इस वेबसाइट की जानकारी किसी देश के लिए योग्य नहीं है जो अपनी प्रकाशन या उपयोग के कारण स्थानीय विधियों को जोड़ता है या प्रक कृपया नोट करें कि अंग्रेजी हमारे सेवा में उपयोग की प्राथमिक भाषा है और हमारे सब कंट्रांट दस्तावेजों में भी वैध अन्य भाषा अनुवाद सिर्फ संदर्भ के लिए है. पाठ अनुवादं",
		tip10: "एसएसएल सुरक्षित संपर्क एनक्रिप्शन. ©  सभी अधिकारों को संरक्षित करें।",
		link1: "निजी नीति",
		link2: "उत्पाद खुलासा विवरण।",
		link3: "संपूर्ण प्रक्रिया",
		link4: "रिस्क डिस्क्लोज़र स्टेटमेंट",
		link5: "अनुकूलन करार",
		toTop: "टॉपिंग",
	},
	nav: {
		tit1: "उत्पादन",
		tit2: "transaction",
		tit3: "निवेश शिक्षण",
		tit4: "हमारे बारेमें",
		nav1: "विदेशी बदलें",
		nav2: "सूची",
		nav3: "कमोडिटी",
		nav4: "साझेदार",
		nav5: "ट्रेडिंग प्लेटफ़ॉर्म",
		nav6: "ट्रेडिंग रणनीति",
		nav7: "ट्रेडिंग दृश्य",
		nav8: "आर्थिक कैलेन्डर",
		nav9: "वास्तविक समय समाचार",
		nav10: "वास्तविक समय क्वोटेशन",
		nav11: "भावनात्मक अनुक्रमणिका",
		nav12: "जोखिया प्रबंधन",
		nav13: "विवरण",
		nav14: "निवेश में परिचय",
		nav15: "निवेश संस्था",
		nav16: "अकाडेमी",
		nav17: "मिट्रेड के बारे में",
		nav18: "प्रतिज्ञा और सम्मान",
		nav19: "मीडिया केंद्र",
		nav20: "आर्थिक सुरक्षा",
		nav21: "फीज़ और फीज़",
		nav22: "अफिलिटेटName",
		nav23: "हमसे संपर्क",
		nav24: "बारे में पूछा गया प्रश्न",
		nav25: "मदद केंद्र",
	},

	city: {
		albania: "अलबानिया",
		algeria: "अल्जीरिया",
		argentina: "अर्जेन्टिना",
		armenia: "आर्मेनिया",
		australia: "ऑस्ट्रेलिया",
		pakistan: "पाकिस्तान",
		austria: "आस्ट्रिया",
		bahrain: "बहरेन",
		belgium: "बेल्जियम",
		bosnia_and_Herzegovina: "बोस्निया और हर्जेगोविना",
		brazil: "ब्राजील",
		brunei: "ब्रुनेई",
		bulgaria: "बुल्गेरिया",
		cambodia: "कंबोडिया",
		canada: "कनाडा",
		cameroon: "कैमेरून",
		chile: "पता पुस्तिका",
		colombia: "कोलंबिया",
		costa_Rica: "कोस्टा रिका",
		croatia: "क्रोएशिया",
		cyprus: "साइप्रस",
		czech_Republic: "चेक गणतंत्र",
		denmark: "डेनमार्क",
		dominican_Republic: "डोमिनिकन गणतंत्र",
		egypt: "मिश्र",
		estonia: "एस्टोनिया",
		ethiopia: "इथोपिया",
		finland: "फिनलैंड",
		france: "फ्रांस",
		georgia: "जार्जिया",
		germany: "जर्मनी",
		ghana: "घाना",
		greece: "ग्रीस",
		guyana: "गुयाना",
		honduras: "होंडुरास",
		hong_Kong_China: "हांग कांग, चीन",
		hungary: "हंगरी",
		iceland: "आइसलैंड",
		ireland: "आयरलैंड",
		italy: "इटली",
		india: "भारत",
		indonesia: "इंडोनेशिया",
		israel: "इस्राएल",
		iran: "इरान",
		iraq: "इराक",
		japan: "जापान",
		kazakstan: "कजाखस्तान",
		kenya: "केनिया",
		korea: "कोरिया",
		kuwait: "कुवेत",
		kyrgyzstan: "किर्गिस्तान",
		laos: "लाओस",
		latvia: "लात्विया",
		lithuania: "लिथुआनिया",
		luxembourg: "लक्समबर्ग",
		macao_China: "मकाउ",
		macedonia: "मेसिडोनिया",
		malaysia: "मलेशिया",
		malta: "माल्टा",
		mexico: "मेक्सिको",
		moldova: "मोल्डोवा",
		monaco: "मोनाको",
		mongolia: "मंगोलिया",
		montenegro: "मोंटेनेग्रो",
		morocco: "मोरोक्को",
		myanmar: "म्यानमार",
		netherlands: "नीदरलैंड",
		new_Zealand: "न्यूजीलैंड",
		nepal: "नेपाल",
		nigeria: "नाइजेरिया",
		norway: "नार्वे",
		oman: "ओमान",
		palestine: "पैलेस्टीन",
		panama: "पानामा",
		paraguay: "पैराग्वे",
		peru: "पेरू",
		philippines: "फिलिपीन्स",
		poland: "पोलेंड",
		portugal: "पोर्टुगाल",
		puerto_Rico: "प्यूरिटो रिको",
		qatar: "कतार",
		romania: "रोमानिया",
		russia: "रूसिया",
		republic_of_Trinidad_and_Tobago: "ट्रिनिदाद तथा टोबागो",
		rwanda: "रुवान्डा",
		saudi_Arabia: "साउदी अरबिया",
		serbia: "सर्बिया",
		singapore: "सिंगापुर",
		slovakia: "स्लोवाकिया",
		slovenia: "स्लोवेनिया",
		south_Africa: "दक्षिण अफ्रीका",
		spain: "स्पेन",
		sri_Lanka: "श्रीलंका",
		sweden: "स्वीडन",
		switzerland: "स्विट्जरलैंड",
		taiwan_China: "ताइवान, चीन",
		tajikistan: "ताजिकिस्तान",
		tanzania: "तान्जानिया",
		thailand: "थाइलैंड",
		turkey: "turkey. kgm",
		turkmenistan: "तुर्कमेनिस्तान",
		ukraine: "तुर्कमेनिस्तान",
		united_Arab_Emirates: "UAE",
		united_Kingdom: "ब्रिटन",
		united_States: "युनाइटेड स्टेट्स",
		uzbekistan: "उज्बेकिस्तान",
		venezuela: "वेनेजुएला",
		vietnam: "विएतनाम",
		afghanistan: "अफगानिस्तान",
		angola: "अंगोला",
		azerbaijan: "अजरबैजान",
		bangladesh: "बांग्लादेश",
		belarus: "बेलारूस",
		belize: "बेलिज",
		benin: "बेनिन",
		bhutan: "भूतान",
		bolivia: "बोलिविया",
		botswana: "बोटस्वाना",
		british_Virgin_Islands: "ब्रिटिश कुमारी द्वीप",
		burkina_Faso: "बर्किना फासो",
		burundi: "बुरुन्डी",
		cape_Verde: "केप वर्डे",
		cayman_Islands: "कैमन द्वीप",
		central_African_Republic: "केंद्रीय अफ्रीकी गणतंत्र",
		chad: "चाड",
		comoros: "कोमोरोस",
		the_Republic_of_the_Congo: "कांगो (ब्राजाविल)",
		democratic_Republic_of_the_Congo: "कांगो",
		djibouti: "जिबुती",
		ecuador: "इक्वेडोर",
		el_Salvador: "एल सल्वाडोर",
		equatorial_Guinea: "इक्वेटोरियल गिनी",
		eritrea: "इरिट्रिया",
		fiji: "फिजी",
		gabon: "गैबन",
		gambia: "गैम्बिया",
		greenland: "ग्रीनलैंड",
		guatemala: "गुआटेमाला",
		guinea: "गिनी",
		haiti: "हैती",
		isle_of_Man: "आइस्ल ऑफ मैन",
		cote_d_Ivoire: "आइवोरी कोस्ट",
		jamaica: "जमैका",
		jordan: "जॉर्डन",
		lebanon: "लेबनान",
		lesotho: "लेसोथो",
		liberia: "लाइबेरिया",
		libya: "लीबिया",
		madagascar: "मैडागास्कर",
		malawi: "मैडागास्कर",
		maldives: "माल्दीव्स",
		mali: "माली",
		mauritania: "मारीटानिया",
		mauritius: "मारीशियस",
		mozambique: "मोजाम्बिक",
		namibia: "नामीबिया",
		nicaragua: "निकारागुआ",
		republic_of_Niger: "नाइजर",
		north_Korea: "उत्तर कोरिया",
		reunion: "रियूनियन",
		san_Marino: "सेन मारिनो",
		senegal: "सेनेगाल",
		sierra_Leone: "सियरा लिओन",
		somalia: "सोमालिया",
		sudan: "सुडान",
		suriname: "सुरीनाम",
		eswatini: "एस्वाटिनी",
		syria: "सिरिया",
		togo: "टोगो",
		tonga: "टोङ्गा",
		tunisia: "ट्यूनीसिया",
		united_States_Virgin_Islands: "वर्जिन द्वीप, यूनाइटेड स्टेट्स",
		uganda: "यूगांडा",
		uruguay: "उरुगुआई",
		vatican: "वाटिकान",
		yemen: "येमेन",
		yugoslavia: "यूगोस्लाविया",
		zambia: "जांबिया",
		zimbabwe: "zimbabwe",
		china: "चीन",
	}
};
