export default {
	crypto: {
		title: { "0": "暗号化通貨の購入", "1": "暗号化通貨の購入", "2": "C2C" },
		list: {
			"0": "C 2 C取引",
			"1": "購入する",
			"2": "売り出す",
			"3": "広告主（完成率）",
			"4": "取り引き",
			"5": "価格",
			"6": "使用可能",
			"7": "制限＃セイゲン＃",
			"8": "支払い方法",
			"9": "貿易",
			"10": "広告主は広告に取引制限を設定する可能性があり、ログインすると制限の理由が表示されます。",
		},
		buy: {
			"0": "暗号化通貨の購入",
			"1": "暗号化通貨の販売",
			"2": "履歴",
			"3": "サポート",
			"4": "支払い",
			"5": "受入",
			"6": "支払方法の選択",
			"7": "東部標準時到着時刻",
			"8": "コスト",
			"9": "オーダー詳細",
			"10": "支払い方法",
			"11": "詳細",
			"12": "価格",
			"13": "合計（費用込み）",
			"14": "免責事項",
			"15": "HKDFinancを出て、第三者としてクレジットカード/デビットカード決済サービスを提供するトランプ金融機関であるMercuryoにリダイレクトされます。サービス利用中に発生するあらゆるリスクは、Mercuryoが負担します。続行する前に利用規約を読んで同意してください。",
			"16": "利用規約を読んで同意しました",
			"17": "あなたは",
			"18": "送信",
			"19": "金額を入力してください",
			"20": "アカウント残高が不足しています",
			"21": "使用可能",
			"22": "現品口座は使用可能です",
			23: "Single purchase limit",
			24: "Minimum",
			25: "Maximum",
		},
	},
	common: {
		confirm: "定義する",
		cancel: "キャンセル",
		tip: "注意を引く",
		place_select: "選択してください",
		submit: "だす",
		more: "もっと見る",
		deposit: "デポジット",
		real_trade: "リアルディール",
		paper_trade: "デモ取引",
		build_paperTrade: "デモ口座の作成",
		start_trade: "今すぐ取引を開始",
		app_name: "HKDFINANC",
		success: "成功！",
		dataSources_name: "Trading Central",
		reset: "リビジョン",
		iknow: "了解した！",
		noData: "データなし",
		modify: "修正",
		pingcang: "ポジションを閉じる",
		all: "フル",
		placeEnter: "入力してください",
		countryCode: "国コード",
	},
	addnew: {
		realFirst: "まず身分認証を行ってください",
		payFirst: "まず取引パスワードを設定してください",
		historyOrder: "履歴オーダー",
		forgot: "パスワードを忘れるा",
		contractTrade: "契約",
		paypass: "取引パスワード",
		loginpassTit: "ログインパスワードの変更",
		paypassTit: "引き出しパスワードの変更",
		paypassTit1: "引き出しパスワードを設定してください",
		paypassTit2: "ログインパスワードを初期化する",
		buyUp: "買い増し",
		buyDown: "買いが落",
		addressName1: "中金金融（国際）控股有限公司",
		addressValue1: "香港、セントラル、ガーデンロード3、クラウンタワー（旧シティバンクタワー）11階、1101号室",
		addressName2: "香港中能金融投資有限公司",
		addressValue2: "香港中環（セントラル）中環（クイーンズロード）131-133室",
		addressName3: "プロスペリティ・フィナンシャル・ホールディングス",
		addressValue3: "香港アドミラルティ、クイーンズウェイ89番地、19階、1908号室 リッポーセンター・タワー2",
		loan: "借り入れる",
		repaid: "払い戻し",
        unpaid: "未払い",
		loanMore: "ローンを組みたい。",
		immediateRepayment: "即時返済",
		accountType1: "コイン口座",
		accountType2: "契約アカウント",
		accountType3: "オプション口座",
		from: "をとおして",
        to: "まで",
		get: "を取得します。",
		labelBreed: "通貨タイプ",
		placeBreed: "通貨を選択してください",
		labelTransAccount: "振替口座",
		labelAmount: "譲渡金額",
		placeAmount: "振替金額を入力してください",
		transferTit:"口座振替",
		convertTit:"コインフラッシュエクスチェンジ",
		balanceTit:"口座残高",
		available: "アベイラブルクレジット",
        pending: "ロック",
        equivalent: "に達する。",
		coinTrade: "コイントレーディング",
		secondContract: "再契約",
		number: "数量",
		labelNumber: "数量",
		placeNumber: "身代金の数を入力してください。",
		time: "时间",
		loadAmount: "融資予定額",
		repaymentCycle: "ローン返済サイクル",
		dailyRate: "日歩",
		repaymentMethod: "返済方法",
		loanTip: "クレジット・ローン（写真がはっきりと見えるようにしてください。）",
		loanTip1: "物件証明書のアップロード",
        loanTip2: "収入証明書（雇用関係）",
        loanTip3: "銀行取引明細",
        loanTip4: "IDカードの正面写真をアップロードする。",
        interest: "興味",
		repaymentMethod1: "一括返済",
	},
	params: {
		product_foreign: "がいこくかわせ",
		product_shop: "商品",
		product_number: "しすう",
		product_stock: "株式",
		product_coin: "暗号化",
		billTypes: [
			{v: 101, name: 'リチャージ'},
			{v: 102, name: 'ちょきん'},
			{v: 103, name: '凍結'},
			{v: 104, name: '凍結解除'},
			{v: 105, name: 'ちょきん'},
			{v: 106, name: 'ちょきん'},
			{v: 201, name: '出金凍結'},
			{v: 202, name: '資金を引き出す'},
			{v: 203, name: '撤退成功'},
			{v: 204, name: '資金を引き出し損ねる'},
			{v: 205, name: '出金手数料'},
			{v: 206, name: '移管'},
			{v: 207, name: 'にシフトする。'},
			{v: 208, name: 'コイン・トランスファー'},
			{v: 209, name: 'コイン・トランスファー'},
			{v: 301, name: '契約手数料'},
			{v: 302, name: '契約収入'},
			{v: 303, name: '契約損'},
			{v: 304, name: '契約証拠金'},
			{v: 305, name: 'コントラクト・マージン・リターン'},
			{v: 311, name: 'オプションの購入'},
			{v: 312, name: 'オプション利益'},
			{v: 313, name: 'オプション・リターン'},
			{v: 314, name: 'オプション取扱手数料'},
			{v: 315, name: 'コイン購入凍結'},
			{v: 316, name: 'コイン購入控除'},
			{v: 317, name: 'コイン買い戻し'},
			{v: 318, name: '口座へのコイン購入'},
			{v: 319, name: '口座へのコイン購入'},
			{v: 320, name: 'コイン販売凍結'},
			{v: 321, name: 'コイン売却控除'},
			{v: 322, name: 'コイン売りリターン'},
			{v: 323, name: '口座へのコイン売却'},
			{v: 324, name: '口座へのコイン売却'},
			{v: 325, name: 'コイン取扱手数料'},
			{v: 401, name: '鉱山労働者が参加'},
			{v: 402, name: 'マイナー・リターンズ'},
			{v: 403, name: '鉱山機械からの収入'},
			{v: 404, name: '鉱山機械の出口'},
			{v: 405, name: '鉱山撤退費用'},
			{v: 411, name: '借入収益'},
			{v: 412, name: '借入手数料'},
			{v: 413, name: '返済成功'},
        ],
	},
	message: {
		logout: "ログアウト",
		register_success: "登録に成功",
		place_inner_personal: "最初に個人情報を入力してください",
		submit_success: "成功裏に終了！",
		copy_success: "コピー成功！",
		copy_error: "生殖の失敗！",
		modify_success: "修正成功",
		no_balance: "保証金の残高不足",
		palce_password: "パスワードを入力してください",
		trade_success: "取引成功",
		placeEnterAmount: "金額を入力してください",
		tipTit1: "この注文の支払いに間違いありませんか？",
	},
	web: {
		account: {
			menu1: "口座情報",
			menu2: "私の資産",
			menu3: "アカウントレポート",
			menu4: "アカウント・セキュリティ",
			menu5: "通知",
			menu6: "アクティビティセンター",
			menu7: "財布が",
			menu8: "金を引き出す",
			walletAdd: "新しい財布を追加する",
			report: {
				history_tit: "取引履歴レポート",
				stream_tit: "資金循環報告書",
				desc: "アカウントレポートをエクスポートするには、適切な日付範囲を選択してください。 (過去1年間のアカウント情報にアクセスできます。）",
				select_date: "日付範囲を選択",
				week: "先週",
				month: "約1ヶ月",
				threeMonth: "過去3ヶ月",
				sixMonth: "過去6ヶ月",
				time: "时间",
				view_report: "ショーレポート",
				email_send: "電子メールで送信",
				dialog_tit: "アカウントレポート",
				dialog_p1: "HKDFINANC Holding Ltdは、ケイマン諸島で登録されており（SIB番号：1612446）、貿易事業体としてお客様の取引を処理します。",
				tit2: "Mitade 取引履歴レポート",
				tit4: "Mitade 資金循環報告書",
				tit3: "口座情報",
				label1: "アカウント名",
				label2: "アカウントID",
				label3: "口座通貨",
				label4: "報告時間",
				label5: "报告期",
				label6: "キャッシュフロー",
				empty: "クローズした取引がない",
				email_des: "レポートはあなたのEメールアドレスに送信されます。",
				tab1: "報告",
				tab2: "デイリー・ステートメント",
				tab3: "月次詳細",
			},
			account: {
				tit1: "HKDFINANCへようこそ",
				tit2: "本物の預金で取引するための完全な本人確認",
				p1: "ライブ取引口座を開設する",
				p2: "認証",
				btn1: "認証",
				tit3: "個人情報",
				label_name: "名字",
				label_email: "電子メールアドレス",
				label_address: "住所",
				tit4: "口座情報",
				label_account: "取引口座（リアル取引）",
				label_currency: "基準通貨（実質取引）",
				label_company: "当座預金会社",
				company_tip: "HKDFINANC Holding Ltd,HKDFINANC Holding Ltd HKDFINANC Group of Companiesは、ケイマン諸島金融管理局（CIMA）の認可を受けています。",
				tit5: "ライブ取引口座を開設する",
				tit6: "情報の改善",
				tit7: "基本情報の入力",
				tit8: "リスクアセスメント",
				tit9: "取引リスク許容度の評価",
				tit10: "認証",
				tit11: "規制要件に従った本人確認の完了",
				p3: "確認のため、以下の補助書類のいずれかを選択してください。",
				type1: "アイデンティティーカード",
				type2: "運転免許",
				type3: "パスポート",
				p4: "私は、提供したすべての情報および書類が完全であり、真実かつ正確であることを確認し、。",
				next: "次のステップ",
				btn2: "認定合格",
				modify_personal_tit: "個人情報の修正",
			},
			activity: {
				tit: "アクティビティセンター",
				desc: "アクティビティに参加して報酬を受け取り、投資収益を高めることができる。",
			},
			capital: {
				real_trade: "リアルディール",
				label_nowAccount: "当座預金",
				label_tradeAccount: "取引口座番号",
				label_currency: "基軸通貨",
				deposit_withdraw_record: "入出金記録",
				capital_flow_details: "資金の流れの詳細",
			},
			notification: {
				tit: "通知設定",
				tit1: "通知チャネルを開く",
				email: "電子メールアドレス",
				message: "テキストメッセージング",
				push: "プッシュ",
				verify: "糺す",
				verified: "有効",
				p1: "重要なニュースを把握するため、以下の通知チャンネルを開設／確認してください！",
				type1: "マーケットプレイス",
				tit2: "マーケティング活動の通知",
				desc2: "プラットフォームの特典情報、運営活動、その他のニュース",
				tit3: "取扱説明書",
				desc3: "豊富で専門的な知識資料",
			},
			security: {
				tit: "暗号化",
				isSet: "設定済み",
				set: "設定する",
				verify: "糺す",
				verified: "有効",
				tit1: "安全認証",
				email: "電子メールアドレス",
				phone: "携帯電話番号",
				phone_tit: "携帯電話番号バインディング",
				password_tit: "パスワードを変更する",
			},
		},
		market: {
			hot: "流行中",
			prev: "前ページ",
			next: "次ページ",
			line: "タイムシェアリング",
			main_subplot: "主要および補助チャート指標",
			main: "メインチャートインジケーター",
			subplot: "チャートインジケーター",
			go_trade: "取引ページへスキップ",
		},
		news: {
			tip: "免責事項：上記の情報は参考のための一般的な情報であり、取引の判断基準として使用するものではありません。",
		},
		trade: {
			state1: "手持ち",
			state2: "注文待ち",
			state3: "歴史",
			state: "情勢",
			state4: "クローズドポジション",
			state5: "引っ込み思案",
			type: "類型論",
			type1: "フル",
			type2: "付く",
			type3: "買い付け",
			left_label1: "セルフサービス",
			left_label2: "最近閲覧した記事",
			left_type1: "コンパクト柱状モデル",
			left_type2: "ゆるやかな柱状模様",
			left_type3: "チャートを表示",
			all: "フル",
			right_bp: "昨日の収穫",
			right_sp: "現代",
			right_height: "最高",
			right_low: "最低",
			remind_tit: "新着アラート",
			remindList: "リマインダーリスト",
			remind_btn: "新着アラート",
			right_tab1: "取引",
			right_tab2: "しょうさい",
			right_sell: "付く",
			right_buy: "買い付け",
			right_sell1: "付く",
			right_buy1: "買い付け",
			right_type: "類型論",
			right_type1: "市場価格表",
			right_type2: "注文待ち",
			right_priceMoney: "未決注文価格",
			right_pointerNumber: "数（ロット）",
			right_lever: "プライバー",
			right_balance: "利用可能残高",
			right_stop_profit: "テイクプロフィット",
			right_stop_loss: "ストップロス",
			right_profit_loss: "盛衰",
			now_order: "注文する",
			confirm_order: "注文の確認",
			right_tit1: "トレーディング戦略",
			right_rinei: "数日中",
			right_short: "短期",
			right_middle: "中期",
			right_time: "リリース時間",
			right_tit2: "代替戦略",
			right_tit3: "テクニカル・レビュー",
			right_tit4: "数据",
			right_tit5: "売買ムード",
			right_label_sell: "うりぬし",
			right_label_buy: "購入者",
			right_tip: "情報提供のみを目的としており、本部の見解ではない",
			right_tip2: "15分ごとに更新",
			right_tit6: "価格変動",
			minute: "議事録",
			today: "現在のところ",
			right_tit7: "価格変動幅",
			now_price: "現在価格",
			right_low_price: "最低価格",
			right_height_price: "最高価格",
			right_tit8: "契約情報",
			right_tit9: "豪ドル/スイスフラン",
			right_label1: "単一取引件数",
			right_label2: "最大レバレッジ",
			right_label3: "契約サイズ",
			right_label4: "最大総ポジション数",
			right_label5: "フローティング・スプレッド",
			right_label6: "オーバーナイトチャージ",
			hand: "熟練工",
			right_collection_time: "収集時間",
			right_tip3: "手数料ベース：取引規模全体から",
			right_label7: "マージン率",
			right_label8: "維持マージン率",
			right_label9: "取引時間",
			right_label10: "現在の取引セッション",
			right_label11: "次の取引セッション",


		},
	},
	home: {
		banner_p1: "とおす",
		banner_p2: "プラットフォームトランザクション",
		banner_p3: "為替, コモディティ, 指数, 株",
		banner_p4: "およびより人気のある金融商品",
		banner_p5: "世界で最も人気のある金融市場を探ることができます。HKDFINANCはコミッション0を提供し、極めて優位な低価格差を提供している。",
		hot: "流行中",
		tit1: "より友好的な取引",
		p1: "シンプルで直感的なインターフェースで操作が簡単",
		p2: "ウェブ、モバイルアプリ、デスクトップサポート、指先での取引",
		p3: "ストップ・ロス／トレーリング・ストップなどのリスク管理ツール",
		p4: "複数のテクニカルチャートと財務カレンダー、リアルタイムのニュース",
		p5: "リアルタイムのEメール、SMS、プッシュ通知",
		p6: "より良い取引体験のための継続的改善",
		btn1: "プラットフォーム",
		row3_tit: "いつでもどこでも自由な取引",
		row3_tip: "ウェブ、モバイルアプリ、デスクトップサポート、指先での取引",
		row3_tit2: "コードをスキャンしてダウンロード",
		row3_tit3: "デスクトップ",
		row3_tit4: "モバイル",
		regular_tit: "信頼できる国際プラットフォーム",
		regular_tip: "私たちは、お客様に安全で責任ある取引環境を提供することをお約束します。。",
		regular_tit1: "権威ある組織による規制",
		regular_p1: "業界当局の規制と認可を受け、世界中の顧客から信頼されている。",
		regular_tit2: "経済的安定を守る",
		regular_p2: "リテール顧客預金は、規制要件に従い、必要に応じて信託勘定に分別保管される。",
		regular_tit3: "マイナス残高保護",
		regular_p3: "口座のマイナス残高の保護、ゼロをクリアするためにタイムリーな方法でマイナス残高を提供し、あなたの損失がエントリの量を超えないように、心の取引の平和",
		regular_tit4: "7*24時間のカスタマーサービス",
		regular_p4: "誠実で専門的なカスタマーサービスチーム、24時間オンラインサポート、どんな問題でも喜んで解決します！",
		service_tit: "その他のサービス",
		service_tit1: "トレーディング戦略",
		service_p1: "リアルタイムの取引戦略により、最新の市場動向を理解し、取引のタイミングをより的確に把握することができます。",
		service_tit2: "トレーディング知識",
		service_p2: "HKDFINANCで無料でトレードを学び、トレードスキルを向上させましょう。",
		service_tit3: "リスク管理",
		service_p3: "HKDFINANCが提供する無料のリスク管理ツールについて知り、ご自身の資産をよりよく守りましょう。",
		step_tit: "口座開設が簡単で便利",
		step_tip: "簡単な3ステップですぐに口座開設",
		step_tit1: "在籍",
		step_p1: "必要事項をご記入の上、お申し込みください。",
		step_tit2: "デポジット",
		step_p2: "様々な方法による迅速な入金",
		step_tit3: "取引開始",
		step_p3: "取引機会を発見し、素早く注文を出す",
		award_tit: "実績",
		award_p1: "私たちは常に卓越性を追求し、卓越性を追求し、質の高い取引サービスをお客様に提供することをお約束します。",
		award_p2: "HKDFINANCブランドは、業界の権威ある機関からこの名誉ある賞を受賞し、チームの継続的な努力とお客様へのコミットメントが認められたことを光栄に思います。",
		row7_tit: "リアルタイムのグローバル財務情報",
		row7_tit1: "リアルタイムニュース",
	},
	learn: {
		education: {

		}
	},
	login: {
		click_download: "クリックしてダウンロード",
		p1: "いつでもどこでもトレード",
		p2: "より迅速でスマートな取引",
		p3: "手数料取引0",
		p4: "世界で最もホットな投資",
		renderDom1: "がいこくかわせ",
		renderDom2: "米国株式市場",
		renderDom3: "プラチナ",
		renderDom4: "原油",
		renderDom5: "指数",
		renderDom6: "暗号通貨",
		login: "サインイン",
		elail: "受信箱",
		phone: "携帯電話番号",
		no_account: "アカウントなし？",
		go_register: "登録する",
		register: "在籍",
		now_register: "今すぐ登録",
		have_account: "すでにアカウントをお持ちの方？",
		go_login: "ログイン",
	},
	about: {
		serviceFee: "サービス料",
		introduction: "プラットフォーム紹介",
		trade_platform: "取引プラットフォーム",
		complaint_process: "苦情処理",
		page: "ウェブページ",
		place_search: "知りたいことを検索する",
		no_answer: "必要な答えが見つからない?",
		contact_us: "お問い合わせ",
		awards: {
			h1: "受賞と栄誉",
			p1: "HKDFINANCブランドは、常に卓越性を追求し、質の高い取引サービスをお客様に提供することに専念しており、この度、業界の権威ある組織から賞を授与されたことは、チームの継続的な努力とお客様へのコミットメントが認められたことを光栄に思います。",
			p2: "，または、受賞歴のある当社のオンライン取引プラットフォームをすぐに体験してください！",
			name1: "ラテンアメリカのベスト・ブローカー",
			name2: "ベストCFDブローカー",
			name3: "ベスト・フィンテック・ブローカー",
			name4: "2021年に最も影響力のある企業トップ10",
			name5: "最も透明性の高いブローカー",
			name6: "究極の金融科学技術賞",
		},
		charges: {
			h1: "手数料",
			p1: "HKDFINANCは、顧客体験を向上させる不可欠な要素として、顧客により費用効果の高い取引サービスを提供したいと考えています。 当社は、手数料および料金においてオープンで透明性が高く、隠れた手数料がないように努めています。 お客様の主な取引コストは、ビッド/アスク・スプレッドとオーバーナイト・ポジション金利です。",
			h2: "まず、HKDFINANCで取引すると、次のようなメリットがあります。",
			li1: "製品の即時見積もり",
			li2: "専門指標とテクニカル指標",
			li3: "独占市場分析",
			free: "フリーウェア",
			view: "仰ぎ見る",
			h3: "ビッドアスクスプレッド",
			p2: "HKDFINANCは、サービスの対価としてビッド/アスクスプレッドを請求します。 この手数料は商品の見積りに反映され、ポジションを建てる際に実際にスプレッドを支払うことになります。 スプレッドの手数料は固定されていないため、各商品の見積もりページにアクセスするか、以下のページにログインすることをお勧めします。",
			h4: "入出金手数料",
			p3: "お客様のコストを最小限に抑えるため、ほとんどの場合、お客様の資金にアクセスするための手数料はいただいておりません。 ただし、第三者企業または銀行が、通過銀行手数料などの入出金手数料をお客様に請求する場合があります。",
			p4: "チャージの必要性に関するお問い合わせは、当社のカスタマーサービス・スペシャリストまでお寄せください。",
			p5: "例：国際クレジットカード取引、銀行口座の出し入れ、未対応通貨での取引（外貨換算）など。",
			h5: "オーバーナイトポジション費用",
			p6: "日本時間午後10時（日本時間午後22時）になってもポジションを保有している場合、夜間手数料が請求/入金されます。 各商品の夜間手数料に関するお問い合わせは、各商品のお見積もりページまたは",
			h6: "その他の費用",
			p7: "その他の費用は一切かかりません。 当社の課金ポリシーは完全に透明であり、課金が必要な項目は事前に発表・通知されるため、隠れた課金について心配する必要はありません。"
		},
		contact: {
			h1: "お問い合わせ",
			address: "住所",
			custom_email: "カスタマー・コンタクトEメール",
			business_email: "業務提携Eメール",
			h2: "私たちに手紙を書いてください。",
			p1: "当社のサービスに関するご質問は、以下のフォームにご記入の上、送信してください。 また、Trading Centralとの提携により提供される日次取引戦略をご希望の方は、下記フォームの種類で「日次市場分析を購読する」を選択し、ご希望の言語バージョンをご記入の上、ご登録ください。 *購読サービスは、既存のHKDFINANCリアルクライアントにのみご利用いただけます。",
			h3: "代理手続き",
			p2: "すべての苦情は、当社の専門チームによって調査・解決されます。",
		},
		faq: {
			h1: "一般的な問題",
			tit1: "デモ口座の使い方？",
			tit2: "ライブ口座開設方法？",
			tit3: "成行注文の作成方法？",
			tit4: "なぜ注文できないのですか？？",
			p1: "デモ口座は、取引インターフェース、データ、および操作の点で、リアル口座とほぼ同じです。 デモ口座には5万ドルの仮想資金が含まれており、お客様が資金を危険にさらすことなく、シミュレーション操作を通じてプラットフォームの機能に慣れることができるように設計されています。",
			p2: "ライブ口座を開設するには、以下の手順に従ってください。：",
			p3: "1.HKDFINANCの登録ページに入り、プロンプトに従って、メールボックス/携帯電話番号の登録、ログインパスワードの設定、確認の送信を行い、自動的に[デモ口座]を作成する。",
			p4: "2.デモ口座ページで「リアル口座に切り替える」を選択し、手順に従って「リアル口座」を開設します。",
			p5: "ライブ口座を開設する前に、取引に関連する法的開示を読み、理解することをお勧めします。",
			p6: "ライブ口座へのご入金は、本人確認が完了してからとなりますのでご注意ください。",
			p7: "取引」（Trade）をクリックすると、プラットフォーム上で取引可能なすべての商品が表示されます。右側の入力ボックスに商品のコードまたは名前を入力して検索し、商品を選択して「売り/買い」（SELL/BUY）をクリックすると、取引ウィンドウが表示され、現在の価格と必要な証拠金の概算が確認できます。 手動でオープンする取引数を調整し、リスクコントロールのためにストップロスやテイクプロフィットを設定し、「SELL/BUY」をクリックしてポジションのオープンを完了します。 すべての金融商品の価格は常に変動しており、市場に合わせて更新されるため、BUY/SELLボタンを押す前に市場が変更されている可能性があることにご注意ください。",
			p8: "まず、ネットワーク接続が正常かどうかを確認してください。 取引種目市場が一時的にクローズしている場合、またはクローズしている場合、注文を発注することができません。 オンラインカスタマーサービスにお問い合わせください。",

		},
		fundSecurity: {
			h1: "金融安全保障",
			p1: "HKDFINANCは、権威ある規制機関の認可を受け、規制を受けています。 コンプライアンスは、私たちのすべての行動と企業文化の中心にあります。 お客様の資金の安全を第一に考え、お客様の預金はすべて分別管理されています。",
			p2: "リテール顧客預金は、規制要件に従い、必要に応じて信託勘定で個別に保管される。",
			p3: "HKDFINANCは、法律および規則で認められている以外のいかなる商業活動にも顧客の資金を使用しません。",
			p4: "HKDFINANCはいかなる市場でも投機を行わない。",
			p5: "第三者会計事務所による独立監査",
		},
		help: {
			h1: "HKDFINANCヘルプセンターへようこそ",
		},
		media: {
			h1: "メディアセンター",
			p1: "HKDFINANCブランドに関する情報、プレスリリース、メディア特集、お知らせなどをご覧いただけます。",
		},
		mitrade: {
			h1: "HKDFINANCについて",
			view_certificate: "（検証方法）",
			p1: "HKDFINANCは、当局の認可と規制を受けたフィンテック企業であり、投資家にシンプルで便利な取引体験を提供することに注力しています。 革新的なHKDFINANCの多様な取引プラットフォームは、「ベスト・モバイル取引プラットフォーム」や「最も革新的な証券会社」などの賞を何度も受賞しています。",
			p2: "HKDFINANCプラットフォームを通じて、株式、株価指数、コモディティ、外国為替、その他何百もの人気種をカバーする、より幅広い国際金融市場に投資・取引することができます。 HKDFINANCは、使いやすいモバイルおよびウェブベースの取引プラットフォームだけでなく、競争力のある取引コスト、迅速な取引執行、優れた顧客サービス、豊富な情報サポートを提供し、お客様がタイムリーな投資・取引機会をつかむお手伝いをいたします。",
			p3: "HKDFINANCは、業界当局、複数の機関によって厳格に規制されています。 HKDFINANC Internationalは、モーリシャスの金融サービス委員会（FSC）により、ライセンス番号GB20025791の認可および規制を受けています。 モーリシャスのライセンスの詳細については、FSCの公式ウェブサイトhttps://www。 fscmauritius.org",
			p4: "をクリックしてから、HKDFINANC Globalは、香港証券投資委員会（ASIC）が認可した金融サービスライセンス（AFSL 398528）を保有している。香港ライセンスの詳細については、ASIC公式サイトwww.asic.gov.auまで",
			p5: "をクリックしてから、HKDFINANCはケイマン諸島金融管理局（CIMA）の権限を得て規制を受けており、SIBライセンス番号は1612446である。ケイマンライセンスの詳細については、CIMA公式サイトwww.cima.kyまで",
			p6: "HKDFINANCのすべての業務運営は、厳格な監督のもと、すべての規制を遵守して行われている。",
			h2: "HKDFINANCの背景",
			p7: "HKDFINANCは金融取引と金融科学技術業界の経験と知識に富んだベテランチームで構成されている。HKDFINANCの理念は、取引がより簡単になり、より友好的になることです",
			p8: "当社の全体的な戦略とビジネスモデルは、業界の発展と市場の需要についてグローバルで深い観察と知識を持つことを可能にし、より的を絞った方法で資源を配分し、継続的に技術を革新し、効率を最適化し、お客様により便利でフレンドリーな取引体験を提供し続けることができます。 ",
			h3: "なぜHKDFINANCなのか",
			why1: "低閾値取引条件",
			why2: "業界当局による規制",
			why3: "シンプルで直感的な取引プラットフォーム",
			why4: "高レベルのオンライン・サポート",
			why5: "競争的取引コスト",
			why6: "マイナス残高保護",
			h4: "受賞と栄誉",
			p9: "私たちは常に卓越性を追求し、卓越性を追求し、質の高い取引サービスをお客様に提供することをお約束します。",
			p10: "HKDFINANCブランドは、業界の権威ある機関からこの名誉ある賞を受賞し、チームの継続的な努力とお客様へのコミットメントが認められたことを光栄に思います。",
			new_add: "世界中の新規ユーザー",
			ol1: "最優秀ニュース・分析プロバイダー",
			ol2: "アジアのベストFXブローカー",
			ol3: "香港の海外顧客は大きな報酬で満足する",
			ol4: "ベスト・モバイル・アプリケーション",
			ol5: "香港で最速の証券商が増加",
			h5: "HKDFINANCの経営理念",
			tip_tit1: "負債",
			tip_tit2: "シンプルだが美しい",
			tip_tit3: "オープン",
			tip_tit4: "新しい道を切り開く",
			tip_p1: "すべての取引にはリスクが伴いますが、HKDFINANCはお客様に責任ある取引環境を提供するため、さらなる措置を講じています。HKDFINANCは、業界当局、複数の組織により規制され、高レベルのセキュリティと安定性をもって運営されています。 当社のプラットフォームは、リスク管理を含む多くの責任ある取引ツールを提供し続けています。",
			tip_p2: "HKDFINANCは、細部に至るまで使いやすさとユーザーエクスペリエンスを追求しています。 初心者から経験豊富な投資家まで、すべての人がHKDFINANCプラットフォームを通じて得られる取引機会を活用できるよう、プラットフォームとサービスの最適化を続けています。",
			tip_p3: "お客様が公平で効率的な取引環境から十分な利益を得られるように、HKDFINANCは厳格で透明性のある自主規制システムに従い、包括的で透明性のあるプラットフォーム商品情報、価格環境およびデータを提供し、すべての手数料を明確かつオープンにすることで、サービスプロセス全体が完全に誠実で透明性のあるものであることを保証します。",
			tip_p4: "HKDFINANCは、技術の進歩、金融革新、より広範なサービスこそが成長の原動力であり、価値を創造し共有し続けることができると強く信じています。 この目的のために、私たちはオープンな思考を奨励し、金融テクノロジーの革新者でありリーダーであることを目指し、イノベーションを推進し続けます。",
			row6_tit1: "私たちの使命",
			row6_tit2: "私たちのビジョン",
			row6_tit3: "求人情報",
			row6_p1: "より多くの取引希望者に、場所・時間・市場の制約を受けない、公正で効率的かつ透明性の高い取引環境を提供し、金融・技術革新において業界をリードする。",
			row6_p2: "私たちは、技術の進歩、金融の革新、多様なサービスこそが私たちの成長の原動力であり、社会に価値を創造し共有し続けることを可能にすると信じています。",
			row6_p3: "そのために、私たちはオープンマインドを奨励し、イノベーションを推進し、継続的な成長と進歩を通じて、以下のような企業になることを目指しています。",
			row6_p4: "世界有数の外国為替会社",
			row6_p5: "世界7カ国に展開するグローバルチームで、急成長中の当社では、常にプロフェッショナルを募集しています。 多文化なチームで、あなたの好きなことをしませんか！",
			row6_p6: "最新の履歴書と志望動機をEメールにてお送りください：",
			row6_p7: "。または、以下のサイトもご参照ください。",
			row6_p8: "もしかしたら",
			row6_p9: "空席情報を見る。",
			row6_p10: "HKDFINANCは、あなたの個人情報のセキュリティを厳密に保護します。 HKDFINANCは、求人応募の過程で金銭的な情報を求めることはありません。 HKDFINANCでは、財務関連情報、クレジットカード情報、銀行口座情報、または採用のためのいかなる支払い形態も要求しません。",
			row6_p11: "不正なポジションであると思われる場合は、以下の連絡先までご連絡ください。",
			row6_p12: "HKDFINANC人事部に連絡する。 虚偽の求人情報に騙されたと確信がある場合は、関連組織の最寄りの部署に連絡してください。",

		}
	},
	product: {
		row2: {
			tit: "取引品種",
			sell: "付く",
			buy: "買い付け",
			trade: "取引",
			more_product: "その他のトレーディング商品",
		},
		row3: {
			tit: "複数デバイスのトランザクションをサポート",
			tit1: "複数デバイスのトランザクションをサポート",
			p1: "HKDFINANCは、ウェブベース、モバイル、デスクトップの取引プラットフォームを提供しています。 いつでもどこでもお取引いただけます。",
			download: "ダウンロード",
		},
		row4: {
			tit: "HKDFINANCを選択",
			tit1: "シンプルで直感的なプラットフォーム",
			p1: "相場、取引、口座管理、情報、リスク管理、強力な機能を統合し、より良いユーザー体験を実現。",
			tit2: "競争的取引コスト",
			p2: "取引手数料0、非常に競争力のある透明性の高い低スプレッド、低SWAPで、低コストの取引をお楽しみいただけます！",
			tit3: "低閾値取引条件",
			p3: "各取引の最小ロットサイズは0.01ロットと小さく、低い証拠金でポジションを建てることができます。",
			tit4: "当局による規制",
			p4: "業界当局の認可と規制を受け、顧客資産の安全を守るため、すべての顧客預金は分別保管されている。",
			tit5: "マイナス残高保護",
			p5: "お客様の口座は、どのような市場環境下でも元本以上の損失を被ることはなく、マイナス残高はタイムリーに清算され、お客様のリスク管理能力を高めます。",
			tit6: "高レベルのオンライン・サポート",
			p6: "迅速な対応のオンラインカスタマーサービスを提供し、専門チームの絶え間ない努力により、より良いサービスを提供する。",
		},
		row5: {
			tit: "関連銘柄",
		},
		commodities: {
			title: "商品貿易",
			p: "貴金属やエネルギーなどのコモディティは、分散投資の重要な一部と考えられている。 なかでも金、銀、原油は大量に取引されるコモディティであり、そのオープンで透明性の高い価格と高い流動性は投資家に広く歓迎されている。 コモディティ価格は、需給、経済・政策情勢、為替などの要因によって大きく変動し、リスク・リターンの機会を提供している。",
		},
		forex: {
			title: "外国為替取引",
			p: "外国為替取引とは、ある通貨を別の通貨に交換することであり、外国為替市場は世界で最も取引量の多い金融市場である。 外国為替市場には多くの参加者がおり、決済のため、為替変動リスクをヘッジするため、あるいは利益を上げるために取引を行っている。 外国為替取引は、世界中の銀行、機関投資家、個人トレーダーのネットワークを通じて行われ、1日24時間、週5日の価格変動により、リスク・リターンの機会を提供しています。",
		},
		indices: {
			title: "指数",
			p: "株価指数とは、特定の取引所における特定の種類の特徴的な銘柄を表す統計値であり、市場における特定の種類の構成銘柄の全体的な価格水準や動きを示す指標である。 市場全体やセクターの投資機会を反映する株価指数を取引することで、個別銘柄への投資リスクを軽減することができる。 米国、欧州、アジア、本土市場で最も人気のある指数、例えば本土200、米国ハイテク100、香港50、ドイツ30などは、世界の株式市場でさまざまな機会を提供している。",
		},
		shares: {
			title: "株式取引",
			p: "株式は取引所で発行される有価証券です。企業は資金調達のため、上場取引所を通じて会社の所有権を証券を利用して分配する。2019年だけでも、世界の株式の取引総額は60兆ドルを超え、規模が大きく、流動性が強いため、長年にわたって経験しても、世界の投資家の目に最も人気のある金融ツールの1つである。株価は全体的な経済、産業の見通し、会社の運営など多くの要素の影響を受け、変動性が大きく、常に投資取引の機会が存在する。",
		},
		coins: {
			title: "暗号通貨",
			p: "暗号通貨はコードによって作られるデジタル通貨である。 従来の銀行や政府のシステムの外側で自律的に運営されている。 その名が示すように、暗号通貨は暗号化によって取引を保護し、他のユニットを作成することができる。ビットコインは、サトシ・ナカモトによって作成され、2009年1月に発売された、元祖であり、最も有名な暗号通貨である。 現在でも、サトシ・ナカモトという名前が個人を指すのかグループを指すのかは不明である。 ビットコインは最初の分散型暗号通貨と考えられている。他の暗号通貨と同様、分散型公開台帳として機能するブロックチェーン取引データベースを通じて管理されている。現在までに、オンラインで取引可能な暗号通貨は1,000種類以上ある。"
		}
	},
	trade: {
		data_sources: "データソース",
		tip: " 上記の情報は参考情報です。HKDFINANCは、情報の正確性、適時性、完全性を保証するものではなく、提供された情報に過度の信頼を置かないようにしてください。 本情報には、当社の価格記録、またはいかなる金融商品の取引の申し出もしくは勧誘も含まれていません。HKDFINANCは金融アドバイス会社ではなく、注文執行サービスのみを提供します。 読者の皆様は、ご自身で投資助言を求めることをお勧めします。 免責事項全文をご覧ください。",
		tip2: "毎週金曜日15:00更新（日本時間）",
		tip3: "トレーディング・ビューは、市場の売買マインドを予測するためのツールで、各注目商品に対する業界専門家の短期・中期的な見解やトレンド予測を反映している。",
		analysis: {
			tit: "トレーディング戦略",
			all: "フル",
			day: "数日中",
			tit1: "トレーディング戦略",
			tit2: "テクニカル・レビュー",
		},
		calendar: {
			tit: "財務カレンダー",
			prev_week: "先週",
			next_week: "来週",
			place_date: "検索日",
			select: "オプション",
			select1: "列国",
			select2: "意義",
			start_time: "開始時間",
			end_time: "終了時間",
			search: "ものを探す。",
		},
		contract: {
			tit: "契約仕様",
			type1: "フル",
			type2: "がいこくかわせ",
			type3: "商品",
			type4: "指数",
			type5: "米国株",
			type6: "本土株",
			tit1: "主な外国為替",
			tit2: "マイナー為替",
			tit3: "商品",
			tit4: "主な指標",
			tit5: "マイナーインデックス",
			tit6: "米国株",
			tit7: "本土株",
			tit8: "一般規定",
			p1: "強制フラットネス比",
			p2: "保留中の注文の有効性",
			p3: "無制限",
			p4: "オーバーナイト料金決済時間",
			p5: "毎日精算、夏期：05:00、冬期：06:00",
			p6: "レバレッジ、オーバーナイトレートなどに関する情報",
			p7: "取引プラットフォームを見る",
			p8: "上記はすべての取引品種に適用される",
			tit9: "週間取引カレンダー",
			p9: "うちきり",
			p10: "上記の取引時間は、休日や特別な市場調整の影響を考慮していない。。",
			p11: "具体的な取引時間の推奨",
			p12: "ログインプラットフォーム",
			p13: "，関連品種のプロフィールをチェックする。。",
		},
		forecast: {
			tit: "トレーディングの視点",
			p1: "平均値",
			p2: "上げ相場",
			p3: "弱気",
			p4: "クロスカレント",
			p5: "オリエンテーション",
		},
		news: {
			tit: "リアルタイムニュース",
		},
		platforms: {
			tit: "HKDFINANC取引プラットフォーム",
			p: "シンプルで直感的なインターフェース、優れた取引パフォーマンス、より多くの投資家のニーズにお応えします。 今すぐHKDFINANCでお取引を始めましょう。",
			tit1: "便利で使いやすいHKDFINANC取引プラットフォーム",
			tit2: "HKDFINANCアプリでいつでもどこでも取引！",
			li1: "資金の安全性を確保するため、権威ある規制の下、数百もの人気市場を取引する。",
			li2: "リアルタイムで市場動向を監視し、取引分析を閲覧し、最新の金融ニュースを閲覧することができます。",
			li3: "様々な種類の取引チャートをいつでも見ることができ、チャート機能を手間をかけずに適用できる。",
			li4: "HKDFINANCの受賞歴のある取引プラットフォームを、あなたのAndroidまたはiOSデバイスで体験してください！",
			code_download: "コードをスキャンしてダウンロード",
			tit3: "ダウンロード不要、ブラウザでオンライン取引",
			li5: "ダウンロード不要、PCブラウザから直接オンライン取引",
			li6: "受賞歴：2022年アジア最優秀FXブローカー、2022年本土最優秀取引プラットフォーム、2022年世界で最も急成長しているFXフィンテック・ブローカー",
			li7: "強力なテクニカル指標を提供：MACD、KDJ、RSI、TRIX、DMA、CCIなど。",
			li8: "ストップロスやマイナスバランス・プロテクションなどのリスク管理ツールを装備し、取引の潜在的なリスクを軽減します。",
			tit4: "デスクトップ取引に最適なソリューション",
			li9: "自分で選択したリストの市場相場を簡単に見ることができる。",
			li10: "アラートを有効にして、価格変更アラートやその他の情報通知を受け取る",
			li11: "複数のチャート・レイアウトにより、異なる時間軸からマーケットを追跡することができる。",
			li12: "トレーディング・センチメントとトレーディング分析が1つのインターフェースに",
			li13: "HKDFINANCで最新の取引ニュースを直接入手！",
			tit5: "HKDFINANC取引プラットフォームを選ぶ理由",
			row5_p1: "安全性",
			row5_p2: "手数料0",
			row5_p3: "マルチプラットフォーム対応",
			row5_p4: "テクニカルチャート",
			row5_p5: "いつでもどこでもアクセス",
			row5_p6: "各種描画ツール",
		},
		quotes: {
			tit: "リアルタイム相場",
			p1: "今話題の金融に関する相場、市場センチメント、関連ニュースを瞬時に提供。",
			hot: "流行中",
		},
		risk: {
			tit: "リスク管理",
			p1: "すべての取引にはリスクが伴いますが、HKDFINANCが提供する無料のリスク管理ツールを活用すれば、市場の状況にかかわらず、効果的にリスクを管理することができます。",
			function_tit1: "利食い/損切り機能",
			function_tit2: "トレーリングストップ機能",
			function_p1: "利益の固定化",
			function_p2: "リミット・ロス",
			function_p3: "利益固定の最大化",
			function_p4: "自動的にポジションを監視する必要がない",
			function_p5: "損失処理価格の調整",
			row2_tit1: "利食い/損切り機能",
			row2_p1: "新規注文を作成する際、または既存の注文を変更する際、「テイクプロフィット」と「ストップロス」を設定することができます。 一旦設定された注文は通常、設定された目標価格で決済され、目標価格に到達した際に利益を確定したり、市場が不利に動いた際に損失を軽減することができます。 この場合、システムは注文を既定の価格で執行せず、目標価格の次に有利な価格でポジションを決済します。",
			row2_tit2: "例子",
			row2_p2: " 現在のEUR/USDの価格は1.13816/1.13837 (売り/買い)です。 1.13837で1ロット（1ロット=100,000ユーロ）の買い注文を建て、ストップロスを1.13806に設定します。",
			row2_p3: "通常、価格が1.13806まで下落すると、ストップロス注文がトリガーされ、ポジションは1.13806で決済され、合計31ドルの損失となる。",
			row2_p4: "しかし、市場の状況が変化し、価格が1.13837から1.13795に直接スライドし、お客様のストップロス目標をスキップすると、市場はジャンプし、システムは1.13806でポジションを決済することはできませんが、次に最も有利な価格、すなわち1.13795でお客様のためにポジションを決済し、最終的な損失は42米ドルとなります。",
			row3_tit1: "トレーリングストップ機能",
			row3_tit2: "例",
			row3_p1: "トレーリングストップ（トレイリングストップとも呼ばれる）は、ポジションを常に監視することなく利益を確定したり、損失を最小限に抑えることができる強力な機能です。 注文を作成する際、トレーリングストップを設定するだけで、価格が有利に動くと、ストップ注文は自動的に最新の価格に更新されます。 逆に、お客様の不利な方向に価格が動くと、ストップロス注文が有効となり、ポジションはお客様が設定された損失距離で決済されます。 市場のボラティリティにより、どのような注文もショートする可能性があることにご注意ください。その場合、システムはお客様のデフォルト価格で注文を執行することはできませんが、ターゲット価格から次に有利な価格でポジションを決済します。",
			row3_p2: "現在のEUR/USDの価格は1.13816/1.13837 (売り/買い)です。 1.13837に1ロットの買い注文を設定し、トレイリングストップロスを100ピップス (0.00100) としました。",
			row3_p3: "商品の売値が1.13816にある場合、ストップロスは1.13716です。商品の売値が1.13845まで上昇すると、ストップロスは設定した距離だけ更新され、更新後のストップロスは1.13745となります。",
			row3_p4: " 逆に、製品価格が1.13845から1.13745にスライドすると、トレーリングストップが発動され、ポジションは1.13745で決済される。。",
			tip: "ポイント：通常、金融商品の価格変動の最小単位はポイントと呼ばれる。 HKDFINANCプラットフォームでは、商品の価格の最後の桁または小数点を指します。",
		},
		sentiment: {
			tit: "感情指数",
			type1: "フル",
			type2: "がいこくかわせ",
			type3: "商品",
			type4: "指数",
			long: "長期",
			short: "ショート",
		},
	},
	layout: {
		aside: {
			nav1: "取引",
			nav2: "現在の市場状況",
			nav3: "インフォメーション",
			nav4: "カレッジ",
			nav5: "私の",
			newsDialog: {
				title: "ニュースセンター",
				type1: "システム通知",
				type2: "会報",
				allRead: "すべて既読にする",
			},
			settingDialog: {
				title: "セットアップ",
				nav1: "通常兵器",
				nav2: "環境設定の表示",
				nav3: "システム情報",
				logout: "ログアウト",
				setting1: "多言語主義",
				setting2: "取引",
				setting2_tip: "オープンポジションは自動的に自分で選択した品種を追加する",
				setting3: "情報を送り返す",
				setting3_tip: "機能的な推奨事項",
				setting4: "実名認証",
				setting4_tip: "実名認証",
				setting5: "テーマ・モード",
				setting5_label1: "ぼやっと",
				setting5_label2: "冴えた色",
				setting6: "色の上昇と下降",
				setting6_label1: "緑は上昇し、赤は下降する",
				setting6_label2: "赤は上昇、緑は下降",
				setting7: "チャート設定",
				setting7_label1: "スタンダード・エディション",
				setting7_label2: "TradingViewエディション",
				setting8: "宣言と合意",
				setting8_label1: "プライバシーポリシー",
				setting8_label2: "製品開示ステートメント",
				setting8_label3: "リスク開示声明",
				setting8_label4: "顧客契約",
				feedback_title: "機能的な推奨事項",
			}
		},
		footer: {
			hot: "人気品種",
			sort: "アソート",
			code: "コーディング",
			buy_price: "購入価格",
			sell_price: "売り手価格",
			chg: "こうげ",
		},
		header: {
			place_search: "取引品種の検索",
			balance: "利用可能残高",
			balance_tip: "利用可能残高：新規ポジションを建てるために使用できる口座の金額",
			profit_loss: "盛衰",
			asset_view: "資産の概要",
			netValue: "純額",
			netValue_tip: "純資産：すべてのポジションの損益を含む現在の口座価値",
			marginLevel: "マージン水準",
			margin: "証拠金",
			margin_tip: "証拠金：外貨のポジションを建て、維持するために必要な金額。",
			maintainsMargin: "維持マージン",
			maintainsMargin_tip: "維持証拠金：すべてのポジションを保有する間、口座で維持する必要のある証拠金の最低額。",

		},
	},
	table: {
		label_name: "な",
		label_buyPrice: "入札価格",
		label_sellPrice: "売りレート",
		label_variableValue: "変化量",
		trade: "取引",
		label_code: "商品コード",
		label_title: "な",
		label_start: "意義",
		label_country: "列国",
		label_event: "イベント",
		label_previous: "前の値",
		label_predictive: "予想値",
		label_announced: "公表値",
		label_stop_loss_min: "ストップロス注文の最短距離",
		label_difference: "ダイナミックスプレッド",
		label_contract_size: "契約サイズ",
		label_max_hand: "単一取引の最大ロットサイズ",
		label_min_hand: "最低敷地面積",
		label_trade_week: "週間取引カレンダー",
		label_week: "1週間",
		label_month: "1月",
		label_quarter: "1クォーター",
		label_dailyTrend: "単一日のトレンド",
		label_tradeId: "トランザクションID",
		label_breed: "アソート",
		label_tradeType: "取引の種類",
		label_currency: "通貨",
		label_amount: "金額",
		label_balance: "バランス",
		label_type: "類型論",
		label_time: "时间",
		label_orderNumber: "注文番号",
		label_pointer_number: "せりばんご",
		label_price_money: "オープニング価格",
		label_stop_win_price: "テイクプロフィット",
		label_stop_lose_price: "ストップロス",
		label_openTime: "始業時間",
		label_profit_loss: "盛衰",
		label_sell_buy: "希望小売価格",
		label_chg: "こうげ",
		label_sell_price: "売り手価格",
		label_buy_price: "購入価格",
		label_condition: "前提条件",
	},
	form: {
		label_feedback: "課題と提言",
		place_feedback: "ご質問またはご意見を入力してください",
		label_img: "写真",
		label_img_tip: "オプションで、問題のスクリーンショットをご提供ください。",
		feedback_tip: "お急ぎの場合は、下記までご連絡ください。",
		online: "オンラインカスタマーサービス",
		label_name: "名字",
		place_name: "お名前を入力してください",
		label_email: "電子メールアドレス",
		place_email: "Eメールアドレス",
		message_email: "メールアドレスを入力してください",
		label_nationality: "市民権",
		place_nationality: "国籍を入力してください",
		place_questionType: "問題の種類",
		message_questionType: "タイプを選択してください",
		questionType0: "取引プラットフォーム",
		questionType1: "商品と料金",
		questionType2: "毎日の市場分析を購読する",
		questionType3: "顧客サービス",
		questionType4: "その他",
		place_question: "ご質問",
		message_question: "質問を入力してください",
		submit_question: "質問の提出",
		label_phone: "携帯電話番号",
		place_phone: "携帯電話番号を入力してください。",
		message_phone: "携帯電話番号を入力してください。",
		label_password: "暗号化",
		place_password: "パスワードを入力してください",
		message_password: "パスワードを入力してください",
		label_confirmPassword: "パスワードの確認",
		place_confirmPassword: "パスワードをもう一度入力してください。",
		message_confirmPassword: "パスワードをもう一度入力してください。",
		label_captcha: "CAPTCHA、チャレンジ・レスポンス・テストの一種（コンピューティング）",
		place_captcha: "認証コードを入力してください。",
		message_captcha: "認証コードを入力してください。",
		get_captcha: "CAPTCHAを取得する",
		label_inviteId: "招待コード",
		place_inviteId: "招待コード（オプション）",
		to: "まで",
		start_time: "開始時間",
		end_time: "終了時間",
		label_cardNumber: "証明書番号",
		place_cardNumber: "ID番号を入力してください。",
		message_cardNumber: "ID番号を入力してください。",
		label_cardMain: "文書の前面",
		message_cardMain: "書類の前面を記入してください。",
		label_cardBack: "证件反面",
		message_cardBack: "書類の裏面をご記入ください。",
		confirm_modify: "変更の確認",
		label_realName: "氏名",
		place_realName: "お名前を入力してください",
		message_realName: "フルネームを入力してください",
		label_firstName: "姓氏",
		place_firstName: "姓を入力してください",
		message_firstName: "姓を入力してください",
		label_lastName: "な",
		place_lastName: "ミドルネームを入力してください",
		message_lastName: "名前を入力してください",
		label_birthday: "生年月日",
		place_birthday: "生年月日を入力してください。",
		message_birthday: "生年月日を入力してください",
		place_nowPsd: "現在のパスワードを入力してください。",
		message_nowPsd: "現在のパスワードを入力してください。",
		place_newPsd: "新しいパスワードを入力してください。",
		message_newPsd: "新しいパスワードを入力してください。",
		place_crmPsd: "新しいパスワードを確認してください。",
		message_crmPsd: "新しいパスワードを確認してください。",
		label_breed: "アソート",
		place_breed: "種を入力してください",
		label_phase: "(落とす",
		label_buyPrice: "購入価格",
		label_sellPrice: "売り手価格",
		label_do: "というのも",
		label_height: "より大きい",
		label_low: "を下回る。",
		label_equal: "に等しい。",
		labelWalletName: "財布の名前",
		messageWalletName: "財布の名前を入力してください",
		placeWalletName: "財布の名前を入力してください",
		labelWalletAddress: "財布の住所",
		messageWalletAddress: "ウォレットアドレスを入力してください。",
		placeWalletAddress: "ウォレットアドレスを入力してください。",
		labelAmount: "金额",
		messageAmount: "金額を入力してください",
		placeAmount: "金額を入力してください",
		placeTimeLimit: "制限時間を入力してください",
		messageTimeLimit: "制限時間を入力してください",
	},
	pay: {
		title: "資金調達が容易",
		p1: "一般的に使用される様々な決済チャネル、迅速な入出金サポート",
		p2: "お支払い方法によっては、お住まいの国でご利用いただけない場合があります。",
		p3: "HKDFINANCのサービス体験へようこそ",
		btn: "口座を開設して今すぐ取引",
	},
	header: {
		tip1: "レバレッジ取引は複雑な金融商品であり、急激な損失のリスクが高い。",
		btn1: "取引プラットフォーム",
		btn2: "ログアウト",
		btn3: "サインイン",
		BigUint64Array: "トレード・ナウ",
	},
	footer: {
		help: "お問い合わせ",
		tip1: "コミュニティをフォローする",
		tip2: "HKDFINANCは公式のテレグラム・グループを開設しておらず、HKDFINANCの名前で結成されたテレグラム・グループは、なりすましの疑いがあることにご注意ください。",
		tip3: "リスク警告：取引により、すべての資金が失われる可能性があります。 店頭デリバティブ取引はすべての方に適しているわけではありません。 当社のサービスをご利用になる前に、当社の法的文書を注意深くお読みになり、取引前に関連するリスクを十分にご理解ください。 お客様は原資産を実際に所有または保有するわけではありません。",
		tip4: "HKDFINANCは、購入、保有、売却に関するいかなる助言、推奨、意見も提供しません。 HKDFINANCが提供するサービスはすべて、取引注文の執行のみに基づいています。",
		tip5: "HKDFINANCは複数の企業が共有するビジネスブランドであり、以下の企業を通じて運営されている：",
		tip6: "HKDFINANC International Ltdは、本ウェブサイトに記載されている、または使用可能な金融商品の発行者です。 HKDFINANC International Ltdは、モーリシャスの金融サービス委員会（FSC）により、ライセンス番号GB20025791の認可および規制を受けており、その登録事務所は6 St Denis Street, 1st Floor River Court, Port Louis 11328にあります。Street, 1st Floor River Court, Port Louis 11328, Mauritiusに登録されています。",
		tip7: "HKDFINANC Global Pty Ltdは、ABN 90 149 011 361、本土金融サービスライセンス（AFSL）番号398528で登録されています。",
		tip8: "HKDFINANC Holding Ltdは、ケイマン諸島金融庁（CIMA）の認可を受け、SIBライセンス番号1612446の規制を受けています。",
		tip9: "本ウェブサイトに掲載されている情報は、アメリカ合衆国、カナダ、日本、ニュージーランドの居住者を対象としたものではなく、また、その掲載または使用が現地の法律または規制に反する国または司法管轄区に属する者による使用を意図したものでもありません。 英語が当社のサービスの主要言語であり、すべての契約文書の法的拘束力のある言語であることにご留意ください。 他の言語への翻訳は参考のためであり、英語版と中国語版の間に不一致がある場合は、英語版が優先されるものとします。",
		tip10: "SSLによる安全な通信暗号化。 著作権© HKDFINANC、すべての権利を保有。",
		link1: "プライバシーポリシー",
		link2: "製品開示ステートメント",
		link3: "苦情処理",
		link4: "リスク開示声明",
		link5: "顧客契約",
		toTop: "粘着",
	},
	nav: {
		tit1: "お飾り产品",
		tit2: "取引",
		tit3: "投資学習",
		tit4: "会社概要",
		nav1: "がいこくかわせ",
		nav2: "指数",
		nav3: "商品",
		nav4: "株式",
		nav5: "取引プラットフォーム",
		nav6: "トレーディング戦略",
		nav7: "トレーディングの視点",
		nav8: "財務カレンダー",
		nav9: "リアルタイムニュース",
		nav10: "リアルタイム相場",
		nav11: "感情指数",
		nav12: "リスク管理",
		nav13: "契約仕様",
		nav14: "投資入門",
		nav15: "投資洞察",
		nav16: "Academy",
		nav17: "HKDFINANCについて",
		nav18: "受賞と栄誉",
		nav19: "メディアセンター",
		nav20: "金融安全保障",
		nav21: "手数料",
		nav22: "Affiliates",
		nav23: "お問い合わせ",
		nav24: "一般的な問題",
		nav25: "ヘルプセンター",
	},

	city: {
		albania: "アルバニア",
		algeria: "アルジェリア",
		argentina: "アルゼンチン",
		armenia: "アルメニア",
		australia: "オーストラリア",
		pakistan: "パキスタン",
		austria: "オーストリア",
		bahrain: "バーレーン",
		belgium: "ベルギー",
		bosnia_and_Herzegovina: "ボスニア・ヘルツェゴビナ",
		brazil: "ブラジル",
		brunei: "ブルネイ",
		bulgaria: "ブルガリア",
		cambodia: "カンボジア",
		canada: "カナダ",
		cameroon: "カメルーン",
		chile: "チリ",
		colombia: "コロンビア",
		costa_Rica: "コスタリカ",
		croatia: "クロアチア",
		cyprus: "キプロス",
		czech_Republic: "チェコ共和国",
		denmark: "デンマーク",
		dominican_Republic: "ドミニカ共和国",
		egypt: "エジプト",
		estonia: "エストニア",
		ethiopia: "エチオピア",
		finland: "フィンランド",
		france: "フランス",
		georgia: "グルジア",
		germany: "ドイツ",
		ghana: "ガーナ",
		greece: "ギリシャ",
		guyana: "ガイアナ",
		honduras: "ホンジュラス",
		hong_Kong_China: "中国香港",
		hungary: "ハンガリー",
		iceland: "アイスランド",
		ireland: "アイルランド",
		italy: "イタリア",
		india: "インド",
		indonesia: "インドネシア",
		israel: "イスラエル",
		iran: "イラン",
		iraq: "イラク",
		japan: "日本",
		kazakstan: "カザフスタン",
		kenya: "ケニア",
		korea: "韓国",
		kuwait: "クウェート",
		kyrgyzstan: "キルギス",
		laos: "ラオス",
		latvia: "ラトビア",
		lithuania: "リトアニア",
		luxembourg: "ルクセンブルク",
		macao_China: "中国マカオ",
		macedonia: "マケドニア",
		malaysia: "マレーシア",
		malta: "マルタ",
		mexico: "メキシコ",
		moldova: "モルドバ",
		monaco: "モナコ",
		mongolia: "モンゴル",
		montenegro: "モンテネグロ共和国",
		morocco: "モロッコ",
		myanmar: "ミャンマー",
		netherlands: "オランダ",
		new_Zealand: "ニュージーランド",
		nepal: "ネパール",
		nigeria: "ナイジェリア",
		norway: "ノルウェー",
		oman: "オマーン",
		palestine: "パレスチナ",
		panama: "パナマ",
		paraguay: "パラグアイ",
		peru: "ペルー",
		philippines: "フィリピン",
		poland: "ポーランド",
		portugal: "ポルトガル",
		puerto_Rico: "プエルトリコ",
		qatar: "カタール",
		romania: "ルーマニア",
		russia: "ロシア",
		republic_of_Trinidad_and_Tobago: "トリニダード・トバゴ共和国",
		rwanda: "ルワンダ",
		saudi_Arabia: "サウジアラビア",
		serbia: "セルビア",
		singapore: "シンガポール",
		slovakia: "スロバキア",
		slovenia: "スロベニア",
		south_Africa: "南アフリカ共和国",
		spain: "スペイン",
		sri_Lanka: "スリランカ",
		sweden: "スウェーデン",
		switzerland: "スイス",
		taiwan_China: "中国台湾",
		tajikistan: "タジキスタン",
		tanzania: "タンザニア",
		thailand: "タイ",
		turkey: "トルコ",
		turkmenistan: "トルクメニスタン",
		ukraine: "ウクライナ",
		united_Arab_Emirates: "アラブ首長国連邦",
		united_Kingdom: "英国",
		united_States: "アメリカ",
		uzbekistan: "ウズベキスタン",
		venezuela: "ベネズエラ",
		vietnam: "ベトナム",
		afghanistan: "アフガニスタン",
		angola: "アンゴラ",
		azerbaijan: "アゼルバイジャン",
		bangladesh: "バングラデシュ",
		belarus: "ベラルーシ",
		belize: "ベリーズ",
		benin: "ベニン",
		bhutan: "ブータン",
		bolivia: "ボリビア",
		botswana: "ボツワナ",
		british_Virgin_Islands: "イギリス領ヴァージン諸島",
		burkina_Faso: "ブルキナファソ",
		burundi: "ブルンジ",
		cape_Verde: "カーボベルデ角",
		cayman_Islands: "ケイマン諸島",
		central_African_Republic: "中央アフリカ共和国",
		chad: "チャド",
		comoros: "コモロ",
		the_Republic_of_the_Congo: "コンゴ（布）",
		democratic_Republic_of_the_Congo: "コンゴ（金）",
		djibouti: "ジブチ",
		ecuador: "エクアドル",
		el_Salvador: "エルサルバドル",
		equatorial_Guinea: "赤道ギニア",
		eritrea: "エリトリア",
		fiji: "フィジー",
		gabon: "ガボン",
		gambia: "ガンビア",
		greenland: "グリーンランド",
		guatemala: "グアテマラ",
		guinea: "ギニア",
		haiti: "ハイチ",
		isle_of_Man: "マーン島",
		cote_d_Ivoire: "コートジボワール",
		jamaica: "ジャマイカ",
		jordan: "ヨルダン",
		lebanon: "レバノン",
		lesotho: "レソト",
		liberia: "リベリア",
		libya: "リビア",
		madagascar: "マダガスカル",
		malawi: "マラウイ",
		maldives: "モルディブ",
		mali: "マリ",
		mauritania: "モーリタニア",
		mauritius: "モーリシャス",
		mozambique: "モザンビーク",
		namibia: "ナミビア",
		nicaragua: "ニカラグア",
		republic_of_Niger: "ニジェール",
		north_Korea: "朝鲜",
		reunion: "レユニオン",
		san_Marino: "サンマリノ",
		senegal: "セネガル",
		sierra_Leone: "シエラレオネ",
		somalia: "ソマリア",
		sudan: "スーダン",
		suriname: "スリナム",
		eswatini: "スワジランド",
		syria: "シリア",
		togo: "トーゴ",
		tonga: "トンガ",
		tunisia: "チュニジア",
		united_States_Virgin_Islands: "アメリカ領ヴァージン諸島",
		uganda: "ウガンダ",
		uruguay: "ウルグアイ",
		vatican: "バチカン",
		yemen: "イエメン",
		yugoslavia: "ユーゴスラビア",
		zambia: "ザンビア",
		zimbabwe: "ジンバブエ",
		china: "中国",
	}
};
