export default {
	crypto: {
		title: {
			0: "Buy Crypto",
			1: "Buy Crypto",
			2: "C2C",
		},
		list: {
			0: "C2C Trading",
			1: "Buy",
			2: "Sell",
			3: "Advertisers (Completion rate)",
			4: "Trades",
			5: "Price",
			6: "Available",
			7: "Limit",
			8: "Payment Method",
			9: "Trade",
			10: "The advertiser may place transaction restrictions on the ad, and you will see the reason for the restriction after logging in.",
		},
		buy: {
			0: "Buy Crypto",
			1: "Sell Crypto",
			2: "History",
			3: "Support",
			4: "Pay",
			5: "Receive",
			6: "Select payment method",
			7: "Est. arrival time",
			8: "FEE",
			9: "Order Details",
			10: "Payment method",
			11: "Learn more",
			12: "Price",
			13: "Total (fee included)",
			14: "Disclaimer",
			15: "You are leaving HKDFinanc and will be redirected to Mercuryo, a licensed financial institution that provides credit/debit card payment service as a third party. Any risk that occurs during the use of the service shall be borne by Mercuryo. Please read and agree to the Terms of Use before proceeding.",
			16: "I have read and agree to the Terms of Use",
			17: "You will get",
			18: "Submit",
			19: "Please enter the amount",
			20: "Insufficient account balance",
			21: "Available",
			22: "Spot Account Availiable",
			23: "Single purchase limit",
			24: "Minimum",
			25: "Maximum",
		}
	},
	common: {
		confirm: "Definiera",
		cancel: "Annulleringar",
		tip: "Fästa uppmärksamheten på något",
		place_select: "Vänligen välj",
		submit: "Lämna (en rapport etc.)",
		more: "Mer",
		deposit: "Insättning",
		real_trade: "Verklig affär",
		paper_trade: "Demohandel",
		build_paperTrade: "Skapa ett demokonto",
		start_trade: "Börja handla nu",
		app_name: "HKDFINANC",
		success: "Framgång!",
		dataSources_name: "Trading Central",
		reset: "Reprovision",
		iknow: "Jag har den!",
		noData: "Inga data tillgängliga",
		modify: "Ändringar",
		pingcang: "Stänga en position",
		all: "Full",
		placeEnter: "Vänligen ange",
		countryCode: "Kod för land/område",
	},
	currency: {
		btcusdt: "Bitcoin (virtuell valuta)",
		ethusdt: "Etherlands",
		ltcusdt: "Litecoin (virtuell valuta)",
		xrpusdt: "Ripple (valuta)",
		adausdt: "Ada-valuta (används i bråkdelar av valuta)",
		eosusdt: "Pomelo-mynt",
		dotusdt: "Boca Raton (tidigare känd som Boca Raton), rysk valuta",
		trxusdt: "Våg jordbruksmark mynt (databehandling)",
		xmrusdt: "Monroe-mynt (t.ex. US-dollar)",
		dogeusdt: "Hundmynt",
		bnbusdt: "BNB",
		solusdt: "SOL",
		aptusdt: "Aptusdt",
		fx_saudusd: "Australisk dollar US-dollar",
		fx_sgbpusd: "Pund-dollar",
		fx_seurusd: "Eurodollar",
		fx_snzdusd: "Nya Zeeländska dollar (NZD) Amerikanska dollar",
		fx_susdcad: "US-dollar-kanadensisk dollar",
		fx_susdchf: "US-dollar Schweiziska francen",
		fx_susdjpy: "US-dollar-yen",
		fx_saudcad: "Australisk dollar Kanadensisk dollar",
		fx_saudchf: "Australisk dollar och schweizisk franc",
		fx_saudjpy: "Australisk dollar Japansk yen",
		fx_saudnzd: "Australisk dollar Nya zeeländsk dollar (NZD)",
		fx_scadchf: "Kanadensisk dollar Schweizisk franc",
		fx_scadjpy: "Kanadensisk dollar Japansk yen",
		fx_schfjpy: "Schweiziska franc yen",
		fx_seuraud: "Euro Australisk dollar",
		fx_seurcad: "Euro Kanadensisk dollar",
		fx_seurgbp: "Euro Pund Sterling",
		fx_seurjpy: "Euro Yen",
		fx_seurnzd: "Euro Nyzeeländsk dollar (NZD)",
		fx_sgbpaud: "Brittiskt pund Australisk dollar",
		fx_sgbpchf: "Pund Sterling Schweizisk Franc",
		fx_sgbpjpy: "GBPJPY",
		fx_sgbpnzd: "Pund Nyzeeländsk dollar (NZD)",
		fx_scadnzd: "Kanadensisk dollar Nya Zeelands dollar",
		hf_CL: "WTI New York råolja",
		hf_OIL: "Brent råolja",
		hf_GC: "New York guld",
		hf_SI: "New York Silver",
		hf_HG: "Koppar (lånord)",
		hf_NG: "Förenta staterna Naturgas",
		hf_CAD: "Koppar (lånord)",
		hf_AHD: "Aluminium från London",
		HX_AAPL: "Granatäpple",
		HX_BABA: "Ali Baba, karaktär från Tusen och en natt",
		HX_GOOG: "Google",
		HX_WMT: "Wal-Mart, Walmart (detaljhandelsföretag)",
		HX_AMZN: "HX_AMZN",
		HX_TSLA: "Nikola Tesla (1856-1943), serbisk uppfinnare och ingenjör",
		HX_N225: "Nikkei 225",
		HX_GDAXI: "DAX30, Tyskland",
		HX_IBEX: "IBEX35, Spanien",
		HX_SPX: "S&P 500 index",
		HX_FTSE: "FTSE 100",
		HX_KS11: "KOSPI Korea",
		HX_NZ50: "Nya Zeeland 50",
		HX_PSI20: "Portugal PSI20",
		hf_ZSD: "London Zink",
		hf_NID: "London Nickel",
		hf_PBD: "Ledning i London",
		hf_SND: "London tin",
		hf_XAU: "London Gold (Spot Gold)",
		hf_XAG: "London Silver (Spot Silver)",
		hf_XPT: "Terminer för platina",
		hf_XPD: "Terminer för palladium",
		hf_CT: "Amerikansk bomull",
		hf_SM: "Pulver av sojabönor",
		hf_BO: "Amerikansk sojabönsolja",
		HX_SENSEX: "SENSEX, Mumbai, Indien",
		HX_KLSE: "FTSE Malaysia KLCI",
		HX_KSE100: "Karachi, Pakistan",
		HX_FCHI: "CAC40, Frankrike",
		HX_SX5E: "Europeisk Snooker 50",
		HX_ICEXI: "ICEX, Island",
		HX_ASE: "ASE Aten, Grekland",
		HX_OMXC20: "OMX Köpenhamn 20",
		HX_OSEBX: "OSEBX, Norge",
		HX_OMXSPI: "OMXSPI, Sverige",
		HX_AMD: "Chaowei Halvledare",
		HX_DIS: "Disney (namn)",
		HX_MELI: "MercadoLibre Inc",
		HX_PSX: "Phillips 66",
		HX_TRUE: "TrueCar Inc",
		HX_SDRL: "Seadrill Ltd",
		HX_MA: "MasterCard (varumärke)",
		HX_NVDA: "NVIDIA, företag som tillverkar grafikkort för datorer",
		HX_INTC: "Intel",
		HX_GOGO: "Gogo Inc",
		HX_STX: "Seagate Technology",
		usdcusdt: "USDC",
		filusdt: "FIL",
		maticusdt: "MATIC",
		linkusdt: "LINK",
		avaxusdt: "AVAX",
		atomusdt: "AstroCoin",
		icpusdt: "ICP",
		uniusdt: "UNI",
	},
	addnew: {
		paypass: "Lösenord för handel",
		loginpassTit: "Ändra lösenord",
		paypassTit: "Ändra lösenordet för din bankomat",
		paypassTit2: "Det sociala lösenordet är ursprungligen inloggningslösenordet.",
		buyUp: "Stigande",
		buyDown: "Köpa och sälja",
		addressName1: "China Gold Financial (International) Holdings Limited",
		addressValue1: "Rum 1101, 11:e våningen, Koon Kwan Mansion (f.d. Citibank Tower), 3 Garden Road, Central, Hongkong",
		addressName2: "Hongkong Zhong Cai Financial Investment Company Limited",
		addressValue2: "Zhongcai Centre, 131-133 Queen's Road Central, Central, Hongkong",
		addressName3: "Po Sun Financial Holdings Limited",
		addressValue3: "Rum 1908, 19:e våningen, 89 Queensway, Admiralty, Hongkong Lippo Centre Tower 2",
		loan: "Ta upp ett lån (från t.ex. en bank)",
		repaid: "Betalat tillbaka",
        unpaid: "Utebliven återbetalning",
		loanMore: "Jag vill ha ett lån.",
		immediateRepayment: "Omedelbar återbetalning",
		accountType1: "Myntkonto",
		accountType2: "Kontraktskonto",
		accountType3: "Optionskonto",
		from: "Genom (en lucka)",
        to: "Till",
		get: "Kommer att få",
		labelBreed: "Valutatyp",
		placeBreed: "Vänligen välj valuta",
		labelTransAccount: "Överföra konto",
		labelAmount: "Överfört belopp",
		placeAmount: "Vänligen ange överföringsbeloppet",
		transferTit:"Överföring av konto",
		convertTit:"Mynt flash växling",
		balanceTit:"Kontosaldo",
		available: "Tillgänglig kredit",
        pending: "Lås (nekar åtkomst till ett datorsystem eller en enhet eller filer)",
        equivalent: "Belopp till",
		coinTrade: "Handel med mynt",
		secondContract: "Andra kontraktet (inom sport)",
		number: "Kvantiteter",
		labelNumber: "Kvantiteter",
		placeNumber: "Vänligen ange antalet ransoms",
		time: "Tider",
		loadAmount: "Förväntat lånebelopp",
		repaymentCycle: "Cykel för återbetalning av lån",
		dailyRate: "Daglig räntesats",
		repaymentMethod: "återbetalningsmetod",
		loanTip: "Kreditlån (se till att bilderna är väl synliga)",
		loanTip1: "Ladda upp fastighetscertifikat",
        loanTip2: "Bevis på inkomst (anställningsförhållande)",
        loanTip3: "Uppgifter om kontoutdrag",
        loanTip4: "Ladda upp det främre fotot av ditt ID-kort",
        interest: "Intresse (önskan att veta mer om något)",
		repaymentMethod1: "Förfaller till betalning i en delbetalning",
	},
	params: {
		product_foreign: "Utländsk (valuta) valuta",
		product_shop: "Varor",
		product_number: "Exponenter",
		product_stock: "Aktie (marknad)",
		product_coin: "Krypterad",
		billTypes: [
			{v: 101, name: 'Ladda (pengar på ett kort)'},
			{v: 102, name: 'Insättning'},
			{v: 103, name: 'Frysning '},
			{v: 104, name: 'Frysa'},
			{v: 105, name: 'Insättning'},
			{v: 106, name: 'Insättning'},
			{v: 201, name: 'Frysning av uttag'},
			{v: 202, name: 'Ta ut medel'},
			{v: 203, name: 'Tillbakadragande framgångsrikt'},
			{v: 204, name: 'Underlåta att ta ut medel'},
			{v: 205, name: 'Uttagsavgift'},
			{v: 206, name: 'Överföra ut'},
			{v: 207, name: 'Skifta till'},
			{v: 208, name: 'Överföring av mynt'},
			{v: 209, name: 'Överföring av mynt'},
			{v: 301, name: 'Avgift för hantering av kontrakt'},
			{v: 302, name: 'Avtalsenliga intäkter'},
			{v: 303, name: 'Kontraktsförlust'},
			{v: 304, name: 'Kontraktsmarginal'},
			{v: 305, name: 'Kontraktsmarginal Avkastning'},
			{v: 311, name: 'Köp av optioner'},
			{v: 312, name: 'Vinst på option'},
			{v: 313, name: 'Avkastning på optioner'},
			{v: 314, name: 'Provision för optioner'},
			{v: 315, name: 'Mynt Köp Frys'},
			{v: 316, name: 'Avdrag för myntköp'},
			{v: 317, name: 'Myntköp Returer'},
			{v: 318, name: 'Inköp av mynt till konto'},
			{v: 319, name: 'Inköp av mynt till konto'},
			{v: 320, name: 'Stopp för försäljning av mynt'},
			{v: 321, name: 'Avdrag för försäljning av mynt'},
			{v: 322, name: 'Mynt säljs returneras'},
			{v: 323, name: 'Mynt säljs till konto'},
			{v: 324, name: 'Mynt säljs till konto'},
			{v: 325, name: 'Avgift för hantering av mynt'},
			{v: 401, name: 'Gruvarbetare ansluter sig'},
			{v: 402, name: 'Miner Returnerar'},
			{v: 403, name: 'Intäkter från gruvmaskiner'},
			{v: 404, name: 'Utgång för gruvmaskin'},
			{v: 405, name: 'Avgift för utträde ur gruvdrift'},
			{v: 411, name: 'Intäkter från upplåning'},
			{v: 412, name: 'Låneavgift'},
			{v: 413, name: 'Lyckad återbetalning'},
        ],
	},
	message: {
		logout: "Logga ut",
		register_success: "Framgångsrik registrering",
		place_inner_personal: "Ange din personliga information först",
		submit_success: "Inlämnat framgångsrikt！",
		copy_success: "Kopiera framgång！",
		copy_error: "Misslyckad reproduktion！",
		modify_success: "Modifierad med framgång",
		no_balance: "Otillräckligt saldo för att täcka deposition",
		palce_password: "Ange ditt lösenord",
		trade_success: "Lyckad transaktion",
		placeEnterAmount: "Vänligen ange beloppet",
		tipTit1: "Är du säker på att du vill betala för denna order?",
	},
	web: {
		account: {
			menu1: "Kontoinformation",
			menu2: "Mina tillgångar",
			menu3: "Konto rapporter",
			menu4: "Kontosäkerhet",
			menu5: "Anmälningar",
			menu6: "Aktivitetscenter",
			menu7: "Min plånbok.",
			menu8: "Ta ut pengar",
			walletAdd: "Lägg till ny plånbok",
			report: {
				history_tit: "Rapport över transaktionshistorik",
				stream_tit: "Rapport om flödet av medel",
				desc: "Välj lämpligt datumintervall för att exportera kontoutdraget. (Du kommer att ha tillgång till kontoinformation för det senaste 1 året)",
				select_date: "Välj datumintervall",
				week: "Den senaste veckan",
				month: "Ungefär en månad",
				threeMonth: "Senaste tre månaderna",
				sixMonth: "De senaste sex månaderna",
				time: "Tider",
				view_report: "Visa rapport",
				email_send: "Skickat via e-post",
				dialog_tit: "Konto rapporter",
				dialog_p1: "HKDFINANC Holding Ltd är registrerat på Caymanöarna (SIB-nummer: 1612446) och behandlar dina transaktioner som en handelsenhet.",
				tit2: "Rapport om handelshistorik för Mitade",
				tit4: "Mitade rapport om flödet av medel",
				tit3: "Kontoinformation",
				label1: "Kontonamn",
				label2: "Konto-ID",
				label3: "Konto Valuta",
				label4: "Rapporteringstid",
				label5: "Rapportperiod",
				label6: "Kassaflöde",
				empty: "Du har inga stängda affärer",
				email_des: "Rapporten kommer att skickas till din e-postadress",
				tab1: "Rapportering",
				tab2: "Dagligt uttalande",
				tab3: "Månatligt kontoutdrag",
			},
			account: {
				tit1: "Välkommen till HKDFINANC",
				tit2: "Fullständig identitetsverifiering för att handla med riktiga insättningar",
				p1: "Öppna ett livehandelskonto",
				p2: "Autentisering",
				btn1: "Autentisering",
				tit3: "Personlig information",
				label_name: "Namn och efternamn",
				label_email: "E-postadress",
				label_address: "Bostadsadress",
				tit4: "Kontoinformation",
				label_account: "Handelskonto (verklig transaktion)",
				label_currency: "Basvaluta (reala transaktioner)",
				label_company: "Företag för löpande konto",
				company_tip: "HKDFINANC Holding Ltd,HKDFINANC Holding Ltd HKDFINANC Group of Companies är licensierat och auktoriserat av Cayman Islands Monetary Authority (CIMA).",
				tit5: "Öppna ett livehandelskonto",
				tit6: "Förbättring av information",
				tit7: "Fyll i dina grundläggande uppgifter",
				tit8: "Riskbedömning",
				tit9: "Bedömning av risktolerans vid handel",
				tit10: "Autentisering",
				tit11: "Slutförande av identitetskontroll i enlighet med lagstadgade krav",
				p3: "Välj någon av följande styrkande handlingar för kontroll",
				type1: "Identitetskort",
				type2: "Körkort",
				type3: "Pass",
				p4: "Jag bekräftar att all information och alla dokument som tillhandahålls är fullständiga, sanna och korrekta och jag åtar mig att tillhandahålla uppdaterad information till HKDFINANC inom 30 dagar vid eventuella uppdateringar.",
				next: "Nästa steg",
				btn2: "Godkänd ackreditering",
				modify_personal_tit: "Ändring av personuppgifter",
			},
			activity: {
				tit: "Aktivitetscenter",
				desc: "Du kan delta i aktiviteter och få belöningar för att öka din avkastning på investeringen",
			},
			capital: {
				real_trade: "Verklig affär",
				label_nowAccount: "Bytesbalans",
				label_tradeAccount: "Nummer på handelskonto",
				label_currency: "Basvaluta",
				deposit_withdraw_record: "Registrering av insättningar och uttag",
				capital_flow_details: "Uppgifter om flödet av medel",
			},
			notification: {
				tit: "Inställningar för meddelanden",
				tit1: "Öppna kanaler för meddelanden",
				email: "E-postadress",
				message: "Textmeddelanden",
				push: "Tryck",
				verify: "Validera (en teori)",
				verified: "Validerad",
				p1: "Öppna/verifiera följande aviseringskanaler för att hålla koll på viktiga nyheter!",
				type1: "Marknadsplats",
				tit2: "Anmälan av marknadsföringsåtgärder",
				desc2: "Plattformens information om specialerbjudanden, driftaktiviteter och andra nyheter",
				tit3: "Bruksanvisning",
				desc3: "Rikt och specialiserat kunskapsmaterial",
			},
			security: {
				tit: "Kryptografisk",
				isSet: "Konfigurerad",
				set: "Fortsätt och ställ in",
				verify: "Validera (en teori)",
				verified: "Validerad",
				tit1: "Säkerhetscertifiering",
				email: "E-postadress",
				phone: "Mobiltelefonnummer",
				phone_tit: "Mobiltelefonnummer Binding",
				password_tit: "Ändra ditt lösenord",
			},
		},
		market: {
			hot: "På modet",
			prev: "Föregående sida",
			next: "Nästa sida",
			line: "Tidsdelning",
			main_subplot: "Indikatorer i huvud- och sidodiagram",
			main: "Indikator för huvuddiagram",
			subplot: "Diagramindikator",
			go_trade: "Hoppa till transaktionssidan",
		},
		news: {
			tip: "Ansvarsfriskrivning: Ovanstående information är endast allmän information för referens och ska inte användas som grund för handelsbeslut.",
		},
		trade: {
			state1: "Innehav",
			state2: "Väntande order",
			state3: "Historier",
			state: "Sakernas tillstånd",
			state4: "Stängt läge",
			state5: "Tillbakadragen",
			type: "Typologi",
			type1: "Full",
			type2: "Reach (ett pris i en auktion)",
			type3: "Inköp",
			left_label1: "Självbetjäning",
			left_label2: "Nyligen sett",
			left_type1: "Kompakt kolumnmodell",
			left_type2: "Löst kolonnmönster",
			left_type3: "Visa diagram",
			all: "Full",
			right_bp: "Gårdagens skörd",
			right_sp: "Modern tid",
			right_height: "Högsta",
			right_low: "Lägsta",
			remind_tit: "Nya varningar",
			remindList: "Lista över påminnelser",
			remind_btn: "Nya varningar",
			right_tab1: "Affärer",
			right_tab2: "Detaljer",
			right_sell: "Reach ",
			right_buy: "Inköp",
			right_sell1: "Reach ",
			right_buy1: "Inköp",
			right_type: "Typologi",
			right_type1: "Marknadsprislista",
			right_type2: "Väntande order",
			right_priceMoney: "Pris för väntande order",
			right_pointerNumber: "Antal (partier)",
			right_lever: "Brytjärn",
			right_balance: "Tillgängligt saldo",
			right_stop_profit: "Göra vinst",
			right_stop_loss: "Stop-loss",
			right_profit_loss: "Vaxande och vaggande",
			now_order: "Beställ nu",
			confirm_order: "Bekräfta order",
			right_tit1: "Handelsstrategi",
			right_rinei: "Om några dagar",
			right_short: "Kortfristig",
			right_middle: "Halvtid",
			right_time: "Tid för frisläppande",
			right_tit2: "Alternativ strategi",
			right_tit3: "Teknisk översyn",
			right_tit4: "Siffror",
			right_tit5: "Handelshumör",
			right_label_sell: "Säljare (av varor)",
			right_label_buy: "Köpare",
			right_tip: "Endast i informationssyfte, inte en ståndpunkt från divisionen",
			right_tip2: "Uppdateras var 15:e minut",
			right_tit6: "Prisvariationer",
			minute: "Minuter",
			today: "För närvarande",
			right_tit7: "Prisändringsintervall",
			now_price: "Aktuellt pris",
			right_low_price: "Lägsta pris",
			right_height_price: "Högsta pris",
			right_tit8: "Information om avtal",
			right_tit9: "AUD/CHF",
			right_label1: "Antal enskilda transaktioner",
			right_label2: "Maximal hävstångseffekt",
			right_label3: "Kontraktets storlek",
			right_label4: "Maximalt totalt antal positioner",
			right_label5: "Rörlig spread",
			right_label6: "Laddning över natten",
			hand: "Person som är utbildad för vissa typer av arbete",
			right_collection_time: "Tid för insamling",
			right_tip3: "Avgiftsgrund: från den totala transaktionsstorleken",
			right_label7: "Marginalkvot",
			right_label8: "Underhållsmarginal",
			right_label9: "Öppettider",
			right_label10: "Nuvarande handelssession",
			right_label11: "Nästa handelssession",


		},
	},
	home: {
		banner_p1: "",
		banner_p2: "Plattformstransaktion",
		banner_p3: "Forex, råvaror, index, aktier",
		banner_p4: "Och mer populära finansiella produkter",
		banner_p5: "Du kan utforska världens hetaste finansmarknader.",
		hot: "På modet",
		tit1: "Vänligare transaktioner",
		p1: "Enkelt och intuitivt gränssnitt för enkel användning",
		p2: "Stöd för webb, mobilapp och stationär dator, handel till hands",
		p3: "Riskhanteringsverktyg såsom stop loss/trailing stop",
		p4: "Flera tekniska diagram och finansiella kalendrar, nyheter i realtid",
		p5: "E-post, SMS och push-meddelanden i realtid",
		p6: "Kontinuerliga förbättringar för en bättre handelsupplevelse",
		btn1: "Utforska våra plattformar",
		row3_tit: "Handla fritt när som helst, var som helst",
		row3_tip: "Stöd för webb, mobilapp och stationär dator, handel till hands",
		row3_tit2: "Skanna koden för att ladda ner",
		row3_tit3: "Skrivbord",
		row3_tit4: "Mobil",
		regular_tit: "Pålitlig internationell plattform",
		regular_tip: "Vi är fast beslutna att tillhandahålla en säker och ansvarsfull handelsmiljö för våra kunder.。",
		regular_tit1: "Regleras av auktoritativa organisationer",
		regular_p1: "Reglerad och auktoriserad av branschmyndigheter och betrodd av kunder över hela världen",
		regular_tit2: "Skydda den finansiella tryggheten",
		regular_p2: "Inlåning från privatkunder avskiljs på förvaltningskonton efter behov i enlighet med lagstadgade krav",
		regular_tit3: "Skydd mot negativ balans",
		regular_p3: "Ge dig skydd mot negativt saldo på kontot, negativt saldo i rätt tid för att rensa nollan, så att din förlust inte överstiger beloppet för posten, sinnesfrid transaktioner",
		regular_tit4: "7 * 24 timmar kundservice",
		regular_p4: "Uppriktig och professionell kundtjänst, 24 timmars online support, gärna lösa eventuella problem du har!",
		service_tit: "Fler tjänster som hjälper dig",
		service_tit1: "Handelsstrategi",
		service_p1: "Handelsstrategier i realtid som hjälper dig att förstå de senaste marknadstrenderna och bättre förstå tidpunkten för handel.",
		service_tit2: "Kunskap om handel",
		service_p2: "Lär dig handla gratis med HKDFINANC och förbättra dina handelsfärdigheter.",
		service_tit3: "Riskhantering",
		service_p3: "Läs mer om de kostnadsfria riskhanteringsverktyg som HKDFINANC erbjuder för att bättre skydda dina tillgångar.",
		step_tit: "Enkelt och smidigt att öppna ett konto",
		step_tip: "Tre enkla steg för att öppna ett konto på några minuter",
		step_tit1: "Inskrivning",
		step_p1: "Fyll i uppgifterna och skicka in din ansökan",
		step_tit2: "Insättning",
		step_p2: "Snabb insättning av pengar genom en mängd olika metoder",
		step_tit3: "Påbörjande av handel",
		step_p3: "Upptäck handelsmöjligheter och lägg order snabbt",
		award_tit: "Våra prestationer",
		award_p1: "Vi har alltid strävat efter excellens och är fast beslutna att förse våra kunder med högkvalitativa transaktionstjänster.",
		award_p2: "HKDFINANC är hedrade över att få denna prestigefyllda utmärkelse från en ansedd institution i branschen, vilket är ett erkännande av teamets kontinuerliga ansträngningar och engagemang för våra kunder.",
		row7_tit: "Global finansiell information i realtid",
		row7_tit1: "Nyheter i realtid",
	},
	learn: {
		education: {

		}
	},
	login: {
		click_download: "Klicka för att ladda ner",
		p1: "Handel när som helst, var som helst",
		p2: "Snabbare och smartare handel",
		p3: "0 Provision handel",
		p4: "Världens hetaste investering",
		renderDom1: "Utländsk (valuta) valuta",
		renderDom2: "Amerikanska aktiemarknaden",
		renderDom3: "Platina",
		renderDom4: "Råolja",
		renderDom5: "Exponenter",
		renderDom6: "Kryptovaluta",
		login: "Anmäla sig",
		elail: "Inkorg",
		phone: "Mobiltelefonnummer",
		no_account: "Inget konto？",
		go_register: "Gå till registret",
		register: "Inskrivning",
		now_register: "Registrera dig nu",
		have_account: "Har redan ett konto？",
		go_login: "Gå till inloggning",
	},
	about: {
		serviceFee: "Serviceavgift",
		introduction: "Introduktion till plattformen",
		trade_platform: "Handelsplattform",
		complaint_process: "Klagomålsförfarande",
		page: "Webbsida",
		place_search: "Sök efter det du vill veta",
		no_answer: "Kan inte hitta det svar du behöver?",
		contact_us: "Kontakta oss",
		awards: {
			h1: "Utmärkelser och hedersbetygelser",
			p1: "I vår ständiga strävan efter excellens och engagemang för att förse våra kunder med högkvalitativa transaktionstjänster, är varumärket HKDFINANC hedrat över att ha tilldelats av en prestigefylld organisation i branschen, vilket erkänner teamets kontinuerliga ansträngningar och engagemang för våra kunder.",
			p2: "Eller upplev vår prisbelönta handelsplattform online direkt!",
			name1: "Bästa mäklare i Latinamerika",
			name2: "Bästa CFD-mäklare",
			name3: "Bästa Fintech-mäklare",
			name4: "De 10 mest inflytelserika företagen 2021",
			name5: "Mest transparenta mäklare",
			name6: "Ultimate Fintech Awards",
		},
		charges: {
			h1: "Avgifter och kostnader",
			p1: "Som en integrerad del av att förbättra kundupplevelsen vill HKDFINANC erbjuda våra kunder en mer kostnadseffektiv handelstjänst. Vi strävar efter att vara öppna och transparenta när det gäller våra avgifter och kostnader, utan dolda avgifter. De huvudsakliga transaktionskostnaderna för kunderna är skillnaden mellan köp- och säljkurs och positionsräntan över natten.",
			h2: "För det första kan du dra nytta av följande fördelar när du handlar med HKDFINANC",
			li1: "Direkt offert på produkt",
			li2: "Professionella och tekniska indikatorer",
			li3: "Exklusiv marknadsanalys",
			free: "Freeware",
			view: "Titta upp",
			h3: "Bid-ask spread",
			p2: "HKDFINANC tar ut en bid/ask-spread som ersättning för sina tjänster. Denna avgift återspeglas i produktnoteringen och du betalar faktiskt spreaden när du öppnar en position. Eftersom spreadavgiften inte är fast, rekommenderas du att gå till den specifika produktnoteringssidan, eller logga in på",
			h4: "Insättnings- och uttagsavgifter",
			p3: "För att minimera kostnaderna för våra kunder tar vi i de flesta fall inte ut någon avgift för att du ska få tillgång till dina medel. Ett tredjepartsföretag eller en bank kan dock ta ut en avgift av dig för att göra en insättning eller ett uttag, t.ex. en bankavgift.",
			p4: "Kontakta vår kundtjänst för mer information om avgifterna.。",
			p5: "T.ex. genom internationella kreditkortstransaktioner, bankkontoöverföringar in/ut eller transaktioner i valutor som inte stöds (valutakonvertering) osv.",
			h5: "Kostnader för övernattning",
			p6: "Om du fortfarande innehar en position kl. 22.00 GMT (10.00pm GMT) kommer du att debiteras/deponeras en avgift för övernattning. Förfrågningar om avgifter för övernattning för varje produkt kan göras på den specifika produktnoteringssidan eller genom att logga in på",
			h6: "Övriga kostnader",
			p7: "Inga andra avgifter. Vår avgiftspolicy är helt transparent och alla objekt som kräver en avgift kommer att meddelas i förväg, så det finns ingen anledning att oroa sig för dolda avgifter."
		},
		contact: {
			h1: "Kontakta oss",
			address: "Adress",
			custom_email: "Kund kontakt e-post",
			business_email: "E-post om affärssamarbete",
			h2: "Skriv till oss.",
			p1: "Om du har några frågor om våra tjänster, vänligen fyll i formuläret nedan för att skicka in din fråga och en av våra kundtjänstspecialister kommer att svara så snart som möjligt. Alternativt, för att registrera dig för våra dagliga handelsstrategier som tillhandahålls i samarbete med Trading Central, välj 'Prenumerera på dagliga marknadsanalyser' i formuläret nedan och fyll i den språkversion du vill få och vi kommer att registrera dig. Prenumerationstjänsten är endast tillgänglig för befintliga kunder hos HKDFINANC.",
			h3: "Förfaranden för representation",
			p2: "Alla klagomål kommer att undersökas och lösas av vårt specialiserade team, för mer information, vänligen se",
		},
		faq: {
			h1: "Gemensamma problem",
			tit1: "Vad är nyttan med ett demokonto？",
			tit2: "Hur man öppnar ett live-konto？",
			tit3: "Hur man skapar en marknadsorder？",
			tit4: "Varför kan jag inte göra en beställning?？",
			p1: "Demokonton är oftast desamma som riktiga konton när det gäller handelsgränssnitt, data och operationer. Demokonton innehåller $ 50,000 XNUMX virtuella medel och är utformade för att göra det möjligt för kunder att bekanta sig med plattformens funktioner genom simulerade operationer utan att riskera sina medel.",
			p2: "För att öppna ett live-konto, följ dessa steg：",
			p3: "1. Gå in på HKDFINANC:s registreringssida och följ anvisningarna: registrera brevlåda/mobilnummer, ange inloggningslösenord, skicka bekräftelsen och skapa automatiskt ett [Demo Account].",
			p4: "2. På sidan Demo Account väljer du 'Switch to Real Account' och följer proceduren för att öppna ett 'Real Account'.",
			p5: "Innan du öppnar ett live-konto rekommenderar vi att du läser och förstår de juridiska upplysningar som är förknippade med handel.",
			p6: "Observera att du endast kan göra insättningar till ditt live-konto efter att ha slutfört identitetsverifieringen.",
			p7: "Klicka på 'Trade' (Trade) för att visa alla produkter som är tillgängliga för handel på plattformen, du kan ange koden eller namnet på produkten i inmatningsrutan till höger för att söka efter den, välj en produkt och klicka på 'SELL/BUY' (SELL/BUY) för att öppna handelsfönstret, där du kan se det aktuella priset och den beräknade marginalen som krävs. Du kan manuellt justera antalet affärer som ska öppnas, ställa in stop-loss och take-profit för riskkontroll och sedan klicka på 'SÄLJ/BUY' för att slutföra öppnandet av positionen. Observera att priserna på alla finansiella produkter fluktuerar och uppdateras med marknaden när som helst, och marknaden kan ha förändrats innan du trycker på knappen KÖP/SÄLJ.",
			p8: "Kontrollera först om nätverksanslutningen är normal. När marknaden för handelsarter är tillfälligt stängd eller stängd kommer det inte att gå att lägga order. Kontakta vår kundtjänst online för ytterligare hjälp.",

		},
		fundSecurity: {
			h1: "Finansiell säkerhet",
			p1: "HKDFINANC är auktoriserat och regleras av auktoritativa tillsynsorgan. Efterlevnad är kärnan i allt vi gör och i vår kultur. Säkerheten för dina pengar är av största vikt och alla kunddepositioner hålls åtskilda.",
			p2: "Inlåning från privatkunder hålls separat på förvaltningskonton om så krävs i enlighet med lagstadgade krav",
			p3: "HKDFINANC använder inte kundmedel för någon annan kommersiell verksamhet än den som är tillåten enligt lagar och förordningar.",
			p4: "HKDFINANC kommer inte att spekulera på någon marknad.",
			p5: "Oberoende revisioner av tredjeparts revisionsföretag",
		},
		help: {
			h1: "Välkommen till HKDFINANC:s hjälpcenter",
		},
		media: {
			h1: "Mediacenter",
			p1: "Se information, pressmeddelanden, medieinslag, tillkännagivanden och annan information relaterad till varumärket HKDFINANC.",
		},
		mitrade: {
			h1: "Om HKDFINANC",
			view_certificate: "（Metoder för kontroll）",
			p1: "HKDFINANC är ett fintech-företag som är auktoriserat och reglerat av myndigheterna, med fokus på att ge investerare en enkel och bekväm handelsupplevelse. Den innovativa diversifierade handelsplattformen HKDFINANC har upprepade gånger vunnit priser som Best Mobile Trading Platform och Most Innovative Brokerage.",
			p2: "Genom HKDFINANC-plattformen kan du investera och handla på ett större antal internationella finansmarknader, som omfattar aktier, index, råvaror, valutor och hundratals andra populära arter. HKDFINANC erbjuder inte bara användarvänliga mobila och webbaserade handelsplattformar, utan även konkurrenskraftiga handelskostnader, snabb handel, utmärkt kundservice och en mängd informationssupport som hjälper dig att ta vara på aktuella investerings- och handelsmöjligheter.",
			p3: "HKDFINANC regleras strikt av branschmyndigheter och flera institutioner. HKDFINANC International är auktoriserad och reglerad av Mauritius Financial Services Commission (FSC) med licensnummer GB20025791. Om du vill veta mer om Mauritius licens detaljer, kan du gå till den officiella FSC webbplats https://www.fscmauritius.org",
			p4: "Utför en fråga. HKDFINANC Global innehar en licens för finansiella tjänster (AFSL 398528) godkänd av Australian Securities and Investments Commission (ASIC). Om du vill veta mer om uppgifter om australiensiska registreringsskyltar kan du besöka ASIC:s officiella webbplats www.asic.gov.au",
			p5: "HKDFINANC är auktoriserat och reglerat av Cayman Islands Monetary Authority (CIMA) under SIB License No. 1612446. För mer information om licenser på Caymanöarna, besök CIMA:s officiella webbplats på www.cima.ky.",
			p6: "All affärsverksamhet inom HKDFINANC bedrivs under strikt övervakning och i enlighet med alla bestämmelser.",
			h2: "Bakgrund till HKDFINANC",
			p7: "HKDFINANC grundades i Melbourne, Australien, av ett team med erfarna och kunniga experter inom finansiell handel och fintech, och har som filosofi att göra handeln enklare och mer användarvänlig.",
			p8: "Vår holistiska strategi och affärsmodell gör det möjligt för oss att ha en global och djupgående observation och kunskap om branschutveckling och marknadsefterfrågan, så att vi kan fördela resurser på ett mer riktat sätt, kontinuerligt förnya tekniken och optimera effektiviteten samt fortsätta att ge våra kunder en mer bekväm och vänlig handelsupplevelse. ",
			h3: "Varför HKDFINANC",
			why1: "Handelsvillkor med låg tröskel",
			why2: "Regleras av branschmyndigheter",
			why3: "Enkel och intuitiv handelsplattform",
			why4: "Hög nivå av online-support",
			why5: "Konkurrenskraftiga transaktionskostnader",
			why6: "Skydd mot negativ balans",
			h4: "Utmärkelser och hedersbetygelser",
			p9: "Vi har alltid strävat efter excellens och är fast beslutna att erbjuda våra kunder högkvalitativa transaktionstjänster.",
			p10: "HKDFINANC är hedrade över att få denna prestigefyllda utmärkelse från en ansedd institution i branschen, vilket är ett erkännande av teamets kontinuerliga ansträngningar och engagemang för våra kunder.",
			new_add: "Nya användare i hela världen",
			ol1: "Bästa leverantör av nyheter och analyser",
			ol2: "Bästa Forex-mäklare i Asien",
			ol3: "Australiska utmärkelser för kundnöjdhet inom Forex",
			ol4: "Bästa mobilapplikation",
			ol5: "Australiens snabbast växande mäklare",
			h5: "HKDFINANCs verksamhetsfilosofi",
			tip_tit1: "Skulder",
			tip_tit2: "Enkel men vacker",
			tip_tit3: "Öppen (icke-hemlig)",
			tip_tit4: "Bryta nya vägar",
			tip_p1: "All handel innebär risk, och HKDFINANC har vidtagit ytterligare åtgärder för att tillhandahålla en ansvarsfull handelsmiljö för sina kunder. HKDFINANC regleras av branschmyndigheter, flera organisationer och arbetar med höga nivåer av säkerhet och stabilitet. Vår plattform fortsätter att erbjuda ett antal verktyg för ansvarsfull handel, inklusive riskhantering.",
			tip_p2: "Enkelhet är skönhet, detaljrikedom är kvalitet - HKDFINANC strävar efter användarvänlighet och användarupplevelse i varje detalj. Vi fortsätter att optimera vår plattform och våra tjänster i hopp om att alla, från nybörjare till erfarna investerare, ska kunna dra nytta av de handelsmöjligheter som finns tillgängliga via HKDFINANCs plattform.",
			tip_p3: "För att våra kunder ska kunna dra full nytta av en rättvis och effektiv handelsmiljö följer HKDFINANC ett rigoröst och transparent självregleringssystem för att tillhandahålla en omfattande och transparent plattform med produktinformation, prismiljö och data, alla avgifter är tydliga och öppna för att säkerställa att hela serviceprocessen är helt uppriktig och transparent.",
			tip_p4: "HKDFINANC tror starkt på att tekniska framsteg, finansiell innovation och bredare tjänster är det som driver tillväxt och att vi kan fortsätta att skapa och dela värde. Därför uppmuntrar vi öppet tänkande och fortsätter att driva innovation, med målet att vara en innovatör och ledare inom finansiell teknik.",
			row6_tit1: "Vårt uppdrag",
			row6_tit2: "Vår vision",
			row6_tit3: "Jobbmöjligheter",
			row6_p1: "Att ge fler människor som vill handla en rättvis, effektiv och transparent handelsmiljö som inte begränsas av utrymme, tid och marknad, och att leda branschen inom finansiell och teknisk innovation.",
			row6_p2: "Vi tror att teknisk utveckling, finansiell innovation och diversifierade tjänster är de krafter som driver vår tillväxt och gör det möjligt för oss att fortsätta skapa och dela värde i samhället.",
			row6_p3: "I detta syfte uppmuntrar vi öppenhet och främjar innovation, med målet att genom kontinuerlig tillväxt och framsteg bli",
			row6_p4: "Världens ledande valutaväxlingsföretag",
			row6_p5: "Vi är ett globalt team i 7 länder och med ett snabbt växande team letar vi alltid efter nya medarbetare. Gör det du älskar med ett mångkulturellt team!",
			row6_p6: "Skicka oss ditt uppdaterade CV och berätta varför du skulle vilja arbeta hos oss:",
			row6_p7: "Alternativt kan du också ta reda på mer om",
			row6_p8: "Kanske",
			row6_p9: "Se våra lediga tjänster på。",
			row6_p10: "HKDFINANC värnar strikt om säkerheten för dina personuppgifter. HKDFINANC kommer inte att be dig om finansiell information under jobbansökningsprocessen. Vi kräver inte någon finansiell information, kreditkorts- eller bankkontoinformation eller någon form av betalning för vår rekrytering.",
			row6_p11: "Om du misstänker att en tjänst är bedräglig, vänligen kontakta oss via",
			row6_p12: "Kontakta HKDFINANC:s personalavdelning. Om du är säker på att du har blivit lurad av ett falskt jobberbjudande, kontakta din lokala avdelning på den berörda organisationen.",

		}
	},
	product: {
		row2: {
			tit: "Handel med sorter",
			sell: "Reach ",
			buy: "Inköp",
			trade: "Affärer",
			more_product: "Fler produkter för handel",
		},
		row3: {
			tit: "Stödjer transaktioner med flera enheter",
			tit1: "Stödjer transaktioner med flera enheter",
			p1: "HKDFINANC erbjuder dig webbaserade, mobila och stationära handelsplattformar. Du kan handla när som helst och var som helst.",
			download: "Nedladdning",
		},
		row4: {
			tit: "Välj HKDFINANC",
			tit1: "Enkel och intuitiv plattform",
			p1: "Integrering av kurser, handel, kontohantering, information, riskhantering, kraftfulla funktioner för att uppnå en bättre användarupplevelse",
			tit2: "Konkurrenskraftiga transaktionskostnader",
			p2: "0 Handelsprovision, mycket konkurrenskraftig och transparent låga spreadar, låga SWAPs, så att du kan njuta av lågkostnadshandel!",
			tit3: "Handelsvillkor med låg tröskel",
			p3: "Den minsta lotstorleken för varje transaktion är så låg som 0,01 lot, och du kan öppna en position med låg marginal.",
			tit4: "Reglerad av myndighet",
			p4: "Godkänd och reglerad av branschmyndigheter, alla kunders insättningar förvaras i separat segregerad lagring för att skydda kundernas tillgångar",
			tit5: "Skydd mot negativ balans",
			p5: "Ditt konto kommer aldrig att förlora mer än sitt kapital under några marknadsförhållanden, och negativa saldon kommer att rensas i rätt tid för att förbättra din riskhantering.",
			tit6: "Hög nivå av online-support",
			p6: "Ge snabb respons online kundtjänst, kommer att ge bättre service genom de outtröttliga ansträngningar av det professionella teamet",
		},
		row5: {
			tit: "Relaterade frågor",
		},
		commodities: {
			title: "Råvaruhandel",
			p: "Råvaror som ädelmetaller och energi anses vara en viktig del av diversifierade investeringar, eftersom de erbjuder skydd mot inflation samt möjligheter till värdestegring. Bland dem är guld, silver och olja råvaror som handlas i enorma volymer, och deras öppna och transparenta priser samt höga likviditet har välkomnats av investerarna. Råvarupriserna fluktuerar kraftigt på grund av faktorer som tillgång och efterfrågan, ekonomiska och politiska situationer samt valutor, vilket ger möjligheter till risk-avkastning.",
		},
		forex: {
			title: "Valutahandel",
			p: "Valutahandel är omvandlingen av en valuta till en annan, och valutamarknaden är den mest omsatta finansiella marknaden i världen. Valutamarknaden har ett stort antal deltagare som handlar via den för att göra betalningar, skydda sig mot risken för valutarörelser eller för att göra vinst. Valutahandel sker via ett nätverk av banker, institutioner och enskilda handlare runt om i världen, med prisfluktuationer 24 timmar om dygnet, 5 dagar i veckan, vilket ger möjligheter till riskbelöning.",
		},
		indices: {
			title: "indices",
			p: "Ett aktieindex är ett statistiskt värde som representerar en viss typ av karakteristiska aktier på en viss börs, och är en indikator på den övergripande prisnivån och rörelsen för en viss typ av ingående aktier på marknaden. Att handla med aktieindex som speglar investeringsmöjligheter på den övergripande marknaden eller i en sektor kan minska risken med att investera i enskilda aktier. De mest populära indexen på marknaderna i USA, Europa, Asien och Australien, t.ex. Australia 200, US Tech 100, Hong Kong 50, Germany 30 etc., erbjuder olika möjligheter på de globala aktiemarknaderna.",
		},
		shares: {
			title: "Aktietransaktion",
			p: "En aktie är ett omsättningsbart värdepapper som emitteras på en börs. För att anskaffa kapital distribuerar företagen äganderätten till företaget genom de noterade börserna med hjälp av certifikaten. Bara under 2019 uppgick den globala aktiehandeln till över 60 biljoner dollar, vilket är så stort och likvid att det fortfarande är ett av de mest populära finansiella instrumenten i de globala investerarnas ögon, även efter många år. Aktiekurserna påverkas av en mängd olika faktorer, inklusive makroekonomi, branschutsikter och företagsverksamhet, och är mycket volatila, med möjligheter att investera och handla alltid närvarande.",
		},
		coins: {
			title: "Kryptovaluta",
			p: "Kryptovalutor är en digital valuta som skapas genom kod. De fungerar självständigt utanför traditionella bank- och myndighetssystem. Som namnet antyder använder kryptovalutor kryptering för att säkra transaktioner och möjliggöra skapandet av andra enheter, där Bitcoin är den ursprungliga och överlägset mest kända kryptovalutan, skapad av Satoshi Nakamoto och lanserad i januari 2009. Än idag är det okänt om namnet Satoshi Nakamoto syftar på en person eller en grupp människor. Bitcoin anses vara den första decentraliserade kryptovalutan. Precis som alla kryptovalutor styrs den genom en blockkedjetransaktionsdatabas som fungerar som en distribuerad offentlig liggare. I dagsläget finns det mer än 1 000 kryptovalutor tillgängliga för handel online."
		}
	},
	trade: {
		data_sources: "Datakällor",
		tip: "Ovanstående information är endast avsedd som referens. HKDFINANC garanterar inte att informationen är korrekt, aktuell eller fullständig och du bör inte förlita dig alltför mycket på den information som tillhandahålls. Denna information innehåller inte våra prisuppgifter eller något erbjudande eller uppmaning att handla med några finansiella instrument. HKDFINANC är inte ett finansiellt rådgivningsföretag och tillhandahåller endast tjänster för utförande av order. Läsare rekommenderas att söka sin egen investeringsrådgivning. Vänligen se vår fullständiga ansvarsfriskrivning.",
		tip2: "Uppdateras varje fredag kl. 15:00 (GMT)",
		tip3: "Trading Views är ett verktyg för att förutsäga stämningen på marknaden och återspeglar branschexperternas kort- och medelfristiga åsikter och trendprognoser för varje het produkt.",
		analysis: {
			tit: "Handelsstrategi",
			all: "Full",
			day: "Om några dagar",
			tit1: "Handelsstrategi",
			tit2: "Teknisk översyn",
		},
		calendar: {
			tit: "Finansiell kalender",
			prev_week: "Förra veckan",
			next_week: "Nästa vecka",
			place_date: "Datum för sökning",
			select: "Alternativ ",
			select1: "Nationer",
			select2: "Betydelse",
			start_time: "Starttidpunkt",
			end_time: "Sluttid",
			search: "Leta efter något",
		},
		contract: {
			tit: "Specifikationer för kontrakt",
			type1: "Full",
			type2: "utländsk (valuta) valuta",
			type3: "Varor",
			type4: "Exponenter",
			type5: "Lager i USA",
			type6: "Australiska aktier",
			tit1: "Större utländsk valuta",
			tit2: "Mindre utländsk valuta",
			tit3: "Varor",
			tit4: "Huvudindex",
			tit5: "Mindre index",
			tit6: "Lager i USA",
			tit7: "Australiska aktier",
			tit8: "Allmänna regler",
			p1: "Tvingat planhetsförhållande",
			p2: "Orderns giltighet under behandling",
			p3: "Gränslös",
			p4: "Avgift över natten Avvecklingstid",
			p5: "Daglig avräkning; Sommar: 05:00, Vinter: 06:00",
			p6: "Information om hävstång, dagslåneränta etc.",
			p7: "Se handelsplattformar",
			p8: "Ovanstående gäller för alla handelsvarianter",
			tit9: "Veckokalender för handel",
			p9: "Stängning (av marknad)",
			p10: "Ovanstående öppettider tar inte hänsyn till effekten av helgdagar eller särskilda marknadsjusteringar.。",
			p11: "Specifika rekommendationer för handelstid",
			p12: "Plattform för inloggning",
			p13: "，Kontrollera profilerna för de relevanta sorterna.。",
		},
		forecast: {
			tit: "Perspektiv på handeln",
			p1: "Genomsnittligt värde",
			p2: "Tjurmarknad ",
			p3: "Nedåtgående",
			p4: "Korsströmmar",
			p5: "Riktningar",
		},
		news: {
			tit: "Nyheter i realtid",
		},
		platforms: {
			tit: "HKDFINANC handelsplattform",
			p: "Enkelt och intuitivt gränssnitt, utmärkt handelsprestanda, uppfyller behoven hos fler investerare. Börja handla med HKDFINANC redan idag.",
			tit1: "Bekväm och lättanvänd HKDFINANC-handelsplattform",
			tit2: "Handla när som helst och var som helst med HKDFINANC-appen!",
			li1: "Handla på hundratals populära marknader med auktoritativ reglering för att säkerställa att pengarna är säkra",
			li2: "Följ marknadstrenderna i realtid, se handelsanalyser och ta del av de senaste finansnyheterna.",
			li3: "Visa olika typer av handelsdiagram när som helst och använd diagramfunktioner utan krångel",
			li4: "Upplev HKDFINANCs prisbelönta handelsplattform på din Android- eller iOS-enhet!",
			code_download: "Skanna koden för att ladda ner",
			tit3: "Inget behov av nedladdning, handel online via din webbläsare",
			li5: "Ingen nedladdning krävs, handla online direkt från din webbläsare",
			li6: "Prisbelönt: Bästa Forex-mäklare i Asien 2022, Bästa handelsplattform i Australien 2022, Snabbast växande Forex Fintech-mäklare i världen 2022",
			li7: "Innehåller kraftfulla tekniska indikatorer: MACD, KDJ, RSI, TRIX, DMA, CCI, etc.",
			li8: "Utrustad med riskhanteringsverktyg som stop-loss och skydd mot negativ balans för att minska den potentiella risken i dina affärer",
			tit4: "Den perfekta lösningen för dina skrivbordstransaktioner",
			li9: "Enkelt se marknadsnoteringarna i den självvalda listan",
			li10: "Aktivera aviseringar för att få aviseringar om prisändringar och annan information",
			li11: "Med flera diagramlayouter kan du följa marknaden från olika tidsdimensioner.",
			li12: "Kontraktsspecifikation, handelssentiment och handelsanalys allt i ett gränssnitt, ingen växling krävs",
			li13: "Få de senaste handelsnyheterna direkt i HKDFINANC!",
			tit5: "Varför välja HKDFINANCs handelsplattform?",
			row5_p1: "Säkerhet",
			row5_p2: "0% provision",
			row5_p3: "Stöd för flera plattformar",
			row5_p4: "Tekniskt diagram",
			row5_p5: "Åtkomst när som helst, var som helst",
			row5_p6: "Olika ritverktyg",
		},
		quotes: {
			tit: "Offerter i realtid",
			p1: "Ger dig direktkurser, marknadssentiment och relaterade nyheter om de hetaste finansbolagen just nu.",
			hot: "På modet",
		},
		risk: {
			tit: "Riskhantering",
			p1: "All handel innebär risk. Genom att använda de kostnadsfria riskhanteringsverktyg som tillhandahålls av HKDFINANC kan du effektivt hantera din risk oavsett marknadsförhållanden.",
			function_tit1: "Funktion för Take Profit/Stop Loss",
			function_tit2: "Funktion för efterföljande stopp",
			function_p1: "Låsning av vinster",
			function_p2: "Begränsa förluster",
			function_p3: "Maximera vinstinlåsning",
			function_p4: "Inget behov av att övervaka positioner automatiskt",
			function_p5: "Justering av pris för förlustavveckling",
			row2_tit1: "Funktion för Take Profit/Stop Loss",
			row2_p1: "När du skapar en ny order eller ändrar en befintlig order erbjuder vi möjligheten att ställa in 'Take Profit' och 'Stop Loss'. När du har angett detta stängs ordern normalt till det målpris du har angett, vilket hjälper dig att låsa in dina vinster när du når ditt mål eller att minska dina förluster om marknaden rör sig mot dig. Observera att alla order kan blankas på grund av marknadsvolatilitet, i vilket fall systemet inte kommer att kunna utföra ordern till standardpriset, utan kommer att stänga din position till nästa mest gynnsamma pris från riktpriset.",
			row2_tit2: "Exempel",
			row2_p2: "Det aktuella priset för EUR/USD är 1.13816/1.13837 (Sälj/Köp). Du öppnar en köporder för 1 lot (1 lot = 100 000 EUR) på 1,13837 och sätter en Stop Loss på 1,13806.",
			row2_p3: "I allmänhet, när priset faller till 1.13806, kommer din stop loss-order att utlösas och positionen kommer att stängas vid 1.13806 för en kombinerad förlust på $ 31.",
			row2_p4: "Men när marknadsförhållandena förändras och priset glider direkt från 1.13837 till 1.13795, hoppar över ditt Stop Loss-mål, hoppar marknaden och systemet kommer inte att kunna stänga positionen vid 1.13806, men kommer att stänga positionen för dig till nästa mest gynnsamma pris, dvs. 1.13795, och den slutliga förlusten kommer att vara 42 USD.",
			row3_tit1: "Funktion för efterföljande stopp",
			row3_tit2: "Exempel",
			row3_p1: "Trailing stops (även kallade efterföljande stopp) är en kraftfull funktion som gör att du kan låsa in vinster eller minimera förluster utan att ständigt behöva övervaka dina positioner. När du skapar en order anger du helt enkelt ett trailing stop och när priset rör sig till din fördel uppdateras din stop order automatiskt med det senaste priset. Omvänt, när priset rör sig mot dig, aktiveras stop loss-ordern och positionen stängs vid det avstånd du har angett för förlusten. Observera att alla order kan blankas på grund av marknadsvolatilitet, i vilket fall systemet inte kommer att kunna utföra ordern till ditt standardpris, utan kommer att stänga positionen för dig till nästa mest gynnsamma pris från målpriset.",
			row3_p2: "Det aktuella priset för EUR/USD är 1.13816/1.13837 (sälj/köp). Du har skapat en 1-lots köporder på 1.13837 med en trailing stop loss på 100 pips (0.00100).",
			row3_p3: "När priset på produkten är 1,13816 är din Stop Loss 1,13716. Om försäljningspriset på produkten går upp till 1,13845 uppdateras Stop Loss med det avstånd du har angett och den uppdaterade Stop Loss kommer att vara 1,13745.",
			row3_p4: "Tvärtom, när produktpriset sjunker från 1.13845 till 1.13745, kommer ett trailing stop att utlösas och positionen kommer att stängas vid 1.13745.",
			tip: "Punkt: Vanligtvis kallas den minsta enheten av förändring i priset på ett finansiellt instrument för en punkt. I HKDFINANC-plattformen avser det den sista siffran eller decimalen i priset på instrumentet.",
		},
		sentiment: {
			tit: "Känslomässigt index",
			type1: "Full",
			type2: "Utländsk (valuta) valuta",
			type3: "Varor",
			type4: "Exponenter",
			long: "Lång sikt (finansiering)",
			short: "Kort (finansiering)",
		},
	},
	layout: {
		aside: {
			nav1: "Affärer",
			nav2: "Den nuvarande marknadssituationen",
			nav3: "Information",
			nav4: "Högskola",
			nav5: "Min",
			newsDialog: {
				title: "Nyhetscenter",
				type1: "Meddelande från systemet",
				type2: "Bulletin",
				allRead: "Markera alla som lästa",
			},
			settingDialog: {
				title: "Ställa in",
				nav1: "Konventionella (vapen)",
				nav2: "Visa inställningar",
				nav3: "Systeminformation",
				logout: "Logga ut",
				setting1: "Flerspråkighet",
				setting2: "Affärer",
				setting2_tip: "Öppna positioner lägger automatiskt till självvalda sorter",
				setting3: "Akicka tillbaka information",
				setting3_tip: "Funktionella rekommendationer",
				setting4: "Autentisering med verkligt namn",
				setting4_tip: "Autentisering med verkligt namn",
				setting5: "Temaläge",
				setting5_label1: "Dunkelt",
				setting5_label2: "Ljus färg",
				setting6: "Stigande eller fallande färger",
				setting6_label1: "Lit. grönt stiger och rött faller",
				setting6_label2: "Lit. rött stiger, grönt faller",
				setting7: "Inställningar för diagram",
				setting7_label1: "Standardutgåva",
				setting7_label2: "TradingView-utgåvan",
				setting8: "Förklaringar och överenskommelser",
				setting8_label1: "Integritetspolicy",
				setting8_label2: "Redogörelse för produktinformation",
				setting8_label3: "Riskförklaring",
				setting8_label4: "Kundavtal",
				feedback_title: "Funktionella rekommendationer",
			}
		},
		footer: {
			hot: "Populära sorter",
			sort: "Sortiment",
			code: "Kodning",
			buy_price: "Inköpspris",
			sell_price: "Säljarens pris",
			chg: "Prisuppgång eller prisnedgång",
		},
		header: {
			place_search: "Sök efter handelsvarianter",
			balance: "Tillgängligt saldo",
			balance_tip: "Tillgängligt saldo: den summa pengar på kontot som kan användas för att öppna nya positioner.",
			profit_loss: "Vaxande och vaggande",
			asset_view: "Översikt över tillgångar",
			netValue: "Nettovärde ",
			netValue_tip: "Nettovärde: aktuellt kontovärde, inklusive vinster och förluster på alla positioner",
			marginLevel: "Marginalnivå",
			margin: "Marginal",
			margin_tip: "Marginal: det belopp som krävs för att öppna och behålla en position i en utländsk valuta.",
			maintainsMargin: "Underhållsmarginal",
			maintainsMargin_tip: "Underhållsmarginal: Det minsta marginalbelopp som måste finnas på ditt konto medan du behåller alla dina positioner.",

		},
	},
	table: {
		label_name: "Namn",
		label_buyPrice: "Anbudspris",
		label_sellPrice: "Säljkurs",
		label_variableValue: "Värdet av förändring",
		trade: "Affärer",
		label_code: "Produktkod",
		label_title: "Namn (på en sak)",
		label_start: "Betydelse",
		label_country: "Nationer",
		label_event: "Evenemang",
		label_previous: "Tidigare värde",
		label_predictive: "Prognostiserat värde",
		label_announced: "Publicerat värde",
		label_stop_loss_min: "Minsta avstånd för stop loss-order",
		label_difference: "Dynamisk spridning",
		label_contract_size: "Kontraktets storlek",
		label_max_hand: "Maximal tomtstorlek för en enskild transaktion",
		label_min_hand: "Minsta tomtstorlek",
		label_trade_week: "Veckokalender för handel",
		label_week: "1 Vecka",
		label_month: "1 Januari",
		label_quarter: "1 Kvartal",
		label_dailyTrend: "Trend för en enskild dag",
		label_tradeId: "Transaktions-ID",
		label_breed: "Sortiment",
		label_tradeType: "Typ av transaktion",
		label_currency: "Valuta",
		label_amount: "Summa pengar",
		label_balance: "Saldon",
		label_type: "Typologi",
		label_time: "Tider",
		label_orderNumber: "Ordernummer",
		label_pointer_number: "Ordningsnummer",
		label_price_money: "Öppningspris",
		label_stop_win_price: "Göra vinst",
		label_stop_lose_price: "Stop-loss",
		label_openTime: "Öppettid",
		label_profit_loss: "Vaxande och vaggande",
		label_sell_buy: "Begärt/köpande pris",
		label_chg: "Prisuppgång eller prisnedgång",
		label_sell_price: "Säljarens pris",
		label_buy_price: "Inköpspris",
		label_condition: "Förkunskapskrav",
	},
	form: {
		label_feedback: "Frågor och rekommendationer",
		place_feedback: "Ange din fråga eller ditt förslag",
		label_img: "Fotografi",
		label_img_tip: "Valfritt, bifoga en skärmdump av problemet",
		feedback_tip: "Om du har en brådskande fråga, vänligen kontakta",
		online: "Kundtjänst online",
		label_name: "Namn och efternamn",
		place_name: "Vänligen ange ditt namn",
		label_email: "E-postadress",
		place_email: "E-postadress",
		message_email: "Ange din e-postadress",
		label_nationality: "Medborgarskap",
		place_nationality: "Vänligen ange din nationalitet",
		place_questionType: "Typ av problem",
		message_questionType: "Vänligen välj typ",
		questionType0: "Handelsplattform",
		questionType1: "Produkter och avgifter",
		questionType2: "Prenumerera på dagliga marknadsanalyser",
		questionType3: "Kundservice",
		questionType4: "Resten",
		place_question: "Din fråga",
		message_question: "Vänligen ange en fråga",
		submit_question: "Inlämnande av frågor",
		label_phone: "Mobiltelefonnummer",
		place_phone: "Ange ditt mobiltelefonnummer",
		message_phone: "Ange ditt mobiltelefonnummer",
		label_password: "Kryptografisk",
		place_password: "Ange ditt lösenord",
		message_password: "Ange ditt lösenord",
		label_confirmPassword: "Bekräfta lösenord",
		place_confirmPassword: "Ange ditt lösenord igen",
		message_confirmPassword: "Ange ditt lösenord igen",
		label_captcha: "CAPTCHA ",
		place_captcha: "Vänligen ange verifieringskoden",
		message_captcha: "Vänligen ange verifieringskoden",
		get_captcha: "Hämta CAPTCHA",
		label_inviteId: "Inbjudningskod",
		place_inviteId: "Kod för inbjudan (valfritt)",
		to: "Till",
		start_time: "Starttidpunkt",
		end_time: "Sluttid",
		label_cardNumber: "Certifikatets nummer",
		place_cardNumber: "Vänligen ange ditt ID-nummer",
		message_cardNumber: "Vänligen ange ditt ID-nummer",
		label_cardMain: "Dokumentets framsida",
		message_cardMain: "Vänligen ange framsidan av dokumentet",
		label_cardBack: "Baksidan av ett dokument",
		message_cardBack: "Vänligen ange baksidan av dokumentet",
		confirm_modify: "Bekräftelse av ändringar",
		label_realName: "Fullständigt namn",
		place_realName: "Vänligen ange ditt namn",
		message_realName: "Ange ditt fullständiga namn",
		label_firstName: "Efternamn",
		place_firstName: "Vänligen ange ditt efternamn",
		message_firstName: "Vänligen ange efternamn",
		label_lastName: "Namn (på en sak)",
		place_lastName: "Vänligen ange ditt mellannamn",
		message_lastName: "Vänligen ange ett namn",
		label_birthday: "Födelsedatum",
		place_birthday: "Vänligen ange ditt födelsedatum",
		message_birthday: "Vänligen ange ditt födelsedatum",
		place_nowPsd: "Ange ditt nuvarande lösenord",
		message_nowPsd: "Ange ditt nuvarande lösenord",
		place_newPsd: "Ange ett nytt lösenord",
		message_newPsd: "Ange ett nytt lösenord",
		place_crmPsd: "Bekräfta det nya lösenordet",
		message_crmPsd: "Bekräfta det nya lösenordet",
		label_breed: "Sortiment",
		place_breed: "Vänligen ange en art",
		label_phase: "(Koll.) misslyckas (en student).",
		label_buyPrice: "Inköpspris",
		label_sellPrice: "Säljarens pris",
		label_do: "På grund av",
		label_height: "Större än",
		label_low: "Vara lägre än",
		label_equal: "Eller lika med",
		labelWalletName: "Namn på plånbok",
		messageWalletName: "Ange namnet på din plånbok",
		placeWalletName: "Ange namnet på din plånbok",
		labelWalletAddress: "Plånbokens adress",
		messageWalletAddress: "Vänligen ange din plånboksadress",
		placeWalletAddress: "Vänligen ange din plånboksadress",
		labelAmount: "Summa pengar",
		messageAmount: "Vänligen ange beloppet",
		placeAmount: "Vänligen ange beloppet",
		placeTimeLimit: "Ange en tidsgräns",
		messageTimeLimit: "Ange en tidsgräns",
	},
	pay: {
		title: "Enkel tillgång till medel",
		p1: "En mängd vanliga betalningskanaler, stöd för snabb insättning och uttag",
		p2: "Vissa betalningsmetoder kanske inte är tillgängliga i ditt land.",
		p3: "Välkommen att uppleva HKDFINANCs tjänster",
		btn: "Öppna ett konto och handla nu",
	},
	header: {
		tip1: "Hävstångshandel är en komplex finansiell produkt med hög risk för snabba förluster.",
		btn1: "Handelsplattform",
		btn2: "Logga ut",
		btn3: "Anmäla sig",
		BigUint64Array: "Handla nu",
	},
	footer: {
		help: "Vilja？",
		tip1: "Följ vår gemenskap",
		tip2: "Observera att HKDFINANC inte har inrättat någon officiell Telegram-grupp och att alla Telegram-grupper som bildats i HKDFINANC:s namn misstänks vara spoofing.",
		tip3: "RISKVARNING: Handel kan leda till förlust av alla dina medel. Handel med OTC-derivat är inte lämplig för alla. Läs våra juridiska dokument noggrant innan du använder våra tjänster och se till att du förstår de risker som är involverade innan du handlar. Du äger eller innehar inte någon av de underliggande tillgångarna.",
		tip4: "HKDFINANC tillhandahåller inga råd, rekommendationer eller åsikter om köp, innehav eller försäljning. Alla produkter vi erbjuder är OTC-derivat baserade på globala tillgångar. Alla tjänster som tillhandahålls av HKDFINANC är endast baserade på utförande av handelsorder.",
		tip5: "HKDFINANC är ett varumärke som delas av ett antal företag och drivs genom följande företag:",
		tip6: "HKDFINANC International Ltd är emittent av de finansiella produkter som beskrivs eller kan användas på denna webbplats. HKDFINANC International Ltd är auktoriserat och regleras av Financial Services Commission (FSC) i Mauritius med licensnummer GB20025791 och har sitt säte på 6 St Denis Denis Street, 1st Floor River Court, Port Louis 11328, Mauritius",
		tip7: "HKDFINANC Global Pty Ltd är registrerat under ABN 90 149 011 361, Australian Financial Services Licence (AFSL) nummer 398528.",
		tip8: "HKDFINANC Holding Ltd är auktoriserat och reglerat av Cayman Islands Monetary Authority (CIMA) under SIB-licensnummer 1612446.",
		tip9: "Informationen på denna webbplats är inte avsedd för personer bosatta i USA, Kanada, Japan eller Nya Zeeland och är inte heller avsedd att användas av någon i något land eller någon jurisdiktion där publicering eller användning skulle strida mot lokala lagar eller bestämmelser. Observera att engelska är det primära språket för våra tjänster och är det juridiskt bindande språket i alla våra avtalsdokument. Översättningar till andra språk är endast avsedda som referens och i händelse av avvikelser mellan den engelska och den kinesiska versionen ska den engelska versionen ha företräde.",
		tip10: "SSL säker kommunikationskryptering. Copyright © HKDFINANC, Alla rättigheter förbehållna.",
		link1: "Integritetspolicy",
		link2: "Redogörelse för produktinformation",
		link3: "Klagomålsförfarande",
		link4: "Riskförklaring",
		link5: "Kundavtal",
		toTop: "Sticky ",
	},
	nav: {
		tit1: "Erbjudanden",
		tit2: "Affärer",
		tit3: "Investeringar Lärande",
		tit4: "Om oss",
		nav1: "Utländsk (valuta) valuta",
		nav2: "Exponenter",
		nav3: "Varor",
		nav4: "Aktie (marknad)",
		nav5: "Handelsplattform",
		nav6: "Handelsstrategi",
		nav7: "Perspektiv på handeln",
		nav8: "Finansiell kalender",
		nav9: "Nyheter i realtid",
		nav10: "Offerter i realtid",
		nav11: "Känslomässigt index",
		nav12: "Riskhantering",
		nav13: "Specifikationer för kontrakt",
		nav14: "Introduktion till investeringar",
		nav15: "Investeringsinsikt",
		nav16: "Academy",
		nav17: "Om HKDFINANC",
		nav18: "Utmärkelser och hedersbetygelser",
		nav19: "Mediacenter",
		nav20: "Finansiell säkerhet",
		nav21: "Avgifter och kostnader",
		nav22: "Affiliates",
		nav23: "Kontakta oss",
		nav24: "Gemensamma problem",
		nav25: "Hjälpcenter",
	},

	city: {
		albania: "Albanien",
		algeria: "Algeriet",
		argentina: "Argentina",
		armenia: "Armenien",
		australia: "Australien",
		pakistan: "Pakistan",
		austria: "Österrikiska",
		bahrain: "Bahrain",
		belgium: "Belgien",
		bosnia_and_Herzegovina: "Abbr. för Bosnien-Hercegovina",
		brazil: "Brasiliansk",
		brunei: "Brunei ",
		bulgaria: "Bulgarien",
		cambodia: "Kambodjanska",
		canada: "Kanadensiska",
		cameroon: "Kamerun",
		chile: "Chile",
		colombia: "Columbia ",
		costa_Rica: "Costa Rica",
		croatia: "Republiken Kroatien ",
		cyprus: "Cypern",
		czech_Republic: "Tjeckien (från 1993)",
		denmark: "Danmark",
		dominican_Republic: "Dominikanska republiken",
		egypt: "Egypten",
		estonia: "Estland",
		ethiopia: "Etiopien",
		finland: "Suomi",
		france: "Franska",
		georgia: "Georgien (land)",
		germany: "Tyska",
		ghana: "Ghana, Västafrika",
		greece: "Grekland",
		guyana: "Guyana",
		honduras: "Honduras",
		hong_Kong_China: "Hongkong, Kina",
		hungary: "Ungern",
		iceland: "Isländska",
		ireland: "Irländska",
		italy: "Italien",
		india: "Indien",
		indonesia: "Indonesien",
		israel: "Israeliska",
		iran: "Iranska",
		iraq: "Irakiska",
		japan: "Japanska",
		kazakstan: "Kazakstan",
		kenya: "Kenya",
		korea: "Sydkorea (Republiken Korea)",
		kuwait: "Kuwait",
		kyrgyzstan: "Kirgizistan",
		laos: "Laos",
		latvia: "Lettland",
		lithuania: "Litauen",
		luxembourg: "Luxemburg",
		macao_China: "Macao, Kina",
		macedonia: "Makedonska",
		malaysia: "Malaysia",
		malta: "Maltesiska",
		mexico: "Mexiko",
		moldova: "Moldavien",
		monaco: "Monaco",
		mongolia: "Mongoliet",
		montenegro: "Montenegro",
		morocco: "Marocko",
		myanmar: "Myanmar",
		netherlands: "Nederländerna",
		new_Zealand: "Nya Zeeland",
		nepal: "Nepali",
		nigeria: "Nigeria, Västafrika",
		norway: "Norge",
		oman: "Omani",
		palestine: "Palestina",
		panama: "Panama",
		paraguay: "Paraguay",
		peru: "Peruanska",
		philippines: "Filippinerna",
		poland: "Polsk",
		portugal: "Portugal",
		puerto_Rico: "Puerto Rico",
		qatar: "Doha",
		romania: "Rumänien",
		russia: "Georgien",
		republic_of_Trinidad_and_Tobago: "Trinidad och Tobago",
		rwanda: "Rwandiska",
		saudi_Arabia: "Saudiarabien",
		serbia: "Serbien",
		singapore: "Singapore",
		slovakia: "Slovakien",
		slovenia: "Slovenien",
		south_Africa: "Sydafrika",
		spain: "Spanska",
		sri_Lanka: "(Tidigare) Ceylon",
		sweden: "Sverige",
		switzerland: "Schweiz",
		taiwan_China: "Kina-Taiwan",
		tajikistan: "Tadzjikistan",
		tanzania: "Tanzania",
		thailand: "Thailand",
		turkey: "Istanbul",
		turkmenistan: "Turkmenistan",
		ukraine: "Vitryssland",
		united_Arab_Emirates: "FÖRENADE ARABEMIRATEN",
		united_Kingdom: "Storbritannien",
		united_States: "Förenta staterna",
		uzbekistan: "Uzbekistan",
		venezuela: "Venezuela",
		vietnam: "Vietnam",
		afghanistan: "Afghanistan",
		angola: "Angola",
		azerbaijan: "Azerbajdzjan",
		bangladesh: "Bangladesh",
		belarus: "Vitryssland",
		belize: "Beliziska",
		benin: "Benin",
		bhutan: "Bhutan",
		bolivia: "Bolivia",
		botswana: "Botswana",
		british_Virgin_Islands: "Brittiska Jungfruöarna",
		burkina_Faso: "Burkina Faso, Västafrika",
		burundi: "Burundi",
		cape_Verde: "Kap Verde",
		cayman_Islands: "Caymanöarna",
		central_African_Republic: "Centralafrikanska republiken",
		chad: "Tchad",
		comoros: "Komorerna",
		the_Republic_of_the_Congo: "Kongo (Brazzaville)",
		democratic_Republic_of_the_Congo: "Demokratiska republiken Kongo",
		djibouti: "Djibouti",
		ecuador: "Ecuador",
		el_Salvador: "El Salvador",
		equatorial_Guinea: "Ekvatorialguinea",
		eritrea: "Eritrea",
		fiji: "Fiji ",
		gabon: "Gabonesiska",
		gambia: "Gambia",
		greenland: "Grönland",
		guatemala: "Guatemala",
		guinea: "Guinea",
		haiti: "Haiti",
		isle_of_Man: "Isle of Mann",
		cote_d_Ivoire: "Elfenbenskusten",
		jamaica: "Jamaicansk",
		jordan: "Jordanien",
		lebanon: "Fientligheter",
		lesotho: "Lesotho",
		liberia: "Liberia",
		libya: "Libyen",
		madagascar: "Madagaskar",
		malawi: "Malawi",
		maldives: "Maldiverna",
		mali: "Mali, Västafrika",
		mauritania: "Mauretanien",
		mauritius: "Mauritius",
		mozambique: "Moçambique",
		namibia: "Namibia",
		nicaragua: "Nicaragua",
		republic_of_Niger: "Niger (afrikansk stat)",
		north_Korea: "Nordkorea",
		reunion: "Reunion Island",
		san_Marino: "San Marino",
		senegal: "Senegal",
		sierra_Leone: "Sierra Leone",
		somalia: "Mogadishu",
		sudan: "Sudan",
		suriname: "Surinam",
		eswatini: "Swaziland",
		syria: "Syrien",
		togo: "Togo, Västafrika",
		tonga: "Tonga",
		tunisia: "Tunisien",
		united_States_Virgin_Islands: "Amerikanska Jungfruöarna",
		uganda: "Uganda",
		uruguay: "Uruguay",
		vatican: "Vatikanen",
		yemen: "Jemen",
		yugoslavia: "Jugoslavien",
		zambia: "Zambia",
		zimbabwe: "Zimbabwe",
		china: "Kina",
	}
};
