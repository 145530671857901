import { getToken } from "@/utils/auth"; // get token from cookie
import { Message } from "element-ui";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style
import router from "./router";
import store from "./store";
NProgress.configure({ showSpinner: false }); // NProgress Configuration

const whiteList = [
  "/login",
  "/register",
  "/home",
  "/",
  "/forgot",
  "/products/forex",
  "/products/indices",
  "/products/commodities",
  "/products/shares",
  "/products/coins",
  "/trade/platforms",
  "/trade/analysis",
  "/trade/forecast",
  "/trade/calendar",
  "/trade/news",
  "/trade/quotes",
  "/trade/sentiment",
  "/trade/risk",
  "/trade/contract",
  "/learn/education",
  "/about/mitrade",
  "/about/awards",
  "/about/media",
  "/about/fundSecurity",
  "/about/charges",
  "/about/contact",
  "/about/faq",
  "/about/help",
  "/buy",
  "/sell",
]; // no redirect whitelist

const whiteNameList = ["p2p", "p2p-detail"];

router.beforeEach(async (to, from, next) => {
  // start progress bar
  NProgress.start();
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
  window.pageYOffset = 0;
  // await store.dispatch('settings/setSetting')

  const hasToken = getToken();
  if (hasToken) {
    // if (true) {
    if (to.path === "/login") {
      // if is logged in, redirect to the home page
      next({ path: "/home" });
      NProgress.done();
    } else {
      try {
        // get user info
        await store.dispatch("user/getInfo");
        // hack method to ensure that addRoutes is complete
        // set the replace: true, so the navigation will not leave a history record
        next();
      } catch (error) {
        // remove token and go to login page to re-login
        await store.dispatch("user/resetToken");
        Message.error(error || "Has Error");
        next(`/`);
        NProgress.done();
      }
    }
  } else {
    /* has no token*/
    if (whiteList.includes(to.path) || whiteNameList.includes(to.name)) {
      // in the free login whitelist, go directly
      next();
    } else {
      console.log(to);
      // other pages that do not have permission to access are redirected to the login page.
      next(`/login`);
      NProgress.done();
    }
  }
});

router.afterEach((to, from, next) => {
  // ga.page(to.name, to.name, to.path)
  // finish progress bar
  NProgress.done();
});
