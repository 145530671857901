export default {
	crypto: {
		title: {
			"0": "Acheter des crypto - monnaies",
			"1": "Acheter des crypto - monnaies",
			"2": "C2c",
		},
		list: {
			"0": "Transactions c2c",
			"1": "Acheter",
			"2": "Vendu",
			"3": "Annonceurs (taux de complétion)",
			"4": "Transactions",
			"5": "Prix",
			"6": "Disponible",
			"7": "Restrictions",
			"8": "Modes de paiement",
			"9": "Commerce",
			"10": "Les annonceurs peuvent définir des restrictions de transaction sur les annonces et vous verrez la raison de la restriction une fois connecté.",
		},
		buy: {
			"0": "Acheter des crypto - monnaies",
			"1": "Vendre des crypto - monnaies",
			"2": "Historique",
			"3": "Soutien",
			"4": "Paiement",
			"5": "Recevoir",
			"6": "Choisir un mode de paiement",
			"7": "Heure de l'est des États - Unis. Heure d'arrivée",
			"8": "Coût",
			"9": "Détails de la commande",
			"10": "Modes de paiement",
			"11": "En savoir plus",
			"12": "Prix",
			"13": "Total (frais inclus)",
			"14": "Disclaimer",
			"15": "Vous quittez HKDFinanc et serez redirigé vers mercuryo, une institution financière agréée qui fournit des services de paiement par carte de crédit / débit en tant que tiers. Tout risque encouru lors de l'utilisation des services est à la charge de mercuryo. Veuillez lire et accepter les conditions d'utilisation avant de continuer.",
			"16": "J'ai lu et j'accepte les conditions d'utilisation",
			"17": "Vous obtiendrez",
			"18": "Soumettre",
			"19": "Veuillez entrer le montant",
			"20": "Solde insuffisant du compte",
			"21": "Disponible",
			"22": "Compte au comptant disponible",
			23: "Single purchase limit",
			24: "Minimum",
			25: "Maximum",
		},
	},
	common: {
		confirm: "Définir",
		cancel: "Annulations",
		tip: "Attirer l'attention sur qqch.",
		place_select: "Veuillez sélectionner",
		submit: "Soumettre (un rapport, etc.)",
		more: "Plus",
		deposit: "Dépôt",
		real_trade: "La vraie affaire",
		paper_trade: "Trading de démonstration",
		build_paperTrade: "Créer un compte de démonstration",
		start_trade: "Commencer à négocier maintenant",
		app_name: "HKDFINANC",
		success: "Succès！",
		dataSources_name: "Trading Central",
		reset: "Reprovision",
		iknow: "Je l'ai !",
		noData: "Pas de données disponibles",
		modify: "Modifications",
		pingcang: "Fermer un poste",
		all: "Complet",
		placeEnter: "Veuillez entrer",
		countryCode: "Code pays / région",
	},
	addnew: {
		realFirst: "Veuillez d'abord vous authentifier",
		payFirst: "Veuillez d'abord définir un mot de passe de transaction",
		historyOrder: "Ordre historique",
		forgot: "Mot de passe oublié",
		contractTrade: "Contrat à effet",
		paypass: "Mot de passe de trading",
		loginpassTit: "Modifier le mot de passe de connexion",
		paypassTit: "Modifier le mot de passe de retrait",
		paypassTit1: "Veuillez définir votre mot de passe de retrait",
		paypassTit2: "Initialiser votre mot de passe à votre mot de passe de connexion",
		buyUp: "Acheter",
		buyDown: "La baisse",
		addressName1: "Joaquin Financial (International) Holdings Limited",
		addressValue1: "Suite 1101, 11e étage, Crown Jun Building (anciennement Citibank tower), 3 Garden Road, Central Hong Kong",
		addressName2: "Hong Kong zhongqi Financial Investment Co., Ltd",
		addressValue2: "131 - 133 Queen's Road central, Hong Kong, Centre Zhongcai",
		addressName3: "Baoxin Financial Holdings Ltd",
		addressValue3: "Lippo Centre Tower 2, 89 queensway, suite 1908, 19e étage, Hong Kong",
		loan: "Obtenir un prêt (auprès d'une banque, par exemple)",
		repaid: "Remboursé",
        unpaid: "Non-remboursement",
		loanMore: "Je veux un prêt.",
		immediateRepayment: "Remboursement immédiat",
		accountType1: "Compte de pièces",
		accountType2: "Compte du contrat",
		accountType3: "Compte d'options",
		from: "à travers (une brèche)",
        to: "Jusqu'à",
		get: "Obtiendra",
		labelBreed: "Type de monnaie",
		placeBreed: "Veuillez sélectionner la devise",
		labelTransAccount: "Compte de transfert",
		labelAmount: "Montant transféré",
		placeAmount: "Veuillez saisir le montant du transfert",
		transferTit:"Transferts de comptes",
		convertTit:"échange flash de pièces de monnaie",
		balanceTit:"Solde du compte",
		available: "Crédit disponible",
        pending: "Verrouillage",
        equivalent: "S'élève à",
		coinTrade: "Commerce de pièces de monnaie",
		secondContract: "Deuxième contrat (dans le domaine du sport)",
		number: "Quantités",
		labelNumber: "Quantités",
		placeNumber: "Veuillez indiquer le nombre de rançons",
		time: "Fois",
		loadAmount: "Montant prévu du prêt",
		repaymentCycle: "Cycle de remboursement du prêt",
		dailyRate: "Taux d'intérêt journalier",
		repaymentMethod: "Mode de remboursement",
		loanTip: "Prêts (veillez à ce que les photos soient clairement visibles)",
		loanTip1: "Télécharger le certificat de propriété",
        loanTip2: "Preuve de revenus (relation de travail)",
        loanTip3: "Relevé d'identité bancaire",
        loanTip4: "Téléchargez la photo du recto de votre carte d'identité",
        interest: "Intérêt (désir de connaître qqch)",
		repaymentMethod1: "Remboursables en une seule fois",
	},
	params: {
		product_foreign: "Change (de devises)",
		product_shop: "Marchandise",
		product_number: "Exposants",
		product_stock: "Stock (marché)",
		product_coin: "Crypté",
		billTypes: [
			{v: 101, name: 'Recharge '},
			{v: 102, name: 'Dépôt '},
			{v: 103, name: 'Le gel'},
			{v: 104, name: 'Dégeler'},
			{v: 105, name: 'Dépôt '},
			{v: 106, name: 'Dépôt '},
			{v: 201, name: 'Gel des retraits'},
			{v: 202, name: 'Retirer des fonds'},
			{v: 203, name: 'Retrait réussi'},
			{v: 204, name: 'Ne pas retirer les fonds'},
			{v: 205, name: 'Frais de retrait'},
			{v: 206, name: 'Transfert'},
			{v: 207, name: 'Passer à'},
			{v: 208, name: 'Transfert de pièces'},
			{v: 209, name: 'Transfert de pièces'},
			{v: 301, name: 'Frais de gestion du contrat'},
			{v: 302, name: 'Revenus contractuels'},
			{v: 303, name: 'Perte de contrat'},
			{v: 304, name: 'Marge contractuelle'},
			{v: 305, name: 'Marge de rendement du contrat'},
			{v: 311, name: 'Achat d"options'},
			{v: 312, name: 'Gain sur option'},
			{v: 313, name: 'Retours d"options'},
			{v: 314, name: 'Frais de traitement des options'},
			{v: 315, name: 'Gel de l"achat de pièces'},
			{v: 316, name: 'Déduction pour l"achat de pièces de monnaie'},
			{v: 317, name: 'Retour des achats de pièces'},
			{v: 318, name: 'Achat de pièces de monnaie pour le compte'},
			{v: 319, name: 'Achat de pièces de monnaie pour le compte'},
			{v: 320, name: 'Gel des ventes de pièces de monnaie'},
			{v: 321, name: 'Déduction pour vente de pièces de monnaie'},
			{v: 322, name: 'Coin Sell Returns'},
			{v: 323, name: 'Vente de pièces sur un compte'},
			{v: 324, name: 'Vente de pièces sur un compte'},
			{v: 325, name: 'Frais de traitement des pièces'},
			{v: 401, name: 'Les mineurs adhèrent'},
			{v: 402, name: 'Retour des mineurs'},
			{v: 403, name: 'Recettes provenant des machines d"exploitation minière'},
			{v: 404, name: 'Sortie de la machine minière'},
			{v: 405, name: 'Taxe de sortie de l"exploitation minière'},
			{v: 411, name: 'Revenus des emprunts'},
			{v: 412, name: 'Commission d"emprunt'},
			{v: 413, name: 'Remboursement réussi'},
        ],
	},
	message: {
		logout: "Déconnexion",
		register_success: "Enregistrement réussi",
		place_inner_personal: "Veuillez d'abord saisir vos informations personnelles",
		submit_success: "Soumis avec succès！",
		copy_success: "Succès de copie！",
		copy_error: "Échec de la reproduction！",
		modify_success: "Modifié avec succès",
		no_balance: "Solde insuffisant pour couvrir le dépôt de garantie",
		palce_password: "Veuillez saisir votre mot de passe",
		trade_success: "Transaction réussie",
		placeEnterAmount: "Veuillez saisir le montant",
		tipTit1: "Êtes-vous sûr de vouloir payer cette commande ?",
	},
	web: {
		account: {
			menu1: "Informations sur le compte",
			menu2: "Mes atouts",
			menu3: "Rapports de comptes",
			menu4: "Sécurité du compte",
			menu5: "Notifications",
			menu6: "Centre d'activités",
			menu7: "Mon portefeuille.",
			menu8: "Retrait",
			walletAdd: "Ajouter un nouveau portefeuille",
			report: {
				history_tit: "Rapport sur l'historique des transactions",
				stream_tit: "Rapport sur les flux de fonds",
				desc: "Veuillez sélectionner la plage de dates appropriée pour exporter le rapport de compte. (Vous aurez accès aux informations sur les comptes des 1 dernières années)",
				select_date: "Sélectionner une plage de dates",
				week: "La semaine dernière",
				month: "Environ un mois",
				threeMonth: "Trois derniers mois",
				sixMonth: "Les six derniers mois",
				time: "Fois",
				view_report: "Rapport d'activité",
				email_send: "Envoyé par e-mail",
				dialog_tit: "Rapports de comptes",
				dialog_p1: "HKDFINANC Holding Ltd est enregistrée aux îles Caïmans (numéro SIB : 1612446) et traite vos transactions en tant qu'entité commerciale.",
				tit2: "Rapport sur l'historique des transactions de Mitade",
				tit4: "Rapport sur les flux de fonds de Mitade",
				tit3: "Informations sur le compte",
				label1: "Nom du compte",
				label2: "ID du compte",
				label3: "Devise du compte",
				label4: "Délai d'établissement des rapports",
				label5: "Période de rapport",
				label6: "Flux de trésorerie",
				empty: "Vous n'avez pas de transactions fermées",
				email_des: "Le rapport sera envoyé à votre adresse électronique",
				tab1: "Rapport",
				tab2: "Déclaration quotidienne",
				tab3: "Relevé mensuel",
			},
			account: {
				tit1: "Bienvenue à HKDFINANC",
				tit2: "Vérification complète de l'identité pour négocier avec des dépôts réels",
				p1: "Ouvrir un compte de trading",
				p2: "L'authentification",
				btn1: "L'authentification",
				tit3: "Informations personnelles",
				label_name: "Nom et prénom",
				label_email: "Adresse électronique",
				label_address: "Adresse résidentielle",
				tit4: "Informations sur le compte",
				label_account: "Compte de négociation (transaction réelle)",
				label_currency: "Monnaie de base (transactions réelles)",
				label_company: "Société de compte courant",
				company_tip: "HKDFINANC Holding Ltd,HKDFINANC Holding Ltd Le groupe de sociétés HKDFINANC est licencié et autorisé par la Cayman Islands Monetary Authority (CIMA).",
				tit5: "Ouvrir un compte de trading",
				tit6: "Amélioration de l'information",
				tit7: "Complétez vos informations de base",
				tit8: "L'évaluation des risques",
				tit9: "Évaluer la tolérance au risque commercial",
				tit10: "L'authentification",
				tit11: "Vérification de l'identité conformément aux exigences réglementaires",
				p3: "Veuillez sélectionner l'une des pièces justificatives suivantes pour vérification",
				type1: "Cartes d'identité",
				type2: "Permis de conduire",
				type3: "Passeport",
				p4: "Je confirme que toutes les informations et tous les documents fournis sont complets.",
				next: "L'étape suivante",
				btn2: "Accréditation réussie",
				modify_personal_tit: "Modification des informations personnelles",
			},
			activity: {
				tit: "Centre d'activités",
				desc: "Vous pouvez participer à des activités et recevoir des récompenses pour améliorer vos revenus d'investissement.",
			},
			capital: {
				real_trade: "La vraie affaire",
				label_nowAccount: "Compte courant",
				label_tradeAccount: "Numéro du compte de négociation",
				label_currency: "Monnaie de base",
				deposit_withdraw_record: "Registres des dépôts et des retraits",
				capital_flow_details: "Détails des flux financiers",
			},
			notification: {
				tit: "Paramètres de notification",
				tit1: "Ouvrir des canaux de notification",
				email: "Adresse électronique",
				message: "La messagerie textuelle",
				push: "Pousser",
				verify: "Valider (une théorie)",
				verified: "Validée",
				p1: "Veuillez ouvrir/vérifier les canaux de notification suivants pour vous tenir au courant des nouvelles importantes !",
				type1: "Marché",
				tit2: "Notification des activités de marketing",
				desc2: "Informations sur les offres spéciales de la plate-forme, activités opérationnelles et autres nouvelles",
				tit3: "Mode d'emploi",
				desc3: "Un matériel de connaissance riche et spécialisé",
			},
			security: {
				tit: "Cryptographique",
				isSet: "Configuré",
				set: "Aller de l'avant et mettre en place",
				verify: "Valider (une théorie)",
				verified: "Validée",
				tit1: "Certification de sécurité",
				email: "Adresse électronique",
				phone: "Numéro de téléphone mobile",
				phone_tit: "Numéro de téléphone mobile liant",
				password_tit: "Modifier votre mot de passe",
			},
		},
		market: {
			hot: "En vogue",
			prev: "Page précédente",
			next: "Page suivante",
			line: "Partage du temps",
			main_subplot: "Indicateurs graphiques principaux et secondaires",
			main: "Indicateur graphique principal",
			subplot: "Indicateur graphique",
			go_trade: "Passer à la page de transaction",
		},
		news: {
			tip: "Clause de non-responsabilité : Les informations ci-dessus sont des informations générales destinées à servir de référence uniquement et ne doivent pas être utilisées comme base pour des décisions commerciales.",
		},
		trade: {
			state1: "Participations",
			state2: "Commande en cours",
			state3: "Histoires",
			state: "état des lieux",
			state4: "Position fermée",
			state5: "Retiré",
			type: "Typologie",
			type1: "Complet",
			type2: "Atteindre",
			type3: "Participation",
			left_label1: "Libre-service",
			left_label2: "Récemment consultés",
			left_type1: "Modèle compact en colonne",
			left_type2: "Motif colonnaire lâche",
			left_type3: "Afficher le graphique",
			all: "Complet",
			right_bp: "La récolte d'hier",
			right_sp: "époque moderne",
			right_height: "Suprême",
			right_low: "Le plus bas",
			remind_tit: "Nouvelles alertes",
			remindList: "Liste de rappels",
			remind_btn: "Nouvelles alertes",
			right_tab1: "Transactions",
			right_tab2: "Particularités",
			right_sell: "Atteindre ",
			right_buy: "Participation",
			right_sell1: "Atteindre ",
			right_buy1: "Participation",
			right_type: "Typologie",
			right_type1: "Liste des prix du marché",
			right_type2: "Commande en cours",
			right_priceMoney: "Prix de l'ordre en attente",
			right_pointerNumber: "Nombre (lots)",
			right_lever: "Barre de levier",
			right_balance: "Solde disponible",
			right_stop_profit: "Prendre des bénéfices",
			right_stop_loss: "Stop-loss",
			right_profit_loss: "Cire et décroissance",
			now_order: "Commander",
			confirm_order: "Confirmer la commande",
			right_tit1: "Stratégie commerciale",
			right_rinei: "Dans quelques jours",
			right_short: "à court terme",
			right_middle: "à mi-parcours",
			right_time: "Heure de sortie",
			right_tit2: "Stratégie alternative",
			right_tit3: "Examen technique",
			right_tit4: "Chiffres",
			right_tit5: "Humeur commerciale",
			right_label_sell: "Vendeur (de biens)",
			right_label_buy: "Acheteur",
			right_tip: "À titre d'information uniquement, il ne s'agit pas d'une position de la division.",
			right_tip2: "Mise à jour toutes les 15 minutes",
			right_tit6: "Variation des prix",
			minute: "Minutes",
			today: "à l'heure actuelle",
			right_tit7: "Fourchette de changement de prix",
			now_price: "Prix actuel",
			right_low_price: "Prix le plus bas",
			right_height_price: "Prix le plus élevé",
			right_tit8: "Informations sur les contrats",
			right_tit9: "AUD/CHF",
			right_label1: "Nombre de transactions uniques",
			right_label2: "Effet de levier maximal",
			right_label3: "Taille du contrat",
			right_label4: "Nombre total maximum de postes",
			right_label5: "Marge flottante",
			right_label6: "Charge de nuit",
			hand: "Hand",
			right_collection_time: "Durée de la collecte",
			right_tip3: "Base de la redevance : à partir du montant total de la transaction",
			right_label7: "Ratio de marge",
			right_label8: "Ratio de marge de maintenance",
			right_label9: "Heures d'ouverture",
			right_label10: "Séance de négociation en cours",
			right_label11: "Prochaine séance de négociation",


		},
	},
	home: {
		banner_p1: "",
		banner_p2: "Négociez le ",
		banner_p3: "Forex, les matières premières,",
		banner_p4: "les indices, les actions et plus encore !",
		banner_p5: "Vous pouvez explorer les marchés financiers les plus chauds du monde. Hkdfinanc offre 0 Commission, une différence de prix très avantageuse.",
		hot: "En vogue",
		tit1: "Des transactions plus conviviales",
		p1: "Interface simple et intuitive pour une utilisation aisée",
		p2: "Support web, application mobile et bureau, le commerce au bout des doigts",
		p3: "Outils de gestion des risques tels que stop loss/trailing stop",
		p4: "Multiples graphiques techniques et calendriers financiers, actualités en temps réel",
		p5: "Notifications en temps réel par e-mail, SMS et push",
		p6: "Amélioration continue pour une meilleure expérience commerciale",
		btn1: "Découvrez nos plateformes",
		row3_tit: "Échanger librement à tout moment et en tout lieu",
		row3_tip: "Support web, application mobile et bureau, le commerce au bout des doigts",
		row3_tit2: "Scanner le code pour télécharger",
		row3_tit3: "Bureau",
		row3_tit4: "Mobile",
		regular_tit: "Plate-forme internationale de confiance",
		regular_tip: "Nous nous engageons à offrir à nos clients un environnement commercial sûr et responsable.。",
		regular_tit1: "Réglementé par des organisations faisant autorité",
		regular_p1: "Réglementé et autorisé par les autorités du secteur, il jouit de la confiance des clients du monde entier.",
		regular_tit2: "Protéger la sécurité des fonds",
		regular_p2: "Les dépôts des clients de détail sont isolés dans des comptes fiduciaires en fonction des besoins, conformément aux exigences réglementaires.",
		regular_tit3: "Protection du solde négatif",
		regular_p3: "Vous fournir une protection contre le solde négatif du compte, le solde négatif en temps opportun pour effacer le zéro, de sorte que votre perte ne dépasse pas le montant de l'entrée, la tranquillité d'esprit des transactions.",
		regular_tit4: "7*24 heures de service à la clientèle",
		regular_p4: "Une équipe de service clientèle sincère et professionnelle, une assistance en ligne 24 heures sur 24, heureuse de résoudre tous les problèmes que vous rencontrez !",
		service_tit: "Plus de services pour vous aider",
		service_tit1: "Stratégie commerciale",
		service_p1: "Des stratégies de trading en temps réel pour vous aider à comprendre les dernières tendances du marché, à mieux appréhender le timing du trading.",
		service_tit2: "Connaissances en matière de commerce",
		service_p2: "Apprenez à trader gratuitement avec HKDFINANC et améliorez vos compétences en trading.",
		service_tit3: "La gestion des risques",
		service_p3: "Découvrez les outils gratuits de gestion des risques proposés par HKDFINANC pour mieux protéger vos actifs.",
		step_tit: "Ouverture d'un compte facile et pratique",
		step_tip: "Trois étapes simples pour ouvrir un compte en quelques minutes",
		step_tit1: "Inscription",
		step_p1: "Complétez les informations et soumettez votre demande",
		step_tit2: "Dépôt",
		step_p2: "Dépôt rapide de fonds par le biais d'une variété de méthodes",
		step_tit3: "Début de l'activité",
		step_p3: "Découvrir les opportunités de trading et passer des ordres rapidement",
		award_tit: "Nos réalisations",
		award_p1: "Nous avons toujours recherché l'excellence et nous nous engageons à fournir à nos clients des services de transaction de qualité.",
		award_p2: "La marque HKDFINANC est honorée de recevoir ce prix prestigieux décerné par une institution prestigieuse du secteur, en reconnaissance des efforts continus de l'équipe et de son engagement envers nos clients.",
		row7_tit: "Informations financières mondiales en temps réel",
		row7_tit1: "Nouvelles en temps réel",
	},
	learn: {
		education: {

		}
	},
	login: {
		click_download: "Cliquer pour télécharger",
		p1: "Négocier à tout moment et en tout lieu",
		p2: "Des échanges plus rapides et plus intelligents",
		p3: "0 commission commerciale",
		p4: "L'investissement le plus en vogue au monde",
		renderDom1: "Change (de devises)",
		renderDom2: "Marché boursier américain",
		renderDom3: "Platine",
		renderDom4: "Pétrole brut",
		renderDom5: "Exposants",
		renderDom6: "Crypto-monnaie",
		login: "S'inscrire",
		elail: "Boîte de réception",
		phone: "Numéro de téléphone mobile",
		no_account: "Pas de compte？",
		go_register: "S'inscrire",
		register: "Inscription",
		now_register: "S'inscrire",
		have_account: "Vous avez déjà un compte？",
		go_login: "Accéder à la connexion",
	},
	about: {
		serviceFee: "Frais de service",
		introduction: "Introduction de la plate-forme",
		trade_platform: "Plate-forme de négociation",
		complaint_process: "Procédure de réclamation",
		page: "Page web",
		place_search: "Recherchez ce que vous voulez savoir",
		no_answer: "Vous ne trouvez pas la réponse que vous cherchez?",
		contact_us: "Nous contacter",
		awards: {
			h1: "Prix et distinctions",
			p1: "Dans sa recherche constante de l'excellence et son engagement à fournir à ses clients des services transactionnels de qualité, la marque HKDFINANC est honorée d'avoir été récompensée par une organisation prestigieuse du secteur, reconnaissant ainsi les efforts continus et l'engagement de l'équipe envers ses clients.",
			p2: "，Ou découvrez immédiatement notre plateforme de trading en ligne primée !",
			name1: "Meilleur courtier en Amérique latine",
			name2: "Meilleur courtier CFD",
			name3: "Meilleur courtier Fintech",
			name4: "Les 10 entreprises les plus influentes en 2021",
			name5: "Le courtier le plus transparent",
			name6: "Le prix ultime fintech",
		},
		charges: {
			h1: "Taxes et redevances",
			p1: "Dans le cadre de l'amélioration de l'expérience client, HKDFINANC souhaite offrir à ses clients un service de négociation plus rentable. Nous nous efforçons d'être ouverts et transparents dans nos frais et charges, sans frais cachés. Les principaux coûts de transaction pour les clients sont l'écart entre le cours acheteur et le cours vendeur et l'intérêt sur la position au jour le jour.",
			h2: "Tout d'abord, vous pouvez bénéficier des avantages suivants en négociant avec HKDFINANC",
			li1: "Devis produit instantané",
			li2: "Indicateurs professionnels et techniques",
			li3: "Analyse exclusive du marché",
			free: "Logiciel gratuit",
			view: "Regarder vers le haut",
			h3: "écart entre les cours acheteur et vendeur",
			p2: "HKDFINANC facture un écart entre le cours acheteur et le cours vendeur en contrepartie de ses services. Cette commission est reflétée dans la cotation du produit et vous payez effectivement l'écart lorsque vous ouvrez une position. Les frais d'écart n'étant pas fixes, il vous est conseillé de consulter la page de cotation du produit en question ou de vous connecter au site Web de HKDFINANC.",
			h4: "Frais de dépôt et de retrait",
			p3: "Afin de minimiser les coûts pour nos clients, dans la plupart des cas, nous ne vous facturons pas de frais pour accéder à vos fonds. Cependant, une société tierce ou une banque peut vous facturer des frais pour effectuer un dépôt ou un retrait, tels que des frais de transit bancaire.",
			p4: "Veuillez contacter notre responsable du service clientèle pour plus d'informations sur les frais.。",
			p5: "Par exemple, par le biais de transactions internationales par carte de crédit, de transferts de/vers des comptes bancaires ou de transactions dans des monnaies non acceptées (conversions de devises), etc.",
			h5: "Coûts des postes de nuit",
			p6: "Si vous détenez toujours une position à 22h00 GMT (22:00 GMT), des frais de nuit vous seront facturés/déposés. Les demandes de renseignements concernant les frais de nuit pour chaque produit peuvent être faites sur la page de cotation du produit spécifique ou en se connectant à la page de cotation du produit.",
			h6: "Autres coûts",
			p7: "Pas d'autres frais. Notre politique de tarification est totalement transparente et tous les éléments payants sont annoncés et communiqués à l'avance, de sorte qu'il n'y a pas lieu de s'inquiéter de frais cachés."
		},
		contact: {
			h1: "Nous contacter",
			address: "Adresse",
			custom_email: "Contact client email",
			business_email: "Coopération commerciale Courriel",
			h2: "Écrivez-nous.",
			p1: "Si vous avez des questions sur nos services, veuillez remplir le formulaire ci-dessous pour soumettre votre question et l'un de nos spécialistes du service clientèle vous répondra dans les plus brefs délais. Pour vous abonner aux stratégies de trading quotidiennes fournies par notre partenariat avec Trading Central, veuillez sélectionner S'abonner à l'analyse quotidienne du marché dans le type de formulaire ci-dessous et indiquer la version linguistique que vous souhaitez recevoir et nous vous inscrirons. *Le service d'abonnement n'est disponible que pour les clients réels de HKDFINANC.",
			h3: "Procédures de représentation",
			p2: "Toutes les plaintes seront examinées et résolues par notre équipe spécialisée. Pour plus de détails, veuillez vous référer à",
		},
		faq: {
			h1: "Problèmes courants",
			tit1: "Quelle est l'utilité d'un compte de démonstration ?？",
			tit2: "Comment ouvrir un compte réel？",
			tit3: "Comment créer un ordre de marché？",
			tit4: "Pourquoi ne puis-je pas passer de commande ?？",
			p1: "Les comptes de démonstration contiennent 50 000 $ de fonds virtuels et sont conçus pour permettre aux clients de se familiariser avec les fonctionnalités de la plateforme par le biais d'opérations simulées sans risquer leurs fonds.",
			p2: "Pour ouvrir un compte réel, veuillez suivre les étapes suivantes：",
			p3: "1. Entrer dans la page d'enregistrement de HKDFINANC, suivre les instructions : enregistrer la boîte aux lettres / le numéro de téléphone portable, définir le mot de passe de connexion, envoyer la confirmation et créer automatiquement un [Compte de démonstration].",
			p4: "2. Sur la page du compte démo, sélectionnez ''Passer au compte réel' et suivez la procédure pour ouvrir un 'compte réel'.",
			p5: "Avant d'ouvrir un compte réel, nous vous recommandons de lire et de comprendre les informations légales associées au trading.",
			p6: "Veuillez noter que vous ne pouvez effectuer un dépôt sur votre compte en direct qu'après avoir procédé à la vérification de votre identité.",
			p7: "Cliquez sur ''Trade' (Trade) pour afficher tous les produits disponibles pour le trading sur la plateforme, vous pouvez entrer le code ou le nom du produit dans la boîte de saisie à droite pour le rechercher, sélectionnez un produit et cliquez sur 'SELL/BUY' (SELL/BUY) pour faire apparaître la fenêtre de trading, dans laquelle vous pouvez voir le prix actuel et la marge estimée nécessaire. Vous pouvez ajuster manuellement le nombre de transactions à ouvrir, définir un stop-loss et un take-profit pour le contrôle du risque, puis cliquer sur 'SELL/BUY' pour terminer l'ouverture de la position. Veuillez noter que les prix de tous les produits financiers fluctuent et s'adaptent au marché à tout moment, et que le marché peut avoir changé avant que vous n'appuyiez sur le bouton ACHETER/VENDRE.",
			p8: "Tout d'abord, veuillez vérifier si la connexion au réseau est normale. Lorsque la variété de négociation du marché est temporairement fermée, la cotation ou la fermeture entraînera l'impossibilité de passer des ordres. Veuillez contacter notre service clientèle en ligne pour obtenir de l'aide.",

		},
		fundSecurity: {
			h1: "La sécurité financière",
			p1: "HKDFINANC est agréée et réglementée par des organismes de régulation faisant autorité. La conformité est au cœur de toutes nos activités et de notre culture. La sécurité de votre argent est primordiale et tous les dépôts des clients sont séparés.",
			p2: "Les dépôts des clients de détail sont conservés séparément sur des comptes fiduciaires, si nécessaire, conformément aux exigences réglementaires.",
			p3: "HKDFINANC n'utilise pas les fonds des clients pour des activités commerciales autres que celles autorisées par les lois et règlements.",
			p4: "HKDFINANC ne spéculera sur aucun marché.",
			p5: "Audits indépendants réalisés par des cabinets comptables tiers",
		},
		help: {
			h1: "Bienvenue au centre d'aide de HKDFINANC",
		},
		media: {
			h1: "Centre des médias",
			p1: "Consultez les informations, les communiqués de presse, les articles de presse, les annonces et autres informations relatives à la marque HKDFINANC.",
		},
		mitrade: {
			h1: "À propos de HKDFINANC",
			view_certificate: "（Méthodes de vérification）",
			p1: "HKDFINANC est une société fintech autorisée et réglementée par les autorités, qui se concentre sur la fourniture aux investisseurs d'une expérience de trading simple et pratique. La plateforme de trading diversifiée et innovante de HKDFINANC a été récompensée à plusieurs reprises, notamment par les prix de la meilleure plateforme de trading mobile et de la société de courtage la plus innovante.",
			p2: "Grâce à la plateforme HKDFINANC, vous pouvez investir et négocier sur un plus large éventail de marchés financiers internationaux, couvrant les actions, les indices, les matières premières, les devises et des centaines d'autres espèces populaires. HKDFINANC ne se limite pas aux plateformes de négociation conviviales sur mobile et sur Internet, mais vous propose également des coûts de négociation compétitifs, une exécution rapide des transactions, un excellent service clientèle et une multitude d'informations, vous aidant ainsi à saisir les opportunités d'investissement et de négociation au moment opportun.",
			p3: "HKDFINANC est strictement réglementé par une autorité sectorielle, multi-agence. HKDFINANC International est autorisé et réglementé par la Financial Services Commission (FSC) de l'île Maurice sous le numéro de licence GB20025791. Pour plus de détails sur la licence de l'île Maurice, vous pouvez consulter le site officiel de la FSC à l'adresse suivante : https://www. fscmauritius.org",
			p4: "Faites une enquête. Hkdfinanc global détient une licence de services financiers (afsl 398528) agréée par la Hong Kong Securities and Investments Commission (ASIC). Pour plus de détails sur les licences de Hong Kong, visitez le site officiel de l'ASIC à l'adresse www.asic.gov.au.",
			p5: "HKFDINANC est agréée et réglementée par l'Autorité monétaire des îles Caïmans (CIMA) sous le numéro de licence SIB 1612446. Pour plus d'informations sur les licences caïmanaises, veuillez consulter le site officiel de la CIMA à l'adresse suivante : www.cima.ky.",
			p6: "Toutes les opérations commerciales de HKDFINANC sont menées sous une supervision stricte et dans le respect de toutes les réglementations.",
			h2: "Historique de HKDFINANC",
			p7: "Hkdfinanc est composé d'une équipe chevronnée possédant une expérience et des connaissances approfondies dans le secteur des transactions financières et des technologies financières. La philosophie de hkdfinanc est que le trading devient plus facile et plus convivial",
			p8: "Notre stratégie globale et notre modèle d'entreprise nous permettent de disposer d'une observation et d'une connaissance approfondies et globales de l'évolution du secteur et de la demande du marché, afin d'allouer les ressources de manière plus ciblée, d'innover continuellement en matière de technologie et d'optimiser l'efficacité, et de continuer à offrir à nos clients une expérience commerciale plus pratique et plus conviviale. ",
			h3: "Pourquoi HKDFINANC",
			why1: "Conditions commerciales à bas seuil",
			why2: "Réglementé par les autorités sectorielles",
			why3: "Plate-forme de négociation simple et intuitive",
			why4: "Niveau élevé d'assistance en ligne",
			why5: "Coûts de transaction compétitifs",
			why6: "Protection du solde négatif",
			h4: "Prix et distinctions",
			p9: "Nous avons toujours recherché l'excellence et nous nous engageons à fournir à nos clients des services de transaction de qualité.",
			p10: "La marque HKDFINANC est honorée de recevoir ce prix prestigieux décerné par une institution prestigieuse du secteur, en reconnaissance des efforts continus de l'équipe et de son engagement envers nos clients.",
			new_add: "Nouveaux utilisateurs dans le monde",
			ol1: "Meilleur fournisseur d'informations et d'analyses",
			ol2: "Meilleur courtier Forex en Asie",
			ol3: "Grand Prix de la satisfaction des clients Forex de Hong Kong",
			ol4: "Meilleure application mobile",
			ol5: "Les investisseurs à la croissance la plus rapide à Hong Kong",
			h5: "Philosophie de fonctionnement de HKDFINANC",
			tip_tit1: "Passif",
			tip_tit2: "Simple mais beau",
			tip_tit3: "Ouvert (non secret)",
			tip_tit4: "Ouvrir de nouvelles voies",
			tip_p1: "Toute transaction comporte des risques et HKDFINANC a pris des mesures supplémentaires pour offrir à ses clients un environnement commercial responsable. HKDFINANC est réglementé par les autorités du secteur, par de multiples organisations, et opère avec des niveaux élevés de sécurité et de stabilité. Notre plateforme continue d'offrir un certain nombre d'outils de trading responsable, y compris la gestion des risques.",
			tip_p2: "La simplicité est la beauté, le détail est l'excellence, HKDFINANC s'engage à faciliter l'utilisation et l'expérience de l'utilisateur dans tous les détails. Nous continuons à optimiser notre plateforme et nos services dans l'espoir que chacun, du novice à l'investisseur expérimenté, puisse profiter des opportunités de trading offertes par la plateforme HKDFINANC.",
			tip_p3: "Afin de permettre à nos clients de bénéficier pleinement d'un environnement commercial équitable et efficace, HKDFINANC suit un système d'autorégulation rigoureux et transparent pour fournir une plate-forme complète et transparente d'informations sur les produits, l'environnement tarifaire et les données, tous les frais sont clairs et ouverts afin de garantir que l'ensemble du processus de service est totalement sincère et transparent.",
			tip_p4: "HKDFINANC est convaincue que les progrès technologiques, l'innovation financière et l'élargissement des services sont les moteurs de la croissance, et que nous pouvons continuer à créer et à partager de la valeur. À cette fin, nous encourageons l'ouverture d'esprit et continuons à stimuler l'innovation, dans le but d'être un innovateur et un leader dans le domaine de la technologie financière.",
			row6_tit1: "Notre mission",
			row6_tit2: "Notre vision",
			row6_tit3: "Offres d'emploi",
			row6_p1: "Offrir à un plus grand nombre de personnes désireuses de faire du commerce un environnement commercial équitable, efficace et transparent qui ne soit pas limité par l'espace, le temps et le marché, et être le chef de file du secteur en matière d'innovation financière et technologique.",
			row6_p2: "Nous pensons que les progrès technologiques, l'innovation financière et la diversification des services sont les moteurs de notre croissance et nous permettent de continuer à créer et à partager de la valeur dans la société.",
			row6_p3: "À cette fin, nous encourageons l'ouverture d'esprit et promouvons l'innovation, dans le but de devenir, grâce à une croissance et à des progrès continus, l'entreprise la plus prospère du monde.",
			row6_p4: "La première société de change au monde",
			row6_p5: "Nous sommes une équipe internationale répartie dans 7 pays et, avec une équipe en pleine croissance, nous sommes toujours à la recherche de professionnels pour nous rejoindre. Faites ce que vous aimez avec une équipe multiculturelle !",
			row6_p6: "Veuillez nous envoyer par courriel votre CV actualisé et les raisons pour lesquelles vous aimeriez travailler avec nous :",
			row6_p7: "。Vous pouvez également obtenir plus d'informations sur le programme",
			row6_p8: "Peut-être",
			row6_p9: "Consultez nos offres d'emploi sur。",
			row6_p10: "HKDFINANC protège strictement la sécurité de vos informations personnelles. HKDFINANC ne vous demandera pas d'informations financières au cours du processus de candidature. Nous ne demandons aucune information financière, aucune carte de crédit, aucune information sur un compte bancaire ni aucune forme de paiement pour nos recrutements.",
			row6_p11: "Si vous soupçonnez qu'un poste est frauduleux, veuillez nous contacter par l'intermédiaire du site Internet",
			row6_p12: "Contactez le département des ressources humaines de HKDFINANC. Si vous êtes certain d'avoir été trompé par une fausse offre d'emploi, veuillez contacter le service local de l'organisation concernée.",

		}
	},
	product: {
		row2: {
			tit: "Variétés commerciales",
			sell: "Atteindre (un prix dans une vente aux enchères)",
			buy: "Participation",
			trade: "Transactions",
			more_product: "Plus de produits d'échange",
		},
		row3: {
			tit: "Prise en charge des transactions portant sur plusieurs appareils",
			tit1: "Prise en charge des transactions portant sur plusieurs appareils",
			p1: "HKDFINANC vous propose des plateformes de trading basées sur le web, mobiles et de bureau. Vous pouvez négocier à tout moment et en tout lieu.",
			download: "Téléchargement",
		},
		row4: {
			tit: "Sélectionnez HKDFINANC",
			tit1: "Une plateforme simple et intuitive",
			p1: "Intégration des cotations, des transactions, de la gestion des comptes, des informations, de la gestion des risques, de fonctions puissantes, afin d'améliorer l'expérience des utilisateurs.",
			tit2: "Coûts de transaction compétitifs",
			p2: "0 commission de trading, des spreads très compétitifs et transparents, des SWAPs bas, pour que vous puissiez profiter d'un trading à bas prix !",
			tit3: "Conditions commerciales à bas seuil",
			p3: "La taille minimale du lot pour chaque transaction est de 0,01 lot, et vous pouvez ouvrir une position avec une faible marge.",
			tit4: "Réglementé par l'autorité",
			p4: "Autorisés et réglementés par les autorités du secteur, tous les dépôts des clients sont conservés dans des entrepôts distincts afin de protéger les actifs des clients.",
			tit5: "Protection du solde négatif",
			p5: "Votre compte ne perdra jamais plus que son principal, quelles que soient les conditions du marché, et les soldes négatifs seront compensés en temps voulu pour améliorer vos capacités de gestion des risques.",
			tit6: "Niveau élevé d'assistance en ligne",
			p6: "Fournir une réponse rapide au service clientèle en ligne, fournir un meilleur service grâce aux efforts incessants de l'équipe professionnelle.",
		},
		row5: {
			tit: "Questions connexes",
		},
		commodities: {
			title: "Commerce des produits de base",
			p: "Les matières premières telles que les métaux précieux et l'énergie sont considérées comme un élément important des investissements diversifiés, car elles offrent une protection contre l'inflation ainsi que des possibilités d'appréciation de l'investissement. L'or, l'argent et le pétrole sont des produits de base échangés dans des volumes considérables, et leurs prix ouverts et transparents ainsi que leur grande liquidité ont été largement salués par les investisseurs. Les prix des matières premières fluctuent considérablement en raison de facteurs tels que l'offre et la demande, la situation économique et politique, et les devises, ce qui offre des opportunités de risque et de rendement.",
		},
		forex: {
			title: "Opérations de change",
			p: "Le marché des changes est la conversion d'une devise en une autre, et le marché des changes est le marché financier le plus négocié au monde. Le marché des changes compte un grand nombre de participants qui y effectuent des transactions pour effectuer des paiements, se couvrir contre le risque de fluctuation des devises ou réaliser des bénéfices. Les transactions sur le marché des changes s'effectuent par l'intermédiaire d'un réseau de banques, d'institutions et de négociants individuels dans le monde entier, avec des fluctuations de prix 24 heures sur 24, 5 jours sur 7, ce qui offre des opportunités de risque et de gain.",
		},
		indices: {
			title: "Exposants",
			p: "Un indice boursier est une valeur statistique représentant un type particulier d'actions caractéristiques sur une bourse donnée, et constitue un indicateur du niveau général des prix et de l'évolution d'un type particulier d'actions constitutives sur le marché. La négociation d'indices boursiers qui reflètent les opportunités d'investissement sur l'ensemble du marché ou du secteur peut réduire le risque lié à l'investissement dans des actions individuelles. Les indices les plus populaires sur les marchés américain, européen, asiatique et Continent, tels que l'Continent 200, l'US Tech 100, le Hong Kong 50, l'Allemagne 30, etc., offrent différentes opportunités sur les marchés boursiers mondiaux.",
		},
		shares: {
			title: "Transaction d'actions",
			p: "Une action est un titre négociables émis en bourse. Pour lever des fonds, les entreprises distribuent leurs titres de propriété par le biais d'une bourse. Avec plus de 60 billions de dollars échangés en 2019, les actions mondiales sont énormes et liquides, ce qui en fait l’un des instruments financiers les plus populaires aux yeux des investisseurs mondiaux, même après des années. Le prix des actions est influencé par de nombreux facteurs tels que l'économie générale, les perspectives industrielles et les opérations de l'entreprise, il y a une grande volatilité et il y a toujours des opportunités de Trading d'investissement.",
		},
		coins: {
			title: "crypto-monnaie",
			p: "Les crypto-monnaies sont des monnaies numériques créées par le biais d'un code. Elles fonctionnent de manière autonome, en dehors des systèmes bancaires et gouvernementaux traditionnels. Comme leur nom l'indique, les crypto-monnaies utilisent le cryptage pour sécuriser les transactions et permettre la création d'autres unités. Le Bitcoin est la crypto-monnaie originale et de loin la plus célèbre, créée par Satoshi Nakamoto et lancée en janvier 2009. Aujourd'hui encore, on ne sait pas si le nom de Satoshi Nakamoto désigne une personne ou un groupe de personnes. Le bitcoin est considéré comme la première crypto-monnaie décentralisée. Comme toutes les crypto-monnaies, il est contrôlé par une base de données de transactions blockchain qui sert de grand livre public distribué. À ce jour, plus de 1 000 crypto-monnaies peuvent être échangées en ligne."
		}
	},
	trade: {
		data_sources: "Sources de données",
		tip: "Les informations ci-dessus sont données à titre indicatif. HKDFINANC ne garantit pas l'exactitude, l'actualité ou l'exhaustivité des informations et vous ne devez pas vous fier indûment aux informations fournies. Ces informations ne contiennent pas nos relevés de prix ni aucune offre ou sollicitation de négociation d'instruments financiers. HKDFINANC n'est pas une société de conseil financier et fournit uniquement des services d'exécution d'ordres. Il est conseillé aux lecteurs de rechercher leurs propres conseils en matière d'investissement. Veuillez vous référer à notre clause de non-responsabilité.",
		tip2: "Mise à jour tous les vendredis à 15h00 (GMT)",
		tip3: "Trading Views est un outil permettant de prédire l'humeur du marché, reflétant les opinions à court et moyen terme et les prévisions de tendances des experts du secteur pour chaque produit en vogue.",
		analysis: {
			tit: "Stratégie commerciale",
			all: "Complet",
			day: "Dans quelques jours",
			tit1: "Stratégie commerciale",
			tit2: "Examen technique",
		},
		calendar: {
			tit: "Calendrier financier",
			prev_week: "La semaine dernière",
			next_week: "La semaine prochaine",
			place_date: "Date de la recherche",
			select: "Options ",
			select1: "Nations",
			select2: "Signification",
			start_time: "Heure de début",
			end_time: "Heure de fin",
			search: "Rechercher qqch.",
		},
		contract: {
			tit: "Cahier des charges",
			type1: "Complet",
			type2: "Change (de devises)",
			type3: "Marchandise",
			type4: "Exposants",
			type5: "Stock américain",
			type6: "Actions Continent",
			tit1: "Principales opérations de change",
			tit2: "Change mineur",
			tit3: "Marchandise",
			tit4: "Principaux indices",
			tit5: "Index mineur",
			tit6: "Stock américain",
			tit7: "Actions Continent",
			tit8: "Règles générales",
			p1: "Rapport de planéité forcée",
			p2: "Validité de l'ordre en attente",
			p3: "Illimité",
			p4: "Délai de règlement des frais d'un jour à l'autre",
			p5: "Règlement quotidien ; été : 05:00, hiver : 06:00",
			p6: "Informations sur l'effet de levier, les taux au jour le jour, etc.",
			p7: "Voir Plateformes de trading",
			p8: "Ce qui précède s'applique à toutes les variétés commerciales",
			tit9: "Calendrier hebdomadaire des échanges",
			p9: "Fermeture (du marché)",
			p10: "Les heures d'ouverture ci-dessus ne tiennent pas compte des effets des jours fériés ou des ajustements spéciaux du marché.。",
			p11: "Recommandations spécifiques en matière de temps de négociation",
			p12: "Plate-forme de connexion",
			p13: "，Consultez les profils des variétés concernées.。",
		},
		forecast: {
			tit: "Perspectives commerciales",
			p1: "Valeur moyenne",
			p2: "Marché haussier ",
			p3: "Baissier",
			p4: "Courants croisés",
			p5: "Orientations",
		},
		news: {
			tit: "Nouvelles en temps réel",
		},
		platforms: {
			tit: "Plate-forme de négociation HKDFINANC",
			p: "Une interface simple et intuitive, d'excellentes performances de trading, répondant aux besoins d'un plus grand nombre d'investisseurs. Commencez à négocier avec HKDFINANC dès aujourd'hui.",
			tit1: "Plateforme de négociation HKDFINANC pratique et facile à utiliser",
			tit2: "Négociez à tout moment et en tout lieu avec l'application HKDFINANC !",
			li1: "Négocier sur des centaines de marchés populaires avec une réglementation faisant autorité pour garantir la sécurité des fonds.",
			li2: "Suivez les tendances du marché en temps réel, consultez les analyses de trading et parcourez les dernières nouvelles financières.",
			li3: "Visualisez différents types de graphiques à tout moment et appliquez les fonctions graphiques sans aucune difficulté.",
			li4: "Découvrez la plateforme de trading primée de HKDFINANC sur votre appareil Android ou iOS !",
			code_download: "Scanner le code pour télécharger",
			tit3: "Aucun téléchargement n'est nécessaire, les transactions s'effectuent en ligne via votre navigateur.",
			li5: "Aucun téléchargement n'est nécessaire, vous pouvez effectuer des transactions en ligne directement à partir du navigateur de votre PC.",
			li6: "Récompensé : Meilleur courtier Forex en Asie 2022, Meilleure plateforme de trading en Continent 2022, Croissance la plus rapide des courtiers Forex Fintech dans le monde 2022",
			li7: "Fournit de puissants indicateurs techniques : MACD, KDJ, RSI, TRIX, DMA, CCI, etc.",
			li8: "Équipé d'outils de gestion des risques tels que la protection contre les pertes et les soldes négatifs afin de réduire le risque potentiel de vos transactions.",
			tit4: "La solution parfaite pour vos transactions de bureau",
			li9: "Visualiser facilement les cotations du marché dans la liste que vous avez sélectionnée.",
			li10: "Activer les alertes pour recevoir des alertes de changement de prix et d'autres notifications d'information",
			li11: "Plusieurs présentations de graphiques vous permettent de suivre le marché à partir de différentes dimensions temporelles.",
			li12: "Spécification des contrats, sentiment de négociation et analyse de la négociation, le tout dans une seule interface, sans changement de fournisseur.",
			li13: "Obtenez les dernières nouvelles commerciales directement dans HKDFINANC !",
			tit5: "Pourquoi choisir la plateforme de trading HKDFINANC ?",
			row5_p1: "La sécurité",
			row5_p2: "0 % de commission",
			row5_p3: "Support multiplateforme",
			row5_p4: "Graphique technique",
			row5_p5: "Accès à tout moment et en tout lieu",
			row5_p6: "Différents outils de dessin",
		},
		quotes: {
			tit: "Devis en temps réel",
			p1: "Vous fournit des cotations instantanées, le sentiment du marché et des informations connexes sur les instruments financiers les plus en vogue du moment.",
			hot: "En vogue",
		},
		risk: {
			tit: "La gestion des risques",
			p1: "En utilisant les outils gratuits de gestion des risques fournis par HKDFINANC, vous pouvez gérer efficacement vos risques quelles que soient les conditions du marché.",
			function_tit1: "Fonction Take Profit/Stop Loss",
			function_tit2: "Fonction Trailing Stop",
			function_p1: "Blocage des bénéfices",
			function_p2: "Limiter les pertes",
			function_p3: "Maximiser l'immobilisation des bénéfices",
			function_p4: "Il n'est pas nécessaire de surveiller les positions automatiquement",
			function_p5: "Ajustement du prix de clôture de la perte",
			row2_tit1: "Fonction Take Profit/Stop Loss",
			row2_p1: "Lors de la création d'un nouvel ordre ou de la modification d'un ordre existant, nous offrons la possibilité de définir un 'Take Profit' et un 'Stop Loss'. Une fois défini, l'ordre sera normalement clôturé au prix cible que vous avez fixé, ce qui vous permet de bloquer vos bénéfices lorsque vous atteignez votre objectif ou de réduire vos pertes si le marché évolue en votre défaveur. Veuillez noter que tout ordre peut être court-circuité en raison de la volatilité du marché, auquel cas le système ne sera pas en mesure d'exécuter l'ordre au prix par défaut, mais clôturera votre position au prix le plus favorable suivant à partir du prix cible.",
			row2_tit2: "Exemples",
			row2_p2: " Le cours actuel de l'EUR/USD est de 1,13816/1,13837 (Vente/Achat). Vous ouvrez un ordre d'achat pour 1 lot (1 lot = 100 000 EUR) à 1,13837 et placez un Stop Loss à 1,13806.",
			row2_p3: "En général, lorsque le prix tombe à 1,13806, votre ordre stop loss sera déclenché et la position sera clôturée à 1,13806 pour une perte combinée de 31 $.",
			row2_p4: "Cependant, lorsque les conditions du marché changent et que le prix glisse directement de 1,13837 à 1,13795, sautant votre objectif de Stop Loss, le marché saute et le système ne sera pas en mesure de fermer la position à 1,13806, mais fermera la position pour vous au prochain prix le plus favorable, c'est-à-dire 1,13795, et la perte finale sera de 42 USD.",
			row3_tit1: "Fonction Trailing Stop",
			row3_tit2: "Exemples",
			row3_p1: "Les stops suiveurs (également connus sous le nom de trailing stops) sont une fonctionnalité puissante qui vous permet de bloquer vos profits ou de minimiser vos pertes sans avoir à surveiller constamment vos positions. Lorsque vous créez un ordre, il vous suffit de définir un stop suiveur et lorsque le cours évolue en votre faveur, votre ordre stop est automatiquement mis à jour avec le dernier cours. Inversement, lorsque le cours évolue en votre défaveur, l'ordre de vente stop est activé et la position est clôturée à la distance que vous avez fixée pour la perte. Veuillez noter que tout ordre peut être court-circuité en raison de la volatilité du marché, auquel cas le système ne sera pas en mesure d'exécuter l'ordre au prix par défaut, mais clôturera la position pour vous au prix le plus favorable suivant à partir du prix cible.",
			row3_p2: "Le cours actuel de l'EUR/USD est de 1,13816/1,13837 (vente/achat). Vous avez établi un ordre d'achat à 1 lot à 1,13837 avec un stop de 100 pips (0,00100).",
			row3_p3: "Lorsque le prix du produit est à 1,13816, votre Stop Loss est à 1,13716. Si le prix de vente du produit monte à 1,13845, le Stop Loss sera mis à jour par la distance que vous avez fixée, et le Stop Loss mis à jour sera à 1,13745.",
			row3_p4: " Au contraire, lorsque le prix du produit glisse de 1,13845 à 1,13745, un stop suiveur sera déclenché et la position sera clôturée à 1,13745.",
			tip: "Point : Habituellement, la plus petite unité de variation du prix d'un instrument financier est appelée point. Dans la plateforme HKDFINANC, il s'agit du dernier chiffre ou de la dernière décimale du prix de l'instrument.",
		},
		sentiment: {
			tit: "Indice émotionnel",
			type1: "Complet",
			type2: "Change (de devises)",
			type3: "Marchandise",
			type4: "Exposants",
			long: "Long terme (financement)",
			short: "Court (finance)",
		},
	},
	layout: {
		aside: {
			nav1: "Transactions",
			nav2: "La situation actuelle du marché",
			nav3: "L'information",
			nav4: "Collège",
			nav5: "Mon",
			newsDialog: {
				title: "Centre d'information",
				type1: "Notification du système",
				type2: "Bulletin",
				allRead: "Marquer tous les textes comme lus",
			},
			settingDialog: {
				title: "Mettre en place",
				nav1: "Conventionnel (armes)",
				nav2: "Afficher les préférences",
				nav3: "Informations sur le système",
				logout: "Déconnexion",
				setting1: "Multilinguisme",
				setting2: "Transactions",
				setting2_tip: "Les postes ouverts ajoutent automatiquement des variétés auto-sélectionnées",
				setting3: "Renvoyer des informations",
				setting3_tip: "Recommandations fonctionnelles",
				setting4: "Authentification par nom réel",
				setting4_tip: "Authentification par nom réel",
				setting5: "Mode Thème",
				setting5_label1: "Obscurément",
				setting5_label2: "Couleur vive",
				setting6: "Couleurs montantes ou descendantes",
				setting6_label1: "éclairé. le vert monte et le rouge descend",
				setting6_label2: "éclairé. le rouge monte, le vert descend",
				setting7: "Paramètres du graphique",
				setting7_label1: "Édition standard",
				setting7_label2: "Édition TradingView",
				setting8: "Déclarations et accords",
				setting8_label1: "Politique de confidentialité",
				setting8_label2: "Déclaration d'information sur les produits",
				setting8_label3: "Déclaration de divulgation des risques",
				setting8_label4: "Accord avec le client",
				feedback_title: "Recommandations fonctionnelles",
			}
		},
		footer: {
			hot: "Variétés populaires",
			sort: "Assortiment",
			code: "Codification",
			buy_price: "Prix d'achat",
			sell_price: "Prix du vendeur",
			chg: "l'augmentation ou la diminution du prix",
		},
		header: {
			place_search: "Recherche de variétés commerciales",
			balance: "Solde disponible",
			balance_tip: "Solde disponible : montant d'argent sur le compte qui peut être utilisé pour ouvrir de nouvelles positions.",
			profit_loss: "Cire et décroissance",
			asset_view: "Aperçu des actifs",
			netValue: "Valeur nette",
			netValue_tip: "Valeur nette : valeur actuelle du compte, y compris les gains et les pertes sur toutes les positions.",
			marginLevel: "Niveau de marge",
			margin: "Marge ",
			margin_tip: "Marge : montant requis pour ouvrir et maintenir une position dans une devise étrangère.",
			maintainsMargin: "Marge de maintenance",
			maintainsMargin_tip: "Marge de maintien : Le montant minimum de marge qui doit être maintenu sur votre compte pendant que vous maintenez toutes vos positions.",

		},
	},
	table: {
		label_name: "Nom",
		label_buyPrice: "Prix de l'offre",
		label_sellPrice: "Taux de vente",
		label_variableValue: "Valeur du changement",
		trade: "Transactions",
		label_code: "Code produit",
		label_title: "Nom (d'une chose)",
		label_start: "Signification",
		label_country: "Nations",
		label_event: "événement",
		label_previous: "Valeur précédente",
		label_predictive: "Valeur projetée",
		label_announced: "Valeur publiée",
		label_stop_loss_min: "Distance minimale pour les ordres stop loss",
		label_difference: "Siffusion dynamique",
		label_contract_size: "Taille du contrat",
		label_max_hand: "Taille maximale du lot pour une transaction unique",
		label_min_hand: "Taille minimale du lot",
		label_trade_week: "Calendrier hebdomadaire des échanges",
		label_week: "1 semaine",
		label_month: "1 Janvier",
		label_quarter: "1 saison (sports)",
		label_dailyTrend: "Tendance journalière",
		label_tradeId: "ID de la transaction",
		label_breed: "Assortiment",
		label_tradeType: "Type de transaction",
		label_currency: "Monnaie",
		label_amount: "Somme d'argent",
		label_balance: "Soldes",
		label_type: "Typologie",
		label_time: "Fois",
		label_orderNumber: "Numéro de commande",
		label_pointer_number: "Nombre ordinal",
		label_price_money: "Prix d'ouverture",
		label_stop_win_price: "Prendre des bénéfices",
		label_stop_lose_price: "Stop-loss",
		label_openTime: "Heure d'ouverture",
		label_profit_loss: "Cire et décroissance",
		label_sell_buy: "Prix demandé/acheté",
		label_chg: "L'augmentation ou la diminution du prix",
		label_sell_price: "Prix du vendeur",
		label_buy_price: "Prix d'achat",
		label_condition: "Condition préalable",
	},
	form: {
		label_feedback: "Questions et recommandations",
		place_feedback: "Veuillez saisir votre question ou suggestion",
		label_img: "Photographie",
		label_img_tip: "Facultatif, fournir une capture d'écran du problème",
		feedback_tip: "Si vous avez une question urgente, veuillez contacter",
		online: "Service clientèle en ligne",
		label_name: "Nom et prénom",
		place_name: "Veuillez saisir votre nom",
		label_email: "Adresse électronique",
		place_email: "Adresse électronique",
		message_email: "Veuillez saisir votre adresse électronique",
		label_nationality: "La citoyenneté籍",
		place_nationality: "Veuillez indiquer votre nationalité",
		place_questionType: "Type de problème",
		message_questionType: "Veuillez sélectionner le type",
		questionType0: "Plate-forme de négociation",
		questionType1: "Produits et tarifs",
		questionType2: "S'abonner aux analyses de marché quotidiennes",
		questionType3: "Service client",
		questionType4: "Le reste",
		place_question: "Votre question",
		message_question: "Veuillez saisir une question",
		submit_question: "Soumission des questions",
		label_phone: "Numéro de téléphone mobile",
		place_phone: "Veuillez saisir votre numéro de téléphone portable",
		message_phone: "Veuillez saisir votre numéro de téléphone portable",
		label_password: "Cryptographique",
		place_password: "Veuillez saisir votre mot de passe",
		message_password: "Veuillez saisir votre mot de passe",
		label_confirmPassword: "Confirmer le mot de passe",
		place_confirmPassword: "Veuillez saisir à nouveau votre mot de passe",
		message_confirmPassword: "Veuillez saisir à nouveau votre mot de passe",
		label_captcha: "CAPTCHA, un type de test défi-réponse (informatique)",
		place_captcha: "Veuillez saisir le code de vérification",
		message_captcha: "Veuillez saisir le code de vérification",
		get_captcha: "Obtenir le CAPTCHA",
		label_inviteId: "Code d'invitation",
		place_inviteId: "Code d'invitation (facultatif)",
		to: "Jusqu'à",
		start_time: "Heure de début",
		end_time: "Heure de fin",
		label_cardNumber: "Numéro de certificat",
		place_cardNumber: "Veuillez saisir votre numéro d'identification",
		message_cardNumber: "Veuillez saisir votre numéro d'identification",
		label_cardMain: "Recto du document",
		message_cardMain: "Veuillez saisir le recto du document",
		label_cardBack: "Le verso d'un document",
		message_cardBack: "Veuillez indiquer le verso du document",
		confirm_modify: "Confirmation des changements",
		label_realName: "Nom complet",
		place_realName: "Veuillez saisir votre nom",
		message_realName: "Veuillez saisir votre nom complet",
		label_firstName: "Nom de famille",
		place_firstName: "Veuillez saisir votre nom de famille",
		message_firstName: "Veuillez saisir votre nom de famille",
		label_lastName: "Nom (d'une chose)",
		place_lastName: "Veuillez saisir votre deuxième prénom",
		message_lastName: "Veuillez saisir un nom",
		label_birthday: "La date de naissance",
		place_birthday: "Veuillez indiquer votre date de naissance",
		message_birthday: "Veuillez indiquer votre date de naissance",
		place_nowPsd: "Veuillez saisir votre mot de passe actuel",
		message_nowPsd: "Veuillez saisir votre mot de passe actuel",
		place_newPsd: "Veuillez saisir un nouveau mot de passe",
		message_newPsd: "Veuillez saisir un nouveau mot de passe",
		place_crmPsd: "Veuillez confirmer le nouveau mot de passe",
		message_crmPsd: "Veuillez confirmer le nouveau mot de passe",
		label_breed: "Assortiment",
		place_breed: "Veuillez saisir une espèce",
		label_phase: "(Coll.) échouer (un étudiant)",
		label_buyPrice: "Prix d'achat",
		label_sellPrice: "Prix du vendeur",
		label_do: "En raison de",
		label_height: "Supérieur à",
		label_low: "Est inférieur à",
		label_equal: "Ou égal à",
		labelWalletName: "Nom du portefeuille",
		messageWalletName: "Veuillez saisir le nom de votre portefeuille",
		placeWalletName: "Veuillez saisir le nom de votre portefeuille",
		labelWalletAddress: "Adresse du portefeuille",
		messageWalletAddress: "Veuillez saisir l'adresse de votre portefeuille",
		placeWalletAddress: "Veuillez saisir l'adresse de votre portefeuille",
		labelAmount: "Somme d'argent",
		messageAmount: "Veuillez saisir le montant",
		placeAmount: "Veuillez saisir le montant",
		placeTimeLimit: "Veuillez indiquer une limite de temps",
		messageTimeLimit: "Veuillez indiquer une limite de temps",
	},
	pay: {
		title: "Accès facile aux fonds",
		p1: "Une variété de canaux de paiement couramment utilisés, permettant des dépôts et des retraits rapides.",
		p2: "Certains modes de paiement peuvent ne pas être disponibles dans votre pays.",
		p3: "Bienvenue dans les services de HKDFINANC",
		btn: "Ouvrir un compte et négocier maintenant",
	},
	header: {
		tip1: "Le trading à effet de levier est un produit financier complexe qui présente un risque élevé de perte rapide.",
		btn1: "Plate-forme de négociation",
		btn2: "Déconnexion",
		btn3: "S'inscrire",
		BigUint64Array: "Commerce maintenant",
	},
	footer: {
		help: "Nous contacter",
		tip1: "Suivez notre communauté",
		tip2: "Veuillez noter que HKDFINANC n'a pas créé de groupe Telegram officiel et que tout groupe Telegram formé au nom de HKDFINANC est suspecté d'être une usurpation d'identité.",
		tip3: "AVERTISSEMENT SUR LES RISQUES : La négociation peut entraîner la perte de tous vos fonds. La négociation de produits dérivés de gré à gré ne convient pas à tout le monde. Veuillez lire attentivement nos documents juridiques avant d'utiliser nos services et assurez-vous de bien comprendre les risques encourus avant d'effectuer des transactions. Vous ne possédez ni ne détenez en réalité aucun des actifs sous-jacents.",
		tip4: "HKDFINANC ne fournit aucun conseil, recommandation ou opinion sur l'achat, la détention ou la vente. Tous les produits que nous proposons sont des produits dérivés de gré à gré basés sur des actifs mondiaux. Tous les services fournis par HKDFINANC sont basés sur l'exécution d'ordres de transaction uniquement.",
		tip5: "HKDFINANC est une marque commerciale partagée par un certain nombre d'entreprises et exploitée par les entreprises suivantes :",
		tip6: "HKDFINANC International Ltd est l'émetteur des produits financiers décrits ou disponibles sur ce site. HKDFINANC International Ltd est autorisé et réglementé par la Financial Services Commission (FSC) de l'île Maurice sous le numéro de licence GB20025791, et son siège social est situé au 6 St Denis Street, 1st Floor River Court, Port Louis 11328, Mauritius",
		tip7: "HKDFINANC Global Pty Ltd est enregistrée sous le numéro ABN 90 149 011 361, licence de services financiers Festland (AFSL) numéro 398528.",
		tip8: "HKDFINANC Holding Ltd est autorisée et réglementée par l'Autorité monétaire des îles Caïmans (CIMA) sous le numéro de licence SIB 1612446.",
		tip9: "Les informations contenues dans ce site ne sont pas destinées aux résidents des États-Unis d'Amérique, du Canada, du Japon ou de la Nouvelle-Zélande. Elles ne sont pas non plus destinées à être utilisées par quiconque dans un pays ou une juridiction où leur publication ou leur utilisation serait contraire à la législation ou à la réglementation locale. Veuillez noter que l'anglais est la langue principale de nos services et la langue juridiquement contraignante de tous nos documents contractuels. Les traductions dans d'autres langues ne sont données qu'à titre de référence et, en cas de divergence entre les versions anglaise et chinoise, c'est la version anglaise qui prévaut.",
		tip10: "Cryptage de communication sécurisé SSL. Copyright © HKDFINANC, Tous droits réservés.",
		link1: "Politique de confidentialité",
		link2: "Déclaration d'information sur les produits",
		link3: "Procédure de réclamation",
		link4: "Déclaration de divulgation des risques",
		link5: "Accord avec le client",
		toTop: "Collant (d'un fil de discussion d'un forum Internet, etc.)",
	},
	nav: {
		tit1: "Offres",
		tit2: "Transactions",
		tit3: "Investissement",
		tit4: "À propos de nous",
		nav1: "Change (de devises)",
		nav2: "Exposants",
		nav3: "Marchandise",
		nav4: "Stock (marché)",
		nav5: "Plate-forme de négociation",
		nav6: "Stratégie commerciale",
		nav7: "Perspectives commerciales",
		nav8: "Calendrier financier",
		nav9: "Nouvelles en temps réel",
		nav10: "Devis en temps réel",
		nav11: "Indice émotionnel",
		nav12: "La gestion des risques",
		nav13: "Spécifications du contrat",
		nav14: "Introduction à l'investissement",
		nav15: "Aperçu de l'investissement",
		nav16: "Academy",
		nav17: "À propos de HKDFINANC",
		nav18: "Prix et distinctions",
		nav19: "Centre des médias",
		nav20: "La sécurité financière",
		nav21: "Taxes et redevances",
		nav22: "Affiliates",
		nav23: "Nous contacter",
		nav24: "Problèmes courants",
		nav25: "Centre d'aide",
	},

	city: {
		albania: "Albanie",
		algeria: "Algérie",
		argentina: "Argentine",
		armenia: "Arménie",
		australia: "Australie",
		pakistan: "Pakistan",
		austria: "Autriche",
		bahrain: "Bahreïn",
		belgium: "Belgique",
		bosnia_and_Herzegovina: "Bosnie _ Herzégovine",
		brazil: "Brésil",
		brunei: "Brunei",
		bulgaria: "Bulgarie",
		cambodia: "Cambodge",
		canada: "Au Canada",
		cameroon: "Cameroun",
		chile: "Chili",
		colombia: "Colombie",
		costa_Rica: "Costa Rica",
		croatia: "Croatie",
		cyprus: "Chypre",
		czech_Republic: "République tchèque",
		denmark: "Danemark",
		dominican_Republic: "République dominicaine",
		egypt: "Égypte",
		estonia: "Estonie",
		ethiopia: "Éthiopie",
		finland: "Finlande",
		france: "France",
		georgia: "Géorgie",
		germany: "Allemagne",
		ghana: "Ghana",
		greece: "Grèce",
		guyana: "Guyana",
		honduras: "Honduras",
		hong_Kong_China: "Hong Kong, Chine",
		hungary: "Hongrie",
		iceland: "Islande",
		ireland: "Irlande",
		italy: "Italie",
		india: "Inde",
		indonesia: "Indonésie",
		israel: "Israël",
		iran: "Iran",
		iraq: "Irak",
		japan: "Japon",
		kazakstan: "Kazakhstan",
		kenya: "Kenya",
		korea: "Corée du Sud",
		kuwait: "Koweït",
		kyrgyzstan: "Kirghizistan",
		laos: "Laos",
		latvia: "Lettonie",
		lithuania: "Lituanie",
		luxembourg: "Luxembourg",
		macao_China: "Macao, Chine",
		macedonia: "Macédoine",
		malaysia: "Malaisie",
		malta: "Malte",
		mexico: "Mexique",
		moldova: "Moldavie",
		monaco: "Monaco",
		mongolia: "Mongolie",
		montenegro: "République du Monténégro",
		morocco: "Maroc",
		myanmar: "Myanmar",
		netherlands: "Pays _ Ba",
		new_Zealand: "Nouvelle _ Zélande",
		nepal: "Népal",
		nigeria: "Nigéria",
		norway: "Norvège",
		oman: "Oman",
		palestine: "Palestine",
		panama: "Panama",
		paraguay: "Paraguay",
		peru: "Pérou",
		philippines: "Philippines",
		poland: "Pologne",
		portugal: "Portugal",
		puerto_Rico: "Porto Rico",
		qatar: "Qatar",
		romania: "Roumanie",
		russia: "Russie",
		republic_of_Trinidad_and_Tobago: "République de Trinité _ et _ Tobago",
		rwanda: "Rwanda",
		saudi_Arabia: "Arabie saoudite",
		serbia: "Serbie",
		singapore: "Singapour",
		slovakia: "Slovaquie",
		slovenia: "Slovénie",
		south_Africa: "Afrique du Sud",
		spain: "Espagne",
		sri_Lanka: "Sri Lanka",
		sweden: "Suède",
		switzerland: "Suisse",
		taiwan_China: "Taïwan, Chine",
		tajikistan: "Tadjikistan",
		tanzania: "Tanzanie",
		thailand: "Thaïlande",
		turkey: "Turquie",
		turkmenistan: "Turkménistan",
		ukraine: "Ukraine",
		united_Arab_Emirates: "Émirats arabes unis",
		united_Kingdom: "Royaume _ Uni",
		united_States: "États _ Unis",
		uzbekistan: "Ouzbékistan",
		venezuela: "Venezuela",
		vietnam: "Vietnam",
		afghanistan: "Afghanistan",
		angola: "Angola",
		azerbaijan: "Azerbaïdjan",
		bangladesh: "Bangladesh",
		belarus: "Biélorussie",
		belize: "Belize",
		benin: "Bénin",
		bhutan: "Bhoutan",
		bolivia: "Bolivie",
		botswana: "Botswana",
		british_Virgin_Islands: "Îles Vierges britanniques",
		burkina_Faso: "Burkina Faso",
		burundi: "Burundi",
		cape_Verde: "Cap _ Vert",
		cayman_Islands: "Îles Caïmans",
		central_African_Republic: "République centrafricaine",
		chad: "Tchad",
		comoros: "Comores",
		the_Republic_of_the_Congo: "Congo (Bu)",
		democratic_Republic_of_the_Congo: "Congo (or)",
		djibouti: "Djibouti",
		ecuador: "Équateur",
		el_Salvador: "Salvador",
		equatorial_Guinea: "Guinée équatoriale",
		eritrea: "Érythrée",
		fiji: "Fidji",
		gabon: "Gabon",
		gambia: "Gambie",
		greenland: "Groenland",
		guatemala: "Guatemala",
		guinea: "Guinée",
		haiti: "Haïti",
		isle_of_Man: "Île de Man",
		cote_d_Ivoire: "Côte d'Ivoire",
		jamaica: "Jamaïque",
		jordan: "Jordanie",
		lebanon: "Liban",
		lesotho: "Lesotho",
		liberia: "Libéria",
		libya: "Libye",
		madagascar: "Madagascar",
		malawi: "Malawi",
		maldives: "Maldives",
		mali: "Mali",
		mauritania: "Mauritania",
		mauritius: "Maurice",
		mozambique: "Mozambique",
		namibia: "Namibie",
		nicaragua: "Nicaragua",
		republic_of_Niger: "Niger",
		north_Korea: "Corée du Nord",
		reunion: "La réunion",
		san_Marino: "Saint _ Marin",
		senegal: "Sénégal",
		sierra_Leone: "Sierra Leone",
		somalia: "Somalie",
		sudan: "Soudan",
		suriname: "Suriname",
		eswatini: "Swaziland",
		syria: "Syrie",
		togo: "Togo",
		tonga: "Tonga",
		tunisia: "Tunisie",
		united_States_Virgin_Islands: "Îles Vierges américaines",
		uganda: "Ouganda",
		uruguay: "Uruguay",
		vatican: "Le Vatican",
		yemen: "Yémen",
		yugoslavia: "Yougoslavie",
		zambia: "Zambie",
		zimbabwe: "Zimbabwe",
		china: "Chine",
	}
};
