export default {
	crypto: {
		title: { "0": "Krypto kaufen", "1": "Krypto kaufen", "2": "C2C" },
		list: {
			"0": "C2C-Handel",
			"1": "Kaufen",
			"2": "Verkaufen",
			"3": "Werbetreibende (Abschlussquote)",
			"4": "Handel",
			"5": "Preis",
			"6": "Verfügbar",
			"7": "Grenzwert",
			"8": "Zahlungsmethode",
			"9": "Handel",
			"10": "Der Werbetreibende kann Transaktionsbeschränkungen für die Anzeige festlegen, und Sie werden den Grund für die Einschränkung sehen, nachdem Sie sich angemeldet haben.",
		},
		buy: {
			"0": "Krypto kaufen",
			"1": "Krypto verkaufen",
			"2": "Geschichte",
			"3": "Unterstützung",
			"4": "Bezahlen",
			"5": "Empfangen",
			"6": "Zahlungsart auswählen",
			"7": "Est. Ankunftszeit",
			"8": "GEBÜHRE",
			"9": "Bestelldetails",
			"10": "Zahlungsmethode",
			"11": "Erfahren Sie mehr",
			"12": "Preis",
			"13": "Insgesamt (Gebühr inbegriffen)",
			"14": "Haftungsausschluss",
			"15": "Sie verlassen HKDFinanc und werden zu Mercuryo weitergeleitet, einem lizenzierten Finanzinstitut, das Kredit-/Debitkartenzahlungsdienste als Drittanbieter anbietet. Alle Risiken, die während der Nutzung des Dienstes auftreten, gehen zu Lasten von Mercuryo. Bitte lesen und stimmen Sie den Nutzungsbedingungen zu, bevor Sie fortfahren.",
			"16": "Ich habe die Nutzungsbedingungen gelesen und bin damit einverstanden",
			"17": "Du wirst bekommen",
			"18": "Absenden",
			"19": "Bitte geben Sie den Betrag ein",
			"20": "Unzureichender Kontostand",
			"21": "Verfügbar",
			"22": "Spot-Konto verfügbar",
			23: "Single purchase limit",
			24: "Minimum",
			25: "Maximum",
		},
	},
	common: {
		confirm: "Definieren.",
		cancel: "Stornierungen",
		tip: "Auf etw. aufmerksam machen",
		place_select: "Bitte auswählen",
		submit: "Vorlegen (einen Bericht usw.)",
		more: "Mehr",
		deposit: "Kaution",
		real_trade: "Real Deal",
		paper_trade: "Demo-Handel",
		build_paperTrade: "Ein Demokonto erstellen",
		start_trade: "Jetzt mit dem Handel beginnen",
		app_name: "HKDFINANC",
		success: "Erfolge！",
		dataSources_name: "Trading Central",
		reset: "Reprovision",
		iknow: "Ich hab's!",
		noData: "Keine Daten verfügbar",
		modify: "Änderungen",
		pingcang: "Eine Position schließen",
		all: "Vollständig",
		placeEnter: "Bitte geben Sie ein",
		countryCode: "Ländercode",
	},
	addnew: {
		realFirst: "Bitte führen Sie zuerst Identitätsauthentifizierung durch",
		payFirst: "Bitte legen Sie zuerst das Transaktionskennwort fest",
		historyOrder: "Historische Ordnungen",
		forgot: "Passwort vergessen",
		contractTrade: "Hebelvertrag",
		loginpassTit: "Login Passwort ändern",
		paypassTit: "Passwort für Auszahlungen ändern",
		paypassTit1: "Bitte geben Sie Ihr Auszahlungskennwort ein",
		paypassTit2: "Initialisieren Sie Ihr Passwort auf Ihr Login-Passwort",
		buyUp: "Aufkaufen",
		buyDown: "Verkaufen",
		addressName1: "Huajin Finance (International) Holding Co., Ltd",
		addressValue1: "Zimmer 1101, 11th Floor, Guanjun Building (ehemals Citibank Building), 3 Garden Road, Central, Hong Kong",
		addressName2: "Hong Kong Zhongcai Financial Investment Co., Ltd.",
		addressValue2: "Zhongcai Center, 131-133 Queen's Road Central, Hongkong",
		addressName3: "Baoxin Financial Holdings Co., Ltd.",
		addressValue3: "Lippo Centre Tower 2, Zimmer 1908, 19th Floor, 89 Queensway, Queensway, Hong Kong",
		loan: "Einen Kredit aufnehmen (z. B. bei einer Bank)",
		repaid: "Zurückgezahlt",
        unpaid: "Nicht-Rückzahlung",
		loanMore: "Ich möchte einen Kredit.",
		immediateRepayment: "Sofortige Rückzahlung",
		accountType1: "Münzkonto",
		accountType2: "Vertragskonto",
		accountType3: "Optionskonto",
		from: "Durch (eine Lücke)",
        to: "Bis",
		get: "Erhalten",
		labelBreed: "Währungstyp",
		placeBreed: "Bitte wählen Sie eine Währung",
		labelTransAccount: "Konto übertragen",
		labelAmount: "Übertragener Betrag",
		placeAmount: "Bitte geben Sie den Überweisungsbetrag ein",
		transferTit:"Kontoübertragungen",
		convertTit:"Münzblitzbörse",
		balanceTit:"Kontostand",
		available: "Verfügbarer Kredit",
        pending: "Sperre",
        equivalent: "Betragen",
		coinTrade: "Münzhandel",
		secondContract: "Zweiter Vertrag (im Sport)",
		number: "Mengen",
		labelNumber: "Mengen",
		placeNumber: "Bitte geben Sie die Anzahl der Lösegelder ein",
		time: "Mal",
		loadAmount: "Erwarteter Darlehensbetrag",
		repaymentCycle: "Zyklus der Darlehensrückzahlung",
		dailyRate: "Tageszinssatz",
		repaymentMethod: "Rückzahlungsmethode",
		loanTip: "Kredite (bitte stellen Sie sicher, dass die Bilder gut sichtbar sind)",
		loanTip1: "Eigentumsnachweis hochladen",
        loanTip2: "Einkommensnachweis (Arbeitsverhältnis)",
        loanTip3: "Angaben zum Kontoauszug",
        loanTip4: "Foto der Vorderseite Ihres Personalausweises hochladen",
        interest: "Interesse (Wunsch, etwas zu wissen)",
		repaymentMethod1: "Zur Rückzahlung in einer Rate fällig",
	},
	params: {
		product_foreign: "Devisen",
		product_shop: "Ware",
		product_number: "Exponenten",
		product_stock: "Aktie (Markt)",
		product_coin: "Verschlüsselt",
		billTypes: [
			{v: 101, name: 'Aufladen (Geld auf eine Karte)'},
			{v: 102, name: 'Einzahlung (z. B. auf ein Bankkonto)'},
			{v: 103, name: 'Einfrieren (Darlehen, Lohn, Preis usw.)'},
			{v: 104, name: 'Auftauen'},
			{v: 105, name: 'Einzahlung (z. B. auf ein Bankkonto)'},
			{v: 106, name: 'Einzahlung (z. B. auf ein Bankkonto)'},
			{v: 201, name: 'Abhebungsstopp'},
			{v: 202, name: 'Geld abheben'},
			{v: 203, name: 'Rückzug Erfolgreich'},
			{v: 204, name: 'Es versäumen, Gelder abzuheben'},
			{v: 205, name: 'Rücknahmegebühr'},
			{v: 206, name: 'Abtreten'},
			{v: 207, name: 'Umschalten'},
			{v: 208, name: 'Münzübertragung'},
			{v: 209, name: 'Münzübertragung'},
			{v: 301, name: 'Vertragsabwicklungsgebühr'},
			{v: 302, name: 'Vertragseinkommen'},
			{v: 303, name: 'Vertragsverluste'},
			{v: 304, name: 'Marge'},
			{v: 305, name: 'Rückzahlung der Kaution'},
			{v: 311, name: 'Kauf von Optionen'},
			{v: 312, name: 'Optionsgewinn'},
			{v: 313, name: 'Option Rückgabe'},
			{v: 314, name: 'Option Bearbeitungsgebühr'},
			{v: 315, name: 'Münzkaufstopp'},
			{v: 316, name: 'Abzug für Münzkäufe'},
			{v: 317, name: 'Münzkauf-Rückgaben'},
			{v: 318, name: 'Münzeinkauf auf Konto'},
			{v: 319, name: 'Münzeinkauf auf Konto'},
			{v: 320, name: 'Münzverkaufsstopp'},
			{v: 321, name: 'Abzug für den Münzverkauf'},
			{v: 322, name: 'Münzen verkaufen Retouren'},
			{v: 323, name: 'Münze Verkaufen an Konto'},
			{v: 324, name: 'Münze Verkaufen an Konto'},
			{v: 325, name: 'Gebühr für Münzverarbeitung'},
			{v: 401, name: 'Bergleute schließen sich an'},
			{v: 402, name: 'Bergwerksrückkehr'},
			{v: 403, name: 'Einnahmen aus Bergbaumaschinen'},
			{v: 404, name: 'Ausgang der Bergbaumaschine'},
			{v: 405, name: 'Ausstiegsgebühr für den Bergbau'},
			{v: 411, name: 'Einnahmen aus Anleihen'},
			{v: 412, name: 'Leihgebühr'},
			{v: 413, name: 'Erfolgreiche Rückzahlung'},
        ],
	},
	message: {
		logout: "Abmelden",
		register_success: "Erfolgreiche Registrierung",
		place_inner_personal: "Bitte geben Sie zuerst Ihre persönlichen Daten ein",
		submit_success: "Erfolgreich abgeschickt！",
		copy_success: "Erfolg kopieren！",
		copy_error: "Fehlgeschlagene Fortpflanzung！",
		modify_success: "Erfolgreich modifiziert",
		no_balance: "Unzureichendes Guthaben zur Deckung der Kaution",
		palce_password: "Bitte geben Sie Ihr Passwort ein",
		trade_success: "Erfolgreiche Transaktion",
		placeEnterAmount: "Bitte geben Sie den Betrag ein",
		tipTit1: "Sind Sie sicher, dass Sie für diese Bestellung bezahlen wollen?",
	},
	web: {
		account: {
			menu1: "Konto-Informationen",
			menu2: "Mein Vermögen",
			menu3: "Konto-Berichte",
			menu4: "Konto Sicherheit",
			menu5: "Benachrichtigungen",
			menu6: "Freizeitzentrum",
			menu7: "Mein Portemonnaie.",
			menu8: "Geld abheben",
			walletAdd: "Neue Geldbörse hinzufügen",
			report: {
				history_tit: "Transaktionsverlaufsbericht",
				stream_tit: "Bericht über die Mittelflüsse",
				desc: "Bitte wählen Sie den entsprechenden Datumsbereich aus, um den Kontobericht zu exportieren. (Sie haben Zugriff auf die Kontoinformationen der letzten 1 Jahr)",
				select_date: "Datumsbereich auswählen",
				week: "In der vergangenen Woche",
				month: "Etwa einen Monat",
				threeMonth: "Letzte drei Monate",
				sixMonth: "Die letzten sechs Monate",
				time: "Mal",
				view_report: "Bericht anzeigen",
				email_send: "Per E-Mail gesendet",
				dialog_tit: "Konto-Berichte",
				dialog_p1: "Die HKDFINANC Holding Ltd ist auf den Kaimaninseln registriert (SIB-Nummer: 1612446) und wickelt Ihre Transaktionen als Handelsgesellschaft ab.",
				tit2: "Mitade Trading History Bericht",
				tit4: "Mitade-Mittelflussbericht",
				tit3: "Konto-Informationen",
				label1: "Kontoname",
				label2: "Konto-ID",
				label3: "Konto Währung",
				label4: "Meldezeit",
				label5: "Berichtszeitraum",
				label6: "Cashflow",
				empty: "Sie haben keine abgeschlossenen Geschäfte",
				email_des: "Der Bericht wird an Ihre E-Mail Adresse geschickt",
				tab1: "Berichterstattung",
				tab2: "Tagesbilanz",
				tab3: "Monatsabrechnung",
			},
			account: {
				tit1: "Willkommen bei HKDFINANC",
				tit2: "Vollständige Identitätsprüfung für den Handel mit echten Einlagen",
				p1: "Eröffnen Sie ein Live-Handelskonto",
				p2: "Authentifizierung",
				btn1: "Authentifizierung",
				tit3: "Persönliche Informationen",
				label_name: "Vorname und Nachname",
				label_email: "E-Mail Adresse",
				label_address: "Wohnadresse",
				tit4: "Konto-Informationen",
				label_account: "Handelskonto (echte Transaktion)",
				label_currency: "Basiswährung (reale Transaktionen)",
				label_company: "Girokonto Unternehmen",
				company_tip: "HKDFINANC Holding Ltd,HKDFINANC Holding Ltd Die HKDFINANC Group of Companies ist von der Cayman Islands Monetary Authority (CIMA) zugelassen und genehmigt.",
				tit5: "Eröffnen Sie ein Live-Handelskonto",
				tit6: "Verbesserung der Information",
				tit7: "Füllen Sie Ihre Basisinformationen aus",
				tit8: "Risikobewertung",
				tit9: "Einschätzung der Risikotoleranz beim Handel",
				tit10: "Authentifizierung",
				tit11: "Abschluss der Identitätsüberprüfung in Übereinstimmung mit den gesetzlichen Anforderungen",
				p3: "Bitte wählen Sie einen der folgenden Belege zur Überprüfung aus",
				type1: "Personalausweise",
				type2: "Führerschein",
				type3: "Reisepass",
				p4: "Ich bestätige, dass alle Angaben und Unterlagen vollständig, wahrheitsgetreu und korrekt sind, und verpflichte mich",
				next: "Der nächste Schritt",
				btn2: "Akkreditierung bestanden",
				modify_personal_tit: "Änderung der persönlichen Daten",
			},
			activity: {
				tit: "Freizeitzentrum",
				desc: "Sie können an Aktivitäten teilnehmen, um Belohnungen zu erhalten, die Ihr Investitionseinkommen erhöhen",
			},
			capital: {
				real_trade: "Real Deal",
				label_nowAccount: "Laufendes Konto",
				label_tradeAccount: "Handelskontonummer",
				label_currency: "Basiswährung",
				deposit_withdraw_record: "Aufzeichnungen über Einzahlungen und Abhebungen",
				capital_flow_details: "Einzelheiten der Geldströme",
			},
			notification: {
				tit: "Einstellungen für Benachrichtigungen",
				tit1: "Offene Benachrichtigungskanäle",
				email: "E-Mail Adresse",
				message: "Textnachrichten",
				push: "Drücken.",
				verify: "Validieren (eine Theorie)",
				verified: "Validiert",
				p1: "Bitte öffnen/bestätigen Sie die folgenden Benachrichtigungskanäle, um über wichtige Neuigkeiten informiert zu werden!",
				type1: "Marktplatz",
				tit2: "Benachrichtigung über Marketingaktivitäten",
				desc2: "Informationen über Sonderangebote der Plattform, Betriebsaktivitäten und andere Neuigkeiten",
				tit3: "Betriebsanleitung",
				desc3: "Reichhaltiges und spezialisiertes Wissensmaterial",
			},
			security: {
				tit: "Kryptographisch",
				isSet: "Konfiguriert",
				set: "Loslegen und einrichten",
				verify: "Validieren (eine Theorie)",
				verified: "Validiert",
				tit1: "Sicherheitsbescheinigung",
				email: "E-Mail Adresse",
				phone: "Mobiltelefonnummer",
				phone_tit: "Mobiltelefonnummer Verbindlich",
				password_tit: "Ihr Passwort ändern",
			},
		},
		market: {
			hot: "En vogue",
			prev: "Vorhergehende Seite",
			next: "Nächste Seite",
			line: "Time-Sharing",
			main_subplot: "Haupt- und Nebendiagrammindikatoren",
			main: "Hauptdiagrammindikator",
			subplot: "Diagrammindikator",
			go_trade: "Weiter zur Transaktionsseite",
		},
		news: {
			tip: "Haftungsausschluss: Die obigen Informationen sind nur allgemeine Hinweise und dürfen nicht als Grundlage für Handelsentscheidungen verwendet werden.",
		},
		trade: {
			state1: "Beteiligungen",
			state2: "Ausstehender Auftrag",
			state3: "Geschichten",
			state: "Stand der Dinge",
			state4: "Geschlossene Position",
			state5: "Zurückgezogen",
			type: "Typologie",
			type1: "Vollständig",
			type2: "Erreichen (ein Preis in einer Auktion)",
			type3: "Einkaufen",
			left_label1: "Selbstbedienung",
			left_label2: "Kürzlich gesehen",
			left_type1: "Kompaktes säulenförmiges Modell",
			left_type2: "Lockeres säulenförmiges Muster",
			left_type3: "Tabelle anzeigen",
			all: "Vollständig",
			right_bp: "Die Ernte von gestern",
			right_sp: "Moderne Zeit",
			right_height: "Oberste",
			right_low: "Niedrigste",
			remind_tit: "Neue Warnungen",
			remindList: "Erinnerungsliste",
			remind_btn: "Neue Warnungen",
			right_tab1: "Geschäfte",
			right_tab2: "Einzelheiten",
			right_sell: "Erreichen",
			right_buy: "Einkaufen",
			right_sell1: "Erreichen",
			right_buy1: "Einkaufen",
			right_type: "Typologie",
			right_type1: "Marktpreisliste",
			right_type2: "Ausstehender Auftrag",
			right_priceMoney: "Preis für ausstehende Aufträge",
			right_pointerNumber: "Anzahl (Lose)",
			right_lever: "Hebeleisen",
			right_balance: "Verfügbares Guthaben",
			right_stop_profit: "Gewinn mitnehmen",
			right_stop_loss: "Stop-Loss",
			right_profit_loss: "Wachsend und abnehmend",
			now_order: "Jetzt bestellen",
			confirm_order: "Bestellung bestätigen",
			right_tit1: "Handelsstrategie",
			right_rinei: "In ein paar Tagen",
			right_short: "Kurzfristig",
			right_middle: "Mittelfristig",
			right_time: "Freigabezeit",
			right_tit2: "Alternative Strategie",
			right_tit3: "Technische Überprüfung",
			right_tit4: "Zahlen",
			right_tit5: "Handelsstimmung",
			right_label_sell: "Verkäufer (von Waren)",
			right_label_buy: "Käufer",
			right_tip: "Nur zu Informationszwecken, keine Stellungnahme der Abteilung",
			right_tip2: "Alle 15 Minuten aktualisiert",
			right_tit6: "Preisvariation",
			minute: "Minuten",
			today: "Zum gegenwärtigen Zeitpunkt",
			right_tit7: "Bereich der Preisänderung",
			now_price: "Aktueller Preis",
			right_low_price: "Niedrigster Preis",
			right_height_price: "Höchster Preis",
			right_tit8: "Informationen zum Vertrag",
			right_tit9: "AUD/CHF",
			right_label1: "Anzahl der einzelnen Transaktionen",
			right_label2: "Maximale Hebelwirkung",
			right_label3: "Vertragsgröße",
			right_label4: "Maximale Gesamtzahl von Positionen",
			right_label5: "Schwimmende Verbreitung",
			right_label6: "Nachtladung",
			hand: "Person, die für bestimmte Arten von Arbeiten qualifiziert ist",
			right_collection_time: "Erhebungszeit",
			right_tip3: "Gebührenbasis: aus dem Gesamtvolumen der Transaktion",
			right_label7: "Margenverhältnis",
			right_label8: "Instandhaltungskostenquote",
			right_label9: "Handelszeiten",
			right_label10: "Aktuelle Handelssitzung",
			right_label11: "Nächste Handelssitzung",


		},
	},
	home: {
		banner_p1: "",
		banner_p2: "Handel mit Devisen",
		banner_p3: "Rohstoffen,",
		banner_p4: "Indizes, Aktien und mehr!",
		banner_p5: "Sie können die heißesten Finanzmärkte der Welt erkunden. HKDFINANC bietet 0-Provision und einen sehr wettbewerbsfähigen Preisunterschied.",
		hot: "En vogue",
		tit1: "Freundlichere Transaktionen",
		p1: "Einfache und intuitive Schnittstelle für leichte Bedienung",
		p2: "Web-, Mobile App- und Desktop-Support, Handel auf Knopfdruck",
		p3: "Risikomanagementinstrumente wie Stop-Loss/Trailing-Stop",
		p4: "Mehrere technische Charts und Finanzkalender, Echtzeit-Nachrichten",
		p5: "E-Mail-, SMS- und Push-Benachrichtigungen in Echtzeit",
		p6: "Kontinuierliche Verbesserung für ein besseres Handelserlebnis",
		btn1: "Erkunden Sie unsere Plattformen",
		row3_tit: "Jederzeit und überall frei handeln",
		row3_tip: "Web-, Mobile App- und Desktop-Support, Handel auf Knopfdruck",
		row3_tit2: "Code zum Herunterladen scannen",
		row3_tit3: "Desktop",
		row3_tit4: "Mobil",
		regular_tit: "Vertrauenswürdige internationale Plattform",
		regular_tip: "Wir sind bestrebt, unseren Kunden ein sicheres und verantwortungsvolles Handelsumfeld zu bieten.。",
		regular_tit1: "Von maßgeblichen Organisationen reguliert",
		regular_p1: "Von den Behörden reguliert und zugelassen und von Kunden weltweit als vertrauenswürdig eingestuft",
		regular_tit2: "Schutz der finanziellen Sicherheit",
		regular_p2: "Die Einlagen von Privatkunden werden gemäß den aufsichtsrechtlichen Anforderungen nach Bedarf auf Treuhandkonten getrennt.",
		regular_tit3: "Schutz vor negativem Saldo",
		regular_p3: "Bieten Sie mit Konto negativen Saldo Schutz, negatives Gleichgewicht in einer fristgerechten Art und Weise zu löschen, die Null, so dass Ihr Verlust nicht mehr als die Höhe der Einreise, Frieden des Geistes Transaktionen",
		regular_tit4: "7*24-Stunden-Kundendienst",
		regular_p4: "Aufrichtiges und professionelles Kundendienstteam, 24 Stunden Online-Support, gerne lösen wir jedes Problem, das Sie haben!",
		service_tit: "Weitere Dienstleistungen für Sie",
		service_tit1: "Handelsstrategie",
		service_p1: "Echtzeit-Handelsstrategien, die Ihnen helfen, die neuesten Markttrends zu verstehen und das Timing des Handels besser zu erfassen.",
		service_tit2: "Handelswissen",
		service_p2: "Lernen Sie kostenlos mit HKDFINANC zu handeln und verbessern Sie Ihre Handelsfähigkeiten.",
		service_tit3: "Risikomanagement",
		service_p3: "Informieren Sie sich über die kostenlosen Risikomanagement-Tools von HKDFINANC, um Ihr Vermögen besser zu schützen.",
		step_tit: "Einfache und bequeme Eröffnung eines Kontos",
		step_tip: "Drei einfache Schritte zur Eröffnung eines Kontos in wenigen Minuten",
		step_tit1: "Einschreibung",
		step_p1: "Füllen Sie die Informationen aus und reichen Sie Ihre Bewerbung ein",
		step_tit2: "Kaution",
		step_p2: "Schnelle Einzahlung von Geldern über eine Vielzahl von Methoden",
		step_tit3: "Aufnahme des Handels",
		step_p3: "Entdecken Sie Handelsmöglichkeiten und erteilen Sie schnell Aufträge",
		award_tit: "Unsere Errungenschaften",
		award_p1: "Wir haben immer nach Spitzenleistungen gestrebt und uns verpflichtet, unseren Kunden hochwertige Transaktionsdienstleistungen zu bieten.",
		award_p2: "Die Marke HKDFINANC fühlt sich geehrt, diese prestigeträchtige Auszeichnung von einer angesehenen Institution der Branche zu erhalten, die die kontinuierlichen Bemühungen und das Engagement des Teams für unsere Kunden würdigt.",
		row7_tit: "Globale Finanzinformationen in Echtzeit",
		row7_tit1: "Nachrichten in Echtzeit",
	},
	learn: {
		education: {

		}
	},
	login: {
		click_download: "Klicken Sie zum Herunterladen",
		p1: "Jederzeit und überall handeln",
		p2: "Schneller und intelligenter handeln",
		p3: "0 Provisionshandel",
		p4: "Die heißeste Investition der Welt",
		renderDom1: "Devisen",
		renderDom2: "US-Aktienmarkt",
		renderDom3: "Platin",
		renderDom4: "Rohöl",
		renderDom5: "Exponenten",
		renderDom6: "Kryptowährung",
		login: "Sich anmelden",
		elail: "Posteingang",
		phone: "Mobiltelefonnummer",
		no_account: "Kein Konto？",
		go_register: "Zur Registrierung gehen",
		register: "Einschreibung",
		now_register: "Jetzt registrieren",
		have_account: "Sie haben bereits ein Konto？",
		go_login: "Zur Anmeldung gehen",
	},
	about: {
		serviceFee: "Servicegebühr",
		introduction: "Plattform Einführung",
		trade_platform: "Handelsplattform",
		complaint_process: "Verfahren für Reklamationen",
		page: "Web-Seite",
		place_search: "Suchen Sie nach dem, was Sie wissen wollen",
		no_answer: "Sie können die gewünschte Antwort nicht finden?",
		contact_us: "Kontakt",
		awards: {
			h1: "Auszeichnungen und Ehrungen",
			p1: "In unserem ständigen Streben nach Spitzenleistungen und unserem Engagement, unseren Kunden hochwertige Transaktionsdienstleistungen zu bieten, fühlt sich die Marke HKDFINANC geehrt, von einer angesehenen Organisation der Branche ausgezeichnet worden zu sein, womit die kontinuierlichen Bemühungen und das Engagement des Teams für unsere Kunden anerkannt werden.",
			p2: "，Oder erleben Sie unsere preisgekrönte Online-Handelsplattform sofort!",
			name1: "Bestes Maklerbüro in Lateinamerika",
			name2: "Bester CFD-Broker",
			name3: "Bester Fintech-Broker",
			name4: "Top 10 der einflussreichsten Unternehmen im Jahr 2021",
			name5: "Transparentester Makler",
			name6: "Ultimate Financial Technology Award",
		},
		charges: {
			h1: "Gebühren und Kosten",
			p1: "HKDFINANC möchte seinen Kunden einen kosteneffizienteren Handelsservice bieten, um die Kundenzufriedenheit zu erhöhen. Wir bemühen uns, unsere Gebühren und Kosten offen und transparent zu gestalten und keine versteckten Gebühren zu erheben. Die wichtigsten Transaktionskosten für Kunden sind die Geld-/Briefspanne und die Zinsen für Übernachtpositionen.",
			h2: "Erstens können Sie beim Handel mit HKDFINANC von folgenden Vorteilen profitieren",
			li1: "Sofortiges Produktangebot",
			li2: "Fachliche und technische Indikatoren",
			li3: "Exklusive Marktanalyse",
			free: "Freeware",
			view: "Nachschlagen",
			h3: "Geld-Brief-Spanne",
			p2: "HKDFINANC erhebt eine Geld-/Briefspanne als Vergütung für seine Dienstleistungen. Diese Gebühr ist in der Produktnotierung enthalten, und Sie zahlen den Spread, wenn Sie eine Position eröffnen. Da die Spread-Gebühr nicht feststeht, sollten Sie die Seite mit dem jeweiligen Produktangebot aufrufen oder sich auf der Website",
			h4: "Gebühren für Einzahlungen und Abhebungen",
			p3: "Um die Kosten für unsere Kunden so gering wie möglich zu halten, erheben wir in den meisten Fällen keine Gebühren für den Zugriff auf Ihr Geld. Es kann jedoch vorkommen, dass ein Drittunternehmen oder eine Bank Ihnen eine Gebühr für eine Einzahlung oder Abhebung in Rechnung stellt, z. B. eine Transitbankgebühr.",
			p4: "Bitte wenden Sie sich an unseren Kundenbetreuer, um weitere Informationen über die Gebühren zu erhalten.。",
			p5: "Z.B. durch internationale Kreditkartentransaktionen, Ein- und Auszahlungen von Bankkonten oder Transaktionen in nicht unterstützten Währungen (Fremdwährungsumrechnung), etc.",
			h5: "Kosten für Übernachtungsplätze",
			p6: "Wenn Sie um 22:00 Uhr GMT noch eine Position halten, wird Ihnen eine Übernachtgebühr in Rechnung gestellt bzw. überwiesen. Erkundigen Sie sich nach den Übernachtgebühren für jedes Produkt auf der jeweiligen Produktangebotsseite oder indem Sie sich auf der Seite",
			h6: "Sonstige Kosten",
			p7: "Keine weiteren Gebühren. Unsere Gebührenpolitik ist völlig transparent, und alle kostenpflichtigen Posten werden im Voraus angekündigt und mitgeteilt, so dass Sie sich keine Sorgen über versteckte Gebühren machen müssen."
		},
		contact: {
			h1: "Kontakt",
			address: "Adresse",
			custom_email: "Kundenkontakt E-Mail",
			business_email: "Business-Kooperation E-Mail",
			h2: "Schreiben Sie uns.",
			p1: "Wenn Sie Fragen zu unseren Dienstleistungen haben, füllen Sie bitte das untenstehende Formular aus, um Ihre Frage zu übermitteln. Einer unserer Kundenservice-Spezialisten wird Ihnen so schnell wie möglich antworten. Wenn Sie sich für die täglichen Handelsstrategien anmelden möchten, die wir im Rahmen unserer Partnerschaft mit Trading Central anbieten, wählen Sie bitte 'Tägliche Marktanalyse abonnieren' in dem Formular unten aus und geben Sie die gewünschte Sprachversion an. *Der Abonnement-Service ist nur für bestehende HKDFINANC-Kunden verfügbar.",
			h3: "Verfahren der Repräsentation",
			p2: "Alle Beschwerden werden von unserem spezialisierten Team untersucht und gelöst. Weitere Informationen finden Sie unter",
		},
		faq: {
			h1: "Allgemeine Probleme",
			tit1: "Was ist der Nutzen eines Demokontos?？",
			tit2: "Wie man ein Live-Konto eröffnet？",
			tit3: "Wie man einen Marktauftrag erstellt？",
			tit4: "Warum kann ich keine Bestellung aufgeben?？",
			p1: "Demokonten sind in Bezug auf Handelsoberfläche, Daten und Operationen weitgehend identisch mit echten Konten. Demokonten enthalten 50.000 $ virtuelles Kapital und sollen es den Kunden ermöglichen, sich durch simulierte Operationen mit den Funktionen der Plattform vertraut zu machen, ohne ihr Kapital zu riskieren.",
			p2: "Um ein Live-Konto zu eröffnen, folgen Sie bitte diesen Schritten：",
			p3: "1. Rufen Sie die HKDFINANC-Registrierungsseite auf und folgen Sie den Anweisungen: Registrieren Sie Ihre E-Mail-Adresse/Handynummer, legen Sie das Anmeldepasswort fest und senden Sie die Bestätigung, um automatisch ein [Demo-Konto] zu erstellen.。",
			p4: "2. Wählen Sie auf der Seite des Demokontos die Option 'Zu einem echten Konto wechseln' und folgen Sie den Anweisungen zur Eröffnung eines 'echten Kontos'.。",
			p5: "Bevor Sie ein Live-Konto eröffnen, empfehlen wir Ihnen, die mit dem Handel verbundenen rechtlichen Hinweise zu lesen und zu verstehen.",
			p6: "Bitte beachten Sie, dass Sie erst dann auf Ihr Live-Konto einzahlen können, wenn Sie die Identitätsprüfung abgeschlossen haben.",
			p7: "Sie können den Code oder den Namen des Produkts in das Eingabefeld auf der rechten Seite eingeben, um es zu suchen, ein Produkt auswählen und auf 'VERKAUFEN/BESTELLEN' (SELL/BUY) klicken, um das Handelsfenster zu öffnen, in dem Sie den aktuellen Preis und die geschätzte erforderliche Marge sehen können. Sie können die Anzahl der zu eröffnenden Geschäfte manuell einstellen, Stop-Loss und Take-Profit zur Risikokontrolle festlegen und dann auf 'SELL/BUY' klicken, um die Eröffnung der Position abzuschließen. Bitte beachten Sie, dass die Preise aller Finanzprodukte schwanken und jederzeit mit dem Markt aktualisiert werden, und dass sich der Markt bereits geändert haben kann, bevor Sie auf die Schaltfläche KAUFEN/VERKAUFEN klicken.",
			p8: "Überprüfen Sie bitte zunächst, ob die Netzwerkverbindung normal ist. Wenn die Handelsarten Markt ist vorübergehend geschlossen Zitat oder geschlossen wird die Unfähigkeit, Aufträge zu platzieren. Bitte kontaktieren Sie unseren Online-Kundendienst für weitere Unterstützung.",

		},
		fundSecurity: {
			h1: "Finanzielle Sicherheit",
			p1: "HKDFINANC ist von den zuständigen Aufsichtsbehörden zugelassen und reguliert. Die Einhaltung der Vorschriften steht im Mittelpunkt unseres Handelns und unserer Unternehmenskultur. Die Sicherheit Ihres Geldes steht an erster Stelle, und alle Kundeneinlagen werden getrennt verwahrt.",
			p2: "Die Einlagen von Privatkunden werden gemäß den aufsichtsrechtlichen Anforderungen separat auf Treuhandkonten gehalten.",
			p3: "HKDFINANC verwendet keine Kundengelder für andere als die gesetzlich zulässigen kommerziellen Aktivitäten.",
			p4: "HKDFINANC wird auf keinem Markt spekulieren.",
			p5: "Unabhängige Prüfungen durch externe Wirtschaftsprüfungsgesellschaften",
		},
		help: {
			h1: "Willkommen im HKDFINANC-Hilfezentrum",
		},
		media: {
			h1: "Medienzentrum",
			p1: "Hier finden Sie Informationen, Pressemitteilungen, Medienberichte, Ankündigungen und andere Informationen über die Marke HKDFINANC.",
		},
		mitrade: {
			h1: "Über HKDFINANC",
			view_certificate: "（Methoden der Überprüfung）",
			p1: "HKDFINANC ist ein behördlich zugelassenes und reguliertes Fintech-Unternehmen, das sich darauf konzentriert, Anlegern ein einfaches und bequemes Handelserlebnis zu bieten. Die innovative, breit gefächerte Handelsplattform von HKDFINANC wurde wiederholt mit Preisen wie 'Beste mobile Handelsplattform' und 'Innovativstes Brokerage' ausgezeichnet.",
			p2: "Über die HKDFINANC-Plattform können Sie in ein breiteres Spektrum an internationalen Finanzmärkten investieren und handeln, das Aktien, Indizes, Rohstoffe, Devisen und Hunderte anderer beliebter Arten umfasst. HKDFINANC beschränkt sich nicht nur auf die benutzerfreundlichen mobilen und webbasierten Handelsplattformen, sondern bietet Ihnen auch wettbewerbsfähige Handelskosten, eine schnelle Handelsausführung, einen ausgezeichneten Kundenservice und eine Fülle von Informationen, die Ihnen helfen, rechtzeitig Investitions- und Handelsmöglichkeiten zu nutzen.",
			p3: "HKDFINANC unterliegt einer strengen Regulierung durch eine branchenübergreifende Behörde. HKDFINANC International ist von der Financial Services Commission (FSC) von Mauritius unter der Lizenznummer GB20025791 zugelassen und wird von ihr reguliert. Einzelheiten zur mauritischen Lizenz finden Sie auf der offiziellen Website der FSC unter https://www. fscmauritius.org",
			p4: "Führen Sie eine Anfrage durch. HKDFINANC Global besitzt eine Financial Services License (AFSL 398528), die von der Hong Kong Securities and Investments Commission (ASIC) genehmigt wurde. Weitere Informationen zum Hongkong-Kennzeichen finden Sie auf der offiziellen ASIC-Website unter www.asic.gov.au.",
			p5: "Die HKDFINANC ist von der Cayman Islands Monetary Authority (CIMA) unter der SIB-Lizenz Nr. 1612446 zugelassen und wird von ihr reguliert. Weitere Informationen zu den Lizenzen der Kaimaninseln finden Sie auf der offiziellen CIMA-Website unter www.cima.ky.",
			p6: "Alle Geschäftstätigkeiten von HKDFINANC werden unter strenger Aufsicht und unter Einhaltung aller Vorschriften durchgeführt.",
			h2: "Hintergrund von HKDFINANC",
			p7: "HKDFINANC besteht aus einem Senior-Team mit reicher Erfahrung und Wissen in der Finanz- und Fintech-Industrie. Die Philosophie von HKDFINANC ist es, Transaktionen einfacher und freundlicher zu machen",
			p8: "Unsere ganzheitliche Strategie und unser Geschäftsmodell ermöglichen es uns, die Entwicklung der Branche und die Marktnachfrage global und eingehend zu beobachten und zu kennen, so dass wir unsere Ressourcen gezielter einsetzen, die Technologie kontinuierlich erneuern und die Effizienz optimieren können und unseren Kunden weiterhin ein bequemeres und freundlicheres Handelserlebnis bieten können. ",
			h3: "Warum HKDFINANC",
			why1: "Niedrigschwellige Handelsbedingungen",
			why2: "Von den Behörden der Branche reguliert",
			why3: "Einfache und intuitive Handelsplattform",
			why4: "Ein hohes Maß an Online-Unterstützung",
			why5: "Wettbewerbsfähige Transaktionskosten",
			why6: "Schutz vor negativem Saldo",
			h4: "Auszeichnungen und Ehrungen",
			p9: "Wir haben immer nach Spitzenleistungen gestrebt und uns verpflichtet, unseren Kunden hochwertige Transaktionsdienstleistungen zu bieten.",
			p10: "Die Marke HKDFINANC fühlt sich geehrt, diese prestigeträchtige Auszeichnung von einer angesehenen Institution der Branche zu erhalten, die die kontinuierlichen Bemühungen und das Engagement des Teams für unsere Kunden würdigt.",
			new_add: "Neue Nutzer weltweit",
			ol1: "Bester Anbieter von Nachrichten und Analysen",
			ol2: "Bester Forex-Broker in Asien",
			ol3: "Hong Kong Devisen Customer Satisfaction Award",
			ol4: "Beste mobile Anwendung",
			ol5: "Am schnellsten wachsende Wertpapierfirma in Hongkong",
			h5: "Die Arbeitsphilosophie von HKDFINANC",
			tip_tit1: "Verbindlichkeiten",
			tip_tit2: "Einfach aber schön",
			tip_tit3: "Offen (nicht-geheimnisvoll)",
			tip_tit4: "Neue Wege beschreiten",
			tip_p1: "Jeder Handel ist mit Risiken verbunden, und HKDFINANC hat zusätzliche Maßnahmen ergriffen, um seinen Kunden ein verantwortungsvolles Handelsumfeld zu bieten. HKDFINANC wird von Branchenbehörden und mehreren Organisationen reguliert und arbeitet mit einem hohen Maß an Sicherheit und Stabilität. Unsere Plattform bietet weiterhin eine Reihe von verantwortungsvollen Handelsinstrumenten, einschließlich Risikomanagement.",
			tip_p2: "Einfachheit ist Schönheit, Details sind Exzellenz - HKDFINANC legt Wert auf Benutzerfreundlichkeit und Benutzererfahrung in jedem Detail. Wir optimieren unsere Plattform und Dienstleistungen kontinuierlich in der Hoffnung, dass jeder, vom Anfänger bis zum erfahrenen Anleger, die Handelsmöglichkeiten der HKDFINANC-Plattform nutzen kann.",
			tip_p3: "Damit unsere Kunden in vollem Umfang von einem fairen und effizienten Handelsumfeld profitieren können, folgt HKDFINANC einem strengen und transparenten Selbstregulierungssystem, um eine umfassende und transparente Plattform mit Produktinformationen, Preisgestaltung und Daten bereitzustellen. Alle Gebühren sind klar und offen, um sicherzustellen, dass der gesamte Dienstleistungsprozess absolut aufrichtig und transparent ist.",
			tip_p4: "HKDFINANC ist der festen Überzeugung, dass technologischer Fortschritt, Finanzinnovationen und ein breiteres Dienstleistungsangebot das Wachstum vorantreiben und dass wir weiterhin Werte schaffen und teilen können. Zu diesem Zweck fördern wir offenes Denken und treiben Innovationen weiter voran, mit dem Ziel, ein Innovator und Vorreiter im Bereich der Finanztechnologie zu sein.",
			row6_tit1: "Unser Auftrag",
			row6_tit2: "Unsere Vision",
			row6_tit3: "Arbeitsmöglichkeiten",
			row6_p1: "Wir wollen mehr Menschen, die Handel treiben wollen, ein faires, effizientes und transparentes Handelsumfeld bieten, das nicht durch Raum, Zeit und Markt begrenzt ist, und in der Branche eine führende Rolle bei finanziellen und technologischen Innovationen spielen.",
			row6_p2: "Wir sind davon überzeugt, dass technologischer Fortschritt, Finanzinnovationen und diversifizierte Dienstleistungen die Triebkräfte unseres Wachstums sind und uns in die Lage versetzen, weiterhin Werte in der Gesellschaft zu schaffen und zu teilen.",
			row6_p3: "Zu diesem Zweck ermutigen wir zu Aufgeschlossenheit und fördern Innovationen, um durch kontinuierliches Wachstum und Fortschritt zum",
			row6_p4: "Das weltweit führende Devisenhandelsunternehmen",
			row6_p5: "Wir sind ein globales Team in 7 Ländern und mit einem schnell wachsenden Team sind wir immer auf der Suche nach Fachleuten, die sich uns anschließen möchten. Machen Sie das, was Sie lieben, in einem multikulturellen Team!",
			row6_p6: "Bitte mailen Sie uns Ihren aktuellen Lebenslauf und warum Sie mit uns zusammenarbeiten möchten:",
			row6_p7: "。Alternativ dazu können Sie auch mehr über die",
			row6_p8: "Vielleicht",
			row6_p9: "Unsere offenen Stellen finden Sie unter。",
			row6_p10: "HKDFINANC schützt die Sicherheit Ihrer persönlichen Daten streng. HKDFINANC wird Sie während des Bewerbungsverfahrens nicht nach finanziellen Informationen fragen. Wir benötigen keine finanziellen Informationen, Kreditkarten- oder Kontodaten oder andere Zahlungsmittel für unsere Stellenausschreibung.",
			row6_p11: "Wenn Sie den Verdacht haben, dass eine Stelle betrügerisch ist, kontaktieren Sie uns bitte über die",
			row6_p12: "Wenden Sie sich an die Personalabteilung von HKDFINANC. Wenn Sie sicher sind, dass Sie durch ein falsches Stellenangebot betrogen wurden, wenden Sie sich bitte an Ihre örtliche Abteilung der betreffenden Organisation.",

		}
	},
	product: {
		row2: {
			tit: "Handel mit Sorten",
			sell: "Erreichen (ein Preis in einer Auktion)",
			buy: "Einkaufen",
			trade: "Geschäfte",
			more_product: "Weitere Handelsprodukte",
		},
		row3: {
			tit: "Unterstützt Transaktionen mit mehreren Geräten",
			tit1: "Unterstützt Transaktionen mit mehreren Geräten",
			p1: "HKDFINANC bietet Ihnen webbasierte, mobile und Desktop-Handelsplattformen. Sie können jederzeit und überall handeln.",
			download: "Herunterladen von",
		},
		row4: {
			tit: "Wählen Sie HKDFINANC",
			tit1: "Einfache und intuitive Plattform",
			p1: "Integration von Kursen, Handel, Kontoverwaltung, Informationen, Risikomanagement, leistungsstarke Funktionen, um eine bessere Benutzererfahrung zu erreichen",
			tit2: "Wettbewerbsfähige Transaktionskosten",
			p2: "0 Handelskommissionen, sehr wettbewerbsfähige und transparente niedrige Spreads, niedrige SWAPs, so dass Sie den Handel zu niedrigen Kosten genießen können!",
			tit3: "Niedrigschwellige Handelsbedingungen",
			p3: "Die minimale Lotgröße für jede Transaktion beträgt nur 0,01 Lot, und Sie können eine Position mit einer geringen Marge eröffnen.",
			tit4: "Von der Behörde reguliert",
			p4: "Zugelassen und reguliert von den Behörden der Branche werden alle Kundeneinlagen in getrennten Lagern aufbewahrt, um die Sicherheit der Kundengelder zu gewährleisten.",
			tit5: "Schutz vor negativem Saldo",
			p5: "Ihr Konto wird unter allen Marktbedingungen nie mehr als sein Kapital verlieren, und negative Salden werden zeitnah ausgeglichen, um Ihr Risikomanagement zu verbessern.",
			tit6: "Ein hohes Maß an Online-Unterstützung",
			p6: "Bieten Sie eine schnelle Reaktion Online-Kundendienst, wird ein besserer Service durch die unermüdlichen Bemühungen des professionellen Teams bieten",
		},
		row5: {
			tit: "Verwandte Themen",
		},
		commodities: {
			title: "Rohstoffhandel",
			p: "Rohstoffe wie Edelmetalle und Energie gelten als wichtiger Bestandteil diversifizierter Anlagen, da sie sowohl Schutz vor Inflation als auch Möglichkeiten zur Wertsteigerung von Investitionen bieten. Gold, Silber und Öl sind Rohstoffe, die in großen Mengen gehandelt werden, und ihre offenen und transparenten Preise sowie die hohe Liquidität werden von den Anlegern sehr begrüßt. Die Rohstoffpreise schwanken aufgrund von Faktoren wie Angebot und Nachfrage, wirtschaftlicher und politischer Lage sowie Währungen erheblich und bieten somit Möglichkeiten für ein gutes Risiko-Rendite-Verhältnis.",
		},
		forex: {
			title: "Devisenhandel",
			p: "Der Devisenhandel ist der Umtausch von einer Währung in eine andere, und der Devisenmarkt ist der am meisten gehandelte Finanzmarkt der Welt. Der Devisenmarkt hat eine große Anzahl von Teilnehmern, die über ihn handeln, um Zahlungen zu leisten, sich gegen das Risiko von Währungsschwankungen abzusichern oder um Gewinne zu erzielen. Der Devisenhandel findet über ein Netz von Banken, Institutionen und Einzelhändlern auf der ganzen Welt statt, wobei die Preise 24 Stunden am Tag und 5 Tage in der Woche schwanken, was Chancen für Risiko und Gewinn bietet.",
		},
		indices: {
			title: "Exponenten",
			p: "Ein Aktienindex ist ein statistischer Wert, der eine bestimmte Art von charakteristischen Aktien an einer bestimmten Börse repräsentiert und ein Indikator für das Gesamtpreisniveau und die Bewegung einer bestimmten Art von konstituierenden Aktien auf dem Markt ist. Der Handel mit Aktienindizes, die die Anlagechancen auf dem Gesamtmarkt oder in einem bestimmten Sektor widerspiegeln, kann das Risiko einer Investition in einzelne Aktien verringern. Die beliebtesten Indizes auf den US-amerikanischen, europäischen, asiatischen und australischen Märkten, wie der Australia 200, der US Tech 100, der Hong Kong 50, der Germany 30 usw., bieten unterschiedliche Chancen auf den globalen Aktienmärkten.",
		},
		shares: {
			title: "Aktiengeschäft",
			p: "Aktien sind eine Art von Wertpapieren, die an einer Börse ausgegeben werden. Um Mittel zu beschaffen, werden Unternehmen das Eigentum an der Gesellschaft durch Wertpapiere über eine börsennotierte Börse zuweisen. Allein im 2019 übertraf das Gesamthandelsvolumen der Weltaktien 60 Billionen Dollar, mit großem Umfang und starker Liquidität. Daher bleibt es auch nach vielen Jahren eines der beliebtesten Finanzinstrumente in den Augen globaler Investoren. Der Aktienkurs wird von verschiedenen Faktoren wie der Gesamtwirtschaft, den Branchenaussichten und dem Unternehmensbetrieb beeinflusst, mit hoher Volatilität und jederzeit Investitions- und Handelsmöglichkeiten.",
		},
		coins: {
			title: "Kryptowährung",
			p: "Kryptowährungen sind digitale Währungen, die durch Code geschaffen werden. Sie funktionieren autonom außerhalb der Grenzen der traditionellen Bank- und Regierungssysteme. Wie der Name schon sagt, verwenden Kryptowährungen Verschlüsselung zur Sicherung von Transaktionen und ermöglichen die Schaffung anderer Einheiten. Bitcoin ist die ursprüngliche und bei weitem bekannteste Kryptowährung, die von Satoshi Nakamoto geschaffen und im Januar 2009 eingeführt wurde. Bis heute ist unbekannt, ob sich der Name Satoshi Nakamoto auf eine Person oder eine Gruppe von Personen bezieht. Bitcoin gilt als die erste dezentralisierte Kryptowährung. Wie alle Kryptowährungen wird er über eine Blockchain-Transaktionsdatenbank gesteuert, die als verteiltes öffentliches Hauptbuch dient. Bis heute gibt es mehr als 1.000 Kryptowährungen, die online gehandelt werden können."
		}
	},
	trade: {
		data_sources: "Quelle der Daten",
		tip: "Die oben genannten Informationen dienen nur als Referenz. HKDFINANC übernimmt keine Gewähr für die Richtigkeit, Aktualität oder Vollständigkeit der Informationen und Sie sollten sich nicht in unangemessener Weise auf die bereitgestellten Informationen verlassen. Diese Informationen enthalten weder unsere Kursaufzeichnungen noch ein Angebot oder eine Aufforderung zum Handel mit Finanzinstrumenten. HKDFINANC ist kein Finanzberatungsunternehmen und bietet lediglich Auftragsausführungsdienste an. Den Lesern wird empfohlen, ihre eigene Anlageberatung in Anspruch zu nehmen. Bitte beachten Sie unseren vollständigen Haftungsausschluss.",
		tip2: "Aktualisiert jeden Freitag um 15:00 Uhr (GMT)",
		tip3: "Trading Views ist ein Instrument zur Vorhersage der Marktstimmung, das die kurz- und mittelfristigen Einschätzungen und Trendprognosen von Branchenexperten für jedes aktuelle Produkt wiedergibt.",
		analysis: {
			tit: "Handelsstrategie",
			all: "Vollständig",
			day: "In ein paar Tagen",
			tit1: "Handelsstrategie",
			tit2: "Technische Überprüfung",
		},
		calendar: {
			tit: "Finanzkalender",
			prev_week: "Letzte Woche",
			next_week: "Nächste Woche",
			place_date: "Datum suchen",
			select: "Optionen (wie bei Computersoftware-Einstellungen)",
			select1: "Nationen",
			select2: "Bedeutung",
			start_time: "Startzeit",
			end_time: "Endzeitpunkt",
			search: "Nach etw. Ausschau halten",
		},
		contract: {
			tit: "Vertragsspezifikationen",
			type1: "Vollständig",
			type2: "Devisen",
			type3: "Ware",
			type4: "Exponenten",
			type5: "US-Aktien",
			type6: "Australische Aktien",
			tit1: "Wichtige Devisen",
			tit2: "Geringfügige Devisen",
			tit3: "Ware",
			tit4: "Hauptindizes",
			tit5: "Kleinerer Index",
			tit6: "US-Aktien",
			tit7: "Australische Aktien",
			tit8: "Allgemeine Regeln",
			p1: "Erzwungenes Ebenheitsverhältnis",
			p2: "Gültigkeit ausstehender Aufträge",
			p3: "Grenzenlos",
			p4: "Abrechnungszeit über Nacht",
			p5: "Tägliche Abrechnung; Sommer: 05:00, Winter: 06:00",
			p6: "Informationen über Hebelwirkung, Tagesgeldsätze usw.",
			p7: "Siehe Handelsplattformen",
			p8: "Das oben Gesagte gilt für alle Handelssorten",
			tit9: "Wöchentlicher Handelskalender",
			p9: "Schließung (des Marktes)",
			p10: "Die oben genannten Handelszeiten lassen die Auswirkungen von Feiertagen oder besonderen Marktanpassungen unberücksichtigt.。",
			p11: "Spezifische Empfehlungen zur Handelszeit",
			p12: "Login-Plattform",
			p13: "，Sehen Sie sich die Profile der betreffenden Sorten an.。",
		},
		forecast: {
			tit: "Handelsperspektiven",
			p1: "Durchschnittswert",
			p2: "Bullenmarkt (die Preise scheinen zu steigen)",
			p3: "Baisse",
			p4: "Querströme",
			p5: "Ausrichtungen",
		},
		news: {
			tit: "Nachrichten in Echtzeit",
		},
		platforms: {
			tit: "HKDFINANC Handelsplattform",
			p: "Einfache und intuitive Schnittstelle, hervorragende Handelsleistung, erfüllen die Bedürfnisse von mehr Investoren. Beginnen Sie noch heute den Handel mit HKDFINANC.",
			tit1: "Bequeme und benutzerfreundliche HKDFINANC-Handelsplattform",
			tit2: "Handeln Sie jederzeit und überall mit der HKDFINANC-App!",
			li1: "Handel mit Hunderten von beliebten Märkten mit maßgeblicher Regulierung, um die Sicherheit der Gelder zu gewährleisten",
			li2: "Verfolgen Sie Markttrends in Echtzeit, sehen Sie sich Handelsanalysen an und informieren Sie sich über die neuesten Finanznachrichten.",
			li3: "Zeigen Sie jederzeit verschiedene Arten von Charts an und wenden Sie Charting-Funktionen mühelos an.",
			li4: "Erleben Sie die preisgekrönte Handelsplattform von HKDFINANC auf Ihrem Android- oder iOS-Gerät!",
			code_download: "Code zum Herunterladen scannen",
			tit3: "Kein Download erforderlich, handeln Sie online über Ihren Browser",
			li5: "Kein Download erforderlich, handeln Sie online direkt von Ihrem PC-Browser aus",
			li6: "Auszeichnungen: Bester Devisenbroker in Asien im 2022, Beste Handelsplattform in Hongkong im 2022 und am schnellsten wachsenden Devisenbroker der Welt im 2022",
			li7: "Bietet leistungsstarke technische Indikatoren: MACD, KDJ, RSI, TRIX, DMA, CCI, etc.",
			li8: "Ausgestattet mit Risikomanagement-Tools wie Stop-Loss und Negativsaldo-Schutz, um das potenzielle Risiko Ihrer Trades zu mindern",
			tit4: "Die perfekte Lösung für Ihre Desktop-Transaktionen",
			li9: "Einfache Anzeige der Marktkurse in der selbstgewählten Liste",
			li10: "Aktivieren Sie Alarme, um Preisänderungswarnungen und andere Benachrichtigungen zu erhalten",
			li11: "Mehrere Chart-Layouts ermöglichen es Ihnen, den Markt in verschiedenen Zeitdimensionen zu verfolgen.",
			li12: "Kontraktspezifikation, Handelsstimmung und Handelsanalyse - alles in einer Schnittstelle, kein Wechsel erforderlich",
			li13: "Erhalten Sie die neuesten Handelsnachrichten direkt in HKDFINANC!",
			tit5: "Warum die HKDFINANC-Handelsplattform?",
			row5_p1: "Sicherheit",
			row5_p2: "0% Provision",
			row5_p3: "Unterstützung mehrerer Plattformen",
			row5_p4: "Technisches Diagramm",
			row5_p5: "Jederzeit und überall Zugriff",
			row5_p6: "Verschiedene Zeichenwerkzeuge",
		},
		quotes: {
			tit: "Echtzeit-Kurse",
			p1: "Bietet Ihnen sofortige Kurse, Marktstimmungen und damit verbundene Nachrichten zu den aktuellen Finanzwerten.",
			hot: "En vogue",
		},
		risk: {
			tit: "Risikomanagement",
			p1: "Jeder Handel ist mit Risiken verbunden. Mit den kostenlosen Risikomanagement-Tools von HKDFINANC können Sie Ihr Risiko unabhängig von den Marktbedingungen effektiv steuern.",
			function_tit1: "Take Profit/Stop Loss-Funktion",
			function_tit2: "Nachlaufende Stopp-Funktion",
			function_p1: "Gewinne festschreiben",
			function_p2: "Verluste begrenzen",
			function_p3: "Maximierung der Gewinnsicherung",
			function_p4: "Keine automatische Überwachung der Positionen erforderlich",
			function_p5: "Anpassung des Verlustausgleichspreises",
			row2_tit1: "Take Profit/Stop Loss-Funktion",
			row2_p1: "Bei der Erstellung eines neuen Auftrags oder der Änderung eines bestehenden Auftrags bieten wir Ihnen die Möglichkeit, 'Take Profit' und 'Stop Loss' festzulegen. Einmal festgelegt, wird die Order normalerweise zu dem von Ihnen festgelegten Zielpreis geschlossen. So können Sie Ihre Gewinne sichern, wenn Sie Ihr Ziel erreichen, oder Ihre Verluste reduzieren, wenn sich der Markt gegen Sie entwickelt. Bitte beachten Sie, dass jeder Auftrag aufgrund der Marktvolatilität geshortet werden kann. In diesem Fall ist das System nicht in der Lage, den Auftrag zum Standardkurs auszuführen, sondern schließt Ihre Position zum nächstgünstigeren Kurs gegenüber dem Zielkurs.",
			row2_tit2: "Beispiele",
			row2_p2: " Der aktuelle Kurs von EUR/USD beträgt 1,13816/1,13837 (Verkauf/Kauf). Sie eröffnen einen Kaufauftrag für 1 Lot (1 Lot = 100.000 EUR) zu 1,13837 und setzen einen Stop Loss bei 1,13806.",
			row2_p3: "Im Allgemeinen wird Ihr Stop-Loss-Auftrag ausgelöst, wenn der Kurs auf 1,13806 fällt, und die Position wird bei 1,13806 mit einem Gesamtverlust von 31 $ geschlossen.",
			row2_p4: "Wenn sich jedoch die Marktbedingungen ändern und der Kurs direkt von 1,13837 auf 1,13795 abrutscht und dabei Ihr Stop-Loss-Ziel überspringt, springt der Markt und das System kann die Position nicht bei 1,13806 schließen, sondern schließt die Position für Sie zum nächstgünstigeren Kurs, d. h. bei 1,13795, und der endgültige Verlust beträgt 42 USD.",
			row3_tit1: "Nachlaufende Stopp-Funktion",
			row3_tit2: "Beispiele",
			row3_p1: "Trailing Stops (auch Nachlaufstopps genannt) sind eine leistungsstarke Funktion, die es Ihnen ermöglicht, Gewinne zu sichern oder Verluste zu minimieren, ohne Ihre Positionen ständig überwachen zu müssen. Wenn Sie einen Auftrag erstellen, legen Sie einfach einen Trailing-Stop fest, und wenn sich der Kurs zu Ihren Gunsten entwickelt, wird Ihr Stop-Auftrag automatisch mit dem neuesten Kurs aktualisiert. Umgekehrt wird die Stop-Loss-Order aktiviert, wenn sich der Kurs gegen Sie bewegt, und die Position wird in dem von Ihnen festgelegten Abstand zum Verlust geschlossen. Bitte beachten Sie, dass jeder Auftrag aufgrund der Marktvolatilität geshortet werden kann. In diesem Fall ist das System nicht in der Lage, den Auftrag zu Ihrem Standardkurs auszuführen, sondern schließt die Position für Sie zum nächstgünstigeren Kurs gegenüber dem Zielkurs.",
			row3_p2: "Der aktuelle Kurs von EUR/USD liegt bei 1,13816/1,13837 (Verkauf/Kauf). Sie haben einen 1-Lot-Kaufauftrag bei 1,13837 mit einem Trailing-Stop-Loss von 100 Pips (0,00100) erteilt.",
			row3_p3: "Wenn der Preis des Produkts bei 1,13816 liegt, beträgt Ihr Stop-Loss 1,13716. Wenn der Verkaufspreis des Produkts auf 1,13845 steigt, wird der Stop-Loss um den von Ihnen festgelegten Abstand aktualisiert, und der aktualisierte Stop-Loss beträgt 1,13745.",
			row3_p4: " Im Gegenteil, wenn der Produktpreis von 1,13845 auf 1,13745 fällt, wird ein Trailing-Stop ausgelöst und die Position bei 1,13745 geschlossen.",
			tip: "Punkt: Normalerweise wird die kleinste Einheit der Preisänderung eines Finanzinstruments als Punkt bezeichnet. Auf der HKDFINANC-Plattform bezieht er sich auf die letzte Ziffer oder Dezimalstelle des Preises des Instruments.",
		},
		sentiment: {
			tit: "Gefühlsindex",
			type1: "Vollständig",
			type2: "Devisen",
			type3: "Ware",
			type4: "Exponenten",
			long: "Langfristig (Finanzierung)",
			short: "Kurz (Finanzen)",
		},
	},
	layout: {
		aside: {
			nav1: "Geschäfte",
			nav2: "Die aktuelle Marktsituation",
			nav3: "Informationen",
			nav4: "Hochschule",
			nav5: "Meine",
			newsDialog: {
				title: "Nachrichtenzentrum",
				type1: "System-Benachrichtigung",
				type2: "Bulletin",
				allRead: "Alle als gelesen markieren",
			},
			settingDialog: {
				title: "Aufstellen",
				nav1: "Konventionell (Waffen)",
				nav2: "Präferenzen anzeigen",
				nav3: "System-Informationen",
				logout: "Abmelden",
				setting1: "Mehrsprachigkeit",
				setting2: "Geschäfte",
				setting2_tip: "Offene Positionen fügen automatisch selbst gewählte Sorten hinzu",
				setting3: "Informationen zurücksenden",
				setting3_tip: "Funktionale Empfehlungen",
				setting4: "Echtnamen-Authentifizierung",
				setting4_tip: "Echtnamen-Authentifizierung",
				setting5: "Thema-Modus",
				setting5_label1: "Undurchsichtig",
				setting5_label2: "Helle Farbe",
				setting6: "Steigende oder fallende Farben",
				setting6_label1: "Lit. grün steigt und rot fällt",
				setting6_label2: "Lit. rot steigt, grün fällt",
				setting7: "Chart-Einstellungen",
				setting7_label1: "Standardausgabe",
				setting7_label2: "TradingView-Ausgabe",
				setting8: "Erklärungen und Vereinbarungen",
				setting8_label1: "Datenschutzbestimmungen",
				setting8_label2: "Erklärung zur Produktoffenlegung",
				setting8_label3: "Erklärung zur Offenlegung von Risiken",
				setting8_label4: "Kundenvereinbarung",
				feedback_title: "Funktionale Empfehlungen",
			}
		},
		footer: {
			hot: "Beliebte Sorten",
			sort: "Sortiment",
			code: "Kodierung",
			buy_price: "Ankaufspreis",
			sell_price: "Verkäuferpreis",
			chg: "Preisanstieg oder -verfall",
		},
		header: {
			place_search: "Suche nach Handelssorten",
			balance: "Verfügbares Guthaben",
			balance_tip: "Verfügbarer Saldo: der Geldbetrag auf dem Konto, der für die Eröffnung neuer Positionen verwendet werden kann",
			profit_loss: "Wachsend und abnehmend",
			asset_view: "Überblick über die Vermögenswerte",
			netValue: "Nettowert",
			netValue_tip: "Nettovermögen: aktueller Kontowert, einschließlich Gewinne und Verluste aus allen Positionen",
			marginLevel: "Höhe der Marge",
			margin: "Einschuss (im Derivatehandel)",
			margin_tip: "Marge: der Betrag, der erforderlich ist, um eine Position in einer Fremdwährung zu eröffnen und zu halten.",
			maintainsMargin: "Wartungsmarge",
			maintainsMargin_tip: "Maintenance Margin: Der Mindestbetrag an Margin, der auf Ihrem Konto verbleiben muss, während Sie alle Ihre Positionen halten.",

		},
	},
	table: {
		label_name: "Name",
		label_buyPrice: "Angebotspreis",
		label_sellPrice: "Verkaufskurs",
		label_variableValue: "Wert der Veränderung",
		trade: "Geschäfte",
		label_code: "Produkt-Code",
		label_title: "Name ",
		label_start: "Bedeutung",
		label_country: "Nationen",
		label_event: "Veranstaltung",
		label_previous: "Vorheriger Wert",
		label_predictive: "Prognostizierter Wert",
		label_announced: "Veröffentlichter Wert",
		label_stop_loss_min: "Mindestabstand für Stop-Loss-Aufträge",
		label_difference: "Dynamische Verbreitung",
		label_contract_size: "Vertragsgröße",
		label_max_hand: "Maximale Losgröße für eine einzelne Transaktion",
		label_min_hand: "Mindestlosgröße",
		label_trade_week: "Wöchentlicher Handelskalender",
		label_week: "1 Woche",
		label_month: "1 Mond",
		label_quarter: "1 Saison ",
		label_dailyTrend: "Ein-Tages-Trend",
		label_tradeId: "Transaktions-ID",
		label_breed: "Sortiment",
		label_tradeType: "Art der Transaktion",
		label_currency: "Währung",
		label_amount: "Geldsumme",
		label_balance: "Salden",
		label_type: "Typologie",
		label_time: "Mal",
		label_orderNumber: "Bestellnummer",
		label_pointer_number: "Ordnungszahl",
		label_price_money: "Eröffnungspreis",
		label_stop_win_price: "Gewinn mitnehmen",
		label_stop_lose_price: "Stop-Loss",
		label_openTime: "Öffnungszeit",
		label_profit_loss: "Wachsend und abnehmend",
		label_sell_buy: "Preisvorstellung/Kaufpreis",
		label_chg: "Preisanstieg oder -verfall",
		label_sell_price: "Verkäuferpreis",
		label_buy_price: "Ankaufspreis",
		label_condition: "Voraussetzung",
	},
	form: {
		label_feedback: "Fragestellungen und Empfehlungen",
		place_feedback: "Bitte geben Sie Ihre Frage oder Anregung ein",
		label_img: "Foto",
		label_img_tip: "Optional, stellen Sie einen Screenshot des Problems zur Verfügung",
		feedback_tip: "Wenn Sie eine dringende Frage haben, wenden Sie sich bitte an",
		online: "Online-Kundendienst",
		label_name: "Vorname und Nachname",
		place_name: "Bitte geben Sie Ihren Namen ein",
		label_email: "E-Mail Adresse",
		place_email: "E-Mail Adresse",
		message_email: "Bitte geben Sie Ihre E-Mail Adresse ein",
		label_nationality: "Staatsbürgerschaft",
		place_nationality: "Bitte geben Sie Ihre Nationalität ein",
		place_questionType: "Art des Problems",
		message_questionType: "Bitte Typ auswählen",
		questionType0: "Handelsplattform",
		questionType1: "Produkte & Gebühren",
		questionType2: "Abonnieren Sie die täglichen Marktanalysen",
		questionType3: "Kundenbetreuung",
		questionType4: "Der Rest",
		place_question: "Ihre Frage",
		message_question: "Bitte geben Sie eine Frage ein",
		submit_question: "Einreichung von Fragen",
		label_phone: "Mobiltelefonnummer",
		place_phone: "Bitte geben Sie Ihre Mobiltelefonnummer ein",
		message_phone: "Bitte geben Sie Ihre Mobiltelefonnummer ein",
		label_password: "Kryptographisch",
		place_password: "Bitte geben Sie Ihr Passwort ein",
		message_password: "Bitte geben Sie Ihr Passwort ein",
		label_confirmPassword: "Bestätigen Sie Ihr Passwort",
		place_confirmPassword: "Bitte geben Sie Ihr Passwort erneut ein",
		message_confirmPassword: "Bitte geben Sie Ihr Passwort erneut ein",
		label_captcha: "CAPTCHA, eine Art von Challenge-Response-Test (Computertechnik)",
		place_captcha: "Bitte geben Sie den Verifizierungscode ein",
		message_captcha: "Bitte geben Sie den Verifizierungscode ein",
		get_captcha: "CAPTCHA abrufen",
		label_inviteId: "Einladungscode",
		place_inviteId: "Einladungscode (optional)",
		to: "Bis",
		start_time: "Startzeit",
		end_time: "Endzeitpunkt",
		label_cardNumber: "Bescheinigungsnummer",
		place_cardNumber: "Bitte geben Sie Ihre ID-Nummer ein",
		message_cardNumber: "Bitte geben Sie Ihre ID-Nummer ein",
		label_cardMain: "Vorderseite des Dokuments",
		message_cardMain: "Bitte geben Sie die Vorderseite des Dokuments ein",
		label_cardBack: "Rückseite eines Dokuments",
		message_cardBack: "Bitte geben Sie die Rückseite des Dokuments ein",
		confirm_modify: "Bestätigung der Änderungen",
		label_realName: "Vollständiger Name",
		place_realName: "Bitte geben Sie Ihren Namen ein",
		message_realName: "Bitte geben Sie Ihren vollständigen Namen ein",
		label_firstName: "Familienname",
		place_firstName: "Bitte geben Sie Ihren Nachnamen ein",
		message_firstName: "Bitte Nachname eingeben",
		label_lastName: "Name (einer Sache)",
		place_lastName: "Bitte geben Sie Ihren zweiten Vornamen ein",
		message_lastName: "Bitte geben Sie einen Namen ein",
		label_birthday: "Geburtsdatum",
		place_birthday: "Bitte geben Sie Ihr Geburtsdatum ein",
		message_birthday: "Bitte geben Sie Ihr Geburtsdatum ein",
		place_nowPsd: "Bitte geben Sie Ihr aktuelles Passwort ein",
		message_nowPsd: "Bitte geben Sie Ihr aktuelles Passwort ein",
		place_newPsd: "Bitte geben Sie ein neues Passwort ein",
		message_newPsd: "Bitte geben Sie ein neues Passwort ein",
		place_crmPsd: "Bitte bestätigen Sie das neue Passwort",
		message_crmPsd: "Bitte bestätigen Sie das neue Passwort",
		label_breed: "Sortiment",
		place_breed: "Bitte geben Sie eine Art ein",
		label_phase: "(coll.) durchfallen (ein Schüler)",
		label_buyPrice: "Ankaufspreis",
		label_sellPrice: "Verkäuferpreis",
		label_do: "Wegen",
		label_height: "Größer als",
		label_low: "Niedriger sein als",
		label_equal: "Oder gleich",
		labelWalletName: "Name der Brieftasche",
		messageWalletName: "Bitte geben Sie Ihren Brieftaschennamen ein",
		placeWalletName: "Bitte geben Sie Ihren Brieftaschennamen ein",
		labelWalletAddress: "Adresse der Brieftasche",
		messageWalletAddress: "Bitte geben Sie Ihre Brieftaschenadresse ein",
		placeWalletAddress: "Bitte geben Sie Ihre Brieftaschenadresse ein",
		labelAmount: "Geldsumme",
		messageAmount: "Bitte geben Sie den Betrag ein",
		placeAmount: "Bitte geben Sie den Betrag ein",
		placeTimeLimit: "Bitte geben Sie ein Zeitlimit ein",
		messageTimeLimit: "Bitte geben Sie ein Zeitlimit ein",
	},
	pay: {
		title: "Leichter Zugang zu Geldmitteln",
		p1: "Eine Vielzahl gängiger Zahlungskanäle, Unterstützung für schnelle Einzahlungen und Abhebungen",
		p2: "Einige Zahlungsarten sind in Ihrem Land möglicherweise nicht verfügbar.",
		p3: "Willkommen bei den Dienstleistungen von HKDFINANC",
		btn: "Jetzt ein Konto eröffnen und handeln",
	},
	header: {
		tip1: "Leveraged Trading ist ein komplexes Finanzprodukt mit einem hohen Risiko für schnelle Verluste.",
		btn1: "Handelsplattform",
		btn2: "Abmelden",
		btn3: "Sich anmelden",
		BigUint64Array: "Jetzt handeln",
	},
	footer: {
		help: "Kontakt",
		tip1: "Folgen Sie unserer Gemeinschaft",
		tip2: "Bitte beachten Sie, dass die HKDFINANC keine offizielle Telegram-Gruppe eingerichtet hat und dass jede Telegram-Gruppe, die im Namen der HKDFINANC gegründet wird, vermutlich eine Fälschung ist.",
		tip3: "RISIKOWARNUNG: Der Handel kann zum Verlust Ihres gesamten Kapitals führen. Der Handel mit OTC-Derivaten ist nicht für jedermann geeignet. Bitte lesen Sie unsere rechtlichen Dokumente sorgfältig durch, bevor Sie unsere Dienste in Anspruch nehmen, und vergewissern Sie sich, dass Sie die damit verbundenen Risiken vollständig verstehen, bevor Sie handeln. Sie besitzen oder halten keine der zugrunde liegenden Vermögenswerte.",
		tip4: "HKDFINANC gibt keine Ratschläge, Empfehlungen oder Meinungen zum Kauf, Halten oder Verkauf ab. Alle von uns angebotenen Produkte sind OTC-Derivate, die auf globalen Vermögenswerten basieren. Alle von HKDFINANC erbrachten Dienstleistungen basieren ausschließlich auf der Ausführung von Handelsaufträgen.",
		tip5: "HKDFINANC ist eine Geschäftsmarke, die von einer Reihe von Unternehmen gemeinsam genutzt und von den folgenden Unternehmen betrieben wird:",
		tip6: "HKDFINANC International Ltd ist der Emittent der Finanzprodukte, die auf dieser Website beschrieben werden oder zur Nutzung zur Verfügung stehen. HKDFINANC International Ltd ist von der Financial Services Commission (FSC) von Mauritius unter der Lizenznummer GB20025791 zugelassen und wird von ihr reguliert; ihr eingetragener Sitz ist 6 St Denis Street, 1st Floor River Court, Port Louis 11328, Mauritius",
		tip7: "HKDFINANC Global Pty Ltd ist unter der ABN 90 149 011 361 und der Australian Financial Services Licence (AFSL) Nummer 398528 registriert.",
		tip8: "HKDFINANC Holding Ltd ist von der Cayman Islands Monetary Authority (CIMA) unter der SIB-Lizenznummer 1612446 zugelassen und unterliegt deren Aufsicht.",
		tip9: "Die Informationen auf dieser Website sind nicht für Einwohner der Vereinigten Staaten von Amerika, Kanadas, Japans oder Neuseelands bestimmt. Sie sind auch nicht für die Nutzung durch Personen in Ländern oder Gerichtsbarkeiten gedacht, in denen ihre Veröffentlichung oder Nutzung gegen die örtlichen Gesetze oder Vorschriften verstoßen würde. Bitte beachten Sie, dass Englisch die Hauptsprache unserer Dienstleistungen und die rechtsverbindliche Sprache aller unserer Vertragsdokumente ist. Übersetzungen in andere Sprachen dienen nur als Referenz und im Falle von Abweichungen zwischen der englischen und der chinesischen Version ist die englische Version maßgebend.",
		tip10: "Sichere Verschlüsselung der Kommunikation durch SSL. Copyright © HKDFINANC, Alle Rechte vorbehalten.",
		link1: "Datenschutzbestimmungen",
		link2: "Erklärung zur Produktoffenlegung",
		link3: "Verfahren für Reklamationen",
		link4: "Erklärung zur Offenlegung von Risiken",
		link5: "Kundenvereinbarung",
		toTop: "Sticky (eines Threads in einem Internetforum usw.)",
	},
	nav: {
		tit1: "Angebote",
		tit2: "Geschäfte",
		tit3: "Investition Lernen",
		tit4: "Über uns",
		nav1: "Devisen",
		nav2: "Exponenten",
		nav3: "Ware",
		nav4: "Aktie (Markt)",
		nav5: "Handelsplattform",
		nav6: "Handelsstrategie",
		nav7: "Handelsperspektiven",
		nav8: "Finanzkalender",
		nav9: "Nachrichten in Echtzeit",
		nav10: "Echtzeit-Kurse",
		nav11: "Gefühlsindex",
		nav12: "Risikomanagement",
		nav13: "Vertragsspezifikationen",
		nav14: "Einführung in das Investieren",
		nav15: "Investitionseinblick",
		nav16: "Academy",
		nav17: "Über HKDFINANC",
		nav18: "Auszeichnungen und Ehrungen",
		nav19: "Medienzentrum",
		nav20: "Finanzielle Sicherheit",
		nav21: "Gebühren und Kosten",
		nav22: "Affiliates",
		nav23: "Kontakt",
		nav24: "Allgemeine Probleme",
		nav25: "Hilfe-Center",
	},

	city: {
        albania: "Albania",
        algeria: "Argelia",
	    argentina: "Argentina",
	    armenia:  "Armenia",
	    australia: "Australia",
	    pakistan: "Pakistán",
        austria:  "Österreich",
	    bahrain: "Bahréin",
	    belgium: "Bélgica",
	    bosnia_and_Herzegovina: "abreviatura de Bosnia-Herzegovina",
	    brazil: "Brasileño",
	    brunei: "Brunei Darussalam, sultanato independiente en el noroeste de Borneo",
	    bulgaria: "Bulgaria",
	    cambodia: "Camboya",
		canada: "Canadiense",
		cameroon: "Camerún",
		chile: "Chile",
		colombia: "Columbia (Distrito de, o Universidad, etc.)",
		costa_Rica: "Costa Rica",
		croatia: "República de Croacia (1991-)",
		cyprus: "Chipre",
		czech_Republic: "República Checa (desde 1993)",
		denmark: "Dinamarca",
		dominican_Republic: "República Dominicana",
		egypt: "Egipto",
		estonia: "Estonia",
		ethiopia: "Etiopía",
        finland : "Suomi",
		france: "Francés",
		georgia: "Georgia (país)",
		germany: "Alemán",
		ghana: "Ghana, África Occidental",
		greece: "Grecia",
		guyana: "Guyana",
		honduras: "Honduras",
		hong_Kong_China: "Hong Kong, China",
		hungary: "Hungría",
		iceland: "Islandia",
		ireland: "Irlandés",
		italy: "Italia",
		india: "India",
		indonesia: "Indonesia",
		israel: "Israel",
		iran: "Irán",
		iraq: "Iraqi",
		japan: "Japonés",
		kazakstan: "Kazajstán",
		kenya: "Kenia",
		korea: "Corea del Sur (República de Corea)",
		kuwait: "Kuwait",
		kyrgyzstan:"Kirguistán",
		laos:"Laos",
		latvia:"Letonia",
		lithuania:"La república lituana, antigua república soviética báltica",
		luxembourg:"Luxemburgo",
		macao_China:"Macao, China",
		macedonia:"Macedonio",
		malaysia:"Malasia",
		malta:"Maltés",
		mexico:"México",
		moldova:"República de Moldavia, antigua república soviética fronteriza con Rumanía",
		monaco:"Mónaco",
		mongolia:"Mongolia",
		montenegro:"Montenegro",
		morocco:"Marruecos",
		myanmar:"Myanmar (o Birmania)",
		netherlands:"Países Bajos",
		new_Zealand:"Nueva Zelanda",
		nepal:"Nepalí",
		nigeria:"Nigeria, África Occidental",
		norway:"Noruega",
		oman:"Omán",
		palestine:"Palestina",
		panama:"Panamá",
		paraguay:"Paraguay",
		peru:"Peruano",
		philippines:"Filipinas",
		poland:"Polaco",
		portugal:"Portugal",
		puerto_Rico:"Puerto Rico",
		qatar:"Doha",
		romania:"Rumanía",
		russia:"Georgia",
		republic_of_Trinidad_and_Tobago:"Trinidad y Tobago",
		rwanda:"Ruanda",
		saudi_Arabia:"Arabia Saudí",
		serbia:"Serbia",
		singapore:"Singapur",
		slovakia:"Eslovaquia",
		slovenia:"Eslovenia",
		south_Africa:"Sudáfrica",
		spain:"Español",
		sri_Lanka:"(antiguamente) Ceilán",
		sweden:"Suecia",
		switzerland:"Suiza",
		taiwan_China:"China-Taiwán",
		tajikistan:"Tayikistán",
		tanzania:"Tanzania",
		thailand:"Tailandia",
		turkey:"Estambul",
		turkmenistan:"Turkmenistán",
		ukraine:"Bielorrusia",
		united_Arab_Emirates:"EAU",
		united_Kingdom:"Reino Unido de Gran Bretaña e Irlanda del Norte",
		united_States:"Estados Unidos de América",
		uzbekistan:"Uzbekistán",
		venezuela:"Venezuela",
		vietnam:"Vietnam",
		afghanistan:"Afganistán",
		angola:"Angola",
		azerbaijan:"Azerbaiyán, ",
		bangladesh:"Bangladesh",
		belarus:"Bielorrusia",
		belize:"Belice",
		benin:"Benín",
		bhutan:"Bután",
		bolivia:"Bolivia",
		botswana:"Botsuana",
		british_Virgin_Islands:"Islas Vírgenes Británicas",
		burkina_Faso:"Burkina Faso, África Occidental",
		burundi:"Burundi",
		cape_Verde:"Cabo Verde",
		cayman_Islands:"Islas Caimán",
		central_African_Republic:"República Centroafricana",
		chad:"Chad",
		comoros:"Comoras",
		the_Republic_of_the_Congo:"Congo (Brazzaville)",
		democratic_Republic_of_the_Congo:"Congo (República Democrática del)",
		djibouti:"Yibuti",
		ecuador:"Ecuador",
		el_Salvador:"El Salvador",
		equatorial_Guinea:"Guinea Ecuatorial",
		eritrea:"Eritrea",
		fiji:"Fiyi ",
		gabon:"Gabonesa",
		gambia:"Gambia",
		greenland:"Groenlandia",
		guatemala:"Guatemala",
		guinea:"Guinea",
		haiti:"Haití",
		isle_of_Man:"Isla de Mann",
		cote_d_Ivoire:"Costa de Marfil en África Occidental",
		jamaica:"Jamaica",
		jordan:"jordan",
		lebanon:"Hostilidades",
		lesotho:"Lesotho",
		liberia:"Liberia",
		libya:"Libia",
		madagascar:"Madagascar",
		malawi:"Malawi",
		maldives:"Maldivas",
		mali:"Malí, África Occidental",
		mauritania:"Mauritania",
		mauritius:"Mauricio",
		mozambique:"Mozambique",
		namibia:"Namibia",
		nicaragua:"Nicaragua",
		republic_of_Niger:"Níger",
		north_Korea:"Dinastía coreana Joseon o Chosun 1392-1910",
		reunion:"Reunión (isla del océano Índico, departamento francés de ultramar)",
		san_Marino:"San Marino",
		senegal:"Senegal",
		sierra_Leone:"Sierra Leona",
		somalia:"Mogadiscio",
		sudan:"sultán",
		suriname:"Surinam",
		eswatini:"Suazilandia",
		syria:"Siria",
		togo:"Togo, África Occidental",
		tonga:"Tonga,",
		tunisia:"Túnez, capital de Túnez",
		united_States_Virgin_Islands:"Islas Vírgenes de Estados Unidos (USVI)",
		uganda:"Uganda",
		uruguay:"Uruguay",
		vatican:"Vaticano",
		yemen:"Yemení",
		yugoslavia:"Yugoslavia, 1943-1992",
		zambia:"Seychelles",
		zimbabwe:"Zimbabue",
		china:"China",
    }
};
